<script setup lang="ts">
import type Zone from "@/models/entity/zone";
import {useEditStore} from "@/stores/edit-store";
import { onBeforeMount, onMounted, computed, onUpdated, watchEffect, watch } from "vue";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import type ModelTextureFile from "@/models/entity/model-texture-file";
import type DPoint from "@/models/entity/d-point";
import { useDeviceStore } from "@/stores/device-store";
import { Euler, LoadingManager, MathUtils, MeshBasicMaterial, MeshNormalMaterial, Object3D, ObjectLoader, Vector3 } from "three";
import CommonUtils from "@/utils/common-util";
import { Mesh, MeshGeometry } from "pixi.js";
import { RectAreaLight } from "three";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";
import {VertexNormalsHelper} from "three/examples/jsm/helpers/VertexNormalsHelper";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import type GrpMember from "@/models/entity/grp_member";
import { useSettingStore } from "@/stores/setting-store";

const editStore = useEditStore();
const deviceStore = useDeviceStore();
const settingStore = useSettingStore();

const props = defineProps<{
  baseData: Zone | null
}>();

const zoneData = computed(() => {
  return props.baseData;
});

// const grpInfo = computed(() => {
//   return editStore.selectGroupInfo as CtrlGrp;
// });

const grpInfo = computed(() => {
  return editStore.selectGroupInfo as CtrlGrp;
});
// const selectZoneData = computed(() => {
//   loadModel(zoneData.value?.modelFileSeq as number);
//   return editStore.selectSpaceInfo;
// });

watch(grpInfo, () => {
  loadGrp();
});

onUpdated(() => {
  console.log("modelView Updated :::::");
});

const selectModelInfo = computed(() => {
  return editStore.selectSpaceInfo?.modelFileGrp;
});

const loadingManager = new LoadingManager();

const loader = new ObjectLoader(loadingManager);
const fbxloader = new FBXLoader(loadingManager);

const x = document.getElementById("editArea")?.clientWidth;
const y = document.getElementById("editArea")?.clientHeight;

// console.log(x, y);
  
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 75, (x as number) / (y as number), 1, 10000 );

editStore.setThreeCamera(camera);

const renderer = new THREE.WebGLRenderer();
renderer.setPixelRatio( window.devicePixelRatio );
renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.toneMapping = THREE.LinearToneMapping;
// renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer.physicallyCorrectLights = true;

const controls = new OrbitControls( camera, renderer.domElement );

editStore.setThreeCameraControl(controls);

controls.update();


function resizeRendererToDisplaySize(renderer: THREE.WebGLRenderer) {
  const canvas = renderer.domElement;
  const pixelRatio = window.devicePixelRatio;
  const width  = canvas.clientWidth  * pixelRatio | 0;
  const height = canvas.clientHeight * pixelRatio | 0;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

function animate() {
  requestAnimationFrame(animate);

  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }

  controls.update();
  renderer.render( scene, camera );
}

function loadLight(dpoints: DPoint[],  worldPosition: THREE.Vector3){

  const Group = new THREE.Group();

  dpoints.map(v => {
    // console.log(v.dpointName);
    const boxSize = editStore.UzoneSizeData.y;
    const devTypes = deviceStore.lightList;

    const targetDevice = devTypes.find(dt => {
      if(dt.devTypeCode === v.devTypeCode){
        return dt;
      }
    });

    if(targetDevice?.devClsfyCode === "LD"){
      const deviceType = deviceStore.lightList.find(d => d.devTypeCode === v.devTypeCode);
      const lightGroup = new THREE.Group();

      // const width = deviceType?.horizSize;
      // const height = deviceType?.height;

      const horiz = deviceType?.horizSize;
      const verti = deviceType?.vertiSize;
      const height = deviceType?.height;

      if(deviceType?.shapeType === "SQUARE"){
        const geo = new THREE.BoxGeometry(horiz as number / 100,  height as number / 100, verti as number / 100);
        const material = new THREE.MeshLambertMaterial();
        const cube = new THREE.Mesh(geo, material);
        lightGroup.add(cube);
      } else {
        const geo = new THREE.CylinderGeometry(horiz as number / 100, horiz as number / 100, height as number /100);
        const material = new THREE.MeshLambertMaterial();
        const cube = new THREE.Mesh(geo, material);
        lightGroup.add(cube);
      }

      // const geo = new THREE.BoxGeometry(horiz as number / 100, verti as number / 100, height as number / 100);
      // const material = new THREE.MeshBasicMaterial();
      // const cube = new THREE.Mesh(geo, material);
      // lightGroup.add(cube);

      // const light = new THREE.PointLight(0xffffff, 1, horiz);

      const light = new RectAreaLight(0xffffff, 1, horiz as number / 100, verti as number / 100);
      light.lookAt(0, -1, 0);

      // const helper = new RectAreaLightHelper(light);

      // scene.add(helper);

      lightGroup.add(light);
    
      lightGroup.position.set(v.centerX as number, v.centerY as number, v.centerZ as number);
      // lightGroup.position.set(v.centerX as number, 30, v.centerZ as number);
      // console.log(Number(v.rotate) / 180);

      lightGroup.rotateY(Math.PI * Number((Number(v.rotate) / 180)));
      lightGroup.name = (v.dpointName as string);
      lightGroup.userData.dpointSeq = v.dpointSeq;
      lightGroup.userData.dpointType = "light";
      // lightGroup.scale.set(6, 6, 6);
      Group.add(lightGroup);
    
    } else if(targetDevice?.devClsfyCode === "SD"){
      const deviceType = deviceStore.lightList.find(d => d.devTypeCode === v.devTypeCode);
      const sensorGroup = new THREE.Group();

      const geometry1 = new THREE.TetrahedronGeometry(boxSize / 50, 0);

      const material1 = new THREE.MeshLambertMaterial( { color: 0x0000ff, wireframe: false } );

      const mesh1 = new THREE.Mesh( geometry1, material1 );
      
      sensorGroup.add(mesh1);
      
      sensorGroup.position.set(v.centerX as number, v.centerY as number, v.centerZ as number);
      sensorGroup.name = v.dpointName as string;
      sensorGroup.userData.dpointSeq = v.dpointSeq;
      sensorGroup.userData.dpointType = "sensor";
      Group.add(sensorGroup);
      // scene.add( mesh1 );

    } else if(targetDevice?.devClsfyCode === "GW"){
      const deviceType = deviceStore.lightList.find(d => d.devTypeCode === v.devTypeCode);
      const GatewayGroup = new THREE.Group();

      const width = deviceType?.horizSize;
      const height = deviceType?.height;

      const geo = new THREE.CylinderGeometry(boxSize / 50, boxSize / 50, boxSize / 50);
      const material = new THREE.MeshLambertMaterial({color: 0x00ff00});
      const gate = new THREE.Mesh(geo, material);
      GatewayGroup.add(gate);
    
      GatewayGroup.position.set(v.centerX as number, v.centerY as number, v.centerZ as number);
      GatewayGroup.name = v.dpointName as string;
      GatewayGroup.userData.dpointSeq = v.dpointSeq;
      GatewayGroup.userData.dpointType = "gateway";
      // lightGroup.position.set(v.centerX as number, 30, v.centerZ as number);

      // lightGroup.scale.set(6, 6, 6);
      Group.add(GatewayGroup);
    }
    
  });

  Group.position.set(-worldPosition.x, -worldPosition.y, -worldPosition.z);
  // Group.scale.set(0.16, 0.16, 0.16);
  console.log(Group);

  Group.name = "lightGroup";
  scene.add(Group);

  loadGrp();
  // console.log(Group);
}

function loadGrp(){
  console.log("loadGrp ::::::");
  console.log(grpInfo);
  console.log(scene);

  const lightGroupInfo = scene.getObjectByName("lightGroup");
  // lightGroupInfo.getObjectByName("lightGroup")
  console.log(lightGroupInfo);
  const memberList = grpInfo.value?.members as GrpMember[];

  if(memberList !== undefined){
    lightGroupInfo?.children.map(light => {
      let resetMaterial = new THREE.MeshLambertMaterial({color: 0x000000});

      if(light.userData.dpointType === "light"){
        resetMaterial =  new THREE.MeshLambertMaterial({color: 0xffffff});
      } else if(light.userData.dpointType === "sensor"){
        resetMaterial =  new THREE.MeshLambertMaterial({color: 0x0000ff});
      } else if(light.userData.dpointType === "gateway"){
        resetMaterial =  new THREE.MeshLambertMaterial({color: 0x00ff00});
      }
      const lightMesh = light.children[0] as THREE.Mesh;
      lightMesh.material = resetMaterial;
    });

    for(let i = 0; i < memberList.length; i++){
      lightGroupInfo?.children.forEach(light => {
        if(light.userData.dpointSeq === memberList[i].dpointSeq){
          const grpMaterial = new THREE.MeshBasicMaterial({color: 0xff0000});
          const lightMesh = light.children[0] as THREE.Mesh;
          lightMesh.material = grpMaterial;
        }
      });
    }
  }
}

function parentVisible(parentObject: THREE.Group){
  if(parentObject instanceof THREE.Group){
    parentObject.visible = true;
    if(parentObject.parent instanceof THREE.Group){
      parentVisible(parentObject.parent);
    }
  }
}

function traverseObject(object: THREE.Group){
  object.traverse(mesh => {
    if(mesh instanceof THREE.Group){
      mesh.visible = false;
      if(mesh.name === selectModelInfo.value as unknown as string){
        mesh.visible = true;
        parentVisible(mesh.parent as THREE.Group);
        return;
      }
    }
  });
}

async function loadModel(){
  while(scene.children.length > 0){ 
    scene.remove(scene.children[0]); 
  }



  // const url = URL.createObjectURL(editStore.selectModelBinary as Blob);

  // const modelFileSeq = editStore.selectZoneInfo?.modelFileSeq;
  // const modelFile = editStore.selectZoneInfo?.modelFile;
      
  // const textureList = [] as {name: string, data: unknown}[];
      
  // for(let i = 0; i < (modelFile?.textureFiles as ModelTextureFile[]).length; i++){
  //   const data = (modelFile?.textureFiles as ModelTextureFile[])[i];
  //   const result = await editStore.getModelTextureFileName(modelFileSeq as number, data.textureFileSeq as number, data.textureFileName as string);
  //   textureList.push(result);
  // }
      
  // console.log(textureList);
      
  // const imagecheck = /(\.gif|\.jpg|\.jpeg|\.png)$/i;
      
  // loadingManager.setURLModifier((url: string) => {
  //   console.log(url);
  //   if(imagecheck.test(url) === false){
  //     return url;
  //   }
          
  //   const newImage = textureList.find(v => {
  //     if(url.includes(v.name) === true){
  //       return v.data;
  //     }
  //   });
      
      
  //   url = `/v1/modelfiles/${modelFileSeq}/texturefiles/${newImage?.name}`;
  //   console.log(url);
      
  //   return url;
  // });
      
  // loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
  //   console.log( "Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files." );
  // };

  // const light = new THREE.HemisphereLight( 0xffffff ); // soft white light
  // scene.add( light );
  
  const object = new THREE.Group;
  if(editStore.localModelInfoList?.length <= 0){
    object.copy(editStore.selectModelInfo as THREE.Group, true);
  } else {
    let isExist = false;
    for(let i = 0; i < editStore.localModelInfoList.length; i++){
      let zoneInfo;
      if(editStore.selectSiteInfo?.siteClsfyCode === "A001"){
        zoneInfo = editStore.selectSpaceInfo;
      } else {
        zoneInfo = editStore.selectZoneInfo;
      }

      if(editStore.localModelInfoList[i].zoneSeq === zoneInfo?.zoneSeq){
        object.copy(editStore.localModelInfoList[i].modelFile as THREE.Group, true);
        isExist = true;
      }
    }

    if(isExist === false){
      object.copy(editStore.selectModelInfo as THREE.Group, true);
    }
    
  }

  // editStore.setSpaceModelSizePosition(object as THREE.Object3D);

  // if(editStore.selectSpaceInfo?.zoneName?.includes("1F") === true){
  //   object.traverse((mesh) => {
  //     if(mesh instanceof THREE.Group){
  //       console.log(mesh.name);
  //       if(mesh.name === "2F"){
  //         mesh.visible = false;
  //       } 
  //     }
  //   });
  // }

  console.log(selectModelInfo.value, object);

  if(selectModelInfo.value !== "" && selectModelInfo.value !== null){
    traverseObject(object);

  } else {
    object.traverse(mesh => {
      mesh.visible = true;
    });
  }


  // object.traverse((mesh) => {
    
  //   if(mesh instanceof THREE.Group){
  //     mesh.visible === false;
  //     if(mesh.uuid as string === selectModelInfo.value as unknown as string){
  //       mesh.visible = true;

  //       if(mesh.parent instanceof THREE.Group){
  //         mesh.parent.visible === true;
  //       }

  //       break;
  //     }
  //   }
    
    
    
  //   // const floorCheck = editStore.selectSpaceInfo?.zoneName?.split("_")[0];

  //   // if(mesh.name.includes(floorCheck as string) === false && mesh.name !== ""){
  //   //   mesh.visible = false;

 
  //   // }

  //   // console.log(typeof mesh);
  //   // let isInGroup = false;
  //   // mesh.children.map(v => {
  //   //   if(v.name !== editStore.selectSpaceInfo?.zoneName){
  //   //     isInGroup = true;
  //   //   }
  //   // });
      
  //   // if(isInGroup === false){
  //   //   mesh.visible = false;
  //   // }
    
  // });

  // 여기부터

  // let selectObject = new THREE.Group;

  // object.traverse(mesh => {
  //   if(mesh instanceof THREE.Group){
  //     if(mesh.visible === true){
  //       selectObject = mesh;
  //     }
  //   }
  // });

  // editStore.setSelectModelAreaInfo(selectObject);


  if(object !== null){
    const light1 = new THREE.AmbientLight(0xffffff);
    light1.position.set(editStore.UzoneSizeData.x /4, editStore.UzoneSizeData.y, editStore.UzoneSizeData.z/4);
    scene.add(light1);
 
    object.position.set(-editStore.UzonePositionData.x, -editStore.UzonePositionData.y, -editStore.UzonePositionData.z);
    // console.log(object.position);
    
    camera.position.set(editStore.UzoneSizeData.x, editStore.UzoneSizeData.y, editStore.UzoneSizeData.z );
    camera.lookAt(object.position);
    camera.updateProjectionMatrix();

    scene.add(object);


    if(settingStore.userSetting.ModelHelper === true){
      const helper = new THREE.BoxHelper(object);
      scene.add(helper);
    }
  
    // object.traverse(mesh => {
    //   const test = new THREE.BoxHelper(mesh,  Math.random() * 0xffffff);
    //   scene.add(test);
    // }); 
 
    // console.log(object);
    // 테스트 용 큐브
    // const geo = new THREE.BoxGeometry(20, 20, 20);
    // const material = new THREE.MeshBasicMaterial();
    // const cube = new THREE.Mesh(geo, material);
    // cube.position.set(editStore.UzonePositionData.x, editStore.UzonePositionData.y, editStore.UzonePositionData.z);
    // scene.add(cube);

    // const cube2 = new THREE.Mesh(geo, material);
    // cube2.position.set(editStore.selectModelPosition.x, editStore.selectModelPosition.y, editStore.selectModelPosition.z);
    // scene.add(cube2);

    // const helper = new THREE.BoxHelper(object);
    // scene.add(helper);
  
    // const lighthelper = new THREE.HemisphereLightHelper( light1, 5 );
    // scene.add(lighthelper);

    loadLight(editStore.selectSpaceInfo?.dpoints as DPoint[], editStore.UzonePositionData as THREE.Vector3);
  }

  // loader.parse(editStore.selectModelJSON, (object) => {
  //   console.log(scene, object);

  //   // const light = new THREE.HemisphereLight( 0xffffff ); // soft white light
  //   // scene.add( light );

  //   const light1 = new THREE.AmbientLight(0xffffff);
  //   scene.add(light1);
 
  //   object.position.set(-editStore.UzonePositionData.x, -editStore.UzonePositionData.y, -editStore.UzonePositionData.z);
    
  //   camera.position.set(editStore.UzoneSizeData.x, editStore.UzoneSizeData.y, editStore.UzoneSizeData.z );
  //   camera.lookAt(object.position);
  //   camera.updateProjectionMatrix();

  //   scene.add(object);
  //   // 테스트 용 큐브
  //   const geo = new THREE.BoxGeometry(20, 20, 20);
  //   const material = new THREE.MeshBasicMaterial();
  //   const cube = new THREE.Mesh(geo, material);
  //   scene.add(cube);


  // });

  // fbxloader.load(url, (object) => {
  //   console.log(scene, object);

  //   // const light = new THREE.HemisphereLight( 0xffffff ); // soft white light
  //   // scene.add( light );

  //   const light1 = new THREE.AmbientLight(0xffffff);
  //   scene.add(light1);
 
  //   object.position.set(-editStore.UzonePositionData.x, -editStore.UzonePositionData.y, -editStore.UzonePositionData.z);
    
  //   camera.position.set(editStore.UzoneSizeData.x, editStore.UzoneSizeData.y, editStore.UzoneSizeData.z );
  //   camera.lookAt(object.position);
  //   camera.updateProjectionMatrix();

  //   scene.add(object);
  //   // 테스트 용 큐브
  //   const geo = new THREE.BoxGeometry(20, 20, 20);
  //   const material = new THREE.MeshBasicMaterial();
  //   const cube = new THREE.Mesh(geo, material);
  //   scene.add(cube);

  //   console.log(object.toJSON());
  // });
}

onMounted(() => {
  renderer.setSize( x as number, y as number );
  document.getElementById("threeTest")?.appendChild( renderer.domElement );
  loadModel();
  animate();
});

</script>

<template>
  <div id="threeTest"  v-if="(zoneData?.modelFileSeq !== null || zoneData?.modelFile !== null || editStore.localModelInfoList.findIndex(v => v.zoneSeq === zoneData?.zoneSeq) !== -1)">
  </div>
  <div v-else :style="{textAlign: 'center'}">
      <div>
        등록된 모델이 존재하지 않습니다.
        <br />
        대표공간을 선택해 모델을 등록해주세요.
      </div>  
  </div>
</template>

<style>
    
</style>





