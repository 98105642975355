import { createRouter, createWebHashHistory, createWebHistory, createMemoryHistory } from "vue-router";

import LJMTestView from "@/views/ljm/LJMTestView.vue";
import DrawCanvasView from "@/views/ljm/DrawCanvasView.vue";
import QuasarTestView from "@/views/QuasarTestView.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import HelloEditor from "@/components/HelloEditor.vue";
import RightBar from "@/components/Temp/RightBar.vue";

import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/MainView.vue";
import SiteView from "@/views/SiteView.vue";
import SpaceLightComponent from "@/components/SpaceLight/SpaceLightComponent.vue";
import ScenarioComponent from "@/components/Scenario/ScenarioComponent.vue";
import SimulationComponent from "@/components/Simulation/SimulationComponent.vue";
import MaterialsComponent from "@/components/Materials/MaterialsComponent.vue";

// 임시 테스트용 zone Parameter json
import TmpZoneJson from "@/components/common/TmpZoneJson.vue";

const router = createRouter({
  //history: createWebHistory(import.meta.env.BASE_URL),  
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting

      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/ljm",
      name: "ljm",
      component: LJMTestView,
    },
    {
      path: "/draw-canvas",
      name: "DrawCanvasView",
      component: DrawCanvasView,
    },
    {
      path: "/quasar-test",
      name: "QuasarTestView",
      component: QuasarTestView,
      children: [
        {
          path: "hello",
          component: HelloWorld,
        },
        {
          path: "editor",
          components: {
            default: HelloEditor,
            RightBar,
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,     
      props: true,
    },
    {
      path: "/main",
      name: "main",
      component: MainView,      

      children: [
        {
          path: "/site",
          name: "site",
          component: SiteView,          
        },
        {
          path: "/main/lightmap",
          
          name: "lightmap",
          component: SpaceLightComponent,
        },
        {
          path: "/main/scenario",
          name: "scenario",
          component: ScenarioComponent,
        },
        {
          path: "/main/simulation",
          name: "simulation",
          component: SimulationComponent,
        },
        {
          path: "/main/materials",
          name: "materials",
          component: MaterialsComponent,
        },
      ],
    },
    {
      path: "/tmpZoneJson",
      name: "tmpZoneJson",
      component: TmpZoneJson
    }
  ],
});

export default router;
