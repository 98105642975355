import type { SensorType } from "@/global/enums";

class SensorDevice {  
  label: string;
  value: string;
  constructor(readonly id: number, readonly name: string, readonly sensorType: SensorType) {
    this.label = name;
    this.value = name;
  }
}

export default SensorDevice;