import type { NodeElementType, NodeType } from "@/global/enums";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import AbstractNodeConfig from "./abstract-node-config";

class ScenarioNodeConfig extends AbstractNodeConfig {
  nodeTypeByEvents?: NodeTypeByEvent[];  
  constructor(id: string, name: string, nodeType: NodeType, nodeElementType: NodeElementType,  w: number, h: number, inPortCount: number, outPortCount: number, description?: string, imageUrl?: string) {

    
    super(id, name, nodeType, nodeElementType, w, h, inPortCount, outPortCount, description, `ico_${nodeElementType}.png`);
  }
}

export default ScenarioNodeConfig;