import type INodeSetting from "./node-setting";

/**
 * Text 노드의 설정 클래스
 * @class
 */
class TextNodeSetting implements INodeSetting {
  
  public text;
  constructor(text: string) {

    this.text = text;
  }
}

export default TextNodeSetting;