import { defineStore } from "pinia";
import { computed } from "vue";
import type SiteSetting from "@/models/site-setting";
import type Site from "../models/site";
import CommonUtils from "@/utils/common-util";
import type ImageFile from "@/models/entity/image-file";
import Zone from "@/models/entity/zone";
import CZone from "@/models/entity/czone";
import type LoginInfo from "@/models/entity/login-info";

import tmpSite from "@/settings/tempSetting_v4.json";
import axios from "axios";

export const useSiteStore = defineStore("site", {
  state: () => ({
    siteList: [] as Array<Site>,
    selectSiteID: null as unknown as number,
    selectSiteInfo: {} as Site,
    siteWidth: 0 as number | undefined,
    siteHeight: 0 as number | undefined,
  }),
  getters: {
    getSiteList(state) {
      return state.siteList;
    },
    getSiteID(state) {
      return state.selectSiteID;
    },
    getSelectSiteInfo(state) {
      const result: Site = this.siteList.find(el => el?.siteSeq === this.selectSiteID) as Site;
      state.selectSiteInfo = result;
      
      return state.selectSiteInfo;
    },
    getSiteWidth(state) {
      return state.siteWidth;
    },
    getSiteHeight(state) {
      return state.siteHeight;
    },
  },
  actions: {
    async addSite(site: Site, imageFile: File) {
      // console.log("add site");
      // this.SiteList.push(site);
      console.log(site);
      let imageRes;
      if(imageFile !== undefined){
        imageRes = await this.apiServices.etcApiService.postImage(imageFile);
        
        console.log(imageRes);
        site.imageFileSeq = imageRes.imageFileSeq as number;
      } else {
        site.imageFileSeq = null;
      }
      
      delete site.imageFile;
      console.log(site);
      this.siteList.push(site);
      
      const siteRes = await this.apiServices.sitezoneApiService.AddSite(site);
      
      console.log(siteRes);
    },
    /**
    * 사이트 수정
    * @param site 
    * @param imageFile 
    * @returns 
    */
    async modifySite(site: Site, imageFile: File) {
      console.log(site);
      console.log("change site");
      
      if(site.zones){
        delete site["zones" as keyof Site];
      }
      
      if(imageFile.size !== 0){
        const imageRes = await this.apiServices.etcApiService.postImage(imageFile);
        console.log(imageRes);
        site.imageFileSeq = imageRes.imageFileSeq as number;
      } else {
        
        if(site.imageFile){
          delete site.imageFile;
        }
      }
      
      const res = this.apiServices.sitezoneApiService.modifySites(site);
      
      return res;
    },
    
    async getImages(imageFileSeq: number){
      const res = await this.apiServices.etcApiService.getImage(imageFileSeq);
      
      console.log(res);
    },
    
    async importRspaceApi(selectSite: Site, data: Zone){
      const sites = selectSite;
      let a = new Zone;
      a = Object.assign(data);
      sites.zones.push(a);
      
      const result = await this.apiServices.sitezoneApiService.addRspace(sites);
      
      
    },
    
    makeRzoneChildCzone(zone: Zone, user: LoginInfo){
      if(zone.rzone){
        if(zone.rzone.rangeOfFloors === ""){
          zone.rzone.rangeOfFloors = "B0-F0";
        }
        
        const overFloor = Number(zone.rzone.rangeOfFloors.split("-")[1].split("F")[1]);
        const underFloor = Number(zone.rzone.rangeOfFloors.split("-")[0].split("B")[1]);
        
        for(let i = overFloor - 1; i >= 0; i--){
          const czones = new Zone();
          czones.czone = new CZone(zone.siteSeq, i+1, "SF", "F");
          czones.area = zone.area;
          czones.boundsPath = "";
          czones.zoneName = `지상${i+1}층`;
          czones.zoneSeq = i+1 + Math.floor(Math.random() * 1000 + 100);
          czones.zoneTypeCode = "C";
          czones.regDate = new Date();
          czones.regUserId = 7;
          czones.modDate = new Date();
          czones.modUserId =7;
          czones.pzoneSeq = zone.zoneSeq;
          czones.vertiSize = zone.vertiSize;
          czones.horizSize = zone.horizSize;
          
          zone.childZones.push(czones);
        }
        
        for(let i = 0; i < underFloor; i++){
          const czones = new Zone();
          czones.czone = new CZone(zone.siteSeq, i+1, "SF", "F");
          czones.area = zone.area;
          czones.boundsPath = "";
          czones.zoneName = `지하${i+1}층`;
          czones.zoneSeq = i+1 + Math.floor(Math.random() * 1000 + 100);
          czones.zoneTypeCode = "C";
          czones.regDate = new Date();
          czones.regUserId = 7;
          czones.modDate = new Date();
          czones.modUserId =  7;
          czones.pzoneSeq = zone.zoneSeq;
          czones.vertiSize = zone.vertiSize;
          czones.horizSize = zone.horizSize;
          
          zone.childZones.push(czones);
        }
        
        console.log(overFloor, underFloor);
      }
      
      
    },
    
    setSiteID(siteId: number) {
      this.selectSiteID = siteId;
      
      const result: Site = this.siteList.find((el) => el?.siteSeq === this.selectSiteID) as Site;
      console.log(result);
      this.selectSiteInfo = result;
    },
    
    removeSite(siteId: number) {
      // const startIndex = this.siteList.findIndex((e) => e === siteId);
      // this.siteList.splice(startIndex, 1);
    },
    
    // 전체 사이트 정보를 불러온다.
    async loadSiteList() {
      // 실제 api
      const result = await this.apiServices.sitezoneApiService.getSiteList();
      this.siteList = result;

      // 테스트용 임시 api
      // const result = await axios.get("http://localhost:4848/tempData?siteSeq=43");
      // console.log(result);
      // this.siteList = result.data.site as Site[];
      // this.siteList = result;


      // this.siteList.push(tmpSite[0] as unknown as Site);
    },    
    
    setSiteWidth() {
      console.log(this.selectSiteInfo);
      const siteInfo = this.selectSiteInfo;
      
      console.log(siteInfo);
      
      this.siteWidth = siteInfo?.horizSize;
    },
    
    setSiteHeight() {
      const siteInfo = this.selectSiteInfo;
      
      this.siteHeight = siteInfo?.vertiSize;
    },
    
    load() {
      if (CommonUtils.isNullOrEmpty(this.selectSiteID)) {
        this.setSiteID(1);
        this.setSiteWidth();
        this.setSiteHeight();
      }
      
      console.log("Loaded Site-Module..");
    },
    
    // async fetchSites() {
    //   //const res = await this.rest.get(`/v1/sites`);
    
    //   //this.siteList = [];
    //   this.siteList = new Array<Site>();
    
    //   for (let i = 0; i < 2; i++) {
    //     const site = new Site();
    //     const id = i + 1;
    //     site.id = id;
    //     site.name = `테스트 사이트-${id}`;
    //     site.address = "서울시 서초구";
    //     site.description = `테스트 사이트-${id} 입니다.`;
    //     site.image_url =
    //       "https://www.officefind.co.kr/bbs/data/file/v2_bd_img/thumb-2040889828_7oW1O5qa_90de8655e9c8e8f79ab5857cf9067aa7650ddd1e_700x450.jpg";
    //     site.width = 300;
    //     //site.img = "";
    //     site.vertical = 600;
    //     site.height = 500;
    //     site.area = 100;
    //     site.azimuthAngle = 30;
    //     site.azimuthAngle = 30.5;
    //     site.mainStructure = "";
    //     //site.type = "주거";
    
    //     this.siteList.push(site);
    //   }
    // },
  },
});
