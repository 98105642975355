import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import CommonUtils from "@/utils/common-util";
import { Type } from "class-transformer";
import { NodeItem, AbtractSelectionNodeItemEvent } from "../info/node-item-info";
import type { IPackageNodeSetting } from "./node-setting";


/**
 * 시나리오 실행자 설정 클래스
 * @class
 */
class ScenarioExecutorNodeSetting implements IPackageNodeSetting {
  public events: ScenarioExecutorEvent[] = [];

  constructor(nodeTypeByEvents: NodeTypeByEvent[]) {

    if(CommonUtils.isNullOrEmpty(nodeTypeByEvents))
      return;

    for(const nodeTypeByEvent of nodeTypeByEvents) {
      this.events.push(new ScenarioExecutorEvent(nodeTypeByEvent));
    }   
  }

  getNodeItems(): NodeItem[] {
    
    this.events.sort((a, b) => {
      return a.seq - b.seq;
    });

    const checkedEvents = this.events.filter(e => e.isChecked);

    const nodeItems: NodeItem[] = [];

    for (const event of checkedEvents) {

      const nodeItemId = event.nodeTypeByEvent.eventCode.toString();
      const nodeItem = new NodeItem(nodeItemId, event.nodeTypeByEvent.eventName ?? "", "");
      nodeItems.push(nodeItem);
    }

    return nodeItems;
  }  
}

class ScenarioExecutorEvent extends AbtractSelectionNodeItemEvent {
  seq: number;
  isChecked = false;

  constructor(nodeTypeByEvent: NodeTypeByEvent) {    
    super(nodeTypeByEvent);

    this.seq = nodeTypeByEvent?.sortOrder ?? -1;
  }

  get name() {
    return this.nodeTypeByEvent.eventName ?? "";
  }
}

export default ScenarioExecutorNodeSetting;
export { ScenarioExecutorEvent };