<script setup lang="ts">
import { inject, ref } from "vue";
import ScenarioInfoComponent from "@/components/Scenario/RightInfoForm/ScenarioInfoComponent.vue";
import ScenarioNodeSettingComponent from "@/components/Scenario/RightInfoForm/ScenarioNodeSettingComponent.vue";
import ProviderComponent from "@/components/Scenario/RightInfoForm/ProviderComponent.vue";
import BranchComponent from "@/components/Scenario/RightInfoForm/BranchComponent.vue";
import ActionComponent from "@/components/Scenario/RightInfoForm/ActionComponent.vue";
import TargetComponent from "@/components/Scenario/RightInfoForm/TargetComponent.vue";
import LinkComponent from "@/components/Scenario/RightInfoForm/LinkComponent.vue";
import { useNodeEditorStore } from "@/stores/node-editor-store";

const nodeEditorStore = useNodeEditorStore();
const isRightPageExpanded = ref(false);

const emitData = defineEmits(["expaned"]);

function RightPageExpandedToggle() {
  isRightPageExpanded.value = !isRightPageExpanded.value;
  emitData("expaned");
}
</script>

<template>
  <q-scroll-area class="fit" :dark="false">
    <div v-if="isRightPageExpanded" class="column ScenarioRightComponent">
      <q-btn square class="expansionIcon" :align="'left'" :unelevated="true" @click="RightPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_l.png'" />
      </q-btn>
      <div class="borderTemp"></div>
    </div>

    <div v-else class="column ScenarioRightComponent">
      <q-btn square class="expansionIcon" :align="'left'" :unelevated="true" @click="RightPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_r.png'" />
      </q-btn>
      <!-- TODO : 추후 분기처리 필요 -->
      <ScenarioInfoComponent></ScenarioInfoComponent>
      <ScenarioNodeSettingComponent></ScenarioNodeSettingComponent>

      <!-- <ProviderComponent></ProviderComponent>
      <BranchComponent></BranchComponent>
      <ActionComponent></ActionComponent>
      <TargetComponent></TargetComponent>
      <LinkComponent></LinkComponent> -->
    </div>
  </q-scroll-area>
</template>

<style lang="scss">
.ScenarioRightComponent {
  background-color: #f2f4fb;
  color: black;
  .expansionIcon {
    height: 20px;
    min-height: 20px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    font-size: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .borderTemp {
    height: 60px;
    min-height: 60px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
  }

  .q-field--outlined .q-field__control {
    height: 30px;
  }

  .q-textarea .q-field__control {
    height: 75px;
  }

  .q-textarea .q-field__native {
    resize: none;
  }

  .ExpansionItem {
    border: 1px solid #ffffff;
    // .q-item {
    //   border: 1px solid #ffffff;
    // }

    // label {
    //   margin-bottom: 10px;
    //   align-self: center;
    // }
  }

  .horizonBorderClass {
    padding: 5px 10px 5px 0px;
    margin-bottom: 10px;
    border: 1px solid #ffffff;

    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }

    label {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
}
</style>
