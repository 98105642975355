<script setup lang="ts">
import { ref, computed } from "vue";

import MaterialsLeftComponent from "@/components/Materials/MaterialsLeftComponent.vue";
import MaterialContentsComponent from "@/components/Materials/MaterialContentsComponent.vue";
import { useMaterialsStore } from "@/stores/materials-store";

const spliterLeftWidth = ref(10);
const spliterLeftDisabled = ref(false);
const spliterLeftLimited = ref([10, 10]);

const materialStore = useMaterialsStore();
materialStore.init();


function LeftExpandedBtn() {
  if (spliterLeftDisabled.value === false) {
    spliterLeftWidth.value = 1;
    spliterLeftDisabled.value = true;
    spliterLeftLimited.value = [1, 1];
    (document.getElementsByClassName("leftTreeComponent")[0] as HTMLElement).style.minWidth = "5px";
  } else {
    spliterLeftWidth.value = 10;
    spliterLeftDisabled.value = false;
    spliterLeftLimited.value = [10, 10];
    (document.getElementsByClassName("leftTreeComponent")[0] as HTMLElement).style.minWidth = "192px";

  }
}

</script>

<template>
  <q-page class="MaterialsComponent">
    <!-- <q-splitter
    v-model="spliterLeftWidth"
    :disable="spliterLeftDisabled"
    class="splitterClass"
    :limits="spliterLeftLimited"
    separator-class="leftSeparator"
    before-class="leftTreeComponent"
    >
    <template v-slot:before>
      <MaterialsLeftComponent @expaned="LeftExpandedBtn"></MaterialsLeftComponent>
    </template>
    
    <template v-slot:after>
      <MaterialContentsComponent></MaterialContentsComponent>
    </template>
    
  </q-splitter> -->

  <MaterialContentsComponent></MaterialContentsComponent>
  
</q-page>
</template>

<style lang="scss">
.MaterialsComponent{
  .splitterClass {
    background-color: #e2e6f3;
    height: calc(100vh - 54px);
    .leftTreeComponent{
      border-right: 1px solid #c1c1c1;
      width: 192px;
    }

    .rightComponent{
      border-left: 1px solid #c1c1c1;
      width: 288px;
    }

    .leftSeparator {
      width: 0 !important;
    }
  }
}

.q-btn-group>.q-btn-item:not(:last-child) {
  border-right: 1px solid #c1c1c1;
}
</style>
