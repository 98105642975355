import { ExeActionKind } from "@/global/enums";
import CommonUtils from "@/utils/common-util";
import type AbstractNodeInfo from "../info/abstract-node-info";
import type INodeSetting from "./node-setting";

/**
 * Task - 순차실행 클래스
 * @class
 */
class ScenarioSequenceExecuteNodeSetting implements INodeSetting {
  public exeActionKind = ExeActionKind.Sequence;
  public tasks: SequneceTask[] = [];
  constructor() {

    // this.actions.push(new SequneceTask("A1", "1F 센서 주간 동작"));
    // this.actions.push(new SequneceTask("A2", "1F 주간 최소 디밍"));
  }

  
  updateTasks(nodeInfos: AbstractNodeInfo[]) {

    this.clearTasks();

    for(let i = 0; i < nodeInfos.length; i++) {
      const nodeInfo = nodeInfos[i];

      const code = (i + 1).toString();
      const task = new SequneceTask(nodeInfo.id, code, nodeInfo.name ?? "");
      this.tasks.push(task);
    }
  }

  addTask(nodeInfo: AbstractNodeInfo) {
    
    const index = this.tasks.findIndex(t => t.nodeId === nodeInfo.id);

    if(index >= 0)
      return;

    const code = CommonUtils.generateUUID();
    const task = new SequneceTask(nodeInfo.id, code, nodeInfo.name ?? "");
    this.tasks.push(task);
  }

  deleteTask(nodeId: string) {
    const index = this.tasks.findIndex(t => t.nodeId === nodeId);

    if(index < 0)
      return;
    
    this.tasks.splice(index, 1);
  }

  updateSequence(srcTaskCode: string, isDown = true) {

    const srcTask = this.tasks.find(t => t.code === srcTaskCode);

    if(CommonUtils.isNullOrEmpty(srcTask))
      return;

    const index = this.tasks.findIndex(t => t.code === srcTask.code);

    //더이상 순서를 내릴 수가 없는경우
    if(isDown && index >= this.tasks.length - 1)
      return;
    
    //더이상 순서를 올릴 수가 없는경우
    if(!isDown && index <= 0)
      return;    
    
    const tempIndex = isDown ? index + 1 : index - 1;

    const temp = this.tasks[tempIndex];
    this.tasks[tempIndex] = this.tasks[index];
    this.tasks[index] = temp;        
  }

  clearTasks() {
    this.tasks.splice(0);
  }
}

class SequneceTask {
  public nodeId: string;
  public code: string;
  public name: string;
  constructor(nodeId: string, code: string, name: string) {
    this.nodeId = nodeId;
    this.code = code;
    this.name = name;
  }
}


export default ScenarioSequenceExecuteNodeSetting;