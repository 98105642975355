<script setup lang="ts">
import { computed, ref, type PropType, onUpdated, nextTick } from "vue";
import type { SimulationLedParameter, SimulationTableDataForm, SimulationChartDataType } from "@/global/types";
import type { ApexOptions } from "apexcharts";
import type ApexCharts from "apexcharts";
import type { QTableColumn } from "quasar";
import CommonUtils from "@/utils/common-util";

const props = defineProps({
  chartData: Array<SimulationChartDataType>,
  chartOption: Object as PropType<ApexOptions>,
  mbeData: Number,
  cvrmseData: Number,
  diffData: Array<number>,
  allData: Array<number>,
});
        
// const tmpChartData = [
//   {
//     "name": "소요량",
//     "data": [
//       1542.56,
//       1641.82,
//       1923.36,
//       2002.12,
//       1703.72,
//       2204.76,
//       1942.34,
//       2391.56,
//       2243.83,
//       2028.73,
//       2194.91,
//       2330.03
//     ]
//   },
//   {
//     "name": "실 사용량",
//     "data": [
//       1752.56,
//       1741.82,
//       2023.36,
//       2202.12,
//       1903.72,
//       2504.76,
//       2142.34,
//       2491.56,
//       2343.83,
//       2305.73,
//       2556.91,
//       2730.03
//     ]
//   }
// ];
            
// const tmpTableData = [
//   {
//     "구분": "소요량",
//     "2021-01":       1542.56,
//     "2021-02":     1641.82,
//     "2021-03":       1923.36,
//     "2021-04":       2002.12,
//     "2021-05":       1703.72,
//     "2021-06":       2204.76,
//     "2021-07":       1942.34,
//     "2021-08":   2391.56,
//     "2021-09":       2243.83,
//     "2021-10":       2028.73,
//     "2021-11": 2194.91,
//     "2021-12":       2330.03,
//     "합계": 24145.74
//   },
//   {
//     "구분": "실 사용량",
//     "2021-01":   1752.56,
//     "2021-02":    1741.82,
//     "2021-03":     2023.36,
//     "2021-04":  2202.12,
//     "2021-05":   1903.72,
//     "2021-06":   2504.76,
//     "2021-07":    2142.34,
//     "2021-08":    2491.56,
//     "2021-09":    2343.83,
//     "2021-10":  2305.73,
//     "2021-11":      2556.91,
//     "2021-12":   2730.03,
//     "합계": 26697.74
//   },
//   {
//     "구분": "차이",
//     "2021-01": -101.11,
//     "2021-02": -45.5,
//     "2021-03": -27.05,
//     "2021-04": -99.06,
//     "2021-05": -146,
//     "2021-06": -100.53,
//     "2021-07": -158.55,
//     "2021-08": -56.77,
//     "2021-09": 162.03,
//     "2021-10": -61.77,
//     "2021-11": -268.93,
//     "2021-12": -433.96,
//     "합계": -2321.2
//   }
// ];
                  
// const tmpColumnData = [
//   {
//     "name": "구분",
//     "label": "구분",
//     "align": "center",
//     "field": "구분"
//   },
//   {
//     "name": "2021-01",
//     "label": "2021-01",
//     "align": "center",
//     "field": "2021-01"
//   },
//   {
//     "name": "2021-02",
//     "label": "2021-02",
//     "align": "center",
//     "field": "2021-02"
//   },
//   {
//     "name": "2021-03",
//     "label": "2021-03",
//     "align": "center",
//     "field": "2021-03"
//   },
//   {
//     "name": "2021-04",
//     "label": "2021-04",
//     "align": "center",
//     "field": "2021-04"
//   },
//   {
//     "name": "2021-05",
//     "label": "2021-05",
//     "align": "center",
//     "field": "2021-05"
//   },
//   {
//     "name": "2021-06",
//     "label": "2021-06",
//     "align": "center",
//     "field": "2021-06"
//   },
//   {
//     "name": "2021-07",
//     "label": "2021-07",
//     "align": "center",
//     "field": "2021-07"
//   },
//   {
//     "name": "2021-08",
//     "label": "2021-08",
//     "align": "center",
//     "field": "2021-08"
//   },
//   {
//     "name": "2021-09",
//     "label": "2021-09",
//     "align": "center",
//     "field": "2021-09"
//   },
//   {
//     "name": "2021-10",
//     "label": "2021-10",
//     "align": "center",
//     "field": "2021-10"
//   },
//   {
//     "name": "2021-11",
//     "label": "2021-11",
//     "align": "center",
//     "field": "2021-11"
//   },
//   {
//     "name": "2021-12",
//     "label": "2021-12",
//     "align": "center",
//     "field": "2021-12"
//   },
//   {
//     "name": "합계",
//     "label": "합계",
//     "align": "center",
//     "field": "합계"
//   }
// ];
                          
const simulationChart = ref({} as ApexCharts);

defineExpose({simulationChart});

const chartData = computed(() => {
  return props.chartData;
});

// onUpdated(() => {
//   nextTick(() => {
//     updateChart(chartData.value as SimulationChartDataType[]);
//   });
// });

const chartOption = computed(() => {
  return props.chartOption;
});

// return {
//   chart: {
//     type: "bar",
//     height: 350
//   },
//   title: {
//     text: "월별 조명 에너지 소요량[kWh]",

//     align: "center",
//     style: {
//       fontSize: "20px",
//       fontWeight: "bold",
//       color: "#313131"
//     }
//   },
//   legend: {
//     show: true,
//     position: "top",
//     horizontalAlign: "left"
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: "55%",
//     },
//   },
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     show: true,
//     width: 2,
//     colors: ["transparent"]
//   },
//   xaxis: {
//     categories: ["2021-01", "2021-02", "2021-03", "2021-04", "2021-05", "2021-06", "2021-07", "2021-08", "2021-09", "2021-10", "2021-11", "2021-12"]   },
//   fill: {
//     opacity: 1
//   }
// };
                                                                    
const column = computed(() => {
  return tableColumn();
  // return tmpColumnData as QTableColumn[];
});
const tableData = computed(() => {
  if(props.diffData !== undefined && props.allData !== undefined){
    return tableDataSet();
  }
  return [];
  // return tmpTableData;
});

console.log(chartData, chartOption, column, tableData);
                       
function tableColumn(){
  const column = [] as QTableColumn[];
                                                                      
  column.push({
    name: "구분",
    label: "구분",
    align: "center",
    field: "구분",
                                                                        
  });
  for(let i = 0; i < chartOption.value?.xaxis?.categories.length; i++){
    column.push({
      name: chartOption.value?.xaxis?.categories[i] as string,
      label: chartOption.value?.xaxis?.categories[i] as string,
      align: "center",
      field: chartOption.value?.xaxis?.categories[i] as string,
    });
  }
                                                                      
                                                                      
  return column;
}
                                                                    
function tableDataSet(){
  const tableData = [] as SimulationTableDataForm[];
                                                                      
  if(chartData.value !== undefined){
    for(let i = 0; i < chartData.value.length; i++){
      tableData.push({"구분": chartData.value[i]?.name});
      for(let j = 1; j < column.value.length; j++){
        tableData[i][column.value[j].field as string] = chartData.value[i]?.data[j-1]?.toFixed(2);
      }  
    }
  }
                                                                      
  const diff = {} as {[x:string] : string | number};
  for(let i = 0; i < column.value.length; i++){
    diff[column.value[i].field as string] = (props.diffData as number[])[i-1]?.toFixed(2);
  }
                                                                      
  diff["구분"] = "차이";
                                                                      
  tableData.push(diff);
                                                                      
  for(let i = 0; i < tableData.length; i++){
    tableData[i]["합계"] = (props.allData as number[])[i]?.toFixed(2);
  }
                                                                      
  return tableData;                                                                 
}
                                                                    
                                                                    
// const series = ref([{
//   name: "Net Profit",
//   data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
// }, {
//   name: "Revenue",
//   data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
// }, {
//   name: "Free Cash Flow",
//   data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
// }]);
                                                                          
// const chartOptions = ref({
//   chart: {
//     type: "bar",
//     height: 350
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: "55%",
//       endingShape: "rounded"
//     },
//   },
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     show: true,
//     width: 2,
//     colors: ["transparent"]
//   },
//   xaxis: {
//     categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
//   },
//   yaxis: {
//     title: {
//       text: "$ (thousands)"
//     }
//   },
//   fill: {
//     opacity: 1
//   },
//   tooltip: {
//     y: {
//       formatter: function (val: string) {
//         return "$ " + val + " thousands";
//       }
//     }
//   }
// });

                                                                                               
</script>
                                                                                                  
<template>

  <div class="SimulationGraphComponent" v-if="chartData && chartData.length">
    <div class="ChartArea">
      <apexchart ref="simulationChart" type="bar" height="350" :options="chartOption" :series="chartData"></apexchart>
      <!-- <div v-if="chartData.length <= 0" class="EmptyChart">
          데이터가 존재하지 않습니다.
      </div> -->
    </div>
    <q-separator class="q-mb-md" :dark="false" size="1px" color="#c1c1c1"></q-separator>
    <div class="tableArea">
      <q-table 
        v-if="tableData.length > 0" 
        row-key="name" :columns="column" 
        :rows="tableData" hide-pagination 
        :dark="false" 
        separator="cell" 
        square 
        table-class="graphTable" 
        table-header-class="graphTableHeader" 
        dense
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td class="graphTableTD" v-for="data in Object.keys(props.row)" :key="`${data}`" :props="props" :style="{backgroundColor: data !== '구분' ? 'transparent' : '#f2f4fb', color: data === '구분' ? '#000000' : 'black', fontSize: '12px'}">
              {{ props.row[data] }}
            </q-td>
          </q-tr>
          
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="1" class="bottomRowTd subjectTd">
              MBE
            </q-td>
            <q-td colspan="5" class="bottomRowTd contentTd">
              {{props.mbeData?.toFixed(2)}}
            </q-td>
            <q-td colspan="3" class="bottomRowTd subjectTd">
              CVRMSE
            </q-td>
            <q-td colspan="5" class="bottomRowTd contentTd">
              {{props.cvrmseData?.toFixed(2)}}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<style lang="scss">
.SimulationGraphComponent{
  padding: 5px 0px 5px 0px;
  .EmptyChart{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    background-color: #40404033;
  }
  .tableArea{

    .graphTable{
      font-size: 10px;
    }
    
    .graphTableHeader{
      background-color: #f2f4fb;
      color: #404040;   
      th:first-child{
        padding: 4px 8px;
      } 
      th:last-child{
        padding: 4px 8px;
      }
    }
    .graphTableTD{
      padding: 4px 8px !important;
    }
    .bottomRowTd{
      text-align: center;

    }
    
    .subjectTd{
      background-color: #f2f4fb;
      color: #404040;

    }
    
    .contentTd{
      color: #404040;
    }

  }
}
</style>