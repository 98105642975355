<script setup lang="ts">
import type { DayofWeek } from "@/global/types";
import type ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import type ScenarioScheduleNodeSetting from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import { ScenarioSchedule } from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useScenarioStore } from "@/stores/scenario-store";
import CommonUtils from "@/utils/common-util";
import { computed, onMounted, ref, } from "vue";

const scenarioStore = useScenarioStore();
const nodeEditorStore = useNodeEditorStore();

const props = defineProps<{
  modelValue: ScenarioSchedule | null;
  isOpen: boolean,  
  isNew: boolean,
}>();

const emitData = defineEmits<{
  (eventName: "update:modelValue"): void;  
  (eventName: "update:isOpen", isOpen: boolean): void;  
}>();

onMounted(() => {  
});

const schedule = ref<ScenarioSchedule>(CommonUtils.deepClone(props.modelValue) ?? getNewSchedule());


function onBeforeShow(evt: Event) {
  const cloneSchedule = CommonUtils.deepClone(props.modelValue);
  schedule.value = cloneSchedule ?? getNewSchedule();
}

function getNewSchedule() {
  return new ScenarioSchedule(CommonUtils.generateUUID(), "", "00:00");
}

const isPopupOpen = computed(() => {
  return props.isOpen;
});

function onClose() {
  emitData("update:isOpen", false);
}

function onDayOfWeekClick(dayOfWeek: DayofWeek) {
  dayOfWeek.isChecked = !dayOfWeek.isChecked;
}

function onRegSchedule() {

  if (CommonUtils.isNullOrEmpty(schedule) || CommonUtils.isNullOrEmpty(schedule.value))
    return;

  const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;

  if (props.isNew)
    scenarioStore.addNodeItemEvent(nodeInfo, schedule.value);
  else
    scenarioStore.updateNodeItemEvent(nodeInfo, schedule.value);

  onClose();
}



</script>

<template>
  <q-dialog class="AddScheduleModal" v-model="isPopupOpen" persistent @before-show="onBeforeShow">
    <q-card style="width: 550px; max-width: 80vw; background: #f2f4fb; color: black; padding: 10px">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">스케줄 설정</div>
        <q-space />
        <q-btn icon="close" flat round dense @click="onClose" v-close-popup />
      </q-card-section>

      <q-card-section class="q-pt-none" style="margin-top: 30px">
        <q-form>
          <label>명칭</label>
          <q-input type="text" maxlength="30" :dark="false" outlined v-model="schedule.name" placeholder="스케줄 명칭" />
          <label>스케줄 시간</label>
          <q-input outlined v-model="schedule.time" mask="time" type="time" :dark="false" />
          <label>요일</label>

          <div>
            <q-btn round :color="dayOfWeek.isChecked ? 'primary' : 'grey'" v-for="dayOfWeek in schedule?.dayOfWeeks" :key="dayOfWeek.day" :label="dayOfWeek.day" @click="onDayOfWeekClick(dayOfWeek)"/>
          </div>
                 
          <q-card-actions align="right" style="padding-right: 0">
            <q-btn label="등록" style="width: 60px" color="grey" v-close-popup @click="onRegSchedule" />
            <q-btn label="취소" style="width: 60px" color="primary" @click="onClose" v-close-popup />
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.AddScheduleModal {
}
</style>
