<script lang="ts" setup>
import { useEditStore } from "@/stores/edit-store";
import {computed} from "vue";
import * as THREE from "three";

const editStore = useEditStore();


const UspaceOffSet: {[x:string] : {x: number, y: number}} = {
  "범아 유니텍" : {
    x: 2,
    y: 10,
  },
  "한일 플라스틱" : {
    x: 0,
    y: 0,
  },
};

const positionX = computed(() => {
  console.log(editStore.selectModelPosition, editStore.zoom); 
  let offsetX = UspaceOffSet[editStore.selectZoneInfo?.zoneName as string]?.x;

  if(offsetX === undefined || offsetX === null){
    offsetX = 0;
  }

  return (editStore.selectModelPosition.x - editStore.selectModelSize.x / 2) * editStore.zoom + (offsetX * editStore.zoom);
});

const positionY = computed(() => {
  let offsetY =  UspaceOffSet[editStore.selectZoneInfo?.zoneName as string]?.y;

  if(offsetY === undefined || offsetY === null){
    offsetY = 0;
  }
  // return ((editStore.selectModelPosition?.z as number ) - editStore.UzonePositionData.z ) * editStore.zoom;
  return (editStore.selectModelPosition.z + editStore.UzoneSizeData.z - editStore.selectModelSize.z / 2) * editStore.zoom + (offsetY * editStore.zoom);
});


</script>

<template>
  <div
  :style="{width: '100%', height: '100%', position: 'absolute'}"
  >
    <svg
    :x="0"
    :y="0"
    :width="editStore.maxWidth"
    :height="editStore.maxHeight"
    class="ZoneSelectArea"
    :style="{display: 'block'}"
    >
        <svg
        :x="positionX"
        :y="positionY"
        :width="editStore.selectModelSize?.x as number * editStore.zoom"
        :height="editStore.selectModelSize?.z as number * editStore.zoom"
        >
            <rect
            :x="0"
            :y="0"
            width="100%"
            height="100%"
            :style="{stroke: '#00ff00', strokeWidth:'5px', fill: 'transparent'}"
            >
            </rect>
            <text
            :x="'50%'"
            :y="'50%'"
            :style="{
                textAnchor: 'middle',
                alignmentBaseline: 'central',
                fontSize: '15px',
                fontWeight: 'bold',
                fill: '#00ff00',
              }"
            >
            {{editStore.selectSpaceInfo?.zoneName}}
            </text>
        </svg>
    </svg>
  </div>
   
</template>

<style lang="scss">
.ZoneSelectArea{
    position: absolute;
    display: block;
    margin: auto;
}
</style>