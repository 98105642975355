<script setup lang="ts">
import type PortInfo from "@/models/editor/nodes/info/port-info";
import { onMounted, reactive } from "vue";


const props = defineProps<{
  portInfo: PortInfo;
}>();

const emitData = defineEmits<{
  (eventName: "portMouseDown", event: MouseEvent, port: PortInfo): void,
  (eventName: "portMouseUp", event: MouseEvent, port: PortInfo): void,
  (eventName: "portMouseOver", event: MouseEvent, port: PortInfo): void,
  (eventName: "portMouseOut", event: MouseEvent, port: PortInfo): void,
}>();

const portInfo = reactive(props.portInfo);

onMounted(() => {
  
});

function onMouseDown(event: MouseEvent) {
  emitData("portMouseDown", event, portInfo);
}

function onMouseUp(event: MouseEvent) {
  emitData("portMouseUp", event, portInfo);
}
function onMouseOver(event: MouseEvent) {
  emitData("portMouseOver", event, portInfo);
}

function onMouseOut(event: MouseEvent) {
  emitData("portMouseOut", event, portInfo);
}

</script>

<template>  
  <g :transform="`translate(${portInfo.x}, ${portInfo.y})`">
    <!-- port_hovered -->
    <rect class="port" :class="{ 'port_hovered': portInfo.isSelected }" rx="3" ry="3" width="8" height="8"
      @mousedown="onMouseDown" @mouseup="onMouseUp" @mouseover="onMouseOver" @mouseout="onMouseOut" />
  </g>
</template>

<style lang="scss" scoped>

$node-selected-color: #ff7f0e;
$port-selected-color: #ff7f0e;

.port {
  stroke: #999;
  stroke-width: 1;
  fill: #ddd;
  cursor: crosshair;

  &:hover {
    stroke: $port-selected-color;
    fill: $port-selected-color;
  }
}
</style>