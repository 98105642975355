
<script setup lang="ts">
import { ref } from "vue";


const jsonData = ref({
  "siteSeq": 43,
  "zoneSeq": 6,
  "pzoneSeq": null,
  "zoneName": "test",
  "zoneTypeCode": "R",
  "originX": 10,
  "originY": 10,
  "originZ": 10,
  "horizSize": 500,
  "vertiSize": 500,
  "boundsPath": "M10 10h500v500H10Z",
  "area": 250000,
  "height": 500,
  "planImageFileSeq": null,
  "modelFile": {
    "modelFileSeq": 21,
    "modelFileName": "Sejung_009.FBX",
    "modelFileType": "FBX",
    "saveFileName": "55EEIkTpBX9yWyXUluxbAT8G3hbF95ENUtj1mdVP",
    "regDate": "2022-10-11T10:00:36",
    "regUserId": 11,
    "textureFiles": [
      {
        "modelFileSeq": 21,
        "textureFileSeq": 1,
        "textureFileName": "handrail_02.png"
      },
      {
        "modelFileSeq": 21,
        "textureFileSeq": 2,
        "textureFileName": "Sejung_1F_t01.jpg"
      },
      {
        "modelFileSeq": 21,
        "textureFileSeq": 3,
        "textureFileName": "Sejung_2F_t01.jpg"
      },
      {
        "modelFileSeq": 21,
        "textureFileSeq": 4,
        "textureFileName": "Sejung_FL_t.jpg"
      }
    ]
  },
  "modelFileSeq": 21,
  "rzone": {
    "siteSeq": 43,
    "zoneSeq": 6,
    "rzoneTypeCode": "BUILDING",
    "loc": "test",
    "azimuthCode": "E",
    "totalFloorArea": 2750000,
    "buildingArea": 250000,
    "rangeOfFloors": "B1-F1"
  },
  "uzone": null,
  "czone": null,
  "regDate": "2022-10-11T09:53:42",
  "regUserId": 11,
  "modDate": "2022-11-10T09:23:44",
  "modUserId": 7,
  "scenarios": [],
  "scenes": [],
  "compos": [],
  "ctrlGrps": [],
  "dpoints": [],
  "faces": [],
  "childZones": [
    {
      "siteSeq": 43,
      "zoneSeq": 7,
      "pzoneSeq": 6,
      "zoneName": "B1",
      "zoneTypeCode": "C",
      "originX": null,
      "originY": null,
      "originZ": null,
      "horizSize": 500,
      "vertiSize": 550,
      "boundsPath": null,
      "area": 400,
      "height": 500,
      "planImageFileSeq": null,
      "modelFile": null,
      "modelFileSeq": null,
      "rzone": null,
      "uzone": null,
      "czone": {
        "siteSeq": 43,
        "zoneSeq": 7,
        "czoneTypeCode": "SF",
        "floorTypeCode": "B"
      },
      "regDate": "2022-10-11T09:53:42",
      "regUserId": 11,
      "modDate": "2022-11-10T09:23:44",
      "modUserId": 7,
      "scenarios": [],
      "scenes": [],
      "compos": [],
      "ctrlGrps": [],
      "dpoints": [],
      "faces": [],
      "childZones": [
        {
          "siteSeq": 43,
          "zoneSeq": 57595,
          "pzoneSeq": 7,
          "zoneName": "탕비실1",
          "zoneTypeCode": "U",
          "originX": 10,
          "originY": 10,
          "originZ": 10,
          "horizSize": 150,
          "vertiSize": 100,
          "boundsPath": "M10 10h50v50H10Z",
          "area": 300,
          "height": 50,
          "planImageFileSeq": null,
          "modelFile": null,
          "modelFileSeq": null,
          "rzone": null,
          "uzone": {
            "siteSeq": 43,
            "zoneSeq": 57595,
            "uzoneTypeCode": "01",
            "outdoorYn": "Y",
            "usageProfileCode": "A102",
            "ceilHeight": 10,
            "nightOperMode": "DOP",
            "weekendOperMode": "NOP"
          },
          "czone": null,
          "regDate": "2022-10-11T07:09:09",
          "regUserId": 0,
          "modDate": "2022-11-10T09:23:44",
          "modUserId": 7,
          "scenarios": [],
          "scenes": [
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "sceneSeq": 1,
              "sceneName": "씬 1",
              "sceneDesc": null,
              "regDate": "2022-11-07T07:47:57",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "sceneSettings": [
                {
                  "modDate": "2022-11-10T01:15:08.172Z",
                  "modUserId": 7,
                  "regDate": "2022-11-10T01:15:08.172Z",
                  "regUserId": 7,
                  "grpSeq": 4285,
                  "lightAttrsMap": "",
                  "sceneSeq": 1,
                  "siteSeq": 43,
                  "zoneSeq": 57595
                },
                {
                  "modDate": "2022-11-10T01:31:05.860Z",
                  "modUserId": 7,
                  "regDate": "2022-11-10T01:31:05.860Z",
                  "regUserId": 7,
                  "grpSeq": 5903,
                  "lightAttrsMap": "",
                  "sceneSeq": 1,
                  "siteSeq": 43,
                  "zoneSeq": 57595
                },
                {
                  "modDate": "2022-11-10T01:31:07.763Z",
                  "modUserId": 7,
                  "regDate": "2022-11-10T01:31:07.763Z",
                  "regUserId": 7,
                  "grpSeq": 7136,
                  "lightAttrsMap": "",
                  "sceneSeq": 1,
                  "siteSeq": 43,
                  "zoneSeq": 57595
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "sceneSeq": 2,
              "sceneName": "씬 2",
              "sceneDesc": null,
              "regDate": "2022-11-07T08:46:00",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "sceneSettings": []
            }
          ],
          "compos": [],
          "ctrlGrps": [
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "grpSeq": 4285,
              "grpName": "그룹4285",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-10-17T09:09:46",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 4285,
                  "memberSeq": 14793,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12684,
                  "moduleSeq": 28456,
                  "regDate": "2022-10-17T09:09:49",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 4285,
                  "memberSeq": 17972,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 10125,
                  "moduleSeq": 28842,
                  "regDate": "2022-10-17T09:09:48",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 4285,
                  "memberSeq": 19302,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12453,
                  "moduleSeq": 27124,
                  "regDate": "2022-10-17T09:09:48",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "grpSeq": 5903,
              "grpName": "그룹5903",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-10-17T08:48:11",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 5903,
                  "memberSeq": 10248,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12171,
                  "moduleSeq": 23860,
                  "regDate": "2022-10-17T08:48:12",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 5903,
                  "memberSeq": 10671,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12453,
                  "moduleSeq": 27124,
                  "regDate": "2022-10-17T08:48:14",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 5903,
                  "memberSeq": 10776,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 13476,
                  "moduleSeq": 25201,
                  "regDate": "2022-10-17T08:48:13",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 5903,
                  "memberSeq": 17485,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12684,
                  "moduleSeq": 28456,
                  "regDate": "2022-10-17T08:48:14",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "grpSeq": 7136,
              "grpName": "그룹7136",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-10-17T07:25:20",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 7136,
                  "memberSeq": 12030,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 11050,
                  "moduleSeq": 26710,
                  "regDate": "2022-10-17T07:25:23",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 7136,
                  "memberSeq": 18161,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 10125,
                  "moduleSeq": 28842,
                  "regDate": "2022-10-17T07:25:23",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "grpSeq": 9818,
              "grpName": "새 그룹",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-11-03T10:00:08",
              "regUserId": 8,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 9818,
                  "memberSeq": 12000,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12171,
                  "moduleSeq": 23860,
                  "regDate": "2022-11-03T10:00:14",
                  "regUserId": 8,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "grpSeq": 9818,
                  "memberSeq": 17990,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 12204,
                  "moduleSeq": 27457,
                  "regDate": "2022-11-03T10:00:14",
                  "regUserId": 8,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            }
          ],
          "dpoints": [
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 10125,
              "dpointName": "조명 10125",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 716,
              "centerY": 30,
              "centerZ": 305,
              "rotate": 0,
              "regDate": "2022-10-17T07:24:17",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 10125,
                  "moduleSeq": 28842,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T07:24:17",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 11050,
              "dpointName": "조명 11050",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 236,
              "centerY": 30,
              "centerZ": 128,
              "rotate": 0,
              "regDate": "2022-10-17T07:24:19",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 11050,
                  "moduleSeq": 26710,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T07:24:19",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 12171,
              "dpointName": "조명 12171",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 520,
              "centerY": 30,
              "centerZ": 273,
              "rotate": 0,
              "regDate": "2022-10-17T08:46:56",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 12171,
                  "moduleSeq": 23860,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T08:46:56",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 12204,
              "dpointName": "조명 12204",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 297,
              "centerY": 30,
              "centerZ": 479,
              "rotate": 0,
              "regDate": "2022-11-03T09:41:18",
              "regUserId": 8,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 12204,
                  "moduleSeq": 27457,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-03T09:41:18",
                  "regUserId": 8,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 12453,
              "dpointName": "조명 12453",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 813,
              "centerY": 30,
              "centerZ": 233,
              "rotate": 0,
              "regDate": "2022-10-17T08:47:02",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 12453,
                  "moduleSeq": 27124,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T08:47:02",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 12684,
              "dpointName": "조명 12684",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 975,
              "centerY": 30,
              "centerZ": 356,
              "rotate": 0,
              "regDate": "2022-10-17T08:47:04",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 12684,
                  "moduleSeq": 28456,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T08:47:04",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 12723,
              "dpointName": "조명 12723",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 747,
              "centerY": 0,
              "centerZ": 581,
              "rotate": 0,
              "regDate": "2022-11-09T08:36:18",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 12723,
                  "moduleSeq": 22202,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:36:18",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 57595,
              "dpointSeq": 13476,
              "dpointName": "조명 13476",
              "devTypeCode": "DL-C-D220xH190",
              "centerX": 961,
              "centerY": 30,
              "centerZ": 124,
              "rotate": 0,
              "regDate": "2022-10-17T08:46:58",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 57595,
                  "dpointSeq": 13476,
                  "moduleSeq": 25201,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-10-17T08:46:58",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            }
          ],
          "faces": [],
          "childZones": [],
          "planImageFile": null
        },
        {
          "siteSeq": 43,
          "zoneSeq": 86376,
          "pzoneSeq": 7,
          "zoneName": "사무실",
          "zoneTypeCode": "U",
          "originX": 150,
          "originY": 150,
          "originZ": 10,
          "horizSize": 200,
          "vertiSize": 200,
          "boundsPath": "M10 10h10v10H10Z",
          "area": 400000,
          "height": 10,
          "planImageFileSeq": null,
          "modelFile": null,
          "modelFileSeq": null,
          "rzone": null,
          "uzone": {
            "siteSeq": 43,
            "zoneSeq": 86376,
            "uzoneTypeCode": "01",
            "outdoorYn": null,
            "usageProfileCode": "A001",
            "ceilHeight": 10,
            "nightOperMode": "NOP",
            "weekendOperMode": "DOP"
          },
          "czone": null,
          "regDate": "2022-10-11T01:04:20",
          "regUserId": 0,
          "modDate": "2022-11-10T09:23:44",
          "modUserId": 7,
          "scenarios": [],
          "scenes": [
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "sceneSeq": 1,
              "sceneName": "씬 1",
              "sceneDesc": null,
              "regDate": "2022-11-07T09:32:12",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "sceneSettings": []
            }
          ],
          "compos": [],
          "ctrlGrps": [
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "grpSeq": 2636,
              "grpName": "그룹2",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-11-07T09:31:27",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 2636,
                  "memberSeq": 11329,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 15475,
                  "moduleSeq": 23733,
                  "regDate": "2022-11-07T09:32:00",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 2636,
                  "memberSeq": 14272,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 17517,
                  "moduleSeq": 22379,
                  "regDate": "2022-11-07T09:32:00",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 2636,
                  "memberSeq": 14701,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 14703,
                  "moduleSeq": 21689,
                  "regDate": "2022-11-07T09:32:00",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 2636,
                  "memberSeq": 16515,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 18831,
                  "moduleSeq": 21494,
                  "regDate": "2022-11-07T09:32:00",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "grpSeq": 4158,
              "grpName": "그룹1",
              "grpTypeCode": "CG",
              "grpDesc": "",
              "regDate": "2022-11-04T08:59:59",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "members": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 4158,
                  "memberSeq": 10767,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 14703,
                  "moduleSeq": 21689,
                  "regDate": "2022-11-04T09:00:09",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 4158,
                  "memberSeq": 13818,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 13657,
                  "moduleSeq": 27856,
                  "regDate": "2022-11-04T09:00:09",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 4158,
                  "memberSeq": 15200,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 18831,
                  "moduleSeq": 21494,
                  "regDate": "2022-11-04T09:00:09",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                },
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "grpSeq": 4158,
                  "memberSeq": 19174,
                  "memberTypeCode": "DPOINT",
                  "dpointSeq": 15930,
                  "moduleSeq": 20230,
                  "regDate": "2022-11-04T09:00:09",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            }
          ],
          "dpoints": [
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 13442,
              "dpointName": "조명 13442",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 982,
              "centerY": 0,
              "centerZ": 499,
              "rotate": 0,
              "regDate": "2022-11-09T08:29:57",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 13442,
                  "moduleSeq": 22858,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:29:57",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 13657,
              "dpointName": "조명 13657",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 263,
              "centerY": 30,
              "centerZ": 386,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:26",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 13657,
                  "moduleSeq": 27856,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:26",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 14692,
              "dpointName": "조명 14692",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 671,
              "centerY": 0,
              "centerZ": 285,
              "rotate": 0,
              "regDate": "2022-11-09T08:29:06",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 14692,
                  "moduleSeq": 29622,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:29:06",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 14703,
              "dpointName": "조명 14703",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 444,
              "centerY": 30,
              "centerZ": 165,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:29",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 14703,
                  "moduleSeq": 21689,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:29",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 15225,
              "dpointName": "조명 15225",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 1012,
              "centerY": 0,
              "centerZ": 119,
              "rotate": 0,
              "regDate": "2022-11-09T08:23:15",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 15225,
                  "moduleSeq": 24711,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:23:15",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 15243,
              "dpointName": "조명 15243",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 930,
              "centerY": 0,
              "centerZ": 310,
              "rotate": 0,
              "regDate": "2022-11-09T08:29:07",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 15243,
                  "moduleSeq": 29661,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:29:07",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 15475,
              "dpointName": "조명 15475",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 16,
              "centerY": 30,
              "centerZ": 307,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:25",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 15475,
                  "moduleSeq": 23733,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:25",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 15930,
              "dpointName": "조명 15930",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 511,
              "centerY": 30,
              "centerZ": 430,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:28",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 15930,
                  "moduleSeq": 20230,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:28",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 17246,
              "dpointName": "조명 17246",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 699,
              "centerY": 0,
              "centerZ": 475,
              "rotate": 0,
              "regDate": "2022-11-09T08:29:56",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 17246,
                  "moduleSeq": 26376,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:29:56",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 17492,
              "dpointName": "조명 17492",
              "devTypeCode": "FL-R-W185xL185xH165",
              "centerX": 723,
              "centerY": 0,
              "centerZ": 119,
              "rotate": 0,
              "regDate": "2022-11-09T08:23:14",
              "regUserId": 7,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 17492,
                  "moduleSeq": 29724,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-09T08:23:14",
                  "regUserId": 7,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 17517,
              "dpointName": "조명 17517",
              "devTypeCode": "DL-C-D220xH190",
              "centerX": 27,
              "centerY": 30,
              "centerZ": 439,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:25",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 17517,
                  "moduleSeq": 22379,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:25",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            },
            {
              "siteSeq": 43,
              "zoneSeq": 86376,
              "dpointSeq": 18831,
              "dpointName": "조명 18831",
              "devTypeCode": "FL-R-W345xL185xH165",
              "centerX": 241,
              "centerY": 30,
              "centerZ": 235,
              "rotate": 0,
              "regDate": "2022-11-04T08:58:27",
              "regUserId": 12,
              "modDate": "2022-11-10T09:23:44",
              "modUserId": 7,
              "mpoints": [
                {
                  "siteSeq": 43,
                  "zoneSeq": 86376,
                  "dpointSeq": 18831,
                  "moduleSeq": 21494,
                  "centerX": 0,
                  "centerY": 0,
                  "centerZ": 0,
                  "rotate": 0,
                  "regDate": "2022-11-04T08:58:27",
                  "regUserId": 12,
                  "modDate": "2022-11-10T09:23:44",
                  "modUserId": 7
                }
              ]
            }
          ],
          "faces": [],
          "childZones": [],
          "planImageFile": null
        }
      ],
      "planImageFile": null
    },
    {
      "siteSeq": 43,
      "zoneSeq": 8,
      "pzoneSeq": 6,
      "zoneName": "F1",
      "zoneTypeCode": "C",
      "originX": null,
      "originY": null,
      "originZ": null,
      "horizSize": 500,
      "vertiSize": 500,
      "boundsPath": null,
      "area": null,
      "height": 500,
      "planImageFileSeq": null,
      "modelFile": null,
      "modelFileSeq": null,
      "rzone": null,
      "uzone": null,
      "czone": {
        "siteSeq": 43,
        "zoneSeq": 8,
        "czoneTypeCode": "SF",
        "floorTypeCode": "F"
      },
      "regDate": "2022-10-11T09:53:42",
      "regUserId": 11,
      "modDate": "2022-11-10T09:23:44",
      "modUserId": 7,
      "scenarios": [],
      "scenes": [],
      "compos": [],
      "ctrlGrps": [],
      "dpoints": [],
      "faces": [],
      "childZones": [
        {
          "siteSeq": 43,
          "zoneSeq": 63025,
          "pzoneSeq": 8,
          "zoneName": "테스트 공간",
          "zoneTypeCode": "U",
          "originX": 10,
          "originY": 10,
          "originZ": 10,
          "horizSize": 100,
          "vertiSize": 100,
          "boundsPath": "M10 10h100v100H10Z",
          "area": 100,
          "height": 100,
          "planImageFileSeq": null,
          "modelFile": null,
          "modelFileSeq": null,
          "rzone": null,
          "uzone": {
            "siteSeq": 43,
            "zoneSeq": 63025,
            "uzoneTypeCode": "01",
            "outdoorYn": null,
            "usageProfileCode": "A003",
            "ceilHeight": 10,
            "nightOperMode": null,
            "weekendOperMode": null
          },
          "czone": null,
          "regDate": "2022-10-11T06:50:23",
          "regUserId": 0,
          "modDate": "2022-11-10T09:23:44",
          "modUserId": 7,
          "scenarios": [],
          "scenes": [],
          "compos": [],
          "ctrlGrps": [],
          "dpoints": [],
          "faces": [],
          "childZones": [],
          "planImageFile": null
        }
      ],
      "planImageFile": null
    }
  ],
  "planImageFile": null
});

</script>

<template>
    <json-viewer :value="jsonData"></json-viewer>
    
    <hr />
    
    <json-viewer
    :value="jsonData"
    :expand-depth=5
    copyable
    boxed
    sort></json-viewer>
    
</template>