<script setup lang="ts">
import type ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import ScenarioTimeBranchNodeSetting, { TimeBranchEvent } from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import { useScenarioStore } from "@/stores/scenario-store";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import CommonUtils from "@/utils/common-util";
import { computed, ref } from "vue";
import { TimeType } from "@/global/enums";

const scenarioStore = useScenarioStore();
const nodeEditorStore = useNodeEditorStore();

const props = defineProps<{
  modelValue: TimeBranchEvent | null;
  isOpen: boolean,
  isNew: boolean,
}>();

const emitData = defineEmits<{
  (eventName: "update:modelValue"): void;
  (eventName: "update:isOpen", isOpen: boolean): void;  
}>();

const timeBranchEvent = ref<TimeBranchEvent>(CommonUtils.deepClone(props.modelValue) ?? getNewTimeBranchEvent());

function onBeforeShow(evt: Event) {
  const cloneSchedule = CommonUtils.deepClone(props.modelValue);
  timeBranchEvent.value = cloneSchedule ?? getNewTimeBranchEvent();
}

function getNewTimeBranchEvent() {
  return new TimeBranchEvent(CommonUtils.generateUUID(), "", TimeType.Custom, TimeType.Custom, "00:00", "00:00");
}

const isPopupOpen = computed(() => {
  return props.isOpen;
});

function onClose() {
  emitData("update:isOpen", false);
}

/**
 * 등록 버튼 처리 함수
 */
function onRegTimeBranchEvent() {
  if(CommonUtils.isNullOrEmpty(timeBranchEvent) || CommonUtils.isNullOrEmpty(timeBranchEvent.value))
    return;

  const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;  

  if(props.isNew)
    scenarioStore.addNodeItemEvent(nodeInfo, timeBranchEvent.value);  
  else
    scenarioStore.updateNodeItemEvent(nodeInfo, timeBranchEvent.value);
  onClose();
}

</script>

<template>
  <q-dialog class="AddTimeBranchModal" v-model="isPopupOpen" persistent @before-show="onBeforeShow">
    <q-card style="width: 550px; max-width: 80vw; background: #f2f4fb; color: black; padding: 10px">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">시간대 분기 설정</div>
        <q-space />
        <q-btn icon="close" @click="onClose" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="q-pt-none" style="margin-top: 30px">
        <q-form>          
          <label>명칭</label>
          <q-input type="text" maxlength="30" :dark="false" outlined v-model="timeBranchEvent.name" placeholder="이벤트 명칭" />

          <label>시작 시간</label>
          
          <div>
            <q-radio class="col-6" label="일출" v-model="timeBranchEvent.startTimeType" :val="TimeType.Sunrise" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
            <q-radio class="col-6" label="일몰" v-model="timeBranchEvent.startTimeType" :val="TimeType.SunSet" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
            <q-radio class="col-6" label="사용자 지정" v-model="timeBranchEvent.startTimeType" :val="TimeType.Custom" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
          </div>

          <q-input :disable="timeBranchEvent.startTimeType !== TimeType.Custom" type="time" maxlength="30" :dark="false" mask="time" outlined v-model="timeBranchEvent.startTime" placeholder="시작 시간" />

          <label>종료 시간</label>
          <div>
            <q-radio class="col-6" label="일출" v-model="timeBranchEvent.endTimeType" :val="TimeType.Sunrise" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
            <q-radio class="col-6" label="일몰" v-model="timeBranchEvent.endTimeType" :val="TimeType.SunSet" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
            <q-radio class="col-6" label="사용자 지정" v-model="timeBranchEvent.endTimeType" :val="TimeType.Custom" size="xs" :dark="false" style="font-size: 13px; margin-bottom: 0px;" />
          </div>
          <q-input :disable="timeBranchEvent.endTimeType !== TimeType.Custom" type="time" maxlength="30" :dark="false" mask="time" outlined v-model="timeBranchEvent.endTime" placeholder="종료 시간" />

          <q-card-actions align="right" style="padding-right: 0">
            <q-btn label="등록" style="width: 60px" color="primary" v-close-popup @click="onRegTimeBranchEvent" />
            <q-btn label="취소" style="width: 60px" color="grey" @click="onClose" v-close-popup />
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.AddTimeBranchModal {
}
</style>
