import PresenseSensorConfigAttrs from "@/models/attrs-maps/presense-sensor-config-attrs";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import CommonUtils from "@/utils/common-util";
import { NodeItem } from "../info/node-item-info";
import { AbstractPackageActionNodeSetting } from "./node-setting";
import { ScenarioPresenceSensorDeviceEvent } from "./scenario-presence-sensor-device-node-setting";

class ScenarioConfigurePresenseSensorNodeSetting extends AbstractPackageActionNodeSetting {

  attrs: PresenseSensorConfigAttrs;
  public events: ScenarioPresenceSensorDeviceEvent[] = [];

  constructor(nodeTypeByEvents: NodeTypeByEvent[]) {
    super();
    this.attrs = new PresenseSensorConfigAttrs;

    if (CommonUtils.isNullOrEmpty(nodeTypeByEvents))
      return;

    for (const nodeTypeByEvent of nodeTypeByEvents) {
      this.events.push(new ScenarioPresenceSensorDeviceEvent(nodeTypeByEvent));
    }    
  }

  solveAttrs(attrsMap: string): void {
    try {
      const o = CommonUtils.jsonDeserialize(PresenseSensorConfigAttrs, attrsMap) as PresenseSensorConfigAttrs;
      this.attrs = o;
    } catch (e) {
    }
  }
  
  getNodeItems(): NodeItem[] {

    this.events.sort((a, b) => {
      return a.seq - b.seq;
    });

    const checkedEvents = this.events.filter(e => e.isChecked);

    const nodeItems: NodeItem[] = [];

    for (const event of checkedEvents) {

      const nodeItemId = event.nodeTypeByEvent.eventCode.toString();
      const nodeItem = new NodeItem(nodeItemId, event.name, "");
      nodeItems.push(nodeItem);
    }

    return nodeItems;    
  }
}

export default ScenarioConfigurePresenseSensorNodeSetting;