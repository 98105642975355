class MPoint{
  centerX?: number;
  centerY?: number;
  centerZ?: number;
  dpointSeq?: number;
  modDate?: string;
  modUserId?: number;
  moduleSeq?: number;
  regDate?: string;
  regUserId?: number;
  rotate?: number;
  siteSeq?: number;
  zoneSeq?: number;

  constructor(siteSeq: number, zoneSeq: number, dpointSeq: number, x: number, y: number, z: number, rotate: number, userID: number){
    this.siteSeq = siteSeq;
    this.zoneSeq = zoneSeq;
    this.dpointSeq = dpointSeq;
    this.modDate = new Date().toISOString();
    this.regDate = new Date().toISOString();
    this.modUserId = userID;
    this.regUserId = userID;
    this.rotate = rotate;
    this.centerX = x;
    this.centerY = y;
    this.centerZ = z;
    this.moduleSeq = Math.floor(Math.random() * 10000 + 19876);
  }
}   

export default MPoint;