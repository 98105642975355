import type DevAttrOption from "./dev-attr-option";



class DevAttr{
  attrSeq?: number;
  attrName?: string;
  attrType?: string | number;
  attrGrpCode?: string;
  attrDesc?: string;
  baseAttrSeq?: number;
  defaultVal?: string | number | null;
  minVal?: number;
  maxVal?: number;
  maxLen?: number| null;
  step?: number;
  unit?: string;
  readOnlyYn?: string;
  devAttrOptions?: DevAttrOption[];
}

export default DevAttr;