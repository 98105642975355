<script setup lang="ts">
import { ref, onMounted } from "vue";
import draggable from "vuedraggable";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import { useLightStore } from "@/stores/light-store";


const lightStore = useLightStore();

const filter = ref(null);
const filterRef = ref<null | { focus: () => null }>(null);

onMounted(async () => {  
});

function onDragStart(event: DragEvent): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedNodeConfig = event.target.__draggable_context.element as LightNodeConfig;
  event.dataTransfer?.setData("selectedNodeConfigId", selectedNodeConfig.id);
  console.log(`onDragStart -> ${selectedNodeConfig.id}`);
}

function canMove() {
  return false;
}

function onDragEnd(event: any): void {}

function onUpdateFilter(value: string | number | null) {
  const configName = value === null ? "" : value.toString();  
  lightStore.updateFilter(configName);
}
</script>

<template>
  <div class="LightListComponent">
    <q-list :dark="false">
      <q-input class="siteFilter" :item-aligned="true" ref="filterRef" color="grey" label-color="black" :dark="false"
        square outlined bg-color="white" v-model="filter" :hide-bottom-space="true" @update:model-value="onUpdateFilter"
        placeholder="Search" />

      <q-expansion-item v-for="elementGroup in lightStore.filterDeviceElementGroups" :label="elementGroup.title" :key="elementGroup.id" :header-inset-level="0.2" :dark="false" dense
        expand-separator default-opened>

        <q-list bordered class="rounded-borders lightList">
          <draggable v-model="elementGroup.configs" item-key="id" class="list-group" :move="canMove"
            @dragstart="onDragStart" @dragend="onDragEnd" @touchstart="onDragStart">
            <template #item="{ element }">

              <q-item bordered class="light-list-group">
                <q-item-section avatar>
                  <!-- <q-icon color="primary" name="bluetooth" /> -->
                  <q-img v-if="element.imageUrl !== 'null'" :src="element.imageUrl" />

                  <div v-else :class="element.shapeType.toString()">

                  </div>

                </q-item-section>
                <q-item-section>{{ element.name }}</q-item-section>
              </q-item>
            </template>
          </draggable>
        </q-list>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<style scoped lang="scss">
.LightListComponent {

  .SQUARE{
    width: 40px;
    height: 40px;
    background-color: #ffffff;
  }

  .CIRCLE{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #ffffff;
  }

  .lightTree {
    font-size: 12px;
  }

  .lightFilter {
    color: black;
    align-items: center;
    margin-bottom: 0px;
    .q-field__inner {
      .q-field__control {
        align-items: center;
      }
    }
  }

  .lightList {
    background-color: #e2e6f3;
    border: none;
    .light-list-group {
      background-color: #f2f4fb;
      border: 1px solid #c1c1c1;
      color: #000000;
      font-weight: bold !important;
      font-size: 14px;
    }
  }
}
</style>
