//import type Code from "@/models/code";
import { HttpMethods } from "@/global/enums";
import Site from "@/models/site";
import type { AxiosResponse } from "axios";
import BaseApiService from "./base-api-service";
import type { AxiosRequestHeaders } from "axios";
import { URLSearchParams } from "url";
import type RestClient from "@/net/rest-client";
import type { RequestParameter } from "@/net/rest-client";

/**
 * 설정 서비스
 * @class
 */
class SettingApiService extends BaseApiService {
  constructor(restClient: RestClient) {
    super(restClient);
  }


  /**
   * 샘플용 (추후 지울예정)
   */
  async getAppInfo() {
    // const appId = "KIE.MECS.Mobile.Android";
    // const res = await this.restClient.call(KieMecsAppInfo, HttpMethods.Get, `/v2/apps/${appId}`);
    // console.log(`Res Data AppName -> ${res.data.appName}`);
  }

  /**
   * 샘플용 (추후 지울예정)
   */
  async login() {
    const appId = "KIE.MECS.Mobile.Android";
    const email = "freedev82@kbet.or.kr";
    const password = "qwer1234";

    // const headers: AxiosRequestHeaders = {
    //   "appId": appId,
    // };

    // const params = {
    //   email: email,
    //   password: password,
    // };

    const requestParams: RequestParameter = {
      headers: { appId: appId },
      params: { email: email, password: password },
    };

    const res = await this.restClient.call(HttpMethods.Post, "/v2/signin/email", requestParams, LoginInfo);
    console.log(`Login AccessToken -> ${res.data?.accessToken}`);
  }
}

class KieMecsAppInfo {
  public appId?: string;
  public appName?: string;
  public osPlatform?: string;
  public lastestVer?: string;
  public verInfo?: string;
  public downloadUrl?: string;
  public mandaUpdateYn?: string;
  public appcenterSecret?: string;
  public statusCd?: string;
  public commonCdSyncModDt?: string;
  public modDt?: string;
}

/**
 * 임시 클래스 (삭제 예정)
 * @class
 */
class LoginInfo {
  public userId?: string;
  public tokenType?: string;
  public accessToken?: string;
  public expiresIn?: number;
  public refreshToken?: string;
  public refreshTokenExpiresIn?: number;
}

export default SettingApiService;
