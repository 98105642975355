<script setup lang="ts">
import type DPoint from "@/models/entity/d-point";
import { useEditStore } from "@/stores/edit-store";
import CommonUtils from "@/utils/common-util";
import { computed, ref } from "vue";

type GroupTableRowItem = {
  modelName: string,
  modelNumber: string,
}

const editStore = useEditStore();

const groupColor = ref("#ffffff");
const emptyVal = ref("");
const colorOpen = ref(false);

const column = [
  { name: "모델명", label: "모델명", field: "modelName", align: "center" as const },
  { name: "번호", label: "모델 번호", field: "modelNumber", align: "center" as const },
];

const rows = computed(() => {
  const tableRows: GroupTableRowItem[] = [];

  if (editStore.selectGroupInfo === null)
    return [];
  else {

    if (CommonUtils.isNullOrEmpty(editStore.selectGroupInfo) || CommonUtils.isNullOrEmpty(editStore.selectGroupInfo.members))
      return [];

    const inDPoints = editStore.getGroupInDPoints(editStore.selectGroupInfo);
    
  
    for (const dPoint of inDPoints) {

      const data: GroupTableRowItem = {
        modelName: dPoint.dpointName ?? "",
        modelNumber: dPoint.dpointSeq.toString(),
      };
      tableRows.push(data);
    }

    console.log(tableRows);

    return tableRows;
  }
});

const groupLightLength = computed(() => {
  return editStore.selectGroupInfo?.members?.length ?? 0;
});

</script>

<template>
  <div class="GroupRightComponent">
    <div class="NoneContents" v-if="editStore.selectGroupInfo === null" >
      그룹을 선택해주세요.
    </div>

    <q-expansion-item v-else label="그룹 정보" header-class="groupInfoExpansion" default-opened :dark="false">
      <q-card>
        <q-card-section class="groupRightContents">
          <q-form>
            <label>명칭</label>
            <q-input
              square
              bg-color="white"
              :dark="false"
              type="text"
              maxlength="30"
              outlined
              v-model="editStore.selectGroupInfo.grpName"
              placeholder="그룹 명칭"
            />

            <label>설명</label>
            <q-input
              square
              bg-color="white"
              :dark="false"
              type="text"
              maxlength="50"
              outlined
              v-model="editStore.selectGroupInfo.grpDesc"
              placeholder="그룹 설명"
            />

            <label>조명 개수</label>
       
              <q-input   
                square
                bg-color="white"
                :dark="false"
                type="text"
                maxlength="50"
                outlined
                v-model="groupLightLength"
                disable
              >
              </q-input>
            

            <label>조명 리스트</label>
            <div class="grouplightList">
              <q-table
                :columns="column"
                :rows="rows"
                :dark="false" 
                :separator="'cell'"
                :no-data-label="'추가된 조명이 없습니다.'" 
                :table-header-style="{backgroundColor: '#f2f4fb', fontSize: '13px'}"
                :pagination="{rowsPerPage: 0}" 
                :rows-per-page-options="[0]"
                virtual-scroll 
                hide-pagination
                dense
              >
            </q-table>

              <!-- <div class="grouplightListRow" v-for="(light, i) in editStore.selectGroupInfo.dpoints" :key="`grouplisht${i}`">
                {{light}} -->
                <!-- <div></div>
                <q-btn class="lightDelBtn" @click="delLightList(light.id)">
                  {{ "삭제" }}
                </q-btn> -->
              <!-- </div> -->
            </div>


            <!-- NOTE - 일시 비활성화 -->
            <!-- <label>그룹 색상</label>
            <div :style="{width: '100%'}">
              <q-btn
              square
              outlined
              flat
              unelevated
              class="full-width"
              v-model="emptyVal"
              :style="{backgroundColor: `${groupColor}`, cursor:'pointer'}"
              @click="colorOpen = !colorOpen"
              ></q-btn>
            </div>
           
            <q-color
              v-if="colorOpen"
              v-model="groupColor"
              no-header
              default-view="palette"
              class="my-picker"
            /> -->
          </q-form>
          </q-card-section>
        </q-card>
    </q-expansion-item>
  </div>
</template>

<style lang="scss">
.GroupRightComponent {
  .NoneContents{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: #e2e6f3;
    padding: 15px;
  }
  .groupInfoExpansion {
    background-color: #e2e6f3;
    color: #000000;
    font-size: 17px;
    border: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 60px;
    .q-item__label {
      font-weight: bold;
    }
  }

  .groupRightContents {
    background-color: #f2f4fb;
    color: black;
    label {
      font-weight: bold;
    }
  }

  .q-field--outlined .q-field__control {
    height: 30px;
    margin-bottom: 0px;
  }

  .grouplightList {
    height: 300px;
    padding: 5px;
    overflow: auto;
    background-color: #e2e6f3;
    .grouplightListRow {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      .lightDelBtn {
        cursor: pointer;
      }
    }
  }

  label {
    font-size: 13px;
  }
}
</style>
