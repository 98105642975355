import type { DeviceShape, NodeElementType, NodeType } from "@/global/enums";
import "reflect-metadata";
/**
 * 추상 노드 컨피그 (노드 설정)
 * @abstract
 */
abstract class AbstractNodeConfig {
  
  public readonly id;
  public readonly name;
  public readonly nodeType;
  public readonly nodeElementType;
  public readonly w;
  public readonly h;
  public readonly inPortCount;
  public readonly outPortCount;
  public readonly description;
  public readonly shapeType;

  //리스트에 표현될 이미지
  public readonly imageUrl;  
  
  constructor(id: string, name: string, nodeType: NodeType, nodeElementType: NodeElementType, w: number, h: number, inPortCount: number, outPortCount: number, description?: string, imageUrl?: string, shapeType?: DeviceShape) {
    this.id = id;
    this.name = name;
    this.nodeType = nodeType;
    this.nodeElementType = nodeElementType;
    this.w = w;
    this.h = h;
    this.inPortCount = inPortCount;
    this.outPortCount = outPortCount;
    this.description = description;
    this.imageUrl = imageUrl;    
    this.shapeType = shapeType;
  }  
}

export default AbstractNodeConfig;