<script setup lang="ts">
import { SizeType } from "@/global/enums";
import type ModelFile from "@/models/entity/model-file";
import type Zone from "@/models/entity/zone";
import { useCommonStore } from "@/stores/common-store";
import { useEditStore } from "@/stores/edit-store";
import CommonUtils from "@/utils/common-util";
import JSzip from "jszip";
// import JSzipUtil from "jszip-utils";
import { ref } from "vue";


const editStore = useEditStore();
const commonStore = useCommonStore();
const jszip = new JSzip();
// const jsziputil = new JSzipUtil();
const file = ref();

const props = defineProps<{
  selectSpace: Zone | null;
}>();


// const rspaceInfo = computed(() => {
//   return prfops.selectSpace;
// });
  
// if(CommonUtils.isNullOrEmpty(props.selectSpace))
//   return;
  
//const rspaceInfo = reactive(props.selectSpace as Zone);
//const rspaceInfo = ref(CommonUtils.deepClone(props.selectSpace) ?? null);
  
console.log("★ 우측창 로드~~~~!!!");
  
  
//const radioFixValue = commonStore.getFindCodeToName("rzoneTypeCode", rspaceInfo.value?.rzone?.rzoneTypeCode as string);
  
//console.log(rspaceInfo);
  
function inputModel() {
  const picker = document.querySelector("[name='modelPicker']") as HTMLElement;
  console.log(picker);
  picker?.click();
}

  
async function changeModel(){
  console.log(file.value);
  let model: Blob | null = null;
  const textureList: {name: string, data: Blob}[] = [];

  const zipFiles = await jszip.loadAsync(file.value);

  const zipFileName = Object.keys(zipFiles.files);

  // const zip = a.files.forEach(async (relativePath, zipEntry) => {
  //   await zipEntry.async("blob");
  // });

  for(let i = 0; i < zipFileName.length; i++){
    if(zipFileName[i].toUpperCase().includes(".FBX") === true){
      model = await zipFiles.files[zipFileName[i]].async("blob");
    } else {
      textureList.push({
        name: zipFileName[i],
        data: await zipFiles.files[zipFileName[i]].async("blob")
      });
    }
  }
  
  editStore.localEditFileInput(model as Blob, textureList, file.value);

}

async function convertModel() {
  try {
      
    if (CommonUtils.isNullOrEmpty(editStore.selectSiteInfo) || CommonUtils.isNullOrEmpty(props.selectSpace))
      return;
      
    if (props.selectSpace?.modelFile !== null) {
      const delmodelSeq = props.selectSpace?.modelFileSeq;
        
      props.selectSpace.modelFile = null;
      props.selectSpace.modelFileSeq = null;
      await editStore.modifyZone(editStore.selectSiteInfo.siteSeq, props.selectSpace);
        
      const removeResult = await editStore.removeModelFile(delmodelSeq as number);
        
      if (removeResult === "Model Delete Failed") {
        return;
      }
    }
      
    const modelFileRes = await editStore.postModelFile(file.value as unknown as File) as ModelFile;
      
    props.selectSpace.modelFile = modelFileRes;
    props.selectSpace.modelFileSeq = modelFileRes.modelFileSeq as number;  
      


    // console.log(modelFileRes);
      
    // await editStore.modifyZone(editStore.selectSiteInfo.siteSeq, editStore.selectZoneInfo);  
  } catch (e) {
    console.error(e);
  }
}

/**
 * 대표공간 이름 변경
 * @param zoneName 
 */
function onChangeRZoneName(zoneName: string) {
  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  props.selectSpace.zoneName = zoneName;
  console.log(`onChangeRZoneName => ${zoneName}`);
}

/**
 * 대표공간 원점 변경
 * @param value 
 * @param isY 
 */
function onChangeOrigin(value: number, isY = false) {

  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  if(isY)
    props.selectSpace.originY  = Number(value);    
  else
    props.selectSpace.originX  = Number(value);
}

/**
 * 대표공간 사이즈 변경
 * @param value 
 * @param sizeType 
 */
function onChangeSize(value: number, sizeType: SizeType) {
  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  switch (sizeType) {
    case SizeType.Horizontal:
      props.selectSpace.horizSize = Number(value);
      break;
    case SizeType.Vertical:
      props.selectSpace.vertiSize = Number(value);
      break;
    case SizeType.Height:
      props.selectSpace.height = Number(value);
      break;
  }
}

/**
 * 대표공간 모델파일 변경
 * @param modelFileName 
 */
function onChangeModelFile(modelFileName: string) {

  if (CommonUtils.isNullOrEmpty(props.selectSpace) || CommonUtils.isNullOrEmpty(props.selectSpace.modelFile))
    return;

  (props.selectSpace.modelFile as ModelFile).modelFileName = modelFileName;
}


</script>

<template>
  <div class="RspaceRightComponent">
    <q-expansion-item label="대표 공간 정보" header-class="rspaceInfoExpansion" default-opened :dark="false" v-if="!CommonUtils.isNullOrEmpty(props.selectSpace) && !CommonUtils.isNullOrEmpty(props.selectSpace.rzone)">
      <q-card>
        <q-card-section class="rspaceRightContents">
          <label>명칭</label>
          <q-input square bg-color="white" :dark="false" type="text" maxlength="30" outlined
            :model-value="props.selectSpace.zoneName" @change="onChangeRZoneName($event)" placeholder="대표공간 명칭" />
                
          <label>유형</label>
          <div style="margin-bottom: 10px">
            <q-input square bg-color="white" :dark="false" :disable="true" type="text" maxlength="30" outlined :model-value="commonStore.getFindCodeToName('rzoneTypeCode', props.selectSpace.rzone.rzoneTypeCode)" />
          </div>
        
          <label>원점 [m]</label>
          <div class="row horizonBorderClass">
            <label class="col-3">X</label>
            <q-input square bg-color="white" :dark="false" class="col-3" type="number" outlined :model-value="props.selectSpace!.originX" @change="onChangeOrigin($event)" placeholder="X" />
        
            <label class="col-3">Y</label>
            <q-input square bg-color="white" :dark="false" class="col-3" type="number" outlined :model-value="props.selectSpace!.originY" @change="onChangeOrigin($event, true)" placeholder="Y" />
          </div>
        
          <label>크기 [m]</label>
          <div class="borderClass">
            <div>
              <label class="col-3">가로 [m]</label>
              <q-input square bg-color="white" :dark="false" class="col-3" type="number" stack-label outlined :model-value="props.selectSpace!.horizSize" @change="onChangeSize($event, SizeType.Horizontal)" placeholder="가로" />
              <label class="col-3">세로 [m]</label>
              <q-input square bg-color="white" :dark="false" class="col-3" type="number" stack-label outlined :model-value="props.selectSpace!.vertiSize" @change="onChangeSize($event, SizeType.Vertical)" placeholder="세로" />
              <label class="col-3">높이 [m]</label>
              <q-input square bg-color="white" :dark="false" class="col-3" type="number" stack-label outlined :model-value="props.selectSpace!.height" @change="onChangeSize($event, SizeType.Height)" placeholder="높이" />
            </div>
          </div>
        
          <div v-if="editStore.selectSiteInfo?.siteClsfyCode !== 'A001'">
            <label>모델 파일</label>
            <div class="row">
              <q-input v-if="props.selectSpace!.modelFile !== null" square bg-color="white" :dark="false" class="col-10" outlined disable :model-value="(props.selectSpace!.modelFile as ModelFile).modelFileName" @change="onChangeModelFile($event)" />
              <q-file id="modelPicker" v-show="props.selectSpace!.modelFile === null" name="modelPicker" class="col-10" outlined square hide-hint :dark="false" bg-color="white" v-model="file" dense @update:model-value="changeModel" />
              <div class="col-2" :style="{ paddingTop: '5px', paddingBottom: '5px', marginBottom: '10px' }">
                <q-btn class="fit" dense outline @click="inputModel">첨부</q-btn>
              </div>
            </div>
          </div>
        
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<style lang="scss">
.RspaceRightComponent {
  .rspaceInfoExpansion {
    background-color: #e2e6f3;
    color: #404040;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 39px;
    
    .q-item__label {
      font-weight: bold;
    }
  }
  
  .rspaceRightContents {
    padding: 20px;
    background-color: #f2f4fb;
    border-bottom: 1px solid #c1c1c1;
    color: #404040;
    
    label {
      font-weight: bold;
    }
  }
  
  .q-field--outlined .q-field__control {
    height: 30px;
    margin-bottom: 10px;
  }
  
  .q-textarea .q-field__control {
    height: 75px;
  }
  
  .q-textarea .q-field__native {
    resize: none;
    padding-top: 10px;
    height: 75px;
  }
  
  label {
    margin-bottom: 0px;
    font-size: 13px;
  }
  
  .row {
    align-items: center;
    
    label {
      margin-bottom: 0px;
    }
  }
  
  .horizonBorderClass {
    padding: 5px 10px 5px 0px;
    margin-bottom: 10px;
    background-color: #e2e6f3;
    
    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }
    
    label {
      font-size: 12px;
      text-align: center;
    }
  }
  
  .borderClass {
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;
    background-color: #e2e6f3;
    
    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }
    
    label{
      margin-bottom: 10px;
    }
    
    .row {
      justify-content: space-between;
      align-items: center;
      
      label {
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }
}
</style>
