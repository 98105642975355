import type { NodeItemEventType } from "@/global/types";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";

/**
 * 노드 아이템 클래스 (노드 아이템 = 에디터 상에서 보이는 이벤트)
 * @class
*/
class NodeItem  {
  
  constructor(readonly id: string, readonly name: string, readonly imageUrl: string ) {

  }
}

interface INodeItemEvent {
  //readonly nodeTypeByEvent: NodeTypeByEvent;
  code: string;
  name: string
  makeEventOccurCond(): string;

  //NOTE: TypeScript Interface가 정적 메소드를 지원하지 않아서 사용 못함
  //static solveEventOccurCond(eventOccurCond: string): any;
}

abstract class AbstractNodeItemEvent {

  constructor(readonly nodeTypeByEvent: NodeTypeByEvent) {
        
  }
}

abstract class AbtractSelectionNodeItemEvent extends AbstractNodeItemEvent implements ISelectionEvent{
  abstract seq: number;
  abstract isChecked: boolean;
}

interface ISelectionEvent {  
  seq: number;
  isChecked: boolean;    
}



export { NodeItem, AbstractNodeItemEvent, AbtractSelectionNodeItemEvent} ;
export type { INodeItemEvent, ISelectionEvent };