import { PresenceEventType, SensorType } from "@/global/enums";
import type SensorDeviceInfo from "@/models/device/sensor-device-info";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import CommonUtils from "@/utils/common-util";
import { settings } from "pixi.js";
import { NodeItem, AbtractSelectionNodeItemEvent } from "../info/node-item-info";
import type { IPackageNodeSetting } from "./node-setting";

class ScenarioPresenceSensorDeviceNodeSetting implements IPackageNodeSetting {

  public dPointSeq: number | null = null;  
  public events: ScenarioPresenceSensorDeviceEvent[] = [];

  constructor(nodeTypeByEvents: NodeTypeByEvent[]) {
    //this.updateEvents(this._sensorType);    

    if (CommonUtils.isNullOrEmpty(nodeTypeByEvents))
      return;

    for (const nodeTypeByEvent of nodeTypeByEvents) {
      this.events.push(new ScenarioPresenceSensorDeviceEvent(nodeTypeByEvent));
    }
  }

  getNodeItems(): NodeItem[] {

    this.events.sort((a, b) => {
      return a.seq - b.seq;
    });

    const checkedEvents = this.events.filter(e => e.isChecked);

    const nodeItems: NodeItem[] = [];

    for (const event of checkedEvents) {

      const nodeItemId = event.nodeTypeByEvent.eventCode.toString();
      const nodeItem = new NodeItem(nodeItemId, event.name, "");
      nodeItems.push(nodeItem);
    }

    return nodeItems;    
  }

  setDPointSeq(dPointSeq: number | null) {
    this.dPointSeq = dPointSeq;
  }
}

class ScenarioPresenceSensorDeviceEvent extends AbtractSelectionNodeItemEvent {
  seq: number;
  isChecked = false;  

  // eventType: string;
  // eventName: string;

  constructor(nodeTypeByEvent: NodeTypeByEvent) {
    super(nodeTypeByEvent);
        
    this.seq = CommonUtils.isNullOrEmpty(nodeTypeByEvent) ? -1 : nodeTypeByEvent.sortOrder!;
  }

  get name() {
    return this.nodeTypeByEvent.eventName ?? "";
  }
}

export default ScenarioPresenceSensorDeviceNodeSetting;
export { ScenarioPresenceSensorDeviceEvent };