import type DevAsetAttr from "./dev-aset-attr";


class DevAset{
  devAsetCode?: string;
  devAsetDesc?: string;
  devAsetName?: string;
  moduleTypeCode?: string;
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;

  devAsetAttrs?: DevAsetAttr[];
}

export default DevAset;