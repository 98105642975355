class Materials {
  public material_seq?: number; // 재료 일련 번호
  public material_name?: string; // 재료 명칭
  public material_code?: string; // 재료 분류 코드
  public material_clsfy_code?: string; // 재질 분류 코드
  public therm_conduct?: number; // 열전도율
  public therm_resist?: number; // 열저항
}

export default Materials;
