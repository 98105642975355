import { AuthTokenType } from "@/global/enums";
import CommonUtils from "@/utils/common-util";
import VueCookies from "vue-cookies";
import { ref, } from "vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//const cookies: VueCookies | null = null;

const isExistRefreshToken = ref(false);
//const isRetry = ref(false);

export function useAuthManager() {

  /**
 * 리프레쉬 토큰을 가져온다.
 * @returns 
 */
  function getRefreshToken(): string | null {        
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const refreshToken = VueCookies.get(AuthTokenType.RefreshToken);

    if(CommonUtils.isNullOrEmpty(refreshToken)) {
      isExistRefreshToken.value = false;
      return null;
    } else
      return refreshToken;
  }


  /**
 * 리프레쉬 토큰을 설정한다.
 * @returns 
 */
  function setRefreshToken(refreshToken: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore    
    VueCookies.set(AuthTokenType.RefreshToken, refreshToken, "7d", "/");
    isExistRefreshToken.value = true;
    console.log(refreshToken, VueCookies);

  }

  /**
 * 리프레쉬 토큰을 삭제
 * @returns
 */
  function clearRefreshToken() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSuccess = VueCookies.remove(AuthTokenType.RefreshToken);

    if (isSuccess)
      isExistRefreshToken.value = false;

    return isSuccess;
  }

  /**
 * IsRetry(토큰 Refresh를 할 수 있는지 여부) 를 설정한다.
 * @param isRetry 
 */
  function setIsRetry(isRetry: boolean) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VueCookies.set(AuthTokenType.IsRetry, isRetry);
  }

  /**
 * IsRetry를 가져온다.
 * @returns 
 */
  function getIsRetry() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isRetryText = VueCookies.get(AuthTokenType.IsRetry);

    if (CommonUtils.isNullOrEmpty(isRetryText) || isRetryText === "")
      return false;

    return Boolean(isRetryText);
  }

  return { getRefreshToken, setRefreshToken, clearRefreshToken, isExistRefreshToken, setIsRetry, getIsRetry };
}