import type ScenarioFlow from "./scenario-flow";
import type ScenarioNode from "./scenario-node";


/**
 * Scene 클래스
 * @class
 */
class Scenario {
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;
  scenarioDesc?: string;
  scenarioName?: string;
  scenarioSeq?: number;
  siteSeq?: number;
  zoneSeq?: number;
  flows = [] as ScenarioFlow[];
  nodes = [] as ScenarioNode[];
}

export default Scenario;
