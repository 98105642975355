<script setup lang="ts">
import { ref, computed } from "vue";
import TopBarComponent from "@/components/common/TopBar/TopBarComponent.vue";
import LightContentsComponent from "@/components/Materials/Light/LightContentsComponent.vue";
import WindoorContentsComponent from "@/components/Materials/Windoor/WindoorContentsComponent.vue";
import WallContentsComponent from "@/components/Materials/Wall/WallContentsComponent.vue";
import ProfileContentsComponent from "@/components/Materials/Profile/ProfileContentsComponent.vue";
import { useMenuStore } from "@/stores/menu-store";
import { Depth2_Kind } from "@/global/enums";
import ProfileComponent from "./ProfileComponent.vue";
import DeviceComponent from "./DeviceComponent.vue";

const menuStore = useMenuStore();


const model = computed(() => {
  return menuStore.selectedMenu;
});

</script>

<template>
   <div class="col-11 col-md-10">
      <!-- <TopBarComponent></TopBarComponent> -->
        <div v-if="model?.menuKind === Depth2_Kind.조명">
          <!-- <LightContentsComponent></LightContentsComponent> -->
          <DeviceComponent></DeviceComponent>
        </div>
        <div v-if="model?.menuKind === Depth2_Kind.창호">
          <WindoorContentsComponent></WindoorContentsComponent>
        </div>
        <div v-if="model?.menuKind === Depth2_Kind.벽체">
          <WallContentsComponent></WallContentsComponent>
        </div>
        <div v-if="model?.menuKind === Depth2_Kind.용도프로필">
          <!-- <ProfileContentsComponent></ProfileContentsComponent> -->
          <ProfileComponent></ProfileComponent>
        </div>
      </div>
</template>

<style lang="scss">
.MaterialContentsComponent {
   background-color: #f2f4fb;
}
</style>
