import type AbstractActionAttrs from "@/models/attrs-maps/abstract-action-attrs";
import "reflect-metadata";
import type { NodeItem } from "../info/node-item-info";

/**
 * 노드 설정 추상 클래스
 * @abstract
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INodeSetting {  
}

interface IPackageNodeSetting extends INodeSetting {

  /**
   * 노드 아이템 리스트를 가져온다.(일반 노드의 연결할 수 있는 하위 자식 노드 개념)
   */
  getNodeItems(): NodeItem[];
}

abstract class AbstractActionNodeSetting implements INodeSetting {
  abstract attrs: AbstractActionAttrs;

  abstract solveAttrs(attrsMap: string): void;
  // public solveAttrs<T>(t: { new(): T }, attrsMap: string) {
  //   const o = CommonUtils.jsonDeserialize(t, attrsMap) as T as AbstractActionAttrs;
  //   this.attrs = o;
  // }

  public toAttrsMap(): string | null {
    const json = JSON.stringify(this.attrs);
    return json;
  }
}

abstract class AbstractPackageActionNodeSetting extends AbstractActionNodeSetting implements IPackageNodeSetting {

  constructor () {
    super();
  }

  /**
   * 노드 아이템 리스트를 가져온다.(일반 노드의 연결할 수 있는 하위 자식 노드 개념)
   */
  abstract getNodeItems(): NodeItem[];
}


export { AbstractActionNodeSetting, AbstractPackageActionNodeSetting };
export type { IPackageNodeSetting };
export default INodeSetting;