import type INodeSetting from "./node-setting";

class LightNodeSetting implements INodeSetting {
  lNum?: number;
  width?: number;
  vertical?: number;
  height?: number;
  constructor(lNum: number, width: number, vertical: number, height: number) {
    this.lNum = lNum;
    this.width = width;
    this.vertical = vertical;
    this.height = height;
  }
}

export default LightNodeSetting;