<script setup lang="ts">
import {computed} from "vue";
import { useEditStore } from "@/stores/edit-store";


const editStore = useEditStore();

const rZone = computed(() => {
  return editStore.selectZoneInfo;
});


const faces = computed(() => {
  return rZone.value?.faces;
});
const compos = computed(()=>{
  return rZone.value?.compos;
});


</script>

<template>
    <div class="MaterialListComponent"> 
        <q-list :dark="false" dense separator class="rounded-borders">
            <div :style="{padding: '15px'}">
                <span>면 컴포넌트</span>
            </div>
            <q-separator :dark="false"/>
            <q-item class="MaterialSection" :dark="false" dense bordered clickable v-for="(face, i) in faces" :key="`face${i+1}`">
                <q-item-section>
                    {{face.faceTypeCode}}
                </q-item-section>
            </q-item>
        </q-list>
        <q-list :dark="false" dense separator class="rounded-borders">
            <div :style="{padding: '15px'}">
                <span>재료 컴포넌트</span>
            </div>
            <q-separator :dark="false"/>
            
            <q-item class="MaterialSection" :dark="false" dense bordered clickable v-for="(compo, i) in compos" :key="`compo${i+1}`">
                <q-item-section>
                    {{compo.compoName}}
                </q-item-section>
            </q-item>
        </q-list>
    </div>
</template>

<style lang="scss">
.MaterialListComponent{
    background-color: #f2f4fb;
    font-weight: bold;
    font-size: 12px;
    
    .MaterialSection{
        border-bottom: 1px solid #c1c1c1;
    }
}
</style>