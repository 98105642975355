import ConfigGroup from "@/models/editor/nodes/config/config-group";
import NodeClassifiedCodeEntity from "@/models/entity/node-classified-code-entity";
import type RestClient from "@/net/rest-client";
import CommonUtils from "@/utils/common-util";
import BaseApiService from "./base-api-service";
import { HttpMethods, NodeElementType, NodeType } from "@/global/enums";
import ScenarioNodeType from "@/models/entity/scenario-node-types";
import type Code from "@/models/entity/code";
import ScenarioNodeConfig from "@/models/editor/nodes/config/scenario-node-config";

class ScenarioApiService extends BaseApiService {

  constructor(restClient: RestClient) {
    super(restClient);
  }


  /**
   * 노드 타입 별 분류코드 리스트
   */
  async getNodeClassifiedCodes() {

    // const json = JSON.stringify(ScenarioElementObj, null, 2);
    // return CommonUtils.jsonDeserialize<ConfigGroup>(ConfigGroup, json) as ConfigGroup[];
    return null;


    /*
    const entites: NodeClassifiedCodeEntity[] = [];

    let entity = new NodeClassifiedCodeEntity();
    entity.classifiedCode = 1;
    entity.nodeTypeCode = 1;
    entity.name = "시나리오 실행자";
    entites.push(entity);

    entity = new NodeClassifiedCodeEntity();
    entity.classifiedCode = 1;
    entity.nodeTypeCode = 2;
    entity.name = "스케줄러";
    entites.push(entity);

    entity = new NodeClassifiedCodeEntity();
    entity.classifiedCode = 1;
    entity.nodeTypeCode = 3;
    entity.name = "센서 디바이스";
    entites.push(entity);    

    return entites;
    */
  }

  async getScenarioConfigGroups(nodeTypeCodes: Code[]) {
    
    const res = await this.restClient.call<ScenarioNodeType[]>(HttpMethods.Get, "/v1/nodetypes", undefined, ScenarioNodeType);    
    const scenarioNodeTypes = res.data;
    
    const configGroups: ConfigGroup[] = [];

    const sortedNodeTypeCodes = nodeTypeCodes.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return a.sortOrder! - b.sortOrder!;
    });

    for(const nodeTypeCode of sortedNodeTypeCodes) {

      const configGroup = new ConfigGroup();
      configGroup.id = nodeTypeCode.code;
      configGroup.title = `${nodeTypeCode.remarks}(${nodeTypeCode.codeName})`;
      configGroups.push(configGroup);

      for (const scenarioNodeType of scenarioNodeTypes) {
        
        if (nodeTypeCode.code !== scenarioNodeType.parentNodeTypeCode)
          continue;

        //const config = new ScenarioNodeConfig(scenarioNodeType);
        const nodeType = scenarioNodeType.eventOccurNodeYn === "Y" ? NodeType.Package : NodeType.Scenario;
        const nodeElementType = scenarioNodeType.nodeTypeCode as NodeElementType;
        
        const config = new ScenarioNodeConfig(scenarioNodeType.nodeTypeCode, scenarioNodeType.nodeTypeName ?? "", nodeType, nodeElementType, scenarioNodeType.horizSize, scenarioNodeType.vertiSize, scenarioNodeType.inputPortCount, scenarioNodeType.outputPortCount, scenarioNodeType.nodeTypeDesc, "");
        config.nodeTypeByEvents = scenarioNodeType.nodeTypeByEvents;
        configGroup.configs.push(config);
      }
    }

    return configGroups;
  }
}

export default ScenarioApiService;