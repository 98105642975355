<script setup lang="ts">
import TopBarComponent from "@/components/common/TopBar/TopBarComponent.vue";
import { EditorInfo, GridSetting } from "@/models/editor/editor-setting";
import { computed, onMounted, onBeforeMount, reactive, ref, watch, onUnmounted, type WatchStopHandle } from "vue";
import DrawCanvas from "@/components/Temp/DrawCanvas.vue";
import BaseNode from "@/components/Nodes/BaseNode.vue";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useScenarioStore } from "@/stores/scenario-store";
import CommonUtils from "@/utils/common-util";
import { NodeEventType, ZoneTypeCode } from "@/global/enums";
import { useEditStore } from "@/stores/edit-store";

const editStore = useEditStore();
const nodeEditorStore = useNodeEditorStore();
const scenarioStore = useScenarioStore();

const gridSetting = new GridSetting(import.meta.env.VITE_SCENARIO_GRID_WIDTH, import.meta.env.VITE_SCENARIO_GRID_HEIGHT);
const editorInfo = reactive(new EditorInfo(gridSetting));

const tab = ref<number | null>(0);
const nodeLength = computed(() => nodeEditorStore.nodeInfos.length);

let scenarioInfoWatchStopHandle: WatchStopHandle | null = null;
let nodeLengthWatchStopHandle: WatchStopHandle | null = null;

onBeforeMount(() => {  
  nodeEditorStore.clearNodes();  
});

onMounted(async () => {
  await scenarioStore.loadScenarioConfigGroups();
  loadTabContents();

  //이 Watch가 Mounted 밖에 있으면 시나리오 추가시 Undo 스택에 추가안됨.
  scenarioInfoWatchStopHandle = watch(() => scenarioStore.selectScenarioInfo, () => {

    nodeEditorStore.clearNodes();
    loadTabContents();
  });

  //순차실행 이벤트 업데이트 Watch
  watch(nodeLength, () => {

    for (const nodeInfo of nodeEditorStore.nodeInfos) {
      scenarioStore.updateScenarioExecutors(nodeInfo as AbstractNodeInfo);
    }
  });
});

onUnmounted(() => {
  stopScenarioInfoWatch();
  stopNodeLengthWatch();
});

function stopScenarioInfoWatch() {
  if (CommonUtils.isNullOrEmpty(scenarioInfoWatchStopHandle))
    return;

  scenarioInfoWatchStopHandle();
  scenarioInfoWatchStopHandle = null;
}

function stopNodeLengthWatch() {
  if (CommonUtils.isNullOrEmpty(nodeLengthWatchStopHandle))
    return;

  nodeLengthWatchStopHandle();
  nodeLengthWatchStopHandle = null;
}

function loadTabContents() {
  if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios))
    return;

  editStore.loadZoneLights(editStore.selectSpaceInfo.zoneSeq);

  if (editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
    return;  

  let scenarioSeq = scenarioStore.selectScenarioInfo?.scenarioSeq ?? null;

  if(CommonUtils.isNullOrEmpty(scenarioStore.selectScenarioInfo)) {
    
    const scenarios = scenarioStore.getScenarios();

    if(!CommonUtils.isNullOrEmpty(scenarios))
      scenarioSeq = scenarios[0].scenarioSeq as number;
  } 
  
  tab.value = scenarioSeq;
  
  scenarioStore.import();
}

function onItemDrop(event: DragEvent) {
  const id = event.dataTransfer?.getData("selectedNodeConfigId");

  if(CommonUtils.isNullOrEmpty(id))
    return;

  scenarioStore.createNewScenarioNodeInfo(id, event.offsetX, event.offsetY);
}

function onNodesEvent(nodeEventType: NodeEventType, nodeIds: string[] | null, nodeInfos: AbstractNodeInfo[] | null) {

  switch (nodeEventType) {
    case NodeEventType.Added:
      if (CommonUtils.isNullOrEmpty(nodeIds))
        return;
      scenarioStore.createScenarioFlows(nodeIds);
      break;
    case NodeEventType.Removed:
      if (CommonUtils.isNullOrEmpty(nodeInfos))
        return;
      scenarioStore.removeScenarioNodes(nodeInfos);
      break;
    case NodeEventType.Moved:
      scenarioStore.updateNodesPoint(nodeIds);      
      break;
    case NodeEventType.Paste:      
      scenarioStore.pasteScenarioNodes();
      break;
  }

}


function onSelectedTab(scenarioSeq: number | undefined) {

  if(CommonUtils.isNullOrEmpty(scenarioSeq))
    return;

  scenarioStore.setSelectScenarioInfo(scenarioSeq);  
}
</script>

<template>
  <div class="ScenarioContentsComponent fit">
    <TopBarComponent></TopBarComponent>
    <div v-if="!CommonUtils.isNullOrEmpty(scenarioStore.selectScenarioInfo)">
      <q-tabs v-model="tab" dense align="left" class="text-white shadow-2" :breakpoint="0">
        <q-tab v-for="(scenario, i) in editStore.selectSpaceInfo?.scenarios" :key="i" :name="scenario.scenarioSeq" :label="scenario.scenarioName" @click="onSelectedTab(scenario.scenarioSeq)" />
      </q-tabs> 
    </div>
    <div class="editArea col">      
      <!-- TODO: 글자 가운데로 맞추기 -->
      <div v-if="editStore?.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존">
          단위존을 선택하세요.
      </div>
      <draw-canvas v-else v-model="editorInfo" @drop="onItemDrop" @nodesEvent="onNodesEvent">
        <template v-slot:real="{ onNodeMouseDown, onNodeMouseUp, onPortMouseDown, onPortMouseUp, onPortMouseOver, onPortMouseOut }">
          <base-node
            v-for="node in nodeEditorStore.nodeInfos"
            :nodeInfo="(node as AbstractNodeInfo)"
            :key="node.id"
            @nodeMouseDown="onNodeMouseDown"
            @nodeMouseUp="onNodeMouseUp"
            @portMouseDown="onPortMouseDown"
            @portMouseUp="onPortMouseUp"
            @portMouseOver="onPortMouseOver"
            @portMouseOut="onPortMouseOut"            
          />
        </template>
      </draw-canvas>
    </div>
  </div>
</template>

<style lang="scss">
.ScenarioContentsComponent {
  overflow: hidden;
  background-color: #181b25;

  .editArea {
    overflow: auto;
    scrollbar-width: thin;
    height: calc(100% - 110px);
  }

  &::-webkit-scrollbar-thumb {
    right: 4px;
    border-radius: 5px;
    background-color: #c1c1c1;
    width: 5px;
    opacity: 0.3;
  }
  &::-webkit-scrollbar-track {
    right: 2px;
    border-radius: 9px;
    background-color: #181b25;
    width: 9px;
    opacity: 0.2;
  }

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    height: 10px;
  }
}
</style>
