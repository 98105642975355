import { NodeElementType, NodeType } from "@/global/enums";
import type { NodeFactoryParam } from "@/global/types";
import type AbstractNodeConfig from "@/models/editor/nodes/config/abstract-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import LightNodeInfo from "@/models/editor/nodes/info/light-node-info";
import LinkNodeInfo from "@/models/editor/nodes/info/link-node-info";
import NodeInfo from "@/models/editor/nodes/info/node-info";
import ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import type INodeSetting from "@/models/editor/nodes/setting/node-setting";
import LightNodeSetting from "@/models/editor/nodes/setting/light-node-setting";
import ScenarioSequenceExecuteNodeSetting from "@/models/editor/nodes/setting/scenario-sequence-execute-node-setting";
import ScenarioConfigureLightNodeSetting from "@/models/editor/nodes/setting/scenario-configure-light-node-setting";
import ScenarioConfigurePresenseSensorNodeSetting from "@/models/editor/nodes/setting/scenario-configure-presence-sensor-node-setting";
import ScenarioControlLightNodeSetting from "@/models/editor/nodes/setting/scenario-control-light-node-setting";
import ScenarioControlSceneNodeSetting from "@/models/editor/nodes/setting/scenario-control-scene-node-setting";
import ScenarioDeviceGroupNodeSetting from "@/models/editor/nodes/setting/scenario-device-group-node-setting";
import ScenarioDeviceNodeSetting from "@/models/editor/nodes/setting/scenario-device-node-setting";
import ScenarioExecutorNodeSetting from "@/models/editor/nodes/setting/scenario-executor-node-setting";
import ScenarioScheduleNodeSetting from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import ScenarioTimeBranchNodeSetting from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import TextNodeSetting from "@/models/editor/nodes/setting/text-node-setting";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import type ScenarioNode from "@/models/entity/scenario-node";
import CommonUtils from "@/utils/common-util";
import ScenarioSensitiveSensorDeviceNodeSetting from "@/models/editor/nodes/setting/scenario-presence-sensor-device-node-setting";
import { useEditStore } from "@/stores/edit-store";

class NodeFactory {

  static create<T>(o: { new(): T }): T {
    return new o();
  }
  
  static createNodes(x: number, y: number, config: AbstractNodeConfig, params: Array<NodeFactoryParam> | null = null): AbstractNodeInfo | null {

    const id = CommonUtils.generateUUID();

    let nodeInfo: AbstractNodeInfo | null = null;
    let nodeSetting: INodeSetting | null = null;

    //시나리오 노드인 경우
    if(config.nodeType === NodeType.Scenario ||  config.nodeType === NodeType.Package) {

      if(CommonUtils.isNullOrEmpty(params))
        return null;

      const scenarioNode = params[0] as ScenarioNode;
      const nodeTypeByEvents = params[1] as NodeTypeByEvent[];
      
      nodeSetting = this.createNodeSetting(config.nodeElementType, nodeTypeByEvents);

      nodeInfo = new ScenarioNodeInfo(id, x, y, scenarioNode, config, nodeSetting);      
    } else {

      switch (config.nodeElementType) {
        case NodeElementType.Device:
          nodeSetting = new LightNodeSetting(0, 0, 0, 0);
          nodeInfo = new LightNodeInfo(id, x, y, config, nodeSetting);
          break;
        case NodeElementType.Text:
          nodeSetting = new TextNodeSetting(config.name);
          nodeInfo = new NodeInfo(id, x, y, config, nodeSetting);
          break;
        case NodeElementType.Link:
          nodeInfo = new LinkNodeInfo(id, x, y, config);
          break;
        default:
          nodeInfo = new NodeInfo(id, x, y, config, nodeSetting);
      }          
    }
    if (CommonUtils.isNullOrEmpty(nodeInfo))
      return null;

    nodeInfo.name = config.name;
    nodeInfo.desc = config.description;

    return nodeInfo;
  }

  static createNodeSetting(nodeElementType: NodeElementType,  nodeTypeByEvents: NodeTypeByEvent[]) {

    let nodeSetting: INodeSetting | null = null;

    switch(nodeElementType) {
        
      case NodeElementType.Scenario_Executor:                    
        nodeSetting = new ScenarioExecutorNodeSetting(nodeTypeByEvents);          
        break;
      case NodeElementType.Scenario_Scheduler:
        nodeSetting = new ScenarioScheduleNodeSetting();
        break;
      case NodeElementType.Scenario_Presence_SensorDevice:
        nodeSetting = new ScenarioSensitiveSensorDeviceNodeSetting(nodeTypeByEvents);          
        break;
      case NodeElementType.Scenario_SequenceExecute:
        nodeSetting = new ScenarioSequenceExecuteNodeSetting();
        break;
      case NodeElementType.Scenario_TimePeriod:
        nodeSetting = new ScenarioTimeBranchNodeSetting();
        break;
      case NodeElementType.Scenario_ControlLight:
        nodeSetting = new ScenarioControlLightNodeSetting();
        break;
      case NodeElementType.Scenario_ControlScene:
        nodeSetting = new ScenarioControlSceneNodeSetting();
        break;
      case NodeElementType.Scenario_Configure_Light:
        nodeSetting = new ScenarioConfigureLightNodeSetting();
        break;
      case NodeElementType.Scenario_Configure_Presense_Sensor:
        nodeSetting = new ScenarioConfigurePresenseSensorNodeSetting(nodeTypeByEvents);          
        break;
      case NodeElementType.Scenario_DeviceGroup:
        nodeSetting = new ScenarioDeviceGroupNodeSetting();
        break;
      case NodeElementType.Scenario_Device:
        nodeSetting = new ScenarioDeviceNodeSetting();
        break;
    }

    return nodeSetting;
  }

  static createNodeConfig<T>(args: T): T {
    return args;
  }

  static createPorts(): void {

  }
}

export default NodeFactory;