import { HttpMethods } from "@/global/enums";
import Materials from "@/models/Components/Materials";
import Code from "@/models/entity/code";
import ImageFile from "@/models/entity/image-file";
import Profile from "@/models/profile";
import type { RequestParameter } from "@/net/rest-client";
import type RestClient from "@/net/rest-client";
import BaseApiService from "./base-api-service";

class EtcApiService extends BaseApiService {
  constructor(restClient: RestClient) {
    super(restClient);
  }

  /**
   * 공통 코드 목록 조회
   * @param grpCode 그룹 코드
   * @param pCode 상위 코드
   * @returns 코드 리스트
   */
  async getCodes(grpCode?: string, pCode?: string) {

    const requestParams: RequestParameter = {
      params: { grpCode: grpCode ?? null, pCode: pCode ?? null }
    };

    const res = await this.restClient.call<Code[]>(HttpMethods.Get, "/v1/codes", requestParams, Code);
    //console.log(`Get Codes ==> ${JSON.stringify(res.data, null, 2)}`);
    
    return res.data;
  }
  
  /**
   * 용도 프로필 목록 조회
   * @returns 
   */
  async getUsageProfileCode(){
    const res = await this.restClient.call<Profile[]>(HttpMethods.Get, "/v1/usage-profiles", {}, Profile);

    return res.data;
  }

  /**
   * 재료 목록 조회
   * @returns 
   */
  async getMaterials(){
    const res = await this.restClient.call<Materials[]>(HttpMethods.Get, "/v1/materials", {}, Materials);

    return res.data;
  }

  /**
   * 이미지 파일 제거
   * @param imageFileSeq 
   * @returns 
   */
  async removeImage(imageFileSeq: number){
    try {
      await this.restClient.call(HttpMethods.Delete, `/v1/imagefiles/${imageFileSeq}`);

      return "Done";
    } catch(e){
      return "error";
    }
  }

  /**
   * 이미지 파일 등록
   * @param imageFile 
   * @returns 
   */
  async postImage(imageFile: File){
    const imageFormData = new FormData();
    imageFormData.append("files", imageFile);

    const requestParams: RequestParameter = {
      form: imageFormData
    };

    const res = await this.restClient.call<ImageFile[]>(HttpMethods.Post, "/v1/imagefiles", requestParams, ImageFile);
  
    return res.data[0];
  }

  /**
   * 이미지 파일 받기
   * @param imageFileSeq 
   * @returns 
   */
  async getImage(imageFileSeq: number){
    const res = await this.restClient.call(HttpMethods.Get, `/v1/imagefiles/${imageFileSeq}`, undefined);

    return res.data;

  }

  /**
   * 모델 파일 받기
   * @param modelFileSeq 
   * @returns 
   */
  async getModel(modelFileSeq: number){
    const res = await this.restClient.call(HttpMethods.Get, `/v1/modelfiles/${modelFileSeq}`, undefined);
    console.log(res);
    return res.data;
  }

  async getModelTexture(modelFileSeq: number, textureFileSeq: number, textureFileName: string){
    const res = await this.restClient.call(HttpMethods.Get, `/v1/modelfiles/${modelFileSeq}/textures/${textureFileSeq}`);
    console.log(res);
    return {
      name: textureFileName,
      data: res.data
    };
  }

  async getModelTextureName(modelFileSeq: number, textureFileSeq: number, textureFileName: string){
    const res = await this.restClient.call(HttpMethods.Get, `/v1/modelfiles/${modelFileSeq}/texturefiles/${textureFileName}`);
    console.log(res);
    return {
      name: textureFileName,
      data: res.data
    };
  }
  
  /**
   * 3D 모델 파일 업로드
   * @param modelFile // 모델 및 텍스쳐 압축 파일
   * @returns 
   */
  async uploadModel(modelFile: File){
    const data = new FormData();
    data.append("file", modelFile);

    const requestParams: RequestParameter = {
      form: data
    };

    const res = await this.restClient.call(HttpMethods.Post, "/v1/modelfiles", requestParams);

    return res.data;
  }

  async removeModel(modelFileSeq: number){

    try {
      await this.restClient.call(HttpMethods.Delete, `/v1/modelfiles/${modelFileSeq}`);

      return "done";
    } catch(e) {
      console.error(e);
      return "Model Delete Failed";
    }

  }
}

export default EtcApiService;