import { HttpMethods } from "@/global/enums";
import LoginInfo from "@/models/entity/login-info";
import User from "@/models/user";
import type { RequestParameter } from "@/net/rest-client";
import type RestClient from "@/net/rest-client";
import type { AxiosError } from "axios";
import BaseApiService from "./base-api-service";

/**
 * 유저 & SignIn 관련 서비스
 * @class
 */
class UserApiService extends BaseApiService {
  constructor(restClient: RestClient) {
    super(restClient);

    // const cookieList = document.cookie.split(";");
    // let defaultToken = "";
    // for (let i = 0; i < cookieList.length; i++) {
    //   if (cookieList[i].split("=")[0] === "access_token") {
    //     defaultToken = cookieList[i].split("=")[1];
    //   }
    // }

    // if (defaultToken !== "") {
    //   this.restClient.setHeaderToken(defaultToken);
    // }
  }

  async login(email: string, password: string) {
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);

    const requestParams: RequestParameter = {
      form: data,
    };

    // const requestParams: RequestParameter = {
    //   headers: { "Content-Type": "application/json"},
    //   params: {
    //     email: email,
    //     password: password
    //   }
    // };
    try{
      const res = await this.restClient.call(HttpMethods.Post, "/v1/signin", requestParams, LoginInfo);
      console.log(res);
      console.log(`Login AccessToken!! -> ${res.data?.accessToken}`);
      console.log(`refresh_Token => ${res.data.refreshToken}`);
  
      this.restClient.setHeaderToken(res.data?.accessToken, res.data?.refreshToken);
      return res.data;
    } catch(e: AxiosError | unknown){
      return "login failed";
    }
  
  }

  async loginWithToken() {
    const res = await this.restClient.call(HttpMethods.Post, "/v1/signin/token", undefined, User);
    return res.data;

  }

  async logout(userId: number) {
    const res = await this.restClient.call(HttpMethods.Post, `/v1/users/${userId}/logout`);
    this.restClient.setHeaderToken(null);
    return res.data;
  }

  async getUser(userId: number) {
    const res = await this.restClient.call(HttpMethods.Get, `/v1/users/${userId}`, undefined, User);
    return res.data;
  }

  async updateToken(refreshToken: string) {
    try {
      const data = new FormData();
      data.append("token", refreshToken);  

      const requestParams: RequestParameter = {
        form: data,
      };
      const res = await this.restClient.call(HttpMethods.Post, "/v1/signin/update-token", requestParams, LoginInfo);      

      if (res.status !== 200)
        return null;

      this.restClient.setHeaderToken(res.data?.accessToken, res.data?.refreshToken);
      return res.data;
    } catch {
      return null;
    }
  }
}

export default UserApiService;
