<script setup lang="ts">
import { ref } from "vue";


const props = defineProps<{
  spaceModal: boolean,
  type: string | null,
}>();

const emit = defineEmits(["update:spaceModal"]);

function resetNoneSelect() {
  emit("update:spaceModal", false, props.type);
}
</script>

<template>
  <div class="NoneSelectModal">
    <q-dialog v-model="spaceModal">
      <q-card>
        <q-card-section>
          <div class="text-h6">오류</div>
        </q-card-section>

        <q-card-section class="q-pt-none"> 추가할 상위 공간을 선택해주세요. </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="resetNoneSelect" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style lang="scss"></style>
