import type { SimulationCommonParameter } from "@/global/types";
import type SimulationResult from "@/models/entity/simulation-result";
import type Zone from "@/models/entity/zone";
import type ZoneSimul from "@/models/entity/zone-sml-type";
import type ZoneSimulParameter from "@/models/entity/zone-simul-parameter";
import CommonUtils from "@/utils/common-util";
import { defineStore } from "pinia";
import { useEditStore } from "./edit-store";

import type ZoneSml from "@/models/entity/zone-sml";
import ZoneSmlType from "@/models/entity/zone-sml-type";
import type ZoneSmlAdjust from "@/models/entity/zone-sml-adjust";

export const useSimulationStore = defineStore("simulationStore", {
  state: () => ({
    SelectZoneInfo: "",
    uzoneList: [] as Zone[],
    SimulationInfo: {} as ZoneSimulParameter,
    ZoneSimuls: [] as ZoneSimul[],
    SelectZoneData:"",
    simulResult: {} as SimulationResult,
    selectZoneSml: {} as ZoneSml,
    selectZoneSmlType: null as ZoneSmlType | null
  }),
  getters: {
    getSimulationInfo(state){
      if(useEditStore().selectSpaceInfo){
        return state.SimulationInfo;
      } else {
        return {} as ZoneSimulParameter;
      }
    }
  },
  actions: {
    initData(){
      this.uzoneList = [];
      const zoneInfo = useEditStore().selectZoneInfo;

      zoneInfo?.childZones.map(zone => {
        if(zone.zoneTypeCode === "U"){
          this.uzoneList.push(zone);
        }
        if(zone.childZones){
          zone.childZones.map(uzone => {
            if(uzone.zoneTypeCode === "U"){
              this.uzoneList.push(uzone);
            }
          });
        }1;
      });
    },
    async getZoneSmlData(siteSeq: number, zoneSeq: number){
      const result = await this.apiServices.simulationApiService.getZoneSmlData(siteSeq, zoneSeq);
      console.log(result);
      
      this.selectZoneSml = result;

      if(this.selectZoneSml.zoneSmlAdjusts === null){
        this.selectZoneSml.zoneSmlAdjusts = [];
      }

      if(this.selectZoneSml.zoneSmlTypes === null){
        this.selectZoneSml.zoneSmlTypes = [];
      }

      if(this.selectZoneSml.zoneSmlWindoors === null){
        this.selectZoneSml.zoneSmlWindoors = [];
      }

      return this.selectZoneSml;

    },
    getZoneSmlType(simulTypeCode: string){
      // this.selectZoneSmlType = this.selectZoneSml.zoneSmlTypes?.find(v => v.simulTypeCode === simulTypeCode) as ZoneSmlType;

      // if(this.selectZoneSmlType !== undefined && this.selectZoneSmlType !== null){
      //   return this.selectZoneSml;
      // }

      // if(simulTypeCode === "SL"){
      //   this.selectZoneSmlType = CommonUtils.deepClone(this.selectZoneSml.zoneSmlTypes?.find(v => v.simulTypeCode === "LL") as ZoneSmlType);
      //   if(this.selectZoneSmlType !== undefined){
      //     this.selectZoneSmlType.simulTypeCode = simulTypeCode;
      //   }
      // } else {
      //   this.selectZoneSmlType = this.selectZoneSml.zoneSmlTypes?.find(v => v.simulTypeCode === simulTypeCode) as ZoneSmlType;
      // }

      this.selectZoneSmlType = this.selectZoneSml.zoneSmlTypes?.find(v => v.simulTypeCode === simulTypeCode) as ZoneSmlType;

      if(this.selectZoneSmlType === undefined || this.selectZoneSmlType === null){
        const newType = new ZoneSmlType(simulTypeCode);
        if(simulTypeCode === "LL" || simulTypeCode === "TL"){
          newType.lightCtrlSystemCode = "LC01";
        }
        this.selectZoneSml.zoneSmlTypes?.push(newType);
        this.selectZoneSmlType = newType;
      }

      console.log(this.selectZoneSmlType);

      return this.selectZoneSmlType;
    },

    getZoneSmlWindoor(){
      return this.selectZoneSml.zoneSmlWindoors;
    },

    // async getSimulationData(siteSeq: number, zoneSeq: number){
    //   const result = await this.apiServices.simulationApiService.getSimulationData(siteSeq, zoneSeq);
    //   this.SimulationInfo = result;
    //   this.ZoneSimuls = CommonUtils.deepClone(result.zoneSml) as ZoneSimul[];
    //   delete this.SimulationInfo.ZoneSml;
    //   return result;
    // },
    
    async setSimulationData(siteSeq: number, zoneSeq: number, simulData: ZoneSimulParameter){
      const result = await this.apiServices.simulationApiService.setSimulationData(siteSeq, zoneSeq, simulData);
      this.simulResult = result;
      return result;
    }
  }
});