<script setup lang="ts">
import { ref, onMounted, onBeforeMount } from "vue";
import { useUserStore } from "@/stores/user-store";
import { useRouter } from "vue-router";
import { useSettingStore } from "@/stores/setting-store";
import AlertWarningComponent from "../common/AlertWarningComponent.vue";
import type { alertMessageType } from "@/global/types";

const settingStore = useSettingStore();

// store 호출 (store 생성자)
const userStore = useUserStore();

// store action 함수 호출 (초기 Users 데이터 등록)
// store getter 함수 호출 (store user정보를 component로 이동)
const userInfos = userStore.getUser;
console.log(userInfos);

const isLoginFailed = ref(false);
const loginFailedMessage = ref({
  message: "",
} as alertMessageType);


const router = useRouter();

console.log(import.meta.env.MODE);
// const email = ref("sample1@kbet.or.kr");
//const email = ref("ygkim0014@kbet.or.kr");
const email = ref("");
const password = ref("");
const failLoginPopup = ref(false);
const failEmailPopup = ref(false);

console.log(userStore);

// onBeforeMount(() => {
//   router.replace({query: {}});
// });

onMounted(() => {  
  //router.replace({query: {}});
});

async function login() {
  // 이메일 유효성 검사, 로그인 정보 일치하지 않았을 때 팝업

  /*
  if (password.value === "1234") {
    console.log(router);

    settingStore.load();
    router.push({ name: "site" });

    // const result = await store.checkUser(loginInfo);
    // console.log(result)

    // if(result === '이메일 없음'){
    //   failEmailPopup.value = true;
    // }
    // else if(result === '패스워드 틀림' ){
    //   failLoginPopup.value = true;
    // }
    // else{
    //   router.push({ name: "main" });
    // }
  }

  */

  const loginInfo = await userStore.login(email.value, password.value);
  console.log(loginInfo);

  if(loginInfo === "login failed"){
    loginFailedMessage.value.message = "로그인에 실패하였습니다. 로그인 정보를 다시 확인해주세요.";
    isLoginFailed.value = true;
  } else {
    settingStore.load();
    router.push({ name: "site" });
  }

}

function closeAlert(state: boolean){
  isLoginFailed.value = state;
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <AlertWarningComponent :static-value="isLoginFailed" :confirm-value="false" :message="loginFailedMessage" @change="closeAlert"></AlertWarningComponent>

      <q-page class="window-height window-width row justify-center items-center" :style="{backgroundColor: '#0f1837'}">
        <div class="col-9" :style="{height: '100%'}" >
          <q-img :src="'/login_bg.png'" :fit="'cover'" :style="{height: '100%'}"></q-img>
        </div>
        <div class="col-3 column" :style="{height: '100%', justifyContent: 'space-around'}">
          <q-img :src="'/login_logo.png'" width="200px" :style="{alignSelf: 'center', flexGrow:3}" :fit="'contain'"></q-img>

          <div class="justify-center items-center" :style="{padding: '40px', flexGrow: 3}">
            <!-- <h5 class="text-h5 text-white q-my-md">스마트 조명</h5> -->
            <q-form style="border-bottom: 1px solid #ced4da; padding: 0 0 15px 0" @keydown.enter="login" >
              <q-input outlined v-model="email" type="email" placeholder="이메일 주소" />
              <q-input outlined v-model="password" type="password" placeholder="비밀번호" />
              <!-- <q-input outlined v-model="password" type="password" placeholder="비밀번호" /> -->
              <q-btn unelevated color="primary" class="full-width" label="로그인" @click="login" style="height: 43px" />
            </q-form>
          </div>
          <div>
            <p class="text-center" style="margin: 20px 0 20px 0; color: #515f93">© Smart Ligthing System All Right Reserved 2022</p>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>

  <!-- 로그인 정보 일치하지 않은 경우 팝업 -->
  <q-dialog v-model="failLoginPopup" persistent>
    <q-card style="width: 300px; background: white; color: black">
      <q-card-section>
        <div class="text-h7"><q-icon name="warning"></q-icon>로그인 오류</div>
      </q-card-section>

      <q-card-section class="q-pt-none"> 로그인 정보가 올바르지 않습니다. </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="확인" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="failEmailPopup" persistent>
    <q-card style="width: 300px; background: white; color: black">
      <q-card-section>
        <div class="text-h7"><q-icon name="warning"></q-icon>이메일 오류</div>
      </q-card-section>

      <q-card-section class="q-pt-none"> 이메일을 입력해 주세요. </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="확인" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.q-field--outlined .q-field__control {
  height: 45px;
  min-width: unset;
}

.selectfile.q-field--auto-height .q-field__native {
  height: 45px;
  min-width: unset;
}
</style>
