import { Direction, NodeStatus, NodeType, PortType } from "@/global/enums";
import type AbstractNodeConfig from "@/models/editor/nodes/config/abstract-node-config";
import CommonUtils from "@/utils/common-util";
import type INodeSetting from "../setting/node-setting";
import type { NodeItem } from "./node-item-info";
import PortInfo from "./port-info";
import "reflect-metadata";
import type ScenarioNodeInfo from "./scenario-node-info";
import type ScenarioExecutorNodeSetting from "../setting/scenario-executor-node-setting";
import type { IPackageNodeSetting } from "../setting/node-setting";

/**
 * @abstract 노드 정보 추상 클래스
 */
abstract class AbstractNodeInfo {
  public id;
  public name?: string;
  public desc?: string;

  public x;
  public y;
  public w;
  public h;
  public height;

  public dx = 0;
  public dy = 0;
  public ox = 0;
  public oy = 0;

  public nodeStatus = NodeStatus.Normal;

  public ports: PortInfo[] = [];
  private _nodeItems?: NodeItem[];

  public readonly nodeConfig;
  public readonly nodeSetting;

  constructor(id: string, x: number, y: number, nodeConfig: AbstractNodeConfig, nodeSetting: INodeSetting | null = null) {


    this.id = id;
    this.x = x;
    this.y = y;
    this.height = 0;

    this.nodeConfig = nodeConfig;
    this.nodeSetting = nodeSetting;

    this.w = nodeConfig?.w ?? 0;
    this.h = nodeConfig?.h ?? 0;

    if(CommonUtils.isNullOrEmpty(nodeConfig))
      return;

    this.updateNodeItems();
  }

  /**
   * 포트 클리어
   */
  private clearPorts() {
    this.ports.splice(0);
  }

  /**
   * 노드 포지셔닝 시작
   * @param event
   */
  startPositioning(scale: number, snap: number, event: MouseEvent) {    
    this.ox = this.x;
    this.oy = this.y;
    this.dx = event.offsetX / scale;
    this.dy = event.offsetY / scale;

    console.log(`startPositioning -> ox: ${this.ox}, oy: ${this.oy}, dx: ${this.dx}, dy: ${this.dy},`);

    this.nodeStatus = NodeStatus.Selected;
  }

  /**
   * 노드 포지션 업데이트
   * @param event 마우스 이벤트
   * @returns
   */
  updatePosition(scale: number, snap: number,  event: MouseEvent) {
    if (this.nodeStatus === NodeStatus.Normal)
      return;
        
    this.x = Math.round((this.ox - (this.dx - event.offsetX / scale)) / snap) * snap;
    this.y = Math.round((this.oy - (this.dy - event.offsetY / scale)) / snap) * snap;

    //console.log(`updatePosition => x: ${this.x}, y: ${this.y}, w: ${this.w}, h: ${this.h}, scale: ${scale}` );

    // const dpoints = useEditStore().selectDpointList as InDPoint[];

    // dpoints.forEach(v => {
    //   if(v.nodeID === this.id){
    //     v.centerX = this.x;
    //     v.centerY = this.h;
    //     v.centerZ = this.y;
    //   }
    // });
  }

  /**
   * 노드 이동 (키보드)
   * @param direction 방향
   */
  movePosition(direction: Direction, snap: number) {
    switch (direction) {
      case Direction.Left:
        this.x -= snap;
        break;
      case Direction.Right:
        this.x += snap;
        break;
      case Direction.Up:
        this.y -= snap;
        break;
      case Direction.Down:
        this.y += snap;
        break;
    }
  }

  /**
   * 노드 포지셔닝 종료
   */
  endPositioning(isNormal = true) {
    this.dx = 0;
    this.dy = 0;
    this.ox = 0;
    this.oy = 0;

    if(isNormal)
      this.nodeStatus = NodeStatus.Normal;
  }

  /**
   * 노드의 포트 생성
   * @returns
   */
  createPorts() {
    const portSizeW = 8;
    const portSizeH = 8;

    for (let i = 0; i < this.nodeConfig.inPortCount; i++) {
      const portInfo = new PortInfo(portSizeW, portSizeH);

      portInfo.x = 0 - portInfo.sizeWidth / 2;
      portInfo.y = this.h / 2 - portInfo.sizeHeight / 2;
      portInfo.portType = PortType.In;

      this.ports.push(portInfo);
    }

    for (let i = 0; i < this.nodeConfig.outPortCount; i++) {
      const portInfo = new PortInfo(portSizeW, portSizeH);
      portInfo.x = this.w - portInfo.sizeWidth / 2;
      portInfo.y = this.h / 2 - portInfo.sizeHeight / 2;
      this.ports.push(portInfo);
      portInfo.portType = PortType.Out;
    }

    if (this.nodeConfig.nodeType !== NodeType.Package)
      return;

    const packageNodeSetting = this.nodeSetting as IPackageNodeSetting;
    const nodeItems = packageNodeSetting.getNodeItems();

    for (let i = 0; i < nodeItems.length; i++) {
      const nodeItem = nodeItems[i];

      const portInfo = new PortInfo(portSizeW, portSizeH);
      portInfo.x = this.w - portInfo.sizeWidth / 2;
      portInfo.y = this.h / 2 - portInfo.sizeHeight / 2 + (i + 1) * this.h;
      this.ports.push(portInfo);
      portInfo.portType = PortType.Out;
      portInfo.nodeItemId = nodeItem.id;
    }
  }

  getPort(portType: PortType, nodeItemId?: string) {
    if(CommonUtils.isNullOrEmpty(nodeItemId))
      return this.ports.find(n => n.portType === portType);
    else
      return this.ports.find(n => n.portType === portType && n.nodeItemId === nodeItemId);
  }

  removeNodeItem(nodeItemId: string) {
    if (CommonUtils.isNullOrEmpty(this._nodeItems))
      return;

    //노드 아이템 삭제
    const nodeItemindex = this._nodeItems.findIndex((n) => n.id === nodeItemId);

    if (nodeItemindex < 0)
      return;

    this._nodeItems.splice(nodeItemindex, 1);

    //포트 삭제
    const portIndex = this.ports.findIndex((p) => p.nodeItemId === nodeItemId);

    if (portIndex < 0)
      return;

    this.ports.splice(portIndex, 1);

  }

  /**
   * 노드 아이템 업데이트
   * @returns
   */
  updateNodeItems() {
    try {
      if (CommonUtils.isNullOrEmpty(this.nodeSetting))
        return;
    
      if(this.nodeConfig.nodeType !== NodeType.Package)
        return;
      
      this._nodeItems = (this.nodeSetting as IPackageNodeSetting).getNodeItems();
    } finally {
      this.clearPorts();
      this.createPorts();
    }
  }

  get nodeItems() {
    return this._nodeItems;
  }  
}

export default AbstractNodeInfo;
