import { defineStore } from "pinia";
import type AbstractNodeConfig from "@/models/editor/nodes/config/abstract-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { Depth1_Kind, Direction, EditorMode, NodeAlignMode, NodeElementType, NodeIntervalMode, NodeStatus, NodeType, PortType } from "@/global/enums";
import type LinkNodeInfo from "@/models/editor/nodes/info/link-node-info";
import type { LinkPoint, MinMaxPosition, NodeFactoryParam, Position } from "@/global/types";
import CommonUtils from "@/utils/common-util";
import DrawUtil from "@/utils/draw-util";
import LinkInfo from "@/models/editor/nodes/info/link-info";
import NodeFactory from "@/core/node-factory";
import LinkNodeConfig from "@/models/editor/nodes/config/link-node-config";
import type PortInfo from "@/models/editor/nodes/info/port-info";
import type { EditorInfo } from "@/models/editor/editor-setting";
import { instanceToInstance, plainToClass, plainToInstance } from "class-transformer";
import ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import ScenarioNodeConfig from "@/models/editor/nodes/config/scenario-node-config";
import { useMenuStore } from "./menu-store";

export const useNodeEditorStore = defineStore("nodeEditor", {
  state: () => ({
    editorInfo: {} as EditorInfo,
    nodeConfigs: [] as AbstractNodeConfig[],
    nodeInfos: [] as AbstractNodeInfo[],
    tempNodeInfos: [] as AbstractNodeInfo[],
    selectionNodeInfo: null as AbstractNodeInfo | null | undefined, //마우스로 선택한 단일 노드 정보
    startPortNode: null as AbstractNodeInfo | null,
    startPortInfo: null as PortInfo | null,
    copyingNodes: [] as AbstractNodeInfo[],
  }),
  getters: {
    getNodes(state): Array<AbstractNodeInfo> {
      return state.nodeInfos as AbstractNodeInfo[];
    },
    getAllLinkNodes(state) {
      return state.nodeInfos.filter(n => n.nodeConfig.nodeType === NodeType.Link) as LinkNodeInfo[];
    },
    getLinkNodes() {
      return (nodeId: string) => this.getAllLinkNodes.filter(ln => ln.srcNodeId === nodeId || ln.targetNodeId === nodeId) as LinkNodeInfo[];
    },
    getFromSrcLinkNodes() {
      return (nodeId: string) => this.getAllLinkNodes.filter(ln => ln.srcNodeId === nodeId) as LinkNodeInfo[];
    },
    getTempNodes(state) {
      return state.tempNodeInfos as AbstractNodeInfo[];
    },

    //선택된 노드들을 가져온다.
    getSelectedNodes(state) {
      return (isIncludeLink: boolean) => {
        
        if (isIncludeLink) {

          const nodes: AbstractNodeInfo[] = [];

          const selectedNodes = state.nodeInfos.filter(n => n.nodeStatus === NodeStatus.Selected) as AbstractNodeInfo[];

          for(const n of selectedNodes) {

            nodes.push(n);

            if(n.nodeConfig.nodeType === NodeType.Link)
              continue;

            const linkNodes = this.getLinkNodes(n.id);      

            if(CommonUtils.isNullOrEmpty(linkNodes))
              continue;

            for(const ln of linkNodes) {

              const findLinkNode = nodes.find(n => n.id === ln.id);

              if(!CommonUtils.isNullOrEmpty(findLinkNode))
                continue;

              nodes.push(ln);
            }          
          }

          return nodes;
        }
        else
          return state.nodeInfos.filter(
            (n) => n.nodeStatus === NodeStatus.Selected && n.nodeConfig.nodeElementType !== NodeElementType.Link) as AbstractNodeInfo[];
      };
    },    
    getNode() {
      return (nodeId: string) => {
        const node = this.getNodes.find((n) => n.id === nodeId);

        if (CommonUtils.isNullOrEmpty(node)) 
          return null;

        return node;
      };
    },
    getNodeConfigs(state) {
      return state.nodeConfigs as AbstractNodeConfig[];
    },
    getNodesMinMaxPos() {
      return (nodes: AbstractNodeInfo[]) => {
        const pos: MinMaxPosition = {
          minX: 0,
          maxX: 0,
          minY: 0,
          maxY: 0,
        };

        let maxW = 0;
        let maxH = 0;
        //Performace를 위해 for문으로 구현

        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (i === 0) {
            pos.minX = node.x;
            pos.maxX = node.x;

            pos.minY = node.y;
            pos.maxY = node.y;

            maxW = node.w;
            maxH = node.h;
          }

          if (pos.minX > node.x) pos.minX = node.x;

          if (pos.maxX < node.x) {
            pos.maxX = node.x;
            maxW = node.w;
          }

          if (pos.minY > node.y) pos.minY = node.y;

          if (pos.maxY < node.y) {
            pos.maxY = node.y;
            maxH = node.h;
          }
        }

        pos.maxX += maxW;
        pos.maxY += maxH;

        return pos;
      };
    },
    // getPort() {
    //   return (nodeInfo: AbstractNodeInfo, portType: PortType, nodeItemId: string) => {
    //     return nodeInfo.ports.find(n => n.portType === portType && n.nodeItemId === nodeItemId);
    //   };
    // }
  },
  actions: {
    setEditorInfo(editorInfo: EditorInfo) {
      this.editorInfo = editorInfo;
    },

    addNodeConfig(nodeConfig: AbstractNodeConfig) {
      this.nodeConfigs.push(nodeConfig);
    },

    clearNodeConfigs() {
      this.nodeConfigs.splice(0);
    },

    //링크 추가전 제약사항 체크
    checkLinkCondition(startLinkPoint: LinkPoint, endLinkPoint: LinkPoint) {
      if (startLinkPoint.portType === endLinkPoint.portType)
        return false;

      if (CommonUtils.isNullOrEmpty(startLinkPoint.nodeId) || CommonUtils.isNullOrEmpty(endLinkPoint.nodeId))
        return false;

      const linkNodes = this.getAllLinkNodes;

      const linkData = CommonUtils.getCorrectLinkData(startLinkPoint, endLinkPoint);

      const srcNodeId = linkData[0];
      const targetNodeId = linkData[1];
      const nodeItemId = linkData[1];

      //소스 노드와 타겟 노드 아이가 동일한 LinkNode가 존재하면 condition false
      if (linkNodes?.some((n) => n.srcNodeId === srcNodeId && n.targetNodeId === targetNodeId && n.srcNodeItemId === nodeItemId))
        return false;
      else
        return true;
    },

    /**
     * 노드 생성
     * @param x X좌표
     * @param y Y좌표
     * @param nodeConfig nodeConfig
     * @param isLink 연결 노드 인지 여부
     * @returns 노드
     */        
    createNode(x: number, y: number, nodeConfig: AbstractNodeConfig, params: Array<NodeFactoryParam> | null = null) {
      const nodeInfo = NodeFactory.createNodes(x, y, nodeConfig, params);

      if (nodeInfo === null)
        return null;

      // if(nodeItems !== null)
      //   nodeInfo.nodeItems = nodeItems;

      //Store에 노드 추가
      this.nodeInfos.push(nodeInfo);

      return nodeInfo;
    },

    /**
     * 연결 노드 생성
     * @param linkInfo 연결 노드
     * @param startLinkPoint 시작 연결 포인트
     * @param endLinkPoint 종료 연결 포인트
     * @returns 연결 노드
     */
    createLinkNode(linkInfo: LinkInfo, startLinkPoint: LinkPoint, endLinkPoint: LinkPoint) {
      
      const nodeConfig = new LinkNodeConfig("", "", NodeType.Link, NodeElementType.Link, 0, 0, 0, 0, "", "");
      const nodeInfo = NodeFactory.createNodes(0, 0, nodeConfig) as LinkNodeInfo;

      if (nodeInfo === null)
        return null;

      nodeInfo.addLink(linkInfo, startLinkPoint, endLinkPoint);

      //TOOD: 기존 소스 -> 타겟이 동일한게 있으면 링크 추가 안되게 해야함.
      if (this.checkLinkCondition(startLinkPoint, endLinkPoint) === false)
        return null;

      //Store에 노드 추가
      this.nodeInfos.splice(0, 0, nodeInfo);

      return nodeInfo;
    },

    /**
     * 임시노드 생성
     * @param x X좌표
     * @param y Y좌표
     * @param nodeConfig nodeConfig
     * @returns 없음
     */
    createTempNode(x: number, y: number, nodeConfig: AbstractNodeConfig) {
      const nodeInfo = NodeFactory.createNodes(x, y, nodeConfig);

      if (nodeInfo === null)
        return;

      //Store에 노드 추가
      this.tempNodeInfos.push(nodeInfo);
    },

    /**
     * 노드 삭제
     * @param isSelected 선택된 노드 인지 여부
     */
    clearNodes(isSelected = false) {


      const cloneNodeInfos: AbstractNodeInfo[] = [];
      
      if (isSelected) {
        const selectedNodeInfos = this.getSelectedNodes(true);

        for (const nodeInfo of selectedNodeInfos) {

          const cloneNodeInfo = CommonUtils.deepClone(nodeInfo as AbstractNodeInfo);
          cloneNodeInfos.push(cloneNodeInfo);

          const index = this.nodeInfos.indexOf(nodeInfo);
          this.nodeInfos.splice(index, 1);
        }

      } else {                
        for(const nodeInfo of this.nodeInfos) {
          const cloneNodeInfo = CommonUtils.deepClone(nodeInfo as AbstractNodeInfo);
          cloneNodeInfos.push(cloneNodeInfo);
        }
        this.nodeInfos.splice(0);
      }
    
         
      this.setSelectionNode(null);

      return cloneNodeInfos;
    },

    /**
     * 임시노드 모두 삭제
     */
    clearTempNodes() {
      this.tempNodeInfos.splice(0);
    },

    /**
     * 노드 삭제
     * @param id 노드 아이디
     */
    removeNode(id: string) {
      const index = this.nodeInfos.findIndex(n => n.id === id);
      this.nodeInfos.splice(index, 1);
    },

    /**
     * 모든 노드 선택
     */
    setSelectionAllNode() {
      const nodes = this.getNodes;
      for (const node of nodes) {
        node.nodeStatus = NodeStatus.Selected;
      }
    },

    /**
     * 마우스로 선택한 단일 노드 설정
     * @param id 노드 아이디
     */
    setSelectionNode(id: string | null | undefined) {
      if (CommonUtils.isNullOrEmpty(id))
        this.selectionNodeInfo = null;
      else {
        const nodeInfo = this.nodeInfos.find(n => n.id === id) as AbstractNodeInfo;
        this.selectionNodeInfo = nodeInfo;
      }
    },

    /**
     * 선택된 노드 이동
     * @param direction 방향
     * @returns 선택된 노드 수
     */
    moveSelectedNodes(direction: Direction) {
      const selectedNodes = this.getSelectedNodes(false);

      for (const node of selectedNodes) {
        node.movePosition(direction, this.editorInfo.snap);
      }

      this.updateLinks();

      return selectedNodes.map(n => n.id);          
    },

    setStartPortData(portNode: AbstractNodeInfo | null, portInfo: PortInfo | null) {
      this.startPortNode = portNode;
      this.startPortInfo = portInfo;
    },

    deselectAllNode() {

      for (const node of this.nodeInfos) {
        node.nodeStatus = NodeStatus.Normal;
      }
    },

    /**
     * 연결노드 업데이트
     * @param nodeId 노드 아이디
     * @returns 없음
     */
    updateLinks(nodeId?: string) {
      const linkNodes: LinkNodeInfo[] = [];
      let nodes: AbstractNodeInfo[] | null = null;

      if (CommonUtils.isNullOrEmpty(nodeId)) {
        nodes = this.getSelectedNodes(false);
      } else {
        const node = this.getNode(nodeId);

        if (CommonUtils.isNullOrEmpty(node) === false) {
          nodes = [];
          nodes.push(node as AbstractNodeInfo);
        }
      }

      if (CommonUtils.isNullOrEmpty(nodes))
        return;

      //링크 노드들을 담는다.
      for (const node of nodes) {
        const selectedNodeLinkNodes = this.getLinkNodes(node.id);
        for (const linkNode of selectedNodeLinkNodes) {
          linkNodes.push(linkNode);
        }
      }

      //링크노드를 업데이트 한다.
      for (const linkNode of linkNodes) {
        const startNodeInfo = this.getNode(linkNode.srcNodeId as string);
        const endNodeInfo = this.getNode(linkNode.targetNodeId as string);

        if (CommonUtils.isNullOrEmpty(startNodeInfo))
          return;
        
        let port: PortInfo | null = null;

        //linkNode.srcNodeItemId가 undefined면 PackageNode가 아니라고 보며, 연결 시작 포트를 노드의 Out포트로 본다.
        if(CommonUtils.isNullOrEmpty(linkNode.srcNodeItemId))
          port = startNodeInfo.ports.find(n => n.portType === PortType.Out) ?? null;
        else
          port = startNodeInfo.ports.find(n => n.nodeItemId === linkNode.srcNodeItemId) ?? null;

        if (CommonUtils.isNullOrEmpty(port) || CommonUtils.isNullOrEmpty(endNodeInfo))
          return;

        const startLinkPoint = DrawUtil.getLinkPoint(startNodeInfo, port);
        const endLinkPoint = DrawUtil.getLinkPoint(endNodeInfo, endNodeInfo.ports[0]);

        if (CommonUtils.isNullOrEmpty(startLinkPoint) || CommonUtils.isNullOrEmpty(endLinkPoint))
          return;

        const nodePath = DrawUtil.getNodePath(startLinkPoint.x, startLinkPoint.y, endLinkPoint.x, endLinkPoint.y);
        console.log(`NodePath -> path: ${nodePath}, sx: ${startLinkPoint.x}, sy: ${startLinkPoint.y}, ex: ${endLinkPoint.x}, ey: ${endLinkPoint.y}`);
        
        const linkInfo = new LinkInfo("", nodePath);

        linkNode.updateLink(linkInfo);
      }
    },

    copying() {
      this.copyingNodes.splice(0);

      const selectedNodes = this.getSelectedNodes(false);

      for (const node of selectedNodes) {        
        const cloneNode = CommonUtils.deepClone(node);
        

        if (CommonUtils.isNullOrEmpty(cloneNode))
          return;

        this.copyingNodes.push(cloneNode);
      }

      console.log(`총 ${this.copyingNodes.length} 개의 노드를 복사했습니다.`);
    },

    pasteNodes() {
      if (this.copyingNodes.length <= 0)
        return;

      const pastedNodes: AbstractNodeInfo[] = [];

      for (const node of this.copyingNodes) {
        const cloneNode = CommonUtils.deepClone(node as AbstractNodeInfo);

        if (CommonUtils.isNullOrEmpty(cloneNode))
          continue;

        cloneNode.id = CommonUtils.generateUUID();
        cloneNode.x += 20;
        cloneNode.y += 20;

        this.nodeInfos.push(cloneNode);        
        const pastedNodeClone = CommonUtils.deepClone(cloneNode);

        if (CommonUtils.isNullOrEmpty(pastedNodeClone))
          continue;

        pastedNodes.push(pastedNodeClone as AbstractNodeInfo);
      }

      this.copyingNodes.splice(0);

      for (const node of pastedNodes) {
        this.copyingNodes.push(node);
      }
    },

    /**
     * 선택한 노드들을 정렬한다.
     * @param nodeAlignMode 노드 정렬 모드
     * @returns
     */
    nodesAlign(nodeAlignMode: NodeAlignMode) {
      //선택된 노드는 반드시 1개 이상이어야 함.
      if (this.getSelectedNodes(false).length <= 0)
        return;

      let minX = this.editorInfo.gridSetting.pageWidth;
      let minY = this.editorInfo.gridSetting.pageHeight;
      let maxX = 0;
      let maxY = 0;

      for (const node of this.getSelectedNodes(false)) {
        minX = Math.min(node.x, minX);
        minY = Math.min(node.y, minY);

        //TODO: 우측 프로퍼티 Input Box로 인한 임시 Number 변환
        maxX = Math.max(node.x + Number(node.w), maxX);
        maxY = Math.max(node.y + Number(node.h), maxY);
      }

      for (const node of this.getSelectedNodes(false)) {
        switch (nodeAlignMode) {
          case NodeAlignMode.Left:
            node.x = minX;
            break;
          case NodeAlignMode.Center:
            node.x = minX + (maxX - minX) / 2 - Number(node.w) / 2;
            break;
          case NodeAlignMode.Right:
            node.x = maxX - Number(node.w);
            break;
          case NodeAlignMode.Top:
            node.y = minY;
            break;
          case NodeAlignMode.Middle:
            node.y = minY + (maxY - minY) / 2 - Number(node.h) / 2;
            break;
          case NodeAlignMode.Bottom:
            node.y = maxY - Number(node.h);
            break;
          default:
            throw new Error("This mode is not supported.");
        }

        this.updateLinks(node.id);
      }
    },

    /**
     * 선택한 노드들을 간격을 맞춘다.
     * @param nodeIntervalMode 노드 간격 모드
     * @returns
     */
    nodesInterval(nodeIntervalMode: NodeIntervalMode) {
      const selectedNodes = this.getSelectedNodes(false);
      if (selectedNodes.length < 3)
        return;

      let orderMovingSet: AbstractNodeInfo[] = [];

      //간격 얻기전 정렬
      if (nodeIntervalMode === NodeIntervalMode.Vertical) {
        orderMovingSet = selectedNodes.sort((a, b) => {
          return a.y - b.y;
        });
      } else {
        orderMovingSet = selectedNodes.sort((a, b) => {
          return a.x - b.x;
        });
      }

      let totalDistanceX = 0;
      let totalDistanceY = 0;

      //간격 계산
      for (let i = 0; i < orderMovingSet.length - 1; i++) {
        const currentNode = orderMovingSet[i];
        const nextNode = orderMovingSet[i + 1];

        const startX = currentNode.x + currentNode.w;
        const endX = nextNode.x;

        const startY = currentNode.y + currentNode.h;
        const endY = nextNode.y;

        totalDistanceX += endX - startX;
        totalDistanceY += endY - startY;
      }

      const avgDistanceX = totalDistanceX / (orderMovingSet.length - 1);
      const avgDistanceY = totalDistanceY / (orderMovingSet.length - 1);

      //간격 적용
      for (let i = 0; i < orderMovingSet.length - 1; i++) {
        if (i === orderMovingSet.length - 2)
          break;

        const currentNode = orderMovingSet[i];
        const nextNode = orderMovingSet[i + 1];

        switch (nodeIntervalMode) {
          case NodeIntervalMode.Vertical:
            nextNode.y = currentNode.y + currentNode.h + avgDistanceY;
            break;
          default:
            nextNode.x = currentNode.x + currentNode.w + avgDistanceX;
            break;
        }
      }
    },
  },
});
