import { defineStore } from "pinia";
import type Site from "@/models/site";
import type Wall from "@/components/SpaceLight/Wall";
import type Zone from "@/models/entity/zone";
import CommonUtils from "@/utils/common-util";
import InDPoint from "@/models/group/in-d-point";
import { Depth1_Kind, Depth2_Kind, InitState, NodeEventType, NodeStatus, ViewType, ZoneTypeCode } from "@/global/enums";
import SVGPathCommander from "svg-path-commander";
import { useNodeEditorStore } from "./node-editor-store";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import DPoint from "@/models/entity/d-point";
import { useMenuStore } from "./menu-store";
import { useUserStore } from "./user-store";
import MPoint from "@/models/entity/m-point";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import CtrlGrp from "@/models/entity/ctrl-grp";
import GroupBox from "@/models/group/group-box";
import GrpMember from "@/models/entity/grp_member";
import { useScenarioStore } from "./scenario-store";
import { useCommonStore } from "./common-store";
import type Code from "@/models/entity/code";
import type LightDeviceType from "@/models/entity/light-device-type";
import type { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import type ModelTextureFile from "@/models/entity/model-texture-file";
import { MeshBasicMaterial } from "three";
import { useDeviceStore } from "./device-store";
import type ModelFile from "@/models/entity/model-file";
import type { EditorInfo } from "@/models/editor/editor-setting";
import { useUndoRedo } from "@/composables/undo-redo-manager";
import { watch, type WatchStopHandle } from "vue";
import { useSceneStore } from "./scene-store";
import type { optionsForm } from "./materials-store";
import type Scene from "@/models/entity/scene";
import { useSimulationStore } from "./simulation-store";
import SnapShot from "@/models/snapshot";

let watchHandle: WatchStopHandle | null = null;

export const useEditStore = defineStore("edit", {
  state: () => ({
    snapShot: new SnapShot(),
    selectSiteInfo: null as Site | null,       //선택된 사이트
    selectZoneInfo: null as Zone | null,       //대표공간
    selectSpaceInfo: null as Zone | null,      //선택한 공간 (RSpace/ C / U)
    selectGroupInfo: null as CtrlGrp | null,   //선택한 그룹
    selectGroupBox: null as GroupBox[] | null,
    selectModelBinary: null as any | null,
    selectModelInfo: null as THREE.Object3D | null,
    selectModelJSON: null as string | null,
    localModelInfoList: [] as {zoneSeq: number, modelFile: THREE.Group}[],
    localTextureInfoList: [] as {name: string, urls: string}[],
    modelFileList: [] as {zoneSeq: number, zipFile: File}[],
    cacheModelingList: [] as {modelFileSeq: number, modelFile: THREE.Object3D}[],
    TempModelDataList: [] as {modelFileSeq: number, modelFile: THREE.Object3D}[],
    editorInfo: null as EditorInfo | null,
    initState: InitState.NotLoad as  InitState,
    ModelGroupInfoList: [] as optionsForm[],
    selectModelPosition: {x:0, y:0, z:0} as THREE.Vector3,
    selectModelSize: {x:0, y:0, z:0} as THREE.Vector3,
    selectModelParentSize: {x:0, y:0, z:0} as THREE.Vector3,
    selectModelGroupInfo: null as string | null,
    selectModelAreaInfo: null as THREE.Object3D | null,
    lastSelectedNodeEL: [] as HTMLElement[],
    tmpRemoveZoneData: [] as Zone[],
    sceneGroupONOFF: true as boolean,
    // NOTE - 모든 사이트 리스트
    AllSiteList: [] as Site[],
    
    // NOTE - 선택된 장소 ID
    // 선택 가능한 값 : 사이트, 대표공간, 묶음공간, 단위공간
    SelectSiteID: 0 as number,

    // NOTE - 메인메뉴 선택 정보
    SelectMainKey: "" as string | undefined,

    // NOTE - 컨텐츠 선택 버튼키
    EditFuncKey: "" as string,

    // NOTE - 최초 선택 사이트 정보
    DefaultSiteInfo: {} as Site,

    RightSpaceInfo: null as Zone | null,

    EditSpaceSelectType: {} as string,

    // NOTE - 에디터 상에서 공간 선택
    SelectEditSpace: {} as Site | Zone,

    TreeSelectValue: "" as string,

    SelectWallInfo: {} as Wall,

    EditInfo: {} as Site | Zone,

    modelViewType: ViewType.SecondDimension,    

    zoom: 1 as number,
    zoneWidth: 0 as number,
    zoneHeight: 0 as number,

    inDPointList: [] as InDPoint[],
    dpointTypeList: null as LightDeviceType[] | null,
    THREECamera: null as THREE.PerspectiveCamera | null,
    CameraControl: null as OrbitControls | null,
    
    UzoneSizeData:  {x:0, y: 0, z:0} as {x: number, y: number, z: number},
    UzonePositionData: {x:0, y: 0, z:0} as {x: number, y: number, z: number} ,
    realRate: {x:1, y:1, z: 1} as {x:number, y: number, z: number},

    areaWidth: Number(import.meta.env.VITE_LIGHT_DEPLOY_GRID_WIDTH),
    areaHeight: Number(import.meta.env.VITE_LIGHT_DEPLOY_GRID_HEIGHT),
    maxWidth: 0,
    maxHeight: 0,
  }),
  getters: {    
    findZone() {
      return (zoneSeq: number, zones: Zone[] | null): Zone | null => {
        if (CommonUtils.isNullOrEmpty(zones)) {
          zones = this.selectZoneInfo === null ? [] : [this.selectZoneInfo];
        }

        let resultZone = null;

        for (const zone of zones) {

          if (zone.zoneSeq === zoneSeq) {
            return zone;
          } else
            resultZone = this.findZone(zoneSeq, zone.childZones);

          if (resultZone !== null)
            break;
        }

        return resultZone;
      };
    },
    /**
     * 조명이 그룹에 포함되어 있는지 여부
     * @returns boolean
     */
    isLightInGroup() {

      return (nodeIds: string[]): boolean => {

        if (CommonUtils.isNullOrEmpty(this.selectSpaceInfo) || CommonUtils.isNullOrEmpty(this.selectSpaceInfo.ctrlGrps))
          return false;

        const inDPoints = this.inDPointList as InDPoint[];

        for (const nodeId of nodeIds) {

          const inDPoint = inDPoints.find(d => d.nodeID === nodeId);

          if (CommonUtils.isNullOrEmpty(inDPoint))
            continue;

          for (const ctrlGrp of this.selectSpaceInfo.ctrlGrps) {

            if (CommonUtils.isNullOrEmpty(ctrlGrp.members))
              break;

            for (const member of ctrlGrp.members) {

              if (CommonUtils.isNullOrEmpty(member.dpointSeq))
                continue;

              if (member.dpointSeq === inDPoint.dpointSeq)
                return true;
              else
                continue;
            }
          }
        }

        return false;
      };
    },
    resetLightColor(){
      
    },
    getGroupInDPoints() {
      return (group: CtrlGrp) => {
        const inDPoints: InDPoint[] = [];

        if (!CommonUtils.isNullOrEmpty(this.selectSpaceInfo) && !CommonUtils.isNullOrEmpty(group.members)) {

          for (const member of group.members) {
            
            // const dPoint = this.selectSpaceInfo.dpoints?.find(d => d.dpointSeq === member.dpointSeq) ?? null;
            const dPoint = this.inDPointList.find(d => d.dpointSeq === member.dpointSeq) ?? null;

            if (CommonUtils.isNullOrEmpty(dPoint))
              continue;

            // inDPoints.push(new InDPoint(dPoint));
            inDPoints.push(dPoint);

          }
        }

        return inDPoints;
      };
    },    
    getGroupBoxes() {
      return (selectBoxId: number | null): GroupBox[] | null => {   
        
        if(this.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
          return null;
        

        // const groups = this.selectGroupInfo === null ? this.selectSpaceInfo?.ctrlGrps : [this.selectGroupInfo];

        const groups = CommonUtils.deepClone(this.selectSpaceInfo.ctrlGrps);
        if(selectBoxId !== null){
          const swapData = groups?.find(v => v.grpSeq === Number(selectBoxId));
          const swapIndex = groups?.findIndex(v => v.grpSeq === Number(selectBoxId));
          groups?.splice(swapIndex as number, 1);
          groups?.push(swapData as CtrlGrp);
        }
    
        if(CommonUtils.isNullOrEmpty(groups))
          return null;
        
        const groupBoxes: GroupBox[] = [];

        for(const group of groups) {
          const groupInDPoints = this.getGroupInDPoints(group);          

          //계산
          const minX = Math.min(...groupInDPoints.map(d => d.centerX ?? 0));
          const maxX = Math.max(...groupInDPoints.map(d => d.centerX ?? 0));

          const minY = Math.min(...groupInDPoints.map(d => d.centerZ ?? 0));
          const maxY = Math.max(...groupInDPoints.map(d => d.centerZ ?? 0));

          let maxWidth = 0;
          let maxHeight = 0;
        
          groupInDPoints.map(d => {
            const nodeList = useNodeEditorStore().nodeInfos;
            nodeList.map(n => {
              if(n.id === d.nodeID){
                if(d.centerX === maxX){
                  maxWidth = n.w;
                } 
                if(d.centerZ === maxY){
                  maxHeight = n.h;
                }
              }
            });
          });

          const padding = 10;

          const width = (maxX - minX) * this.zoom + maxWidth + (padding * 2);
          const height = (maxY - minY) * this.zoom + maxHeight + (padding * 2);     
          
          const groupBox = new GroupBox((minX * this.zoom) - padding, ((minY + this.UzoneSizeData.z) * this.zoom) - padding, width, height, group);

          
          // NOTE - 하드 코딩.. 나중에 수정할것
          // if(i === 1)
          //   groupBox.color = "#ff0000";
          // else if(i === 2)
          //   groupBox.color = "#00ff00";

          groupBoxes.push(groupBox);          
          // i++;
        }
        this.selectGroupBox = groupBoxes;

        console.log(this);

        return groupBoxes;
      };
    },
    getNewDPointSeq() {
      return () => {

        if(CommonUtils.isNullOrEmpty(this.selectSpaceInfo) || this.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존 || CommonUtils.isNullOrEmpty(this.selectSpaceInfo.dpoints))
          return 1;

        const seqList = this.selectSpaceInfo.dpoints.map(d => d.dpointSeq);
        return CommonUtils.getNewId(seqList);        
      };
    },
    /**
     * ZoomBase 관련 계산 Getter
     */  
    calcWidth() {
      return (areaWidth: number, width: number, maxWidth: number) => {
        // const areaWidth = document.getElementsByClassName("editArea")[0].clientWidth;
        const calcwidth = ((width * 100) / areaWidth) * (areaWidth / maxWidth) * this.zoom;
        return calcwidth + "%";
      };
    },
    calcHeight() {
      return (areaHeight: number, height: number, maxHeight: number) => {
        // const areaHeight = document.getElementsByClassName("editArea")[0].clientHeight;
        const calcheight = ((height * 100) / areaHeight) * (areaHeight / maxHeight) * this.zoom;
        return calcheight + "%";
      };
    },
    calcX() {
      return (areaWidth: number, pointX: number, maxWidth: number) => {
        // const areaWidth = document.getElementsByClassName("editArea")[0].clientWidth;
        const calcwidth = ((pointX * 100) / areaWidth) * (areaWidth / maxWidth) * this.zoom;
        return calcwidth + "%";
      };
    },
    calcY() {
      return (areaHeight: number, pointY: number, maxHeight: number) => {
        // const areaHeight = document.getElementsByClassName("editArea")[0].clientHeight;
        const calcheight = ((pointY * 100) / areaHeight) * (areaHeight / maxHeight) * this.zoom;
        return calcheight + "%";
      };
    },
  },
  actions: {
    reverseSceneGroupOnOFF(){
      this.sceneGroupONOFF = !this.sceneGroupONOFF;
    },
    setEditorInfo(editorInfo: EditorInfo){
      this.editorInfo = editorInfo;
    },
    setThreeCamera(camera: THREE.PerspectiveCamera){
      this.THREECamera = camera;
    },
    setThreeCameraControl(control: OrbitControls){
      this.CameraControl = control;
    },
    setModelSize(x: number, y: number, z: number){
      this.UzoneSizeData = {
        x: x,
        y: y,
        z: z
      };
    },
    setModelPostion(x: number, y: number, z: number){
      this.UzonePositionData = {
        x: x,
        y: y,
        z: z
      };
    },
    setSelectModelAreaInfo(selectModel: THREE.Object3D){
      this.selectModelAreaInfo = selectModel;

      if(selectModel.parent !== null){
        const parentBox = new THREE.Box3().setFromObject(selectModel.parent as THREE.Object3D);
        const parentModelSize = new THREE.Vector3;
        parentBox.getSize(parentModelSize);

        this.selectModelParentSize = parentModelSize;
      }
    },
    setSelectModelGroupInfo(groupName: string){
      this.selectModelGroupInfo = groupName;
      (this.selectSpaceInfo as Zone).modelFileGrp = groupName as string;
    },
    setSelectModelVector(position: THREE.Vector3, size: THREE.Vector3){
      this.selectModelSize = size;
      this.selectModelPosition = position;
    },
    localEditFileInput(fbxModel: Blob, textureList: {name: string, data: Blob}[], zipFile: File){
      this.setViewType(ViewType.SecondDimension);
      
      const url = URL.createObjectURL(fbxModel as Blob);
      const loadingManager = new THREE.LoadingManager();
      const loader = new FBXLoader(loadingManager);

      const imagecheck = /(\.gif|\.jpg|\.jpeg|\.png)$/i;

      const textureUrls: {name: string, url: string}[] = [];

      for(let i = 0; i < textureList.length; i++){
        textureUrls.push({
          name: textureList[i].name,
          url: URL.createObjectURL(textureList[i].data),
        });
      }

      loadingManager.setURLModifier((textureUrl: string) => {

        if(imagecheck.test(textureUrl) === false){
          return textureUrl;
        }
          
        const newURLS = textureUrls.find(v => {
          if(textureUrl.includes("1.jpg") === true){
            if(v.name === "01 - Default.jpg"){
              return v.url;
            }
          }

          if(textureUrl.includes(v.name) === true){
            return v.url;
          }
        });
      
      
        textureUrl = newURLS?.url as string;
        return textureUrl;
      });


      loader.load(url, (object) => {
        let zoneInfo: Zone;

        if(this.selectSiteInfo?.siteClsfyCode === "A001"){
          zoneInfo = this.selectSpaceInfo as Zone;
        } else {
          zoneInfo = this.selectZoneInfo as Zone;
        }

        const idx = this.localModelInfoList.findIndex(v => v.zoneSeq === zoneInfo?.zoneSeq);

        if(idx !== -1){
          this.localModelInfoList.splice(idx, 1);
          this.modelFileList.splice(idx, 1);
        }

        this.localModelInfoList?.push({
          zoneSeq: zoneInfo?.zoneSeq as number,
          modelFile: object
        });

        this.modelFileList.push({
          zoneSeq: zoneInfo?.zoneSeq as number, 
          zipFile: zipFile
        });
      
        this.setSpaceModelSizePosition(object as THREE.Object3D);

        this.setViewType(ViewType.ThirdDimension);
      });
    },
    zoneTypeCheck(zone: Zone){
      zone.childZones.forEach(czone => {
        for(let i = 0; i < (czone.dpoints as InDPoint[]).length; i++){
          if(Object.keys((czone.dpoints as InDPoint[])[i]).findIndex(v => v === "nodeID") !== -1){
            delete (czone.dpoints as InDPoint[])[i].nodeID;
          }
        }
        czone.childZones.forEach(uzone => {
          for(let i = 0; i < (uzone.dpoints as InDPoint[]).length; i++){
            if(Object.keys((uzone.dpoints as InDPoint[])[i]).findIndex(v => v === "nodeID") !== -1){
              delete (uzone.dpoints as InDPoint[])[i].nodeID;
            }
          }
        });
      });
    },

    async getModelFile(modelFileSeq: number){
      const result  = await this.apiServices.etcApiService.getModel(modelFileSeq);

      return result;
    },
    async getModelTextureFile(modelFileSeq: number, textureFileSeq: number, textureFileName: string){
      const result = await this.apiServices.etcApiService.getModelTexture(modelFileSeq, textureFileSeq, textureFileName);

      return result;
    },

    async getModelTextureFileName(modelFileSeq: number, textureFileSeq: number, textureFileName: string){
      const result = await this.apiServices.etcApiService.getModelTextureName(modelFileSeq, textureFileSeq, textureFileName);

      return result;
    },

    async postModelFile(modelFile: File){
      const result = await this.apiServices.etcApiService.uploadModel(modelFile);
    
      return result;
    },

    async removeModelFile(modelFileSeq: number){
      const result = await this.apiServices.etcApiService.removeModel(modelFileSeq);

      return result;
    },

    clearEditStore(){
      this.selectSpaceInfo = null;
    },

    delZone(zoneSeq: number) {
      const space = this.selectZoneInfo;

      const returnZoneSeq = 0;

      if(CommonUtils.isNullOrEmpty(space))
        return returnZoneSeq;

      const notSavingZoneInfo = this.persistStateManager.getRSpace();

      if(CommonUtils.isNullOrEmpty(notSavingZoneInfo))
        return returnZoneSeq;

      notSavingZoneInfo.childZones.forEach((space: Zone, j: number) => {
        if(space.zoneTypeCode === "C"){
          if(space.zoneSeq === zoneSeq){
            space.childZones.forEach(us => {
              this.tmpRemoveZoneData.push(us);
            });

            notSavingZoneInfo.childZones.splice(j, 1);
            this.setSelectSpaceInfo(notSavingZoneInfo.zoneSeq);
            this.setTreeSelectValue(notSavingZoneInfo.zoneTypeCode as string);
            this.selectZoneInfo = notSavingZoneInfo;
            //this.persistStateManager.setRSpace(notSavingZoneInfo);
            return returnZoneSeq;          
          }

          if (space.childZones.length > 0) {
            space.childZones.forEach((uspace, k) => {
              if (uspace.zoneSeq === zoneSeq) {
                this.tmpRemoveZoneData.push(uspace);

                space.childZones.splice(k, 1);
                this.setSelectSpaceInfo(space.zoneSeq);
                this.setTreeSelectValue(space.zoneTypeCode as string);
                this.selectZoneInfo = notSavingZoneInfo;
                //this.persistStateManager.setRSpace(notSavingZoneInfo);
                return returnZoneSeq;
                // editStore.setSelectSpaceType(cspace.zoneTypeCode as string);
              }
            });
          }
        }

        if(space.zoneTypeCode === "U"){
          if(space.zoneSeq === zoneSeq){
            this.tmpRemoveZoneData.push(space);
            notSavingZoneInfo.childZones.splice(j, 1);
            this.setSelectSpaceInfo(notSavingZoneInfo.zoneSeq);
            this.setTreeSelectValue(notSavingZoneInfo.zoneTypeCode as string);
            this.selectZoneInfo = notSavingZoneInfo;
            //this.persistStateManager.setRSpace(notSavingZoneInfo);

            return returnZoneSeq;
          }
        }
      
      });

    },
    validateDelZone(zone: Zone){
      let checkScene = zone.scenes as Scene[];

      if(checkScene.length > 0){
        checkScene = [] as Scene[];
        this.modifyZone(this.selectZoneInfo?.siteSeq as number, this.selectZoneInfo as Zone);
        useSceneStore().resetScene();
      }
      
      let checkGrp = zone.ctrlGrps as CtrlGrp[];

      if(checkGrp.length > 0){
        checkGrp = [] as CtrlGrp[];
        this.modifyZone(this.selectZoneInfo?.siteSeq as number, this.selectZoneInfo as Zone);
        this.selectGroupInfo = null;
      }

      let checkDpoint = zone.dpoints as DPoint[];

      if(checkDpoint.length > 0){
        checkDpoint = [] as DPoint[];
        this.modifyZone(this.selectZoneInfo?.siteSeq as number, this.selectZoneInfo as Zone);
      }

    },
    changeEditZoneParameter(zone: Zone | null){
      const uzones = this.selectZoneInfo?.childZones as Zone[];

      for(let i = 0; i < uzones.length; i++){
        if(uzones[i].zoneSeq === (zone as Zone).zoneSeq){
          uzones[i] = zone as Zone;
          return;
        }

        const czones = uzones[i].childZones as Zone[];

        for(let j = 0; j < czones.length; j++){
          if(czones[j].zoneSeq === (zone as Zone).zoneSeq){
            czones[j] = zone as Zone;
            return;
          }
        }
      }
    },

    removeGroup(grpSeq: number){
      const sceneStore = useSceneStore();
      const selectGrpList = this.selectSpaceInfo?.ctrlGrps as CtrlGrp[];

      for(let i = 0; i < selectGrpList.length; i++){
        if(selectGrpList[i].grpSeq === grpSeq){
          selectGrpList.splice(i, 1);
          sceneStore.removeSceneInGrp(grpSeq);
          this.setSelectGroupInfo(null);
          // this.getGroupBoxes(null);
          break;
        }
      }
    },
    
    async modifyModelFile(modelFile: THREE.Object3D, modelFileSeq: number){
      const isIndex = this.TempModelDataList.findIndex(v => v.modelFileSeq === modelFileSeq);

      if(isIndex === -1){
        this.TempModelDataList.push({
          modelFileSeq: modelFileSeq,
          modelFile: modelFile
        });
      }
    },
    async modifyModelFileList(){
      const zone = this.selectZoneInfo;
      for(let i = 0; i < this.modelFileList.length; i++){
        try{
          const zoneInfo = this.findZone(this.modelFileList[i].zoneSeq as number, [zone as Zone]) as Zone;
          const zoneModelFileSeq = zoneInfo?.modelFileSeq;
          let removeModelResult;
          if(zoneModelFileSeq !== null){
            zoneInfo.modelFile = null;
            zoneInfo.modelFileSeq = null;
            await this.modifyZone(this.selectSiteInfo?.siteSeq as number, this.selectZoneInfo as Zone, true);
            removeModelResult = await this.removeModelFile(zoneModelFileSeq as number);
          }

          const addModelResult = await this.postModelFile(this.modelFileList[i].zipFile) as ModelFile;

          zoneInfo.modelFile = addModelResult;
          zoneInfo.modelFileSeq = addModelResult.modelFileSeq;

          this.modelFileList.splice(i, 1);
          i--;
        } catch(e){
          console.log(e);
          throw e;
        }
        
      }

      this.modifyZone(this.selectSiteInfo?.siteSeq as number, zone as Zone);

    },
    async modifyZone(siteSeq: number, zone: Zone, isModel = false){
      this.zoneTypeCheck(zone);

      // for(let i = 0; i < this.tmpRemoveZoneData.length; i++){
      //   this.validateDelZone(this.tmpRemoveZoneData[i]);
      //   this.tmpRemoveZoneData.splice(i, 1);
      //   i--;
      //   console.log("::: uspace list remove ::::");
      // }
    
      const result = await this.apiServices.sitezoneApiService.modifyZone(siteSeq, zone);
      if(result.status.toString().includes("4")){
        alert("오류가 발생하였습니다. 다시 시도해주세요.");
        return;
      }
      
      if(result.status.toString().includes("2")){
        // alert("저장 완료");
      }

      const selectSpaceInfoSeq = this.selectSpaceInfo?.zoneSeq as number;
      const selectGroupInfoSeq = this.selectGroupInfo?.grpSeq as number;
      this.selectZoneInfo = result.data;

      if(isModel === false){
        this.setSelectSpaceInfo(selectSpaceInfoSeq);
        this.loadZoneLights(selectSpaceInfoSeq);
        this.setSelectGroupInfo(selectGroupInfoSeq);
      }
      
      // this.findZone(selectSpaceInfoSeq as number, null);
      
      this.persistStateManager.setRSpace(result.data);

      return result;
    },

    zoneGrpDpointCheck(selectZone: Zone) {
      // for(let i = 0; i < this.selectZoneInfo.childZones.length; i++){
      //   const czone = selectZone.childZones[i];
      // const NOTSavingCzone = notS 
      // }
    },

    /**
     * 사이트 삭제
     * @param siteSeq 
     * @returns 
     */
    async removeSites(siteSeq: number){
      const result = await this.apiServices.sitezoneApiService.removeSite(siteSeq);

      return result;
    },

    /**
     * 선택 대표 공간 정보 호출
     * @param siteSeq 
     * @param zoneSeq 
     * @returns 
     */
    async getSelectZoneList(siteSeq: number, zoneSeq: number){
      const result = await this.apiServices.sitezoneApiService.getZoneList(siteSeq, zoneSeq);
      return result;
    },
    /**
     * 대표 공간 추가
     * @param siteSeq 
     * @param rzone 
     * @returns 
     */
    async addRzone(siteSeq: number, rzone: Zone){
      const result = await this.apiServices.sitezoneApiService.addRzone(siteSeq, rzone);

      return result;
    },
    /**
     * 대표공간 삭제
     * @param siteSeq 
     * @param zoneSeq 
     * @returns 
     */
    async removeRzone(siteSeq: number, zoneSeq: number){
      const result = await this.apiServices.sitezoneApiService.removeRzone(siteSeq, zoneSeq);
      return result;
    },


    setEditSpaceSelectType(type: string){
      this.EditSpaceSelectType = type;
    },

    setRightSpaceInfo(zone: Zone | null){
      this.RightSpaceInfo = zone;
      // this.setTreeSelectValue(zone.zoneTypeCode as string);
    },

    async setInit(siteSeq: number, rSpaceSeq: number) {

      this.persistStateManager.clearInitData();
      const site = await this.apiServices.sitezoneApiService.getSiteDetail(siteSeq);
      const rspace = await this.apiServices.sitezoneApiService.getZoneList(siteSeq, rSpaceSeq);

      this.persistStateManager.setSite(site);
      this.persistStateManager.setRSpace(rspace);     


      const commonStore =  useCommonStore();
      this.persistStateManager.setCommonCodes(commonStore.commonList);      
      
      const menuStore = useMenuStore();
      menuStore.selectionMenu(Depth2_Kind.공간편집);

      console.log("★ editStore Set Init ★");
    },

    setViewType(type: ViewType){
      this.modelViewType = type;
    },

    /**
     * 메인 화면 시작전 초기 로드 함수
     */
    async initLoad() {
      
      this.stopWatch();
      this.initState = InitState.NotLoad;

      this.selectModelAreaInfo = null;

      for(let i = 0; i < this.cacheModelingList.length; i++){
        this.cacheModelingList.splice(i, 1);
        i--;
      }
      

      const commonStore = useCommonStore();
      const commonCodes = this.persistStateManager.getCommonCodes() as Code[];

      commonStore.setCommonCodes(commonCodes);

      this.selectSiteInfo = this.persistStateManager.getSite();
      this.selectZoneInfo = this.persistStateManager.getRSpace();
      this.snapShot.zone = this.selectZoneInfo;

      const storageSpace = this.persistStateManager.getStorageSpace();

      const undoRedo = useUndoRedo();
      undoRedo.clear();

      // console.log(storageSpace);
      if (!CommonUtils.isNullOrEmpty(storageSpace)) {
        //this.selectSpaceInfo = this.findZone(storageSpace?.zoneSeq, null);
        this.setSelectSpaceInfo(storageSpace?.zoneSeq);
      } else {
        //this.selectSpaceInfo = this.selectZoneInfo;

        if(!CommonUtils.isNullOrEmpty(this.selectZoneInfo))
          this.setSelectSpaceInfo(this.selectZoneInfo.zoneSeq);
      }

      this.zoneWidth = this.selectZoneInfo?.horizSize as number;
      this.zoneHeight = this.selectZoneInfo?.vertiSize as number;

      this.maxWidth = this.zoneWidth;
      this.maxHeight = this.zoneHeight;

      this.setModelSize(this.maxWidth, this.maxHeight, this.maxHeight);
      this.setModelPostion(0, 0, 0);
      this.setZoneZoomValue();

      if(this.selectSiteInfo?.siteClsfyCode !== "A001"){
        if(this.selectZoneInfo?.modelFileSeq !== null){
          this.setSelectZoneModelInfo("in editstore");
        }
      }

      const deviceStore = useDeviceStore();

      await deviceStore.load();
      await deviceStore.getDeviceModels();
      await deviceStore.getDevAsets();
      await deviceStore.getDevAttrs();


      this.startWatch();
      console.log("★ editStore Init Load Completed. ★");
      
      this.initState = InitState.Completed;
    },

    setSelectSiteSpaceInfo(siteInfo: Site) {
      this.selectSiteInfo = siteInfo;
    },

    /**
     *  3D 모델 정보 저장
     */
    async setSelectZoneModelInfo(where: string){
      console.log("where :::: " + where, this.selectSpaceInfo);

      // for(let i = 0; i < this.localModelInfoList.length; i++){
      //   if(this.localModelInfoList[i].zoneSeq === this.selectSpaceInfo){

      //   }
      // }
      let modelFileSeq = -1;
      let modelFile = {} as ModelFile;

      if(this.selectSiteInfo?.siteClsfyCode === "A001"){
        modelFileSeq = this.selectSpaceInfo?.modelFileSeq as number;
        modelFile = this.selectSpaceInfo?.modelFile as ModelFile;
      } else {
        modelFileSeq = this.selectZoneInfo?.modelFileSeq as number;
        modelFile = this.selectZoneInfo?.modelFile as ModelFile;
      }

      // const modelFileSeq = this.selectSpaceInfo?.modelFileSeq;
      
      const modelInfo = this.cacheModelingList.find(v => {
        if(v.modelFileSeq === modelFileSeq){
          return v;
        }
      });
      
      if(modelFileSeq === null){
        this.selectModelInfo = {} as THREE.Object3D;
        this.setZoneZoomValue();
        // console.log(this.selectModelInfo, Object.keys(this.selectModelInfo));
        return;
      }


      if(modelInfo === undefined || modelInfo === null){
        const modelBinary = await this.getModelFile(modelFileSeq as number);
        // modelInfo = await this.getModelFile(modelFileSeq as number);
        this.selectModelBinary = modelInfo;
        const url = URL.createObjectURL(modelBinary as Blob);
        const loadingManager = new THREE.LoadingManager();
        const loader = new FBXLoader(loadingManager);
      
        if(CommonUtils.isNullOrEmpty(this.selectSpaceInfo))
          return;
        // const modelFile = this.selectSpaceInfo?.modelFile;
        
        const textureList = [] as {name: string, data: unknown}[];
        for(let i = 0; i < (modelFile?.textureFiles as ModelTextureFile[])?.length; i++){
          const data = (modelFile?.textureFiles as ModelTextureFile[])[i];
          const result = await this.getModelTextureFileName(modelFileSeq as number, data.textureFileSeq as number, data.textureFileName as string);
          textureList.push(result);
        }

        const textureURL = [] as string[];

        const imagecheck = /(\.gif|\.jpg|\.jpeg|\.png)$/i;

        loadingManager.setURLModifier((url: string) => {
  
          if(imagecheck.test(url) === false){
            return url;
          }
            
          const newImage = textureList.find(v => {
            if(url.includes("1.jpg") === true){
              if(v.name === "01 - Default.jpg"){
                return v.data;
              }
            }
  
            if(url.includes(v.name) === true){
              return v.data;
            }
          });
        
          
          if(newImage === undefined){
            url = "";
          } else {
            url = `/v1/modelfiles/${modelFileSeq}/texturefiles/${newImage?.name}`;
          }
          textureURL.push(url);
          return url;
        });


        loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
          console.log( "Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files." );
        };

        loader.load(url, (object) => {

          const textureLoader = new THREE.TextureLoader();
          this.ModelGroupInfoList = [];
          const lightParentGroupName = [] as string[];


          // Material 변환
          object.traverse((mesh) => {
            if(mesh instanceof THREE.Mesh){
              if(Array.isArray(mesh.material) === true){
                const materials = [];
  
                for(let i = 0; i < mesh.material.length; i++){

                  if(mesh.material[i].map === null){
                    textureURL.forEach(v => {
                      const fileName = v.split("texturefiles/")[1];
                      let mapName = mesh.material[i].name;
                      // 예외 처리
                      if(mesh.material[i].name === "02 - Default"){
                        mapName = "84b_fl_t";
                      }
                      if(fileName.split(".jpg")[0] === mapName){
                        textureLoader.load(v, (texture) => {
                          mesh.material[i].map = texture;
                          return;
                        });
                      }
                    });

                    // mesh.material[i].map = textureURL.map(v => {
                    //   console.log(v);
                    //   // const fileName = v.split("texturefiles")
                    //   if((v.split("texturefiles/")[1] as string).split(".jpg")[0] === mesh.material[i].name){
                    //     return textureLoader.load(v);
                    //   }
                    // });
                  }

                  // const material = new MeshStandardMaterial();
                  const material = new MeshBasicMaterial();

                  material.copy(mesh.material[i]);
                  materials.push(material);
                }
                mesh.material = materials;
  
              } else {
                // const material = new MeshStandardMaterial();
                const material = new MeshBasicMaterial();

                material.copy(mesh.material);
  
                mesh.material = material;
              }
            }

            if(mesh instanceof THREE.Group){
              const checkDevice = ["light", "gateway", "sensor"];
              if(checkDevice.some(el => mesh.name.toLowerCase().includes(el)) === false){
                if(lightParentGroupName.findIndex(v => v === mesh.name) === -1){
                  this.ModelGroupInfoList.push({
                    value: mesh.name,
                    label: mesh.name,
                    color: "dark"
                  });
                }
              } else {
                if(lightParentGroupName.findIndex(v => v === mesh.parent?.name) === -1){
                  lightParentGroupName.push(mesh?.parent?.name as string);
                }
              }
          
            }


          });

  
          this.cacheModelingList.push({
            modelFileSeq: modelFileSeq as number,
            modelFile: object
          });
  
          this.selectModelInfo = object;


          this.setSpaceModelSizePosition(object as THREE.Object3D);

          // const exporter = new ColladaExporter();
          // const data = exporter.parse()
        });
      } else {
        this.selectModelInfo = modelInfo.modelFile as THREE.Object3D;
        this.setSpaceModelSizePosition(this.selectModelInfo as THREE.Object3D);
        this.ModelGroupInfoList = [];
        const lightParentGroupName = [] as string[];
        this.selectModelInfo.traverse(mesh => {
          if(mesh instanceof THREE.Group){
            const checkDevice = ["light", "gateway", "sensor"];
            if(checkDevice.some(el => mesh.name.toLowerCase().includes(el)) === false){
              if(lightParentGroupName.findIndex(v => v === mesh.name) === -1){
                this.ModelGroupInfoList.push({
                  value: mesh.name,
                  label: mesh.name,
                  color: "dark"
                });
              }
            } else {
              if(lightParentGroupName.findIndex(v => v === mesh.parent?.name) === -1){
                lightParentGroupName.push(mesh?.parent?.name as string);
              }
            }
        
          }
        });

        this.setSpaceModelSizePosition(this.selectModelInfo);
      }
      
    },
    setZoneZoomValue(){
      console.log("::: Zoom Value Start :::");
      this.zoomReset();
      // console.log(this.selectModelInfo, this.areaWidth, this.areaHeight, this.maxWidth, this.maxHeight);
      
      
      // const maxWidth = this.UzoneSizeData.x * this.zoom;
      // const maxHeight = this.UzoneSizeData.z * this.zoom;

      const defaultWidth = Number(this.areaWidth) - 150; 
      const defaultHeight = Number(this.areaHeight) - 150; 

      let zoomValue = 1;
      const rectRate = Number((defaultWidth / defaultHeight).toFixed(2));

      const width = this.maxWidth;
      const height = this.maxHeight * rectRate;

      if(width >= height){
        zoomValue = Number(((defaultWidth - 50) / this.maxWidth));
      } else {
        zoomValue = Number(((defaultHeight - 50) / this.maxHeight));
      }

      this.maxWidth = this.UzoneSizeData.x * zoomValue;
      this.maxHeight = this.UzoneSizeData.z * zoomValue;
      
      this.zoom = zoomValue;
    },

    //선택한 공간 (RSpace/ C / U)
    async setSelectSpaceInfo(zoneSeq: number | null) {
      const formerGrp = this.selectSpaceInfo?.ctrlGrps as CtrlGrp[];
      if(formerGrp !== null && formerGrp !== undefined){
        for(let i = 0; i < formerGrp.length; i++){
          this.removeDpointNode(formerGrp[i]);
        }
      }
      
      this.zoom = 1;

      if(CommonUtils.isNullOrEmpty(this.selectZoneInfo) || CommonUtils.isNullOrEmpty(zoneSeq))
        return;

      this.selectSpaceInfo = this.findZone(zoneSeq, null);

      //존이 검색이 안되면 대표공간으로 설정
      if (CommonUtils.isNullOrEmpty(this.selectSpaceInfo))
        this.selectSpaceInfo = this.selectZoneInfo;

      //스냅샷에 선택한 공간번호와 공간 타입을 셋
      this.snapShot.selectZoneSeq = zoneSeq;
      this.snapShot.selectZoneType = this.selectSpaceInfo.zoneTypeCode;

      this.persistStateManager.setSelectedSpace(this.selectSpaceInfo);

      this.inDPointList.splice(0);
      for (const dPoint of this.selectSpaceInfo?.dpoints ?? []) {
        this.inDPointList.push(new InDPoint(dPoint));
      }  

      console.log(`★setSelectSpaceInfo★ => zoneSeq: ${this.selectSpaceInfo?.zoneSeq}, zoneName: ${this.selectSpaceInfo?.zoneName}`);
      //this.selectSpaceInfo = this.findSelectZoneInfo(this.selectZoneInfo, zoneSeq) as Zone;

      const nodeEditorStore = useNodeEditorStore();
      nodeEditorStore.deselectAllNode();

      this.maxWidth = this.selectSpaceInfo?.horizSize as number;
      this.maxHeight = this.selectSpaceInfo?.vertiSize as number;
      this.setModelSize(this.selectSpaceInfo?.horizSize as number, this.selectSpaceInfo?.height as number, this.selectSpaceInfo?.vertiSize as number);

      this.setSelectZoneModelInfo("in spaceselect");
      const scenarioStore = useScenarioStore();

      
      //트리에서 공간을 선택하였을때 단위존인경우 Scenario-Store에 첫번째
      if(this.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존) {
        scenarioStore.setSelectScenarioInfo(null);
        return;
      }
                  
      //선택한 단위공간에 시나리오가 존재하지 않으면 새 시나리오 생성
      if(CommonUtils.isNullOrEmpty(this.selectSpaceInfo.scenarios) || this.selectSpaceInfo.scenarios.length <= 0)
        scenarioStore.addNewScenario();

      let scenarioSeq = null;

      if (CommonUtils.isNullOrEmpty(this.snapShot.scenarioSeq)) {
        scenarioSeq = this.selectSpaceInfo.scenarios?.[0].scenarioSeq ?? null;
      } else {
        scenarioSeq = this.snapShot.scenarioSeq;
      }

      scenarioStore.setSelectScenarioInfo(scenarioSeq);

      const simulationStore = useSimulationStore();

      simulationStore.initData();
      simulationStore.getZoneSmlData(this.selectSpaceInfo.siteSeq as number, this.selectSpaceInfo.zoneSeq as number);
    },

    setSpaceModelSizePosition(object: THREE.Object3D | null = null){
      console.log("setSpaceModel Size Position :::::");
      const selectName = name;
      const selectObject = object;
      // if(selectName !== null && selectName !== undefined){
      // if(selectName.includes("1F") === true){
      //   this.selectModelInfo?.traverse((mesh) => {
      //     if(mesh.name === "1F"){
      //       selectObject = mesh;
      //     }
      //   });
      // } else {
      //   this.selectModelInfo?.traverse((mesh) => {
      //     if(mesh.name === "2F"){
      //       selectObject = mesh;
      //     }
      //   });
      // }    
      // }


      if(selectObject !== null){
        const selectBox3 = new THREE.Box3().setFromObject(selectObject as THREE.Object3D);
        const vector = new THREE.Vector3();
        selectBox3.getCenter(vector);
      
        const size = new THREE.Vector3();
        selectBox3.getSize(size);
  
        this.setModelSize(size.x, size.y, size.z);
        this.setModelPostion(vector.x, vector.y, vector.z);

        this.maxWidth = this.UzoneSizeData.x;
        this.maxHeight = this.UzoneSizeData.z;

        this.setZoneZoomValue();
      } else {
        const selectZone = this.selectSpaceInfo as Zone;

        this.setModelSize(selectZone.horizSize, selectZone.height, selectZone.vertiSize);
      }

   
      if(this.selectSpaceInfo?.modelFileGrp !== null){
        const object = selectObject;

        const parentVisible = (parentObject: THREE.Group) => {
          if(parentObject instanceof THREE.Group){
            parentObject.visible = true;
            if(parentObject.parent instanceof THREE.Group){
              parentVisible(parentObject.parent);
            }
          }
        };

        const traverseObject = (objects: THREE.Group) => {
          objects.traverse(mesh => {
            if(mesh instanceof THREE.Group){
              mesh.visible = false;
              if(mesh.name === this.selectSpaceInfo?.modelFileGrp as unknown as string){
                mesh.visible = true;
                parentVisible(mesh.parent as THREE.Group);
                return;
              }
            }
          });
        };

        traverseObject(object as THREE.Group);

        let selectObjectss = new THREE.Group;

        object?.traverse(mesh => {
          if(mesh instanceof THREE.Group){
            if(mesh.visible === true){
              selectObjectss = mesh;
            }
          }
        });

        this.selectModelAreaInfo = selectObjectss;


        const boxes = new THREE.Box3().setFromObject(selectObjectss as THREE.Object3D);
        const selectModelPosition = new THREE.Vector3;
        const selectModelSize = new THREE.Vector3;
        boxes.getCenter(selectModelPosition);
        boxes.getSize(selectModelSize);
        this.setSelectModelVector(selectModelPosition, selectModelSize);
      } else {
        this.selectModelAreaInfo = null;
      }

    },
    
    findSelectZoneInfo(zoneList: Zone, zoneSeq: number | null){
      if(zoneSeq === null){
        return {} as Zone;
      }
      if(zoneList.zoneSeq === zoneSeq){
        return zoneList;
      }

      for(let i = 0; i < zoneList.childZones.length; i++){
        const czone = zoneList.childZones[i] as Zone;
        if(czone.zoneSeq === zoneSeq){
          return czone;
        }
        for(let j = 0; j < czone.childZones.length; j++){
          const uzone = czone.childZones[j] as Zone;
          if(uzone.zoneSeq === zoneSeq){
            return uzone;
          }
        }
      }
    },

    setSelectWallInfo(data: Wall) {
      this.SelectWallInfo = data;
    },
    // setSelectEditSpace(infoId: number) {
    //   let info: Site | Zone;
    //   if (this.selectSiteInfo instanceof Site) {
    //     info = this.selectSpaceInfo.childZones.find((el) => el.siteSeq === infoId) as Zone;
    //   } else {
    //     info = {} as Site;
    //   }

    //   // this.setSelectEditSpace(info);
    // },
    setTreeSelectValue(data: string) {
      this.TreeSelectValue = data;
    },
    setSelectMainKey(data: string | undefined) {
      this.SelectMainKey = data;
    },
    setEditFuncKey(data: string) {
      this.EditFuncKey = data;
    },
    setSelectSiteID(siteId: number) {
      this.SelectSiteID = siteId;
    },
    setAllSiteList(data: Site[]) {
      this.AllSiteList = data;
    },

    setDefaultSiteInfo(data: Site) {
      this.DefaultSiteInfo = data;
    },

    // NOTE - 단위공간 추가
    async importUspace(uzone: Zone, boundPath: string) {
      console.log(boundPath);
      const pzone = this.selectSpaceInfo as Zone;

      if(uzone.uzone){
        uzone.uzone.siteSeq = pzone.siteSeq;
      }

      uzone.siteSeq = pzone.siteSeq;
      uzone.pzoneSeq = pzone.zoneSeq;
      
      if(CommonUtils.isNullOrEmpty(boundPath) === true){
        const attr = {
          type: "rect",
          x: uzone.originX,
          y: uzone.originY,
          width: uzone.horizSize,
          height: uzone.vertiSize
        };

        console.log(attr);
      
        const boundspath = SVGPathCommander.shapeToPath(attr);
        uzone.boundsPath =  boundspath.getAttribute("d");

      } else {
        uzone.boundsPath =  boundPath;
      }

      console.log(uzone);
      pzone.childZones.push(uzone);

      if (CommonUtils.isNullOrEmpty(this.selectZoneInfo))
        return;

      await this.modifyZone(pzone.siteSeq, this.selectZoneInfo);
    },
    addLastSelectedNodeEL(nodes: HTMLElement[]){
      // if(isClear === true){
      //   this.lastSelectedNodeEL = [] as HTMLElement[];
      // } else {
      //   this.lastSelectedNodeEL.push(node as HTMLElement);
      // }
      
      this.lastSelectedNodeEL = nodes;

    },
    clearNodeEL(){
      const menu = useMenuStore();
      if(menu.selectedMenu?.menuKind !== Depth1_Kind.시나리오){
        const groups = document.getElementById("drawItemGroup") as HTMLElement;
        const nodes = groups.children;
  
        for(let i = 0; i < nodes.length; i++){
          nodes[i].remove();
          i--;
        }

        for(let i = 0; i < this.lastSelectedNodeEL.length; i++){
          this.lastSelectedNodeEL[i].remove();
        }
        // const groupBox = document.getElementById("groupBoxes") as HTMLElement;
        // if(groupBox !== undefined && groupBox !== null){
        //   const boxNode = groupBox.children;
        
        //   for(let i = 0; i < boxNode.length; i++){
        //     boxNode[i].remove();
        //     i--;
        //   }
        // }

      }
    },
    // NOTE - 선택 조명 태그를 최상단 이동 로직
    selectGroupLightUpper(viewType: ViewType){
      console.log(" :::: SELECT GROUP LIGHT UPPER :::::");


      const selectGrp = this.selectGroupInfo;

      if(selectGrp === null) {
        // isSelectedNodes.value = [] as HTMLElement[];
        return;
      }
      const groups = document.getElementsByClassName(`GroupBox${selectGrp.grpSeq}`)[0];

      if(groups === undefined || groups === null){
        return;
      }


      const dpointList = this.inDPointList;
      const selectGrpDpoint = [] as InDPoint[];
      const formerGroup = document.getElementById("drawItemGroup");

      for(let i = 0; i < this.lastSelectedNodeEL.length; i++){
        const selectnode = this.lastSelectedNodeEL[i] as HTMLElement;
        formerGroup?.appendChild(selectnode);
      }

      // isSelectedNodes.value = [] as HTMLElement[];
      if(selectGrp !== null){
        selectGrp?.members?.map(light => {
          for(let i = 0; i < dpointList.length; i++){
            if(light.dpointSeq === dpointList[i].dpointSeq){
              selectGrpDpoint.push(dpointList[i]);
              break;
            }
          }
        });
        const dpointElement = [] as HTMLElement[];
        // editStore.addLastSelectedNodeEL(null, true);

        selectGrpDpoint.map(dpoint => {
          const el = document.getElementById(dpoint.nodeID as string);
          if(el !== null){
            dpointElement.push(el as HTMLElement);
            this.addLastSelectedNodeEL(dpointElement);
          }
        });

        dpointElement.map(node => {
          if(node !== null){
            if(viewType === ViewType.SecondDimension){
              groups.parentElement?.parentElement?.appendChild(node);
            }
          }
        });
      }
    },
    removeDpointNode(groupInfo: CtrlGrp){
      const group = this.selectSpaceInfo?.ctrlGrps?.find(v => v.grpSeq === groupInfo.grpSeq);
      const groupMember = group?.members as GrpMember[];
      const grpDpoints = [] as InDPoint[];

      for(let i = 0; i < groupMember.length; i++){
        this.inDPointList.find(v => {
          if(v.dpointSeq === groupMember[i].dpointSeq){
            grpDpoints.push(v);
          }
        });
      }
      const grpNodeList = [] as HTMLElement[];

      for(let i = 0; i < grpDpoints.length; i++){
        const node = document.getElementById(grpDpoints[i].nodeID as string);

        grpNodeList.push(node as HTMLElement);
      }


      // const groupItem = document.getElementById("drawItemGroup");
      // console.log(groupItem);

      grpNodeList.map(v => {if(v !== null) v.remove();});
    },
    resetDpointNode(groupInfo: CtrlGrp){

      this.inDPointList.map(v => {
        const node = document.getElementById(v.nodeID as string);
        const nodeShape = node?.children[0] as HTMLElement;
        nodeShape.style.filter = "brightness(1)";
        nodeShape.style.fill = "#ffffff";
      });

      if(groupInfo === null) return;
      
      // console.log(groupInfo);

      const group = this.selectSpaceInfo?.ctrlGrps?.find(v => v.grpSeq === groupInfo.grpSeq);
      const groupMember = group?.members as GrpMember[];
      const grpDpoints = [] as InDPoint[];

      for(let i = 0; i < groupMember.length; i++){
        this.inDPointList.find(v => {
          if(v.dpointSeq === groupMember[i].dpointSeq){
            grpDpoints.push(v);
          }
        });
      }
      const grpNodeList = [] as HTMLElement[];

      for(let i = 0; i < grpDpoints.length; i++){
        const node = document.getElementById(grpDpoints[i].nodeID as string);

        grpNodeList.push(node as HTMLElement);
      }

      const groupItem = document.getElementById("drawItemGroup");
      // console.log(groupItem);
      // for(let i = 0; i < grpNodeList.length; i++){
      //   groupItem?.appendChild(grpNodeList[i]);
      //   i--;
      // }

      grpNodeList.map(v => groupItem?.appendChild(v));




    },
    resetColor(){
      console.log(" ::: edit reset color ::::");
      const dpointList = this.inDPointList;

      const elementPointList = [] as HTMLElement[];

      dpointList.forEach(point => {
        const el = document.getElementById(point.nodeID as string);
        elementPointList.push(el as HTMLElement);
      });

      elementPointList.forEach(el => {
        if(el !== null){
          const elChildren = el.children;
          let shape = {} as HTMLElement;
          for(let i = 0; i < elChildren.length; i++){
            if(elChildren[i].className === "" || elChildren[i].className === null){
              shape = elChildren[i] as HTMLElement;
            }
          }    
          // console.log(shape);      

          if(Object.keys(shape).length > 0){
            shape.style.fill = "#ffffff";
            shape.style.filter = "brightness(1)";
          }
        }
      });
    },
    loadZoneLights(zoneSeq: number | null) {
      console.log("::: call node light zone ::: ");

      const nodeEditorStore = useNodeEditorStore();
      console.log(" :::: clearNodes :::::");

      nodeEditorStore.clearNodes();

      if (CommonUtils.isNullOrEmpty(zoneSeq))
        return;

      const zone = this.findZone(zoneSeq, null);

      if (CommonUtils.isNullOrEmpty(zone) || CommonUtils.isNullOrEmpty(zone.dpoints))
        return;
      
      for (const inDPoint of this.inDPointList) {
        const config = (nodeEditorStore.getNodeConfigs as LightNodeConfig[]).find(n => n.lightType === inDPoint.devTypeCode);

        if (CommonUtils.isNullOrEmpty(config))
          continue;

        const x = inDPoint.centerX as number * this.zoom;
        const y = (inDPoint.centerZ as number + this.UzoneSizeData.z) * this.zoom;
        // const x = inDPoint.centerX as number;
        // const y = inDPoint.centerZ as number;

        const height = inDPoint.centerY as number;


        const node = nodeEditorStore.createNode(x, y, config);
        (node as AbstractNodeInfo).height = height;

        inDPoint.nodeID = node?.id;
      }
    },

    /**
     * 노드 이벤트에 따른 DPoints 업데이트(추가, 삭제, 이동)
     * @param nodeEventType 
     * @param nodes 
     * @returns 
     */
    updateDPoints(nodeEventType: NodeEventType, nodeIds: string[] | null) {

      if (CommonUtils.isNullOrEmpty(nodeIds) || CommonUtils.isNullOrEmpty(this.selectSpaceInfo))
        return;


      const inDPoints = this.inDPointList as InDPoint[];
      const nodeEditorStore = useNodeEditorStore();


      for (const nodeId of nodeIds) {

        const inDPointIndex = inDPoints.findIndex(d => d.nodeID === nodeId);
        const inDPoint = inDPoints[inDPointIndex];


        let node: AbstractNodeInfo | null = null;
        switch (nodeEventType) {
          case NodeEventType.Moved:
          case NodeEventType.Removed:

            if (inDPointIndex < 0)
              continue;

            if (nodeEventType === NodeEventType.Moved) {
              node = nodeEditorStore.getNode(nodeId);

              const Dpoint = this.selectSpaceInfo.dpoints?.find(d => d.dpointSeq === inDPoint.dpointSeq);
              const nx = node?.x as number / this.zoom ?? 0;
              const ny = node?.height ?? 0;
              const nz = node?.y as number / this.zoom - this.UzoneSizeData.z ?? 0;

              inDPoint.centerX = nx;
              inDPoint.centerY = ny;
              inDPoint.centerZ = nz;

              if(Dpoint !== null && Dpoint !== undefined){
                Dpoint.centerX = nx;
                Dpoint.centerY = ny;
                Dpoint.centerZ = nz;
              }
           

            } else if (nodeEventType === NodeEventType.Removed) {

              if(CommonUtils.isNullOrEmpty(this.selectSpaceInfo.dpoints))
                continue;

              //inDPoint 삭제
              inDPoints.splice(inDPointIndex, 1);
              const dPointIndex = this.selectSpaceInfo.dpoints.findIndex(d => d.dpointSeq === inDPoint.dpointSeq);

              //DPoint 삭제
              this.selectSpaceInfo.dpoints?.splice(dPointIndex, 1);
            }
            break;
          case NodeEventType.Paste:
          case NodeEventType.Added:
            node = nodeEditorStore.getNode(nodeId);

            if (CommonUtils.isNullOrEmpty(node))
              continue;

            this.addInDPoint(nodeId, this.selectSpaceInfo.siteSeq, this.selectSpaceInfo.zoneSeq, node.nodeConfig.id, node.x, 0, node.y);                        
            break;
        }
      }

      //this.push();
    },    

    addInDPoint(nodeId: string, siteSeq: number, zoneSeq: number, devTypeCode: string, x: number, y: number, z: number) {
      const dPointSeq = this.getNewDPointSeq();
      // const newPoint = new DPoint(siteSeq, zoneSeq, dPointSeq, useUserStore().user?.userId as number);
      // newPoint.devTypeCode = devTypeCode;
      // newPoint.centerX = x / this.zoom;
      // newPoint.centerY = y;
      // newPoint.centerZ = z / this.zoom - this.UzoneSizeData.z;
      // newPoint.rotate = 0;
      // newPoint.dpointName = `조명 ${newPoint.dpointSeq}`;
      // Object.defineProperty(newPoint, "nodeID", {
      //   value: nodeID
      // });
      const newDPoint = new DPoint(siteSeq, zoneSeq, dPointSeq, useUserStore().user?.userId as number);

      // const tx = x as number * this.zoom;
      // const tz = (z as number + this.UzoneSizeData.z) * this.zoom;

      newDPoint.devTypeCode = devTypeCode;
      newDPoint.centerX = x / this.zoom;
      newDPoint.centerY = y;
      newDPoint.centerZ = z / this.zoom - this.UzoneSizeData.z;
      newDPoint.rotate = 0;
      newDPoint.dpointName = `조명 ${newDPoint.dpointSeq}`;

      const newInDpoint = new InDPoint(newDPoint, nodeId);

      const mpoint = this.createMpoint(siteSeq, zoneSeq, newInDpoint.dpointSeq, 0, 0, 0);
      newInDpoint.mpoints?.push(mpoint);

      if(CommonUtils.isNullOrEmpty(this.selectSpaceInfo) || CommonUtils.isNullOrEmpty(this.selectSpaceInfo.dpoints))
        return;

      this.selectSpaceInfo.dpoints.push(newDPoint);
      this.inDPointList.push(newInDpoint);
    },

    pasteDeviceNodes() {

    },    

    createMpoint(siteSeq: number, zoneSeq: number, dpointSeq: number, x: number, y: number, z: number) {
      const newMpoint = new MPoint(siteSeq, zoneSeq, dpointSeq, x, y, z, 0, useUserStore().user?.userId as number);      
      return newMpoint;
    },

    /**
     * Group 관련 액션
     */

    /**
     * 신규 그룹을 추가
     * @param siteSeq 
     * @param zoneSeq 
     */
    addNewGroup(siteSeq: number, zoneSeq: number) {
      const userID = useUserStore().user?.userId;
      const newGroup = new CtrlGrp();
      newGroup.grpSeq = Math.floor(Math.random() * 10000 + 1000);
      newGroup.grpName = "새 그룹";
      newGroup.grpDesc = "";
      newGroup.grpTypeCode = "CG";
      newGroup.members = [];
      newGroup.siteSeq = siteSeq;
      newGroup.zoneSeq = zoneSeq;
      newGroup.regUserId = userID;
      newGroup.modDate = new Date().toISOString();
      newGroup.regDate = new Date().toISOString();
      
      this.selectSpaceInfo?.ctrlGrps?.push(newGroup);
    },

    findGroupName(grpSeq: number){
      const grpName = this.selectSpaceInfo?.ctrlGrps?.find(v => {
        if(v.grpSeq === grpSeq){
          return v.grpName;
        }
      });

      return grpName;
    },

    setSelectGroupInfo(groupID: number | null) {      
      // console.log(groupID);

      // const selectBox = this.selectGroupBox as GroupBox[];
      // console.log(selectBox, groupID);

      // if(selectBox !== null){
      //   selectBox.forEach(v => {
      //     v.isSelect = false;
      //   });

      //   for(let i = 0; i < selectBox.length; i++){
      //     if(selectBox[i].group.grpSeq === Number(groupID)){
      //       console.log(selectBox[i]);
      //       selectBox[i].isSelect = true;
      //     }
      //   }
      // }

      
      if(groupID === null){
        this.selectGroupInfo = null;
        this.lastSelectedNodeEL = [] as HTMLElement[];
      } else {
        this.selectGroupInfo = this.selectSpaceInfo?.ctrlGrps?.find(g => g.grpSeq === Number(groupID)) ?? null;
        this.selectGroupLightUpper(this.modelViewType);

      }

    },   

    /**
     * 그룹 멤버를 표시한다.
     * @returns 
     */
    displayGroupMembers() {
      if (CommonUtils.isNullOrEmpty(this.selectGroupInfo))
        return;
        
      this.resetDpointNode(this.selectGroupInfo);

      const nodeEditorStore = useNodeEditorStore();
      const inDPoints: InDPoint[] = this.inDPointList ?? [];

      for (const member of this.selectGroupInfo.members ?? []) {

        const inDPoint = inDPoints.find(d => d.dpointSeq === member.dpointSeq);

        if (CommonUtils.isNullOrEmpty(inDPoint))
          continue;

        const node = nodeEditorStore.nodeInfos.find(n => n.id === inDPoint.nodeID);

        if (CommonUtils.isNullOrEmpty(node))
          continue;

        node.nodeStatus = NodeStatus.Selected;
      }
    },
    
    /**
     * 현재 선택된 그룹 멤버를 업데이트 한다.
     * @returns 
     */
    updateCurrentGroupMember() {
      if(CommonUtils.isNullOrEmpty(this.selectGroupInfo))
        return;

        
      const nodeEditorStore = useNodeEditorStore();
      //const dPoints = this.getGroupInDPoints(this.selectGroupInfo);
      const inDPoints: InDPoint[] = this.inDPointList ?? [];      
      const addingMembers: GrpMember[] = [];
      const removingMembers: GrpMember[] = [];

      for(const node of nodeEditorStore.nodeInfos) {

        if(CommonUtils.isNullOrEmpty(this.selectGroupInfo.members))
          this.selectGroupInfo.members = [];

        const inDPoint = inDPoints.find(d => d.nodeID === node.id);        
        if (CommonUtils.isNullOrEmpty(inDPoint)){
          continue;

        }

        const member = this.selectGroupInfo.members.find(m => m.dpointSeq === inDPoint.dpointSeq);
        if (node.nodeStatus === NodeStatus.Selected) {

          //노드가 선택되어 있고 멤버가 존재 하지 않으면 멤버 추가함.
          if (!CommonUtils.isNullOrEmpty(member))
            continue;

          let moduleSeq;

          if(inDPoint.mpoints?.[0] === undefined){
            moduleSeq = undefined;
          } else {
            moduleSeq = inDPoint.mpoints?.[0].moduleSeq;
          }

          if(CommonUtils.isNullOrEmpty(this.selectGroupInfo.grpSeq))
            continue;

          const newMember = new GrpMember(inDPoint.siteSeq, inDPoint.zoneSeq, this.selectGroupInfo.grpSeq, inDPoint.dpointSeq, moduleSeq, "DPOINT", useUserStore().user?.userId as number);
          //this.selectGroupInfo.members.push(newMember);
          const deviceStore = useDeviceStore();
          const devTypeList = deviceStore.lightList;
          const memberDevType = devTypeList.find(v => v.devTypeCode === inDPoint.devTypeCode);    
          if(memberDevType?.devClsfyCode !== "GW"){
            
            addingMembers.push(newMember);
          }
        } else {

          //노드가 선택되어 있지 않고 멤버가 존재하면 멤버 삭제함.          
          if (CommonUtils.isNullOrEmpty(member))
            continue;

          removingMembers.push(member);
          // const memberIndex = this.selectGroupInfo.members.indexOf(member);
          // this.selectGroupInfo.members.splice(memberIndex, 1);          
        }        
      }      

      for(const member of addingMembers) {
        (this.selectGroupInfo.members as GrpMember[]).push(member);
      }

      for (const member of removingMembers) {
        const members = (this.selectGroupInfo.members as GrpMember[]);
        const memberIndex = members.indexOf(member);
        members.splice(memberIndex, 1);
      }
      
      nodeEditorStore.deselectAllNode();
      nodeEditorStore.setSelectionNode(null);
    },

    /**
     * ZoomBase Action 함수
     */

    zoomIn() {
      this.zoom += 0.1;
      this.zoom.toFixed(2);
    },
    btnZoomIn(){
      console.log(" ::: zoom in :::");
      this.zoom += 0.5;
      this.zoom.toFixed(2);
      
      this.removeDpointNode(this.selectGroupInfo as CtrlGrp);

      this.clearNodeEL();

      this.loadZoneLights(this.selectSpaceInfo?.zoneSeq as number);

      if(this.editorInfo !== null){
        this.editorInfo.gridSetting.pageWidth = Number(this.editorInfo.gridSetting.pageWidth) + 100 * this.zoom;
        this.editorInfo.gridSetting.pageHeight = Number(this.editorInfo.gridSetting.pageHeight) + 100 * this.zoom;
      }
    },
    zoomOut() {
      if (this.zoom > 0.2) {
        this.zoom -= 0.1;
        this.zoom.toFixed(2);
      }
    },
    btnZoomOut(){
      console.log(" ::: zoom out :::");

      if(this.zoom > 0.2){
        this.zoom -= 0.5;
        this.zoom.toFixed(2);

        this.removeDpointNode(this.selectGroupInfo as CtrlGrp);
        this.clearNodeEL();
        this.loadZoneLights(this.selectSpaceInfo?.zoneSeq as number);

        if(this.editorInfo !== null){
          this.editorInfo.gridSetting.pageWidth = Number(this.editorInfo.gridSetting.pageWidth) - 100 * this.zoom;
          this.editorInfo.gridSetting.pageHeight = Number(this.editorInfo.gridSetting.pageHeight) - 100 * this.zoom;
        }
      }
    },
    zoomReset() {
      this.zoom = 1;
    },    

    /**
     * Undo 수행
     * @returns 
     */
    async undo() {
      try {
        const undoRedoManager = useUndoRedo();

        if (!undoRedoManager.isCanUndo)
          return;

        this.stopWatch();
        const snapShot = undoRedoManager.undo();
        await this.applySnapShot(snapShot);

      } finally {
        this.startWatch();
      }
    },

    /**
     * Redo 수행
     * @returns 
     */
    async redo() {
      try {
        const undoRedoManager = useUndoRedo();

        if (!undoRedoManager.isCanRedo)
          return;

        this.stopWatch();
        const snapShot = undoRedoManager.redo();
        await this.applySnapShot(snapShot);

      } finally {
        this.startWatch();
      }
    },

    /**
     * 스냅샷 적용
     * @param snapShot 
     * @returns 
     */
    async applySnapShot(snapShot: SnapShot | null | undefined) {

      if (CommonUtils.isNullOrEmpty(snapShot))
        return;

      this.snapShot = snapShot;
      this.selectZoneInfo = snapShot.zone;

      if (CommonUtils.isNullOrEmpty(this.selectSpaceInfo))
        return;

      const menuStore = useMenuStore();

      if (!CommonUtils.isNullOrEmpty(menuStore.selectedMenu) && !CommonUtils.isNullOrEmpty(snapShot.menuKind))
        menuStore.selectionMenu(snapShot.menuKind);

      //공간 선택
      await this.setSelectSpaceInfo(snapShot.selectZoneSeq);
      // this.loadZoneLights(snapShot.selectZoneSeq);

      if (snapShot.menuKind === Depth1_Kind.시나리오) {
        //시나리오 선택
        const scenarioStore = useScenarioStore();
        scenarioStore.setSelectScenarioInfo(snapShot.scenarioSeq);
      }      

      if(snapShot.menuKind === Depth2_Kind.씬){
        console.log(snapShot);

        const sceneStore = useSceneStore();
        sceneStore.setSelectSceneInfo(snapShot.sceneSeq);
        sceneStore.setSceneSettingGrpSeq(snapShot.sceneSelectGrpSeq);
        useEditStore().setSelectGroupInfo(snapShot.sceneSelectGrpSeq);
        // sceneStore.setSelectSceneInfo()

      }
    },

    /**
     * Undo-Redo 스택에 적용
     * @param zone 
     * @param newZone 
     * @returns 
     */
    push(snapShot: SnapShot, newSnapShot: SnapShot) {
      const menuStore = useMenuStore();

      if (!(menuStore.selectedMenu?.isReactUndoRedo ?? false))
        return;

      const undoRedoManager = useUndoRedo();

      if (CommonUtils.isNullOrEmpty(this.selectZoneInfo) || CommonUtils.isNullOrEmpty(snapShot))
        return;
    
      undoRedoManager.push(snapShot, newSnapShot);

      console.log(`★★ SnapShot => MenuKind: ${JSON.stringify(snapShot.menuKind)}, scenarioSeq: ${snapShot.scenarioSeq}, ${snapShot.selectZoneSeq}`);
      console.log(`★★ New SnapShot => MenuKind: ${JSON.stringify(newSnapShot.menuKind)}, scenarioSeq: ${newSnapShot.scenarioSeq}, ${newSnapShot.selectZoneSeq}`);
    },

    /**
     * Undo-Redo를 위한 존정보 감시 시작
     * @returns 
     */
    startWatch() {
      console.log("~~ :: start watch ::: ~~");
      if (!CommonUtils.isNullOrEmpty(watchHandle))
        return;

      watchHandle = watch(() => CommonUtils.deepClone(this.snapShot), (newVal, oldVal) => {
        console.log(newVal, oldVal);
        try {
          if (CommonUtils.isNullOrEmpty(this.selectZoneInfo))
            return;

          this.push(oldVal as SnapShot, newVal as SnapShot);
        } finally {
        }
      }, {
        deep: true
      });
    },    
    
    /**
     * 존정보 감시 중지
     * @returns 
     */
    stopWatch() {
      console.log(" ~~~~ :: stop watch :::: ~~~~~~");
      if (CommonUtils.isNullOrEmpty(watchHandle))
        return;

      watchHandle();
      watchHandle = null;
    },
  },
});
