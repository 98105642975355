<script setup lang="ts">
import type TextNodeConfig from "@/models/editor/nodes/config/text-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import type TextNodeSetting from "@/models/editor/nodes/setting/text-node-setting";
import { reactive } from "vue";
import { onMounted } from "vue";

const props = defineProps<{
  nodeInfo: AbstractNodeInfo,
}>();

const nodeInfoRef = reactive(props.nodeInfo);
const nodeConfig = nodeInfoRef.nodeConfig as TextNodeConfig;
const nodeSetting = nodeInfoRef.nodeSetting as TextNodeSetting;

onMounted(() => {
  nodeSetting.text = nodeConfig.text;  
  console.log(`config Text: ${nodeConfig.text}, info Text: ${nodeSetting.text}`);
  
});

</script>
<template>
  <g>
    <rect :width="nodeInfoRef.nodeConfig.w" :height="nodeInfoRef.nodeConfig.h"></rect>
    <text :x="nodeInfoRef.nodeConfig.w / 2" :y="nodeInfoRef.nodeConfig.h / 2" font-family="Verdana" font-size="20"
      fill="blue">
      {{ nodeSetting.text }}      
    </text>
  </g>
</template>
<style lang="scss">
</style>