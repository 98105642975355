<script setup lang="ts">
import { NodeStatus, NodeType, NodeElementType, DeviceShape, Depth2_Kind } from "@/global/enums";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { onMounted, reactive } from "vue";
import TextNode from "@/components/Nodes/TextNode.vue";
import FlowPort from "@/components/Nodes/FlowPort.vue";
import PackageNode from "./PackageNode.vue";
import ScenarioNode from "./ScenarioNode.vue";
import LinkNode from "./LinkNode.vue";
import type PortInfo from "@/models/editor/nodes/info/port-info";
import { useEditStore } from "@/stores/edit-store";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import { useMenuStore } from "@/stores/menu-store";

const props = defineProps<{
  nodeInfo: AbstractNodeInfo;
}>();

const emitData = defineEmits<{
  (eventName: "nodeMouseDown", event: MouseEvent, id: string): void;
  (eventName: "nodeMouseUp", event: MouseEvent, id: string): void;
  (eventName: "portMouseDown", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void;
  (eventName: "portMouseUp", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void;
  (eventName: "portMouseOver", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void;
  (eventName: "portMouseOut", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void;
}>();

const nodeInfoRef = reactive(props.nodeInfo);
const menuStore = useMenuStore();

/**
 * 마운트
 */
onMounted(() => {
  
});

/**
 * 노드 마우스 다운 이벤트
 * @param event 마우스 이벤트
 */
function nodeMouseDown(event: MouseEvent) {
  emitData("nodeMouseDown", event, nodeInfoRef.id);
}

/**
 * 노드 마우스 업 이벤트
 * @param event 마우스 이벤트
 */
function nodeMouseUp(event: MouseEvent) {
  emitData("nodeMouseUp", event, nodeInfoRef.id);
}

function getComponent() {}

function onPortMouseDown(event: MouseEvent, port: PortInfo) {
  emitData("portMouseDown", event, props.nodeInfo, port);
}

function onPortMouseUp(event: MouseEvent, port: PortInfo) {
  emitData("portMouseUp", event, props.nodeInfo, port);
}

function onPortMouseOver(event: MouseEvent, port: PortInfo) {
  emitData("portMouseOver", event, props.nodeInfo, port);
}

function onPortMouseOut(event: MouseEvent, port: PortInfo) {
  emitData("portMouseOut", event, props.nodeInfo, port);
}
</script>

<template>
  <g :id="nodeInfoRef.id" :transform="`translate(${nodeInfoRef.x},${(nodeInfoRef.y)})`" @mousedown="nodeMouseDown" @mouseup="nodeMouseUp">
    <!-- 선택 테두리 박스 -->
    <rect
      class="rect"   
      :x="-6"
      :y="-6"
      :cy="300"      
      :width="nodeInfoRef.nodeConfig.w + 12"
      :height="nodeInfoRef.nodeConfig.h * ((nodeInfoRef.nodeItems?.length ?? 0) + 1) + 12"
      v-if="nodeInfoRef.nodeStatus === NodeStatus.Selected"
    />
    
    <image
      v-if="(nodeInfoRef.nodeConfig.nodeType === NodeType.Image && (nodeInfoRef.nodeConfig as LightNodeConfig).devClsfyCode === 'LD')"
      :width="nodeInfoRef.nodeConfig.w"
      :height="nodeInfoRef.nodeConfig.h"
      :href="nodeInfoRef.nodeConfig.imageUrl"
    />

    <rect v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Shape && nodeInfoRef.nodeConfig.shapeType === DeviceShape.SQUARE"  
      :width="nodeInfoRef.nodeConfig.w"
      :height="nodeInfoRef.nodeConfig.h"
      :style="{fill: '#ffffff'}"
    />

    <circle v-else-if="(nodeInfoRef.nodeConfig as LightNodeConfig).devClsfyCode === 'GW' &&
    nodeInfoRef.nodeConfig.shapeType === DeviceShape.CIRCLE
    "  
      :cx="(nodeInfoRef.nodeConfig.w /2)"
      :cy="(nodeInfoRef.nodeConfig.w /2)"
      :r="nodeInfoRef.nodeConfig.w /2 + 'px'"
      :style="{
        fill: '#00ff00',
        width: nodeInfoRef.nodeConfig.w + 'px',
        height: nodeInfoRef.nodeConfig.h + 'px',
        display: menuStore.selectedMenu?.menuKind === Depth2_Kind.씬 ? 'none' : 'block'
      }"
    />

    <circle v-else-if="(nodeInfoRef.nodeConfig as LightNodeConfig).devClsfyCode === 'SD' &&
    nodeInfoRef.nodeConfig.shapeType === DeviceShape.CIRCLE
    "  
      :cx="(nodeInfoRef.nodeConfig.w /2)"
      :cy="(nodeInfoRef.nodeConfig.w /2)"
      :r="nodeInfoRef.nodeConfig.w /2+ 'px'"
      :style="{
        fill: '#0000ff',
        width: nodeInfoRef.nodeConfig.w + 'px',
        height: nodeInfoRef.nodeConfig.h + 'px' ,
        display: menuStore.selectedMenu?.menuKind === Depth2_Kind.씬 ? 'none' : 'block'
      }"
    />

    <circle v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Shape && nodeInfoRef.nodeConfig.shapeType === DeviceShape.CIRCLE"  
      :cx="nodeInfoRef.nodeConfig.w / 2"
      :cy="nodeInfoRef.nodeConfig.w / 2"
      :r="nodeInfoRef.nodeConfig.w / 2+ 'px'"
      :style="{
        fill: '#ffffff',
        width: nodeInfoRef.nodeConfig.w + 'px',
        height: nodeInfoRef.nodeConfig.h + 'px'  
      }"
    />

    <text-node v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Text" :nodeInfo="nodeInfo" />

    <package-node v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Package" :nodeInfo="nodeInfo" />

    <link-node v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Link" :nodeInfo="nodeInfo" />

    <scenario-node v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Scenario" :nodeInfo="nodeInfo" />

    <!-- 선연결 포트 -->
    <!-- <flow-port
      v-for="(portInfo, i) in nodeInfoRef.ports"
      :key="i"
      :portInfo="portInfo"
      :v-if="nodeInfoRef.nodeConfig.nodeType !== NodeType.Package"
      @portMouseDown="onPortMouseDown"
      @portMouseUp="onPortMouseUp"
      @portMouseOver="onPortMouseOver"
      @portMouseOut="onPortMouseOut"
    /> -->

    <flow-port
      v-for="(portInfo, i) in nodeInfoRef.ports"
      :key="i"
      :portInfo="portInfo"      
      @portMouseDown="onPortMouseDown"
      @portMouseUp="onPortMouseUp"
      @portMouseOver="onPortMouseOver"
      @portMouseOut="onPortMouseOut"
    />

    <!-- <rect v-else-if="nodeInfoRef.nodeConfig.nodeType === NodeType.Text" :width="nodeInfoRef.nodeConfig.w" :height="nodeInfoRef.nodeConfig.h" :fill="selectedColor" /> -->
    <!-- <text x="20" y="20" font-family="Verdana" font-size="20" fill="blue">HIHI</text> -->
    <!-- <slot /> -->
  </g>
</template>

<style lang="scss" scoped>
.rect {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-dasharray: 5;
  image {
    background-size: contain;
  }
}
</style>
