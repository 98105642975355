import SceneAttrs from "@/models/attrs-maps/scene-attrs";
import CommonUtils from "@/utils/common-util";
import { AbstractActionNodeSetting } from "./node-setting";

class ScenarioControlSceneNodeSetting extends AbstractActionNodeSetting {
  attrs: SceneAttrs;
  
  constructor() {
    super();
    this.attrs = new SceneAttrs();
  }

  public solveAttrs(attrsMap: string): void {
    try {
      const o = CommonUtils.jsonDeserialize(SceneAttrs, attrsMap) as SceneAttrs;
      this.attrs = o;
    } catch (e) {

    }
  }
}

export default ScenarioControlSceneNodeSetting;