<script setup lang="ts">
import { computed, ref } from "vue";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useDeviceStore } from "@/stores/device-store";

import type { QTableProps } from "quasar";
import { useLightStore } from "@/stores/light-store";
import { useEditStore } from "@/stores/edit-store";
import { NodeEventType, TopBar_Action_Kind } from "@/global/enums";
import CommonUtils from "@/utils/common-util";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import { useMenuStore } from "@/stores/menu-store";

const nodeEditorStore = useNodeEditorStore();
const deviceStore = useDeviceStore();
const menuStore = useMenuStore();
const lightStore = useLightStore();
const editStore = useEditStore();

const modelRef = ref(null);

const rows = deviceStore.makeDeviceOption();

const selectedLightInfo = computed(() => {
  return nodeEditorStore.selectionNodeInfo;
});

const selectLightTypePopup = ref(false);
// const rows = [
//   { name: "LED 일자등", name: "10", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { name: "LED 직부등", name: "20", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { name: "LED 일자등", name: "30", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { name: "LED 직부등", name: "40", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { name: "LED 일자등", name: "50", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { email: "LED 직부등", name: "60", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { name: "LED 일자등", name: "70", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
//   { email: "LED 직부등", name: "80", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
// ];

const columns: QTableProps["columns"] = [
  { name: "명칭", label: "모델명", field: "name", align: "center" },
  { name: "전력량", label: "전력[W]", field: "energy", align: "center" },
  { name: "수량", label: "개수", field: "quantity", align: "center" },
  { name: "총전력량", label: "사용 총 전력[W]", field: "allenergy", align: "center" },
  { name: "타입", label: "타입 코드", field: "type", align: "center" },
  { name: "등록날짜", label: "제거", field: "regDate", align: "center" },
];



const selected = ref([]);

const filters = ref("");

function modifyNodeLight() {
  if (CommonUtils.isNullOrEmpty(selectedLightInfo.value))
    return;

  editStore.updateDPoints(NodeEventType.Moved, [selectedLightInfo.value.id]);
}
</script>

<template>
  <q-expansion-item expand-separator header-class="lightInfoExpansion" label="자동조명설정" default-opened :dark="false">
    <q-card class="LightInfoComponent">      
      <q-card-section class="selectLightSection">
        <label>조명 요소</label>
        
        <q-select outlined dense square :dark="false" bg-color="white" emit-value map-options v-model="lightStore.autoDeployDeviceConfig"
          :options="lightStore.filterDeviceElementGroups" option-value="id" option-label="name" :disable="!CommonUtils.isNullOrEmpty(menuStore.lastSelectedTopBarMenu) && menuStore.lastSelectedTopBarMenu.menuKind === TopBar_Action_Kind.조명자동추가 && menuStore.lastSelectedTopBarMenu.isSelected">
          <template v-slot:option="scope">
            <q-item :label="scope.opt.title" style="background: lightgray; color:black;">
              <q-item-section>{{ scope.opt.title }}</q-item-section>
            </q-item>
            <template v-for="config in scope.opt.configs" :key="config.id">
              <q-item clickable v-ripple v-close-popup style="background: #e2e6f3; color:black;" @click="lightStore.autoDeployDeviceConfig = config">
                <q-item-section avatar>
                  <q-avatar square>
                    <q-img v-if="config.imageUrl !== 'null'" :src="config.imageUrl" />
                    <div v-else :class="config.shapeType.toString()" />
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="q-ml-md">{{ config.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </template>
        </q-select>
        <label>조명 갯수</label>
        <div class="row borderClass">
          <div class="col-2">
            <label>수평</label>
          </div>
          <div class="col-3">
            <q-input square bg-color="white" :dark="false" type="number" maxlength="30"
              v-model.number="lightStore.lightAutoDeployCount.horizontalCount" outlined />
          </div>
          <div class="col" />
          <div class="col-2">
            <label>수직</label>
          </div>
          <div class="col-3">
            <q-input square bg-color="white" :dark="false" type="number" maxlength="30" outlined
              v-model.number="lightStore.lightAutoDeployCount.verticalCount" />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-expansion-item>
  <q-expansion-item expand-separator header-class="lightInfoExpansion" label="조명 정보" default-opened :dark="false">
    <q-card class="LightInfoComponent">
      <!-- {{ selectedLightInfo }} -->
      <q-card-section class="selectLightSection" v-if="selectedLightInfo !== null">
        <label>조명 타입</label>
        <div class="row">
          <q-input square bg-color="white" :dark="false" class="col-12" disable outlined v-model="selectedLightInfo!.nodeConfig.name" />
          <!-- <q-btn class="col-2" outline @click="selectLightTypePopup = true" :style="{marginBottom: '10px'}">...</q-btn> -->
        </div>

        <label>모델명</label>
        <q-input
          square
          bg-color="white"
          :dark="false"
          type="text"
          maxlength="30"
          disable
          outlined
          v-model="selectedLightInfo!.nodeConfig.name"
          placeholder="모델명"
        />

        <label>사용 전력 [W]</label>
        <q-input
          square
          bg-color="white"
          :dark="false"
          type="text"
          maxlength="30"
          disable
          outlined
          v-model="(selectedLightInfo!.nodeConfig as LightNodeConfig).consumpPower"
          placeholder="사용 전력"
        />

        <label>조명 모양</label>
        <q-input
          square
          bg-color="white"
          :dark="false"
          type="text"
          maxlength="30"
          disable
          outlined
          v-model="selectedLightInfo!.nodeConfig.description"
          placeholder="조명 모양"
        />

        <div v-if="(selectedLightInfo?.nodeConfig.nodeElementType as string) === 'Sensor'">
          <div>
            센서 종류
          </div>
        </div>

        <label>조명 크기 [cm]</label>
        <div class="borderClass">
          <div class="row">
            <label class="col-3">가로 [cm]</label>
            <label class="col-3">세로 [cm]</label>
            <label class="col-3">높이 [cm]</label>
          </div>

          <div class="row">
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="text"
              disable
              outlined
              v-model.number="selectedLightInfo!.nodeConfig.w"
              placeholder="가로"
            />
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="text"
              disable
              outlined
              v-model.number="selectedLightInfo!.nodeConfig.h"
              placeholder="세로"
            />
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="text"
              disable
              outlined
              v-model.number="selectedLightInfo!.nodeConfig.inPortCount"
              placeholder="높이"
            />
          </div>
        </div>

        <label>좌표</label>
        <div class="borderClass">
          <div class="row">
            <label class="col-3">X 좌표</label>
            <label class="col-3">Y 좌표</label>
            <label class="col-3">Z 좌표</label>
          </div>

          <div class="row">
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="number"
              stack-label
              outlined
              v-model.number="selectedLightInfo!.x"
              placeholder="ㅌ"
              @change="modifyNodeLight"
            />
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="number"
              stack-label
              outlined
              v-model.number="selectedLightInfo!.height"
              placeholder="Y"
              @change="modifyNodeLight"

            />
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-3"
              type="number"
              stack-label
              outlined
              v-model.number="selectedLightInfo!.y"
              placeholder="Z"
              @change="modifyNodeLight"

            />
          </div>
        </div>

        <label>각도</label>
        <div class="borderClass">
          <div class="row">
            <label class="col-5">X축 회전각</label>
            <label class="col-5">Y축 회전각</label>
          </div>

          <div class="row">
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-5"
              type="number"
              stack-label
              outlined
              v-model.number="selectedLightInfo!.nodeConfig.inPortCount"
              placeholder="X축"
            />
            <q-input
              square
              bg-color="white"
              :dark="false"
              class="col-5"
              type="number"
              stack-label
              outlined
              v-model.number="selectedLightInfo!.nodeConfig.inPortCount"
              placeholder="Y축"
            />
          </div>
        </div>

        <!-- 조명 정보 선택 팝업-->
        <q-dialog v-model="selectLightTypePopup" persistent>
          <q-card style="width: 750px; max-width: 80vw; background: gray; color: black">
            <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">조명 정보 선택</div>
              <q-space />
              <q-btn icon="close" flat round dense v-close-popup />
            </q-card-section>

            <q-card-section class="q-pt-none" style="margin-top: 30px">
              <q-form class="q-gutter-md">
                <div class="row justify-end">
                  <q-input class="col-5" ref="filterRef" v-model="filters" outlined placeholder="제조사/모델명 검색" style="margin: 0 0 0 16px">
                    <template v-slot:append>
                      <q-icon name="search" color="white" />
                    </template>
                  </q-input>
                </div>

                <q-table
                  :rows="rows"
                  :columns="columns"
                  row-key="regDate"
                  style="height: 255px"
                  v-model:selected="selected"
                  virtual-scroll
                  bordered
                  selection="single"
                  hide-pagination
                  dense
                >
                  <template v-slot:header-cell="props">
                    <q-th :props="props" style="font-size: 14px; height: 35px">
                      {{ props.col.label }}
                    </q-th>
                  </template>

                  <template v-slot:body-cell-regDate="props">
                    <q-td :props="props">
                      <q-btn round icon="delete" size="0.9em" />
                    </q-td>
                  </template>
                </q-table>
                <div>
                  <label style="margin: 20px 0 0 0">* 리스트의 조명으로 수정하려면 해당 조명을 선택 후 "선택 추가" 버튼을 클릭하세요.</label>
                </div>

                <q-card-actions align="right" style="padding-right: 0">
                  <q-btn style="width: 100px" label="선택 추가" color="primary" />
                  <q-btn label="취소" style="width: 64px" color="grey" v-close-popup />
                </q-card-actions>
              </q-form>
            </q-card-section>
          </q-card>
        </q-dialog>

        <!-- <div class="row">
          <label class="col-4" for="">명칭</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.nodeConfig.name"></q-input>
          </div>
        </div>
        <div class="row">
          <label class="col-4" for="">설명</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.nodeConfig.description"></q-input>
          </div>
        </div>

        <div class="row">
          <label class="col-4" for="">가로</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.nodeConfig.w"></q-input>
          </div>
        </div>
        <div class="row">
          <label class="col-4" for="">세로</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.nodeConfig.h"></q-input>
          </div>
        </div>
        <div class="row">
          <label class="col-4" for="">포트개수</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.nodeConfig.portCount"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">X</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.x" type="number"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">Y</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.y" type="number"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">dx</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.dx" type="number"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">dy</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.dy"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">ox</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.ox"></q-input>
          </div>
        </div>
        <div class="row">
          <label for="" class="col-4">oy</label>
          <div class="col-8">
            <q-input outlined dense v-model="selectedLightInfo!.oy"></q-input>
          </div>
        </div> -->
      </q-card-section>
      <q-card-section v-else class="nonSelectLight"> 조명을 선택해주세요. </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<style lang="scss">
.lightInfoExpansion {
  background-color: #e2e6f3;
  color: #404040;
  font-size: 15px;
  border-bottom: 1px solid #c1c1c1;
  border-radius: 0px;
  height: 39px;
  .q-item__label {
    font-weight: bold;
  }
}
.LightInfoComponent {

  .nonSelectLight {
    background-color: #f2f4fb;
    color: #404040;
    text-align: center;
    font-size: 15px;
    padding: 15px;
    font-weight: bold;
  }

  .selectLightSection {
    background-color: #f2f4fb;
    color: #000000;
    padding: 20px;

    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 10px;
    }

    label {
      font-weight: bold;
      margin-bottom: 0px;
      font-size: 13px;
    }

    .row {
      justify-content: space-between;
      align-items: center;

      label {
        font-weight: bold;
        font-size: 12px;
      }
    }

    .borderClass {
      padding: 5px 10px 5px 10px;
      margin-bottom: 10px;
      background-color: #e2e6f3;

      .q-field--outlined .q-field__control {
        height: 30px;
        margin-bottom: 0px;
      }

      .row {
        justify-content: space-between;
        align-items: center;

        label {
          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
  }
}

.q-field__marginal {
  height: 35px !important;
}

.q-field--outlined .q-field__control {
  height: 35px;
  min-width: unset;
}


.SQUARE{
    width: 40px;
    height: 40px;
    background-color: #ffffff;
}

.CIRCLE{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #ffffff;
}

</style>
