abstract class AbstractActionAttrs {  
}

class SelectionAttr {
  isSelected: boolean;
  constructor(isSelected: boolean, readonly name: string) {
    this.isSelected = isSelected;
  }
}


export { SelectionAttr };
export default AbstractActionAttrs;