<script setup lang="ts">
import { useSceneStore } from "@/stores/scene-store";
import { computed, ref } from "vue";
import SceneControlRightComponent from "@/components/SpaceLight/RightInfoForm/SceneControlRightComponent.vue";
import SceneGroupListComponent from "./SceneGroupListComponent.vue";
import Scene from "@/models/entity/scene";


const sceneStore = useSceneStore();

const selectScene = computed(() => {
  if(sceneStore.SelectSceneInfo === null){
    return new Scene();
  }
  
  return sceneStore.SelectSceneInfo;
});

const name = ref("");
const description = ref("");
</script>

<template>
  <div class="SceneRightComponent">
    <div class="NoneContents" v-if="sceneStore.SelectSceneInfo === null">
      씬을 선택해주세요.
    </div>
    <div v-else>
      <q-expansion-item label="씬 정보" header-class="sceneInfoExpansion" default-opened :dark="false">
        <q-card>
          <q-card-section class="sceneRightContents">
            <q-form>
              <label>명칭</label>
              <q-input
              type="text"
              square
              bg-color="white"
              :dark="false"
              maxlength="30"
              outlined
              v-model="(selectScene as Scene).sceneName"
              placeholder="씬 명칭"
              />
              
              <label>설명</label>
              <q-input
              type="text"
              square
              bg-color="white"
              :dark="false"
              
              maxlength="50"
              outlined
              v-model="(selectScene as Scene).sceneDesc"
              placeholder="씬 설명"
              />
              
              <!-- <div class="row sequenScene">
                <label>시퀀스</label>
                <q-btn>+</q-btn>
              </div>
              
              <div class="inSequence"></div> -->
            </q-form>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <SceneControlRightComponent></SceneControlRightComponent>
    </div>
    
  </div>
</template>

<style lang="scss">
.SceneRightComponent {
  .NoneContents{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: #e2e6f3;
    padding: 15px;
  }
  
  .sceneInfoExpansion {
    background-color: #e2e6f3;
    color: #404040;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: auto;
    .q-item__label {
      font-weight: bold;
    }
  }
  
  .sceneRightContents {
    background-color: #f2f4fb;
    color: #404040;
    label {
      font-weight: bold;
    }
  }
  
  .q-field--outlined .q-field__control {
    height: 30px;
  }
  
  .q-textarea .q-field__control {
    height: 75px;
  }
  
  .q-textarea .q-field__native {
    resize: none;
    padding-top: 10px;
  }
  
  label {
    font-size: 13px;
  }
}
</style>
