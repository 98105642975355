import type Zone from "@/models/entity/zone";
import SnapShot from "@/models/snapshot";
import CommonUtils from "@/utils/common-util";
import Stack from "@/utils/stack";
import { ref, } from "vue";

const undoStack = new Stack<string>();
const redoStack = new Stack<string>();

const isCanUndo = ref(undoStack.getSize() > 0);
const isCanRedo = ref(redoStack.getSize() > 0);

const undoCount = ref(0);
const redoCount = ref(0);

let state: string | null = null;

export function useUndoRedo() {

  function apply(s: string) {
    state = s;
    return state;
  }

  function push(snapshot: SnapShot, newSnapShot: SnapShot) {
    try {
      const json = CommonUtils.jsonSerialize(snapshot);

      const newJson = CommonUtils.jsonSerialize(newSnapShot);
      undoStack.push(json);
      apply(newJson);
      redoStack.clear();

    } catch (error) {

    } finally {
      updateCanState();
    }
  }

  function undo() {
    try {
      
      const z = undoStack.pop();

      if (CommonUtils.isNullOrEmpty(z))
        return null;

      redoStack.push(state!);
      apply(z);
            
      return CommonUtils.jsonDeserialize(SnapShot, z) as SnapShot;

    } catch (error) {

    } finally {
      updateCanState();
    }

  }

  function redo() {
    try {

      const z = redoStack.pop();

      if (CommonUtils.isNullOrEmpty(z))
        return null;

      undoStack.push(state!);
      apply(z);

      return CommonUtils.jsonDeserialize(SnapShot, z) as SnapShot;
    } catch (error) {

    }
    finally {
      updateCanState();
    }
  }

  function clear() {
    undoStack.clear();
    redoStack.clear();

    updateCanState();
  }

  function updateCanState() {
    isCanUndo.value = undoStack.getSize() > 0;
    isCanRedo.value = redoStack.getSize() > 0;

    undoCount.value = undoStack.getSize();
    redoCount.value = redoStack.getSize();
  }

  return { isCanUndo, isCanRedo, undoCount, redoCount, undo, redo, push, clear };
}