import DPoint from "../entity/d-point";

class SensorDeviceInfo extends DPoint {
  devModelSeq: number;
  devModelName: string;

  constructor(devModelSeq: number, devModelName: string, dPoint: DPoint) {
    super(dPoint.siteSeq, dPoint.zoneSeq, dPoint.dpointSeq, dPoint.regUserId);

    this.devModelSeq = devModelSeq;
    this.devModelName = devModelName;

    this.devTypeCode = dPoint.devTypeCode;
    this.centerX = dPoint.centerX;
    this.centerY = dPoint.centerY;
    this.centerZ = dPoint.centerZ;
    this.mpoints = dPoint.mpoints;
    this.rotate = dPoint.rotate;
    this.regDate = dPoint.regDate;
    this.modDate = dPoint.modDate;
    this.dpointName = dPoint.dpointName;
  }
}

export default SensorDeviceInfo;