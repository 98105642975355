<script setup lang="ts">
import { useRouter } from "vue-router";
import { useSiteStore } from "@/stores/site-store";

import NewSiteModal from "./NewSiteModal.vue";
import { ref, onMounted, onBeforeMount, watch, onBeforeUnmount, } from "vue";
import Site from "@/models/site";
import NewRspaceModal from "../common/TopBar/Modal/NewRspaceModal.vue";
import { useEditStore } from "@/stores/edit-store";
import { useCommonStore } from "@/stores/common-store";
import Zone from "@/models/entity/zone";
import type { OptionsType } from "@/global/types";
import { useQuasar } from "quasar";
import { useAuthManager } from "@/composables/auth-manager";

interface imageToUrl {
  id: number,
  url: string | undefined
}

const siteStore = useSiteStore();
const editStore = useEditStore();
const router = useRouter();
const commonStore = useCommonStore();
const authManager = useAuthManager();

const siteClsfyCode = ref([] as OptionsType[]);
const rzoneTypeCode = ref([] as OptionsType[]);
const districtTypeCode = ref([] as OptionsType[]);
const azimuthTypeCode = ref([] as OptionsType[]);

const $q = useQuasar();

// function alert() {
//   $q.dialog({
//     title: "Alert",
//     message: "Some message"
//   }).onOk(() => {
//     console.log("OK");
//   }).onCancel(() => {
//     console.log("Cancel");
//   }).onDismiss(() => {
//     console.log("I am triggered on both OK and Cancel");
//   });
// }


onBeforeMount(async () => {
  
  try {
    await siteStore.loadSiteList();
    await commonStore.loadCodes();

    siteClsfyCode.value = commonStore.getOptionTypes("siteClsfyCode");
    rzoneTypeCode.value = commonStore.getOptionTypes("rzoneTypeCode");
    districtTypeCode.value = commonStore.getOptionTypes("districtCode");
    azimuthTypeCode.value = commonStore.getOptionTypes("azimuthCode");
  }
  catch {
    checkAuth();
  }
});

onBeforeUnmount(() => {  
});

onMounted(() => {  
});

//오류 팝업 처리
watch(() => commonStore.errorMessage, () => {
  if (commonStore.errorMessage === null)
    return;

  console.log(`Error Message => ${commonStore.errorMessage}`);
  $q.dialog({ title: "오류", message: commonStore.errorMessage ?? "", ok: "OK" })
    .onOk(() => {
      commonStore.setErrorMessage(null);
    })
    .onCancel(() => {
      commonStore.setErrorMessage(null);
    })
    .onDismiss(() => {
      commonStore.setErrorMessage(null);
    });
});

const NewModal = ref(false);
const newRspaceModal = ref(false);
const checkModify = ref(false);
const checkRzoneModify = ref(false);
const selectSiteInfo = ref(new Site());
const selectRzoneInfo = ref(new Zone());
const sendSiteData = ref(new Site());

const siteImages = ref([] as imageToUrl[]);


function checkAuth() {
  if (authManager.isExistRefreshToken.value)
    return;

  alert("인증 정보가 없습니다. 로그인 페이지로 이동합니다.");
  router.push("/");
}

async function selectSiteRspace(siteInfo: Site, zoneInfo: Zone) {

  await editStore.setInit(siteInfo.siteSeq, zoneInfo.zoneSeq);  
  await editStore.initLoad();  
  
  // try {
  //   await editStore.setInit(siteInfo.siteSeq, zoneInfo.zoneSeq);
  //   await deviceStore.makeDeviceModel();

  //   router.push({ name: "main" });
  // } catch {
  //   //console.log("대표공간 호출 오류$$$$$$");
  //   //alert();
  //   $q.dialog( { title: "오류 발생", message: "TEST Message", ok: "OK"});
  //   //$q.notify("Message");
  // }
}

async function isOpen(value: boolean) {
  console.log("opens");
  NewModal.value = value;
  sendSiteData.value = new Site();

  reloaded();
}

async function reloaded(){
  await siteStore.loadSiteList();
  // siteList.value.map(async v => await siteStore.getImages(v.imageFileSeq as number));
}


function isRspaceOpen(value: boolean) {
  newRspaceModal.value = value;

  reloaded();
}

function rpsaceAdd(site: Site, isModify: boolean) {
  selectSiteInfo.value = site;
  checkRzoneModify.value = isModify;
  newRspaceModal.value = true;
}

function addSite(siteData: Site | null) {
  if (siteData === null) {
    checkModify.value = false;
  } else {
    sendSiteData.value = JSON.parse(JSON.stringify(siteData));
    if(sendSiteData.value.imageFile){
      sendSiteData.value.imageFile.imageUrl = siteImages.value.find(v => v.id === siteData.imageFile?.imageFileSeq)?.url;
    }
    checkModify.value = true;
  }

  NewModal.value = true;
}

async function removeRzone(siteSeq: number, zoneSeq: number){
  await editStore.removeRzone(siteSeq, zoneSeq);
  reloaded();
}

async function removeSite(siteSeq: number){
  await editStore.removeSites(siteSeq);

  reloaded();
}
</script>

<template>

  <div class="scroll SiteComponent">
    <NewSiteModal v-if="siteClsfyCode.length !== 0" :districtOptions="districtTypeCode" :siteTypeOptions="siteClsfyCode" :siteModal="NewModal" @update:siteModal="isOpen" :siteData="sendSiteData" :isModify="checkModify"></NewSiteModal>
    <NewRspaceModal v-if="rzoneTypeCode.length !== 0" :azimuthTypeCode="azimuthTypeCode" :rzoneOptionType="rzoneTypeCode" :spaceModal="newRspaceModal" @update:spaceModal="isRspaceOpen" :selectSite="selectSiteInfo" :selectRzone="selectRzoneInfo" :is-modify="checkRzoneModify"></NewRspaceModal>
    <q-page class="SitePage">
      <div class="row q-col-gutter-sm">
        <div class="SiteList col-3 col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <div class="newSiteList" :style="{ height: '350px' }" @click="addSite(null)">
            <div class="SiteListCaption absolute-full text-subtitle2 flex flex-center">
              <q-btn class="addIcon fit" icon="add" size="50px" flat />
            </div>
          </div>
        </div>

        <div v-for="(site, i) in siteStore.siteList" class="SiteList col-3 col-xs-12 col-sm-6 col-md-4 col-lg-3" :key="`Site${i}`">
          <q-card class="SiteListItem" :style="{ height: '350px' }">
            <q-card-section>
              <div class="row items-center no-wrap justify-evenly SiteListHeader" :style="{ height: '50px' }">
                <div class="col">
                  <div class="text-h6">{{ site.siteName }}</div>
                </div>

                <div class="col-auto" :style="{ position: 'absolute', right: 0 }">
                  <q-btn color="white" round flat icon="more_vert">
                    <q-menu cover auto-close>
                      <q-list>
                        <q-item clickable @click="addSite(site as Site)">
                          <q-item-section>수정</q-item-section>
                        </q-item>
                        <q-item clickable @click="removeSite(site.siteSeq)">
                          <q-item-section>삭제</q-item-section>
                        </q-item>
                        <q-item clickable @click="rpsaceAdd(site as Site, false)">
                          <q-item-section>대표공간 추가</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </q-card-section>
            <!-- <q-img src="defaultSite.png"></q-img> -->
            <!-- :src="siteImages.find(v => v.id === site.imageFile?.imageFileSeq as number)?.url" -->
            <q-img
              v-if="site.imageFileSeq !== null"
              :fit="`fill`"
              :src="`/v1/imagefiles/${site.imageFileSeq}`"
              :style="{ height: '170px' }"
            >
              <div class="SiteListCaption absolute-full text-subtitle2 flex flex-center">
                {{ `설명 : ${site.siteDesc}` }}
                <br />
                {{ `가로 : ${site.horizSize}` }}
                <br />
                {{ `세로 : ${site.vertiSize}` }}
                <br />
                {{ `면적 : ${site.siteArea}` }}
                <br />
                {{ `분류 : ${commonStore.getFindCodeToName('siteClsfyCode', site.siteClsfyCode as string)}` }}
              </div>
            </q-img>
            <q-img
              v-else 
              class="SiteListItem"
              fit="fill" 
              :style="{ height: '170px' }"
              :src="`defaultSite.png`"
            >
  

            <div class="SiteListCaption absolute-full text-subtitle2 flex flex-center">
                {{ `설명 : ${site.siteDesc}` }}
                <br />
                {{ `가로 : ${site.horizSize}` }}
                <br />
                {{ `세로 : ${site.vertiSize}` }}
                <br />
                {{ `면적 : ${site.siteArea}` }}
                <br />
                {{ `분류 : ${commonStore.getFindCodeToName('siteClsfyCode', site.siteClsfyCode as string)}` }}
              </div>
            </q-img>
          
            <div class="SiteRspaceList" v-if="site.zones.length !== 0">
              <q-list class="SiteRspaceListItem" dense bordered separator :dark="false" v-for="rspace in site.zones" :key="`${rspace.zoneSeq}`">
                <q-item clickable v-ripple :dark="false" @click.prevent="selectSiteRspace(site, rspace)">
                  <q-item-section class="rspaceSection">
                    <q-item-label>{{ rspace.zoneName }}</q-item-label>
                    <q-item-label caption>{{ rspace.rangeOfFloors }}</q-item-label>
                  </q-item-section>
                  <!-- <q-item-section side>
                    <q-btn color="black" round flat icon="more_vert" size="10px" @click.stop>
                      <q-menu cover auto-close>
                        <q-list>
                          <q-item clickable @click.stop="rpsaceAdd(site as Site, rspace as Zone, true)">
                            <q-item-section>대표 공간 수정</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </q-item-section> -->
                  <q-item-section side class="row">
                 
                    <q-icon name="delete" size="20px" @click.stop="removeRzone(site.siteSeq, rspace.zoneSeq)"></q-icon>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div v-else class="SiteRspaceEmptyList">
              <div>공간을 추가해주세요</div>
            </div>
          </q-card>
        </div>
      </div>
    </q-page>
  </div>

</template>

<style lang="scss">
.SiteComponent {
  background-color: #ffffff;

  .SiteListHeader {
    background-color: #0f1837;
    color: #ffffff;
  }
  .SiteRspaceEmptyList {
    align-self: center;
    display: flex;
    background-color: #ffffff;
    color: #000000;
    height: 130px;
    div {
      margin: auto;
    }
  }

  .SiteRspaceList {
    background-color: #ffffff;
    color: #000000;
    height: 130px;

    overflow: auto;

    &::-webkit-scrollbar-thumb {
      right: 4px;
      border-radius: 5px;
      background-color: #c1c1c1;
      width: 5px;
      opacity: 0.3;
    }
    &::-webkit-scrollbar-track {
      right: 2px;
      border-radius: 9px;
      background-color: #181b25;
      width: 9px;
      opacity: 0.2;
    }

    &::-webkit-scrollbar {
      position: absolute;
      width: 10px;
      height: 10px;
    }

    .SiteRspaceListItem {
      background-color: #ffffff;
      color: #000000;
    }

    .rspaceSection {
      text-align: left;
      .rzoneDelBtn{
        text-align: center;
        align-self: center;
      }
      .q-item__label {
        color: #000000;

        &.text-caption {
          color: #c1c1c1;
        }
      }
    }
  }
  .SitePage {
    padding: 15px;

    .SiteList {
      padding: 15px;

      .SiteListItem {
        cursor: pointer;
        text-align: center;

        .SiteListCaption {
          display: none;
        }

        &:hover {
          .SiteListCaption {
            text-align: left;
            font-size: 15px;
            font-weight: bold;
            display: flex;
          }
        }
      }
    }

    .newSiteList {
      cursor: pointer;
      text-align: center;
      border: 3px solid #3eb4ff;

      .addIcon {
        color: #3eb4ff;
      }
    }
  }
  .q-card__section {
    padding: 0;
  }
}
</style>
