import { PortType } from "@/global/enums";
import type { LinkPoint } from "@/global/types";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import type PortInfo from "@/models/editor/nodes/info/port-info";
import { path } from "d3";
import * as d3 from "d3";

class DrawUtil {
  constructor() {

  }

  //노드와 포트를 기반으로 연결지점 정보를 가져온다. (연결 지점 보정 포인트)
  static getLinkPoint(nodeInfo: AbstractNodeInfo, portInfo: PortInfo): LinkPoint | null {

    if (portInfo === null || portInfo.portType === undefined)
      return null;

    let x = nodeInfo.x + portInfo.x;
    let y = nodeInfo.y + portInfo.y;

    y += (portInfo.sizeHeight / 2);

    if (portInfo.portType === PortType.Out)
      x += portInfo.sizeWidth / 2;
    // else
    //   x -= portInfo.sizeWidth / 2;

    const linkPoint: LinkPoint =
    {      
      nodeId: nodeInfo.id,
      portType: portInfo.portType,      
      nodeItemId: portInfo.nodeItemId,
      x: x,
      y: y,      
    };

    return linkPoint;
  }

  /**
   * 노드와 노드의 Path를 가져온다.
   * @param x0 선 시작 x좌표
   * @param y0 선 시작 y좌표
   * @param x1 선 종료 x좌표
   * @param y1 선 종료 y좌표
   * @returns 
   */
  static getNodePath(x0: number, y0: number, x1: number, y1: number) {
    
    //직선
    // const path = d3.path();
    // path.moveTo(x0, y0);
    // path.lineTo(x1, y1);

    // const d = path?.toString();
    // return d;

    //베지어 곡선
    const path = d3.path();
    path.moveTo(x0, y0);
    
    path.bezierCurveTo(x0 + (x1 * 0.12), y0, x1 * 0.88, y1, x1, y1);        
    return path?.toString();
  }
}

export default DrawUtil;
