import type { NodeElementType, NodeType } from "@/global/enums";
import AbstractNodeConfig from "./abstract-node-config";

/**
 * 연결 노드 설정
 * @class
 */
class LinkNodeConfig extends AbstractNodeConfig {

  constructor(id: string, name: string, nodeType: NodeType, nodeElementType: NodeElementType, w: number, h: number, inPortCount: number, outPortCount: number, description?: string, imageUrl?: string) {
    super(id, name, nodeType, nodeElementType, w, h, inPortCount, outPortCount, description, imageUrl);
  }
}

export default LinkNodeConfig;