

/**
 * Scene 클래스
 * @class
 */
class SceneSetting {
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;
  grpSeq?: number;
  lightAttrsMap= "" as string;
  sceneSeq?: number;
  siteSeq?: number;
  zoneSeq?: number;
}
  
export default SceneSetting;
  