class LoginInfo {
  constructor(
    readonly userId: number,
    readonly tokenType: string,
    readonly accessToken: string,
    readonly expiresIn: number,
    readonly refreshToken: string,
    readonly refreshTokenExpiresIn: number
  ) {}
  // constructor() {
  // }
}

export default LoginInfo;
