import { defineStore } from "pinia";
import CommonUtils from "@/utils/common-util";
import type NodeProps from "@/models/scenario/node-props";
import type { AbtractSelectionNodeItemEvent, INodeItemEvent } from "@/models/editor/nodes/info/node-item-info";
import { NodeElementType, NodeType, PortType, SensorType, TargetType, ZoneTypeCode } from "@/global/enums";
import { useEditStore } from "./edit-store";
import type ScenarioInfo from "@/models/scenario-info";
import { useNodeEditorStore } from "./node-editor-store";
import Scenario from "@/models/entity/scenario";
import ScenarioNode from "@/models/entity/scenario-node";
import type { NodeEventParam, NodeFactoryParam, OptionsType } from "@/global/types";
import { useCommonStore } from "./common-store";
import type ScenarioNodeConfig from "@/models/editor/nodes/config/scenario-node-config";
import ScenarioNodeEvent from "@/models/entity/scenario-node-event";
import type ConfigGroup from "@/models/editor/nodes/config/config-group";
import ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import type AbstractNodeConfig from "@/models/editor/nodes/config/abstract-node-config";
import type ScenarioSequenceExecuteNodeSetting from "@/models/editor/nodes/setting/scenario-sequence-execute-node-setting";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import ScenarioFlow from "@/models/entity/scenario-flow";
import type LinkNodeInfo from "@/models/editor/nodes/info/link-node-info";
import type DPoint from "@/models/entity/d-point";
import SensorDeviceInfo from "@/models/device/sensor-device-info";
import { useDeviceStore } from "./device-store";
import type ScenarioExecutorNodeSetting from "@/models/editor/nodes/setting/scenario-executor-node-setting";
import type INodeSetting from "@/models/editor/nodes/setting/node-setting";
import NodeFactory from "@/core/node-factory";
import DrawUtil from "@/utils/draw-util";
import LinkInfo from "@/models/editor/nodes/info/link-info";
import type ScenarioTimeBranchNodeSetting from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import { TimeBranchEvent } from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import { useUserStore } from "./user-store";
import { ScenarioSchedule } from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import type ScenarioScheduleNodeSetting from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import type ScenarioConfigurePresenseSensorNodeSetting from "@/models/editor/nodes/setting/scenario-configure-presence-sensor-node-setting";
import type ScenarioDeviceNodeSetting from "@/models/editor/nodes/setting/scenario-device-node-setting";
import type ScenarioPresenceSensorDeviceNodeSetting from "@/models/editor/nodes/setting/scenario-presence-sensor-device-node-setting";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import type ScenarioDeviceGroupNodeSetting from "@/models/editor/nodes/setting/scenario-device-group-node-setting";
import { AbstractActionNodeSetting } from "@/models/editor/nodes/setting/node-setting";


export const useScenarioStore = defineStore("scenarioStore", {
  
  state: () => ({        
    scenarioConfigGroups: [] as ConfigGroup[],
    selectScenarioInfo: null as ScenarioInfo | null,
  }),
  getters: {
    /**
     * 시나리오 노드 OR 플로우 ID를 새로 생성한다.
     * @param state 
     * @returns 
     */
    getNewId(state) {

      return (isFlow = false) => {

        let seqList: number[] = [];

        if (!CommonUtils.isNullOrEmpty(this.selectScenarioInfo)) {
          if (isFlow) {
            if (!CommonUtils.isNullOrEmpty(this.selectScenarioInfo.flows)) {
              seqList = this.selectScenarioInfo.flows.map(n => {
                return n.flowSeq as number;
              });
            }
          } else {
            if (!CommonUtils.isNullOrEmpty(this.selectScenarioInfo.nodes)) {
              seqList = this.selectScenarioInfo.nodes.map(n => {
                return n.nodeSeq as number;
              });
            }
          }
        }

        return CommonUtils.getNewId(seqList);
      };
    },

    /**
     * editStore의 현재 선택된 단위존에 있는 시나리오 리스트를 가져온다.
     * @param state 
     * @returns 
     */
    getScenarios(state) {
      return () => {
        const editStore = useEditStore();

        if (editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
          return null;

        if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios))
          return null;

        return editStore.selectSpaceInfo.scenarios;
      };
    },
    /**
     * 시나리오 리스트에서 시나리오를 가져온다.
     * @param state 
     * @returns 
     */
    getScenario(state) {
      return (scenarioSeq: number) => {

        const scenarios = this.getScenarios();

        if (CommonUtils.isNullOrEmpty(scenarios))
          return null;

        return scenarios.find(s => s.scenarioSeq === scenarioSeq) ?? null;
      };
    },

    /**
     * 센서 디바이스 리스트를 가져온다.
     * @param state 
     * @returns 
     */
    getSensorDevices(state) {
      return (sensorType: SensorType) => {
        
        const editStore = useEditStore();

        const sensorDeviceInfos: SensorDeviceInfo[] = [];

        if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.dpoints))
          return sensorDeviceInfos;

        if (editStore.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존)
          return sensorDeviceInfos;

        const deviceStore = useDeviceStore();
        const dPoints = editStore.selectSpaceInfo.dpoints.filter(d => d.devTypeCode === sensorType);

        for (const dp of dPoints) {
          const deviceModel = deviceStore.deviceModelList.find(d => d.devTypeCode === dp.devTypeCode);

          if(CommonUtils.isNullOrEmpty(deviceModel) || CommonUtils.isNullOrEmpty(deviceModel.devModelSeq))
            continue;
          const sensorDeviceInfo = new SensorDeviceInfo(deviceModel.devModelSeq, deviceModel.devModelName ?? "", dp);
          sensorDeviceInfos.push(sensorDeviceInfo);
        }

        return sensorDeviceInfos;
      };
    },
    getGroups(state) {
      return () => {
        const editStore = useEditStore();

        const groups: CtrlGrp[] = [];

        if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || (editStore.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존))
          return groups;
        
        return editStore.selectSpaceInfo.ctrlGrps ?? [];
      };
    },
    getDevices(state) {
      return() => {
        const editStore = useEditStore();

        if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || (editStore.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존))
          return [];

        return editStore.inDPointList;
      };
    },
    getScenarioNodes(state) {
      return (scenarioSeq: number) => {

        const scenario = this.getScenario(scenarioSeq);

        if (CommonUtils.isNullOrEmpty(scenario))
          return null;
        
        
      };
    },
    /**
     * scenarioSeq로 nodeEidtor의 nodeInfo를 가져온다.
     * @param state 
     * @returns 
     */
    getScenarioNodeInfo(state) {
      return (scenarioNodeSeq: number) => {
        const nodeEditor = useNodeEditorStore();

        for(const node of nodeEditor.nodeInfos) {

          if(!(node instanceof ScenarioNodeInfo))
            continue;
          
          if(node.scenarioNode.nodeSeq !== scenarioNodeSeq)
            continue;

          return node;
        }        
      };
    },
    getSceneOptionTypes(state) {

      const editStore = useEditStore();
      
      if (editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
        return [];

      return editStore.selectSpaceInfo.scenes?.map((s) => ({
        label: s.sceneName,
        value: s.sceneSeq,
      })) as OptionsType[];
    },    
  },
  actions: {

    /**
    * 시나리오 요소 리스트를 가져온다.
    * @param state 
    * @returns 
    * 
    */
    async loadScenarioConfigGroups() {

      const nodeEditorStore = useNodeEditorStore();
      nodeEditorStore.clearNodeConfigs();

      // const json = JSON.stringify(ScenarioElementObj, null, 2);
      // return CommonUtils.jsonDeserialize<ConfigGroup>(ConfigGroup, json) as ConfigGroup[];

      const commonStore = useCommonStore();
      const nodetypeCodes = commonStore.getCodeList("nodeTypeCode");

      this.scenarioConfigGroups = await this.apiServices.scenarioApiService.getScenarioConfigGroups(nodetypeCodes);

      for(const se of this.scenarioConfigGroups) {
        for(const config of se.configs) {
          nodeEditorStore.addNodeConfig(config);
        }
      }      
      //
    },
    /**
     * 시나리오를 새로 추가 한다.
     * @returns 
    */
    addNewScenario() {

      const editStore = useEditStore();

      if (editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
        return;

      let scenarioSeqList: number[];

      if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios) || editStore.selectSpaceInfo.scenarios.length <= 0)
        scenarioSeqList = [];
      else
        scenarioSeqList = editStore.selectSpaceInfo.scenarios.map(s => s.scenarioSeq ?? []) as number[];

      const scenario = new Scenario();
      scenario.siteSeq = this.selectScenarioInfo?.siteSeq;
      scenario.zoneSeq = this.selectScenarioInfo?.zoneSeq;
      scenario.scenarioSeq = CommonUtils.getNewMaxId(scenarioSeqList);
      scenario.scenarioName = "새 시나리오";

      //editStore의 현재 선택된 단위존에 있는 시나리오 리스트를 가져온다.
      const scenarios = this.getScenarios();

      if (CommonUtils.isNullOrEmpty(scenarios))
        return;

      //시나리오 리스트에서 새로 만든 시나리오를 추가한다.
      scenarios.push(scenario);
      this.selectScenarioInfo = scenario;
      editStore.snapShot.scenarioSeq = scenario.scenarioSeq ?? null;
    },

    /**
     * 시나리오를 삭제 한다.
     * @returns 
    */
    delScenario() {

      const editStore = useEditStore();

      if(editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존)
        return;

      if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios) || editStore.selectSpaceInfo.scenarios.length <= 1)
        return;

      const findIndex = editStore.selectSpaceInfo.scenarios.findIndex(s => s.scenarioSeq === this.selectScenarioInfo?.scenarioSeq);
      editStore.selectSpaceInfo.scenarios.splice(findIndex, 1);

      const selectionIndex = findIndex === 0 ? 0 : findIndex - 1;
      const scenarioSeq = editStore.selectSpaceInfo.scenarios[selectionIndex].scenarioSeq;

      if (CommonUtils.isNullOrEmpty(scenarioSeq))
        return;

      this.setSelectScenarioInfo(scenarioSeq);
    },
    /**
     * 시나리오를 수정한다.
     * @param scenarioInfo 
     * @returns 
     */
    editScenario(scenarioInfo: ScenarioInfo | null) {

      if(CommonUtils.isNullOrEmpty(this.selectScenarioInfo))
        return;

      const scenarios = this.getScenarios();

      if(CommonUtils.isNullOrEmpty(scenarios))
        return;

      const findIndex = scenarios.findIndex(s => s.scenarioSeq === scenarioInfo?.scenarioSeq);      

      if(findIndex < 0 || CommonUtils.isNullOrEmpty(scenarioInfo))
        return;
        
      scenarios[findIndex] = CommonUtils.deepClone(scenarioInfo);      
    },
    setSelectScenarioInfo(scenarioSeq: number | null) {

      const editStore = useEditStore();
      
      if(CommonUtils.isNullOrEmpty(scenarioSeq)) {
        this.selectScenarioInfo = null;
        editStore.snapShot.scenarioSeq = null;
        return;
      }

      if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios) || editStore.selectSpaceInfo.scenarios.length <= 0)
        return;

      const selectScenario = editStore.selectSpaceInfo.scenarios.find(s => s.scenarioSeq === scenarioSeq);

      if (CommonUtils.isNullOrEmpty(selectScenario))
        return;      
        
      this.selectScenarioInfo = selectScenario;
      editStore.snapShot.scenarioSeq = scenarioSeq;
    },

    updateSelectedNodeName(nodeName: string) {
      const nodeEditorStore = useNodeEditorStore();

      const scenarioNodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;

      if (CommonUtils.isNullOrEmpty(scenarioNodeInfo) || CommonUtils.isNullOrEmpty(scenarioNodeInfo.nodeConfig) || CommonUtils.isNullOrEmpty(scenarioNodeInfo.nodeSetting))
        return;
            
      scenarioNodeInfo.name = nodeName;
      scenarioNodeInfo.update();      


      //순차실행의 노드이름도 변경한다.
      for(const nodeInfo of nodeEditorStore.nodeInfos) {
        
        if(nodeInfo.nodeConfig.nodeType !== NodeType.Scenario || nodeInfo.nodeConfig.nodeElementType !== NodeElementType.Scenario_SequenceExecute)
          continue;
        
        const nodeSettng = nodeInfo.nodeSetting as ScenarioSequenceExecuteNodeSetting;

        for(const task of nodeSettng.tasks) {
          
          if(task.nodeId !== scenarioNodeInfo.id)
            continue;
          
          task.name = scenarioNodeInfo.name ?? "";
        }      
      }
    },

    /**
     * CheckBox로 노드 아이템 이벤트를 추가 되거나 삭제 하였을때 업데이트
     * @param selectedOptions 
     * @param events 
     * @returns 
     */
    updateSelectionNodeItemEvents(nodeInfo: AbstractNodeInfo, selectedOptions: string[], events: AbtractSelectionNodeItemEvent[]) {
      const nodeEditorStore = useNodeEditorStore();

      if (CommonUtils.isNullOrEmpty(nodeInfo))
        return;
    
      const options = selectedOptions;
      
      const linkNodes = nodeEditorStore.getLinkNodes(nodeInfo.id);
      
      let addCount = 0; 

      const scenarioNodeInfo = nodeInfo as ScenarioNodeInfo;
      scenarioNodeInfo.scenarioNode.nodeEvents = scenarioNodeInfo.scenarioNode.nodeEvents ?? [];
      scenarioNodeInfo.scenarioNode.nodeEvents.splice(0);

      for (const event of events) {

        const eventCode = event.nodeTypeByEvent.eventCode;

        if(options.some(o => o === eventCode.toString())) {
          event.isChecked = true;
          addCount++;        

          const scenarioNodeEvent = new ScenarioNodeEvent();

          scenarioNodeEvent.siteSeq = this.selectScenarioInfo?.siteSeq;
          scenarioNodeEvent.zoneSeq = this.selectScenarioInfo?.zoneSeq;
          scenarioNodeEvent.scenarioSeq = this.selectScenarioInfo?.scenarioSeq;
          scenarioNodeEvent.nodeSeq = scenarioNodeInfo.scenarioNode.nodeSeq;
          scenarioNodeEvent.eventCode = eventCode;
          scenarioNodeEvent.eventName = event.nodeTypeByEvent.eventName;          
          scenarioNodeInfo.scenarioNode.nodeEvents.push(scenarioNodeEvent);
        }
        else {
          event.isChecked = false;
          nodeInfo.removeNodeItem(eventCode.toString());
          
          if(CommonUtils.isNullOrEmpty(nodeInfo.nodeItems))
            continue;
  
          const nodeItemId = eventCode.toString();                
          const linkNode = linkNodes.find(n => n.srcNodeItemId === nodeItemId);
  
          if(CommonUtils.isNullOrEmpty(linkNode))
            continue;
  
          //연결 노드 삭제
          nodeEditorStore.removeNode(linkNode.id);
        }
      } 
          
      if(addCount > 0)
        nodeInfo.updateNodeItems();
        
  
      const nodeItems = nodeInfo.nodeItems;
  
      if(CommonUtils.isNullOrEmpty(nodeItems))
        return;
  
      if(nodeItems.length <= 0)
        return;
  
      //NodeItem 추가 삭제로 인한 링크(연결) 노드 업데이트
      nodeEditorStore.updateLinks(nodeInfo.id);  
    },

    /**
     * 노드 아이템 이벤트를 추가한다.
     * @param nodeInfo 노드 정보
     * @param nodeItemEvent 노드 아이템 이벤트
     */
    addNodeItemEvent(nodeInfo: ScenarioNodeInfo, nodeItemEvent: INodeItemEvent) {

      const userStore = useUserStore();
      const scenarioNodeEvent = new ScenarioNodeEvent();

      scenarioNodeEvent.siteSeq = this.selectScenarioInfo?.siteSeq;
      scenarioNodeEvent.zoneSeq = this.selectScenarioInfo?.zoneSeq;
      scenarioNodeEvent.scenarioSeq = this.selectScenarioInfo?.scenarioSeq;
      scenarioNodeEvent.nodeSeq = nodeInfo.scenarioNode.nodeSeq;
      scenarioNodeEvent.eventCode = nodeItemEvent.code;
      scenarioNodeEvent.eventName = nodeItemEvent.name;
      scenarioNodeEvent.eventOccurCond = nodeItemEvent.makeEventOccurCond();
      scenarioNodeEvent.regUserId = userStore.user?.userId;

      switch (nodeInfo.nodeConfig.nodeElementType) {

        case NodeElementType.Scenario_Scheduler:
          const scheduleNodeSetting = nodeInfo.nodeSetting as ScenarioScheduleNodeSetting;
          const schedule = nodeItemEvent as ScenarioSchedule;
          scheduleNodeSetting.addAndReplaceSchedule(schedule);
          break;

        case NodeElementType.Scenario_TimePeriod:
          const timeBranchNodeSetting = nodeInfo.nodeSetting as ScenarioTimeBranchNodeSetting;
          const timeBranchEvent = nodeItemEvent as TimeBranchEvent;
          timeBranchNodeSetting.addAndReplaceEvent(timeBranchEvent);

          break;
      }

      if (CommonUtils.isNullOrEmpty(nodeInfo.scenarioNode.nodeEvents))
        nodeInfo.scenarioNode.nodeEvents = [];

      nodeInfo.scenarioNode.nodeEvents.push(scenarioNodeEvent);

      nodeInfo.updateNodeItems();
    },    

    /**
     * 노드 아이템 이벤트를 업데이트 한다.
     * @param nodeInfo 노드 정보
     * @param nodeItemEvent 노드 아이템 이벤트
     * @returns 
     */
    updateNodeItemEvent(nodeInfo: ScenarioNodeInfo, nodeItemEvent: INodeItemEvent) {

      switch (nodeInfo.nodeConfig.nodeElementType) {

        case NodeElementType.Scenario_Scheduler:
          const scheduleNodeSetting = nodeInfo.nodeSetting as ScenarioScheduleNodeSetting;
          const schedule = nodeItemEvent as ScenarioSchedule;
          scheduleNodeSetting.addAndReplaceSchedule(schedule);
          break;

        case NodeElementType.Scenario_TimePeriod:
          const timeBranchNodeSetting = nodeInfo.nodeSetting as ScenarioTimeBranchNodeSetting;
          const timeBranchEvent = nodeItemEvent as TimeBranchEvent;
          timeBranchNodeSetting.addAndReplaceEvent(timeBranchEvent);
          break;        
      }
      
      const event = nodeInfo.scenarioNode.nodeEvents?.find(e => e.eventCode === nodeItemEvent.code);

      if (CommonUtils.isNullOrEmpty(event))
        return;

      const userStore = useUserStore();
      event.eventName = nodeItemEvent.name;
      event.eventOccurCond = nodeItemEvent.makeEventOccurCond();
      event.modUserId = userStore.user?.userId;

      nodeInfo.updateNodeItems();
    },

    /**
     * 노드 아이템 이벤트 삭제
     * @param nodeInfo 노드 정보
     * @param eventCode  이벤트 코드
     * @returns 
     */
    removeNodeItemEvent(nodeInfo: ScenarioNodeInfo, eventCode: string) {

      if (CommonUtils.isNullOrEmpty(nodeInfo.scenarioNode.nodeEvents))
        return;

      switch (nodeInfo.nodeConfig.nodeElementType) {
        case NodeElementType.Scenario_Scheduler:
          const scheduleNodeSetting = nodeInfo.nodeSetting as ScenarioScheduleNodeSetting;
          scheduleNodeSetting.deleteSchedule(eventCode);
          break;
        case NodeElementType.Scenario_TimePeriod:
          const timeBranchNodeSetting = nodeInfo.nodeSetting as ScenarioTimeBranchNodeSetting;
          timeBranchNodeSetting.deleteEvent(eventCode);
          break;
      }

      const index = nodeInfo.scenarioNode.nodeEvents.findIndex(e => e.eventCode === eventCode);
      nodeInfo.scenarioNode.nodeEvents?.splice(index, 1);
      nodeInfo.updateNodeItems();
    },

    /**
     * 노드 설정을 업데이트 한다.
     * @param nodeInfo 노드 정보
     * @param params 파라미터
     */
    updateNodeSetting(nodeInfo: ScenarioNodeInfo, params: NodeEventParam[]) {

      switch (nodeInfo.nodeConfig.nodeElementType) {
        case NodeElementType.Scenario_Presence_SensorDevice:
          const presenceSensorDeviceNodeSetting = nodeInfo.nodeSetting as ScenarioPresenceSensorDeviceNodeSetting;
          const dPointSeq = CommonUtils.isNullOrEmpty(params[0]) ? null : Number(params[0]);
          presenceSensorDeviceNodeSetting.setDPointSeq(dPointSeq);
          nodeInfo.scenarioNode.dpointSeq = dPointSeq ?? undefined;
          break;
        case NodeElementType.Scenario_ControlLight:          
        case NodeElementType.Scenario_ControlScene:
        case NodeElementType.Scenario_Configure_Light:
        case NodeElementType.Scenario_Configure_Presense_Sensor:
          const actionNodeSetting = nodeInfo.nodeSetting as AbstractActionNodeSetting;
          const attrsMap = params[0]?.toString();
          actionNodeSetting.solveAttrs(attrsMap ?? "");
          break;
        case NodeElementType.Scenario_Device:          
        case NodeElementType.Scenario_DeviceGroup:
          const targetSeq = Number(params[0]);
          const targetType = params[1] as TargetType;
          nodeInfo.scenarioNode.targetSeq = targetSeq;
          nodeInfo.scenarioNode.targetTypeCode = targetType;

          if(nodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Device) {
            const deviceNodeSetting = nodeInfo.nodeSetting as ScenarioDeviceNodeSetting;                    
            deviceNodeSetting.deviceSeq = targetSeq;
          } else {
            const deviceGroupNodeSetting = nodeInfo.nodeSetting as ScenarioDeviceGroupNodeSetting;                        
            deviceGroupNodeSetting.groupSeq = targetSeq;
          }
          
          break;
      }
    },

    /**
     * 시나리오 노드를 생성한다.
     * @param nodeConfigId 
     * @param offsetX 
     * @param offsetY 
     * @returns 
     */
    createNewScenarioNodeInfo(nodeConfigId: string, offsetX: number, offsetY: number) {

      if (CommonUtils.isNullOrEmpty(this.scenarioConfigGroups))
        return;

      let config: AbstractNodeConfig | null = null;

      for (const element of this.scenarioConfigGroups) {
        if (CommonUtils.isNullOrEmpty(element.configs))
          continue;

        for (const c of element.configs) {
          if (c.id === nodeConfigId) {
            config = c;
            break;
          }
        }

        if (CommonUtils.isNullOrEmpty(config) === false)
          break;
      }

      if (CommonUtils.isNullOrEmpty(config))
        return;

      const nodeEditorStore = useNodeEditorStore();
      
      const scale = nodeEditorStore.editorInfo.scale;
      const snap = nodeEditorStore.editorInfo.snap;
      const x = Math.round((offsetX / scale - (config.w / 2)) / snap) * snap;
      const y = Math.round((offsetY / scale - (config.h / 2)) / snap) * snap;

      //console.log(`offsetX: ${offsetX}, config.w: ${config.w}, x: ${x}`);
      //console.log(`offsetY: ${offsetY}, config.w: ${config.h}, x: ${y}`);
      
      
      //시나리오 노드 생성
      const scenarioNode = new ScenarioNode();
      scenarioNode.siteSeq = this.selectScenarioInfo?.siteSeq;
      scenarioNode.zoneSeq = this.selectScenarioInfo?.zoneSeq;
      scenarioNode.scenarioSeq = this.selectScenarioInfo?.scenarioSeq;
      scenarioNode.nodeSeq = this.getNewId();
      scenarioNode.originX = x;
      scenarioNode.originY = y;
      scenarioNode.nodeTypeCode = config.nodeElementType;
      scenarioNode.nodeName = config.name;
      scenarioNode.nodeDesc = config.description;      
      scenarioNode.nodeEvents =[];

      //AbstractNodeConfig  -> ScenarioNodeConfig로 형변환
      const scenarioNodeConfig = config as ScenarioNodeConfig;

      //노드 팩토리 파라미터
      const parmas: Array<NodeFactoryParam> = [scenarioNode, scenarioNodeConfig.nodeTypeByEvents ?? null];      
            
      //노드 생성
      const nodeInfo = nodeEditorStore.createNode(x, y, config, parmas);

      if (CommonUtils.isNullOrEmpty(nodeInfo))
        return;
      
      this.selectScenarioInfo?.nodes?.push(scenarioNode);      
    },

    /**
     * ScenarioFlow 생성
     * @param nodeIds 노드 아이디 리스트
     * @returns 
     */
    createScenarioFlows(nodeIds: string[]) {

      const nodeEditorStore = useNodeEditorStore();

      if (CommonUtils.isNullOrEmpty(this.selectScenarioInfo))
        return;

      for (const nodeId of nodeIds) {

        const nodeInfo = nodeEditorStore.getNode(nodeId);

        if(CommonUtils.isNullOrEmpty(nodeInfo))
          continue;

        if(nodeInfo.nodeConfig.nodeType !== NodeType.Link)
          continue;

        const linkNodeInfo = nodeInfo as LinkNodeInfo;

        const siteSeq = this.selectScenarioInfo?.siteSeq;
        const zoneSeq = this.selectScenarioInfo?.zoneSeq;
        const scenarioSeq = this.selectScenarioInfo?.scenarioSeq;
        const flowSeq = this.getNewId(true);

        const scenarioFlow = new ScenarioFlow(siteSeq!, zoneSeq!, scenarioSeq!, flowSeq);

        if(CommonUtils.isNullOrEmpty(linkNodeInfo.srcNodeId) || CommonUtils.isNullOrEmpty(linkNodeInfo.targetNodeId))
          continue;

        const srcNodeInfo = nodeEditorStore.getNode(linkNodeInfo.srcNodeId) as ScenarioNodeInfo;
        const targetNodeInfo = nodeEditorStore.getNode(linkNodeInfo.targetNodeId) as ScenarioNodeInfo;
        
        scenarioFlow.sourceNodeSeq = srcNodeInfo.scenarioNode.nodeSeq;        
        scenarioFlow.targetNodeSeq = targetNodeInfo.scenarioNode.nodeSeq;    
        scenarioFlow.eventCode = linkNodeInfo.srcNodeItemId;
        
        this.selectScenarioInfo?.flows?.push(scenarioFlow); 
      }      
    },
    removeScenarioNodes(nodeInfos: AbstractNodeInfo[]) {

      if (CommonUtils.isNullOrEmpty(this.selectScenarioInfo))
        return;    

      const nodeEditorStore = useNodeEditorStore();

      for(const nodeInfo of nodeInfos) {                  

        if(nodeInfo.nodeConfig.nodeType !== NodeType.Scenario && nodeInfo.nodeConfig.nodeType !== NodeType.Package && nodeInfo.nodeConfig.nodeType !== NodeType.Link)
          continue;                  
        
        if(nodeInfo.nodeConfig.nodeType === NodeType.Scenario || nodeInfo.nodeConfig.nodeType === NodeType.Package) {

          const scenarioNodeInfo = nodeInfo as ScenarioNodeInfo;
          const scenarioNode = scenarioNodeInfo.scenarioNode;                  

          if(CommonUtils.isNullOrEmpty(this.selectScenarioInfo.nodes))
            return;

          const index = this.selectScenarioInfo.nodes?.findIndex(n => n.nodeSeq === scenarioNode.nodeSeq);
          this.selectScenarioInfo.nodes.splice(index, 1);
        } else if (nodeInfo.nodeConfig.nodeType === NodeType.Link) {

          const linkNodeInfo = nodeInfo as LinkNodeInfo;

          if (CommonUtils.isNullOrEmpty(linkNodeInfo.srcNodeId) || CommonUtils.isNullOrEmpty(linkNodeInfo.targetNodeId))
            continue;

          let srcNodeInfo = nodeEditorStore.getNode(linkNodeInfo.srcNodeId);

          if(CommonUtils.isNullOrEmpty(srcNodeInfo))
            srcNodeInfo = nodeInfos.find(n => n.id === linkNodeInfo.srcNodeId) ?? null;

          let targetNodeInfo = nodeEditorStore.getNode(linkNodeInfo.targetNodeId);

          if(CommonUtils.isNullOrEmpty(targetNodeInfo))
            targetNodeInfo = nodeInfos.find(n => n.id === linkNodeInfo.targetNodeId) ?? null;

          if (CommonUtils.isNullOrEmpty(srcNodeInfo) || CommonUtils.isNullOrEmpty(targetNodeInfo) || CommonUtils.isNullOrEmpty(this.selectScenarioInfo.flows))
            continue;

          const srcScenarioNodeInfo = srcNodeInfo as ScenarioNodeInfo;
          const targetScenarioNodeInfo = targetNodeInfo as ScenarioNodeInfo;

          //TODO: 이벤트 코드 비교도 넣어야함.
          const index = this.selectScenarioInfo.flows.findIndex(f => f.sourceNodeSeq === srcScenarioNodeInfo.scenarioNode.nodeSeq && f.targetNodeSeq === targetScenarioNodeInfo.scenarioNode.nodeSeq && f.eventCode === linkNodeInfo.srcNodeItemId);
          this.selectScenarioInfo.flows?.splice(index, 1);
        }        
      }
    },

    /**
     * 시나리오 편집 화면에서 존재하는 ScenarioExecutor 노드들의 업데이트
     * @param nodeInfo 
     * @returns 
     */
    updateScenarioExecutors(nodeInfo: AbstractNodeInfo) {
      if (nodeInfo.nodeConfig.nodeType !== NodeType.Scenario || nodeInfo.nodeConfig.nodeElementType !== NodeElementType.Scenario_SequenceExecute)
        return;

      const nodeEditorStore = useNodeEditorStore();

      const scenarioNodeInfo = nodeInfo as ScenarioNodeInfo;
      const nodeSettng = scenarioNodeInfo.nodeSetting as ScenarioSequenceExecuteNodeSetting;

      const links = nodeEditorStore.getFromSrcLinkNodes(scenarioNodeInfo.id);

      for (const link of links) {

        if (CommonUtils.isNullOrEmpty(link.targetNodeId))
          continue;

        const targetNode = nodeEditorStore.getNode(link.targetNodeId);

        if (CommonUtils.isNullOrEmpty(targetNode))
          continue;

        nodeSettng.addTask(targetNode);
      }

      //Task에는 존재 하지만 nodeEditorStore.nodeInfos 존재 하지 않으면 Task에서 삭제
      for (const task of nodeSettng.tasks) {

        const taskNodeInfo = nodeEditorStore.getNode(task.nodeId);

        if (!CommonUtils.isNullOrEmpty(taskNodeInfo))
          continue;

        nodeSettng.deleteTask(task.nodeId);
      }
    },

    // async getSensorDevices (sensorType: SensorType) {      
    //   return await this.apiServices.deviceApiService.getSensorDevice(sensorType);
    // },    

    async loadScenarios() {
      
      // this.scenarioInfos.splice(0);

      // for (let i = 0; i < 5; i++) {
      //   const scenario = new Scenario();
      //   scenario.id = Math.floor(Math.random() * 100000 + i);
      //   scenario.name = `시나리오 ${i + 1}`;
      //   scenario.description = `시나리오 ${i + 1} 입니다`;
  
      //   this.scenarioInfos.push(scenario);
      // }
      const editStore = useEditStore();
      const zone = editStore.selectSpaceInfo;

      if(zone?.zoneTypeCode !== ZoneTypeCode.단위존)
        return [];
      return null;

    },

    updateNodesPoint(nodeIds: string[] | null) {

      const editStore = useEditStore();
      const nodeEditorStore = useNodeEditorStore();      

      if (CommonUtils.isNullOrEmpty(nodeIds) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo))
        return;

      for (const nodeId of nodeIds) {        
        const node = nodeEditorStore.getNode(nodeId) as ScenarioNodeInfo;

        if (CommonUtils.isNullOrEmpty(node) || CommonUtils.isNullOrEmpty(node.scenarioNode))
          continue;
      
        const scenarioNode = node.scenarioNode;
        scenarioNode.originX = node.x;
        scenarioNode.originY = node.y;        
      }
    },

    /**
     * 시나리오 노드 붙여넣기 처리 함수
     */
    pasteScenarioNodes() {
      const nodeEditorStore = useNodeEditorStore();


      for (const node of nodeEditorStore.nodeInfos) {
        
        const findIndex = nodeEditorStore.copyingNodes.findIndex(n => n.id === node.id);

        if(findIndex < 0)
          continue;

        const scenarioNodeInfo = node as ScenarioNodeInfo;

        const nodeSeq = this.getNewId();
        scenarioNodeInfo.scenarioNode.nodeSeq = nodeSeq;
        scenarioNodeInfo.scenarioNode.originX = node.x;
        scenarioNodeInfo.scenarioNode.originY = node.y;
                      
        for(const event of scenarioNodeInfo.scenarioNode.nodeEvents ?? []) {                              
          switch(node.nodeConfig.nodeElementType) {
            case NodeElementType.Scenario_Executor:
            case NodeElementType.Scenario_Presence_SensorDevice:
            case NodeElementType.Scenario_Configure_Presense_Sensor:
              break;
            default:
              event.eventCode = CommonUtils.generateUUID();
              break;
          }

          event.nodeSeq = nodeSeq;
        }

        this.selectScenarioInfo?.nodes?.push(scenarioNodeInfo.scenarioNode); 
      }
    },

    updateAttrsMap(nodeInfo: ScenarioNodeInfo) {

      if(!(nodeInfo.nodeSetting instanceof AbstractActionNodeSetting))
        return;

      const nodeSetting: AbstractActionNodeSetting = nodeInfo.nodeSetting;
      const attrsMap = nodeSetting.toAttrsMap();      
      nodeInfo.scenarioNode.attrsMap = attrsMap ?? undefined;    
    },

    /**
     * ScenarioNodeEvent 객체 기반으로 UI Event 클래스로 변환
     * @param nodeElementType 
     * @param scenarioNodeEvent 
     * @returns 
     */
    convertToSettingEvents(nodeElementType: NodeElementType, scenarioNodeEvent: ScenarioNodeEvent) {

      const e = scenarioNodeEvent;

      if(CommonUtils.isNullOrEmpty(e.eventCode))
        return null;

      switch (nodeElementType) {
        case NodeElementType.Scenario_Scheduler:
          const schd_parmas = ScenarioSchedule.solveEventOccurCond(e.eventOccurCond ?? "");
          return new ScenarioSchedule(e.eventCode, e.eventName ?? "", schd_parmas[0], schd_parmas[1]);

        case NodeElementType.Scenario_TimePeriod:          
          const tmbr_parmas = TimeBranchEvent.solveEventOccurCond(e.eventOccurCond ?? "");
          return new TimeBranchEvent(e.eventCode, e.eventName ?? "", tmbr_parmas[0], tmbr_parmas[1], tmbr_parmas[2], tmbr_parmas[3]);        
      }
    },

    import() {

      const editStore = useEditStore();
      editStore.stopWatch();

      if(CommonUtils.isNullOrEmpty(this.selectScenarioInfo) || CommonUtils.isNullOrEmpty(this.selectScenarioInfo.nodes))
        return;

      const nodeEditorStore = useNodeEditorStore();
      let nodeInfo: ScenarioNodeInfo| null = null;
      let nodeConfig: ScenarioNodeConfig | null = null;
      let nodeSetting: INodeSetting | null = null;
      let selectedEventCodes: string[] = [];

      //노드 생성
      for (const sn of this.selectScenarioInfo.nodes) {

        if (CommonUtils.isNullOrEmpty(sn.nodeTypeCode))
          continue;

        const nodeId = CommonUtils.generateUUID();

        //String을 열거형으로 변환
        const nodeElementType = sn.nodeTypeCode as NodeElementType;

        nodeConfig = nodeEditorStore.nodeConfigs.find(c => c.nodeElementType === nodeElementType) as ScenarioNodeConfig;
        nodeSetting = NodeFactory.createNodeSetting(nodeElementType, nodeConfig.nodeTypeByEvents ?? []);

        nodeInfo = new ScenarioNodeInfo(nodeId, sn.originX ?? 0, sn.originY ?? 0, sn, nodeConfig, nodeSetting);
        nodeInfo.name = sn.nodeName;
        nodeInfo.desc = sn.nodeDesc;        

        nodeEditorStore.nodeInfos.push(nodeInfo);

        if (CommonUtils.isNullOrEmpty(sn.nodeEvents))
          continue;

        switch (nodeElementType) {
          case NodeElementType.Scenario_Executor:
            selectedEventCodes = sn.nodeEvents?.map(n => n.eventCode!) ?? [];
            this.updateSelectionNodeItemEvents(nodeInfo, selectedEventCodes, (nodeSetting as ScenarioExecutorNodeSetting).events);
            break;
          case NodeElementType.Scenario_SequenceExecute:
            break;
          case NodeElementType.Scenario_Scheduler:

            for (const event of sn.nodeEvents) {
              const settingEvent = this.convertToSettingEvents(nodeElementType, event);

              if (CommonUtils.isNullOrEmpty(settingEvent))
                continue;
                
              (nodeSetting as ScenarioScheduleNodeSetting).addAndReplaceSchedule(settingEvent as ScenarioSchedule);
            }
          
            break;
          case NodeElementType.Scenario_Presence_SensorDevice:
            selectedEventCodes = sn.nodeEvents?.map(n => n.eventCode!) ?? [];
            this.updateSelectionNodeItemEvents(nodeInfo, selectedEventCodes, (nodeSetting as ScenarioPresenceSensorDeviceNodeSetting).events);
            const dPointSeq = nodeInfo.scenarioNode.dpointSeq;
            this.updateNodeSetting(nodeInfo as ScenarioNodeInfo, [dPointSeq]);
            break;
          case NodeElementType.Scenario_TimePeriod:

            for (const event of sn.nodeEvents) {
              const settingEvent = this.convertToSettingEvents(nodeElementType, event);

              if (CommonUtils.isNullOrEmpty(settingEvent))
                continue;

              (nodeSetting as ScenarioTimeBranchNodeSetting).addAndReplaceEvent(settingEvent as TimeBranchEvent);
            }
            break;
          case NodeElementType.Scenario_ControlLight: 
          case NodeElementType.Scenario_ControlScene:
          case NodeElementType.Scenario_Configure_Light:          
            this.updateNodeSetting(nodeInfo as ScenarioNodeInfo, [nodeInfo.scenarioNode.attrsMap]);
            break;          
          case NodeElementType.Scenario_Configure_Presense_Sensor:
            selectedEventCodes = sn.nodeEvents?.map(n => n.eventCode!) ?? [];
            this.updateSelectionNodeItemEvents(nodeInfo, selectedEventCodes, (nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).events);
            this.updateNodeSetting(nodeInfo as ScenarioNodeInfo, [nodeInfo.scenarioNode.attrsMap]);

            break;
          case NodeElementType.Scenario_Device:            
          case NodeElementType.Scenario_DeviceGroup:
            this.updateNodeSetting(nodeInfo as ScenarioNodeInfo, [nodeInfo.scenarioNode.targetSeq, nodeInfo.scenarioNode.targetTypeCode]);
            break;

        }

        nodeInfo.updateNodeItems();

        if (CommonUtils.isNullOrEmpty(nodeConfig) || CommonUtils.isNullOrEmpty(nodeSetting))
          continue;
      }

      //Flow 생성
      if(CommonUtils.isNullOrEmpty(this.selectScenarioInfo.flows))
        return;

      for(const flow of this.selectScenarioInfo.flows) {

        if(CommonUtils.isNullOrEmpty(flow.sourceNodeSeq) || CommonUtils.isNullOrEmpty(flow.targetNodeSeq))
          continue;
        
        const srcNodeInfo = this.getScenarioNodeInfo(flow.sourceNodeSeq);
        const targetNodeInfo = this.getScenarioNodeInfo(flow.targetNodeSeq);

        if(CommonUtils.isNullOrEmpty(srcNodeInfo) || CommonUtils.isNullOrEmpty(targetNodeInfo))
          continue;
        
        const srcPortInfo = srcNodeInfo.getPort(PortType.Out, flow.eventCode);
        const targetPortInfo = targetNodeInfo.getPort(PortType.In);

        if(CommonUtils.isNullOrEmpty(srcPortInfo) || CommonUtils.isNullOrEmpty(targetPortInfo))
          continue;

        const startLinkPoint = DrawUtil.getLinkPoint(srcNodeInfo, srcPortInfo);
        const endLinkPoint = DrawUtil.getLinkPoint(targetNodeInfo, targetPortInfo);

        if (startLinkPoint === null || endLinkPoint === null)
          continue;
                  
        const nodePath = DrawUtil.getNodePath(startLinkPoint.x, startLinkPoint.y, endLinkPoint?.x, endLinkPoint?.y);
        const linkInfo = new LinkInfo("", nodePath);      
        const linkNodeInfo = nodeEditorStore.createLinkNode(linkInfo, startLinkPoint, endLinkPoint);
      }

      editStore.startWatch();
    },
  },
});
