import { defineStore } from "pinia";
import CommonUtils from "@/utils/common-util";
import type User from "@/models/user";
import type LoginInfo from "@/models/entity/login-info";
import { useAuthManager } from "@/composables/auth-manager";
import type { WatchStopHandle } from "vue";

let refreshTokenWatchStopHandle: WatchStopHandle | null = null;

export const useUserStore = defineStore("user", {
  state: () => ({
    codes: [],
    appinfo: null,
    user: null as User | null,
  }),
  getters: {
    getCodes(state) {
      return state.codes;
    },
    getUser(state) {
      return state.user;
    }
  },
  actions: {
    async setFetchUser(userID: number) {
      // const result = axios.get(`/v1/users/${userID}`);
      // this.userInfo = new User(result.data);
    },

    //async checkUser(data: loginForm) {
    // for (let i = 0; i < this.userInfo.length; i++) {
    //   if (data.email === this.userInfo[i].email) {
    //     if (data.password === this.userInfo[i].password) {
    //       return true;
    //     } else {
    //       return "패스워드 틀림";
    //     }
    //   } else {
    //     return "이메일 없음";
    //   }
    // }
    //},

    async fetchCodes() {
      // const data = await this.netPlugins.rest.get("/v2/codes");
      // this.codes = data.data;
    },

    async fetchAppInfo() {
      // const params = {
      //     appId: "KIE.MECS.Mobile.Android",
      // }
      // const appId = "KIE.MECS.Mobile.Android";
      // const data = await this.rest.get(`/v2/apps/${appId}`);
      // this.appinfo = data.data;            
    },

    async login(email: string, password: string) {

      const result = await this.apiServices.userApiService.login(email, password);

      if(result !== "login failed"){
        const user = await this.apiServices.userApiService.getUser((result as LoginInfo).userId);
        this.user = user;
  
        return user;
      } else {
        return "login failed";
      }   
    },

    async tokenLogin(){
      const result = await this.apiServices.userApiService.loginWithToken();
      this.user = result;

      return this.user;
    },

    async updateToken() {

      try {
        const authManager = useAuthManager();
        console.log(authManager.getRefreshToken());
        if (CommonUtils.isNullOrEmpty(authManager.getRefreshToken()))
          return false;

        const refresh_token = authManager.getRefreshToken();

        if (CommonUtils.isNullOrEmpty(refresh_token))
          return false;

        const result = await this.apiServices.userApiService.updateToken(refresh_token);

        const user = await this.apiServices.userApiService.getUser((result as LoginInfo).userId);
        this.user = user;

        return true;

      } catch {
        return false;
      }
    },

    async logout() {

      try {
        if (!CommonUtils.isNullOrEmpty(refreshTokenWatchStopHandle)) {
          refreshTokenWatchStopHandle();
          refreshTokenWatchStopHandle = null;
        }

        const userId = this.user?.userId;

        if (CommonUtils.isNullOrEmpty(userId))
          return;

        const result = await this.apiServices.userApiService.logout(userId);
        this.persistStateManager.clearInitData();
        console.log(`LogOut!!!!!!, ${JSON.stringify(result, null, 2)}`);
      } catch {

      } finally {
        this.user = null;
      }
    },

    setRefreshTokenWatchStopHandle(watchStopHandle: WatchStopHandle) {
      refreshTokenWatchStopHandle = watchStopHandle;
    }
  },
});
