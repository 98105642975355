<script setup lang="ts">
import type { alertMessageType } from "@/global/types";
import {onMounted, computed, ref} from "vue";

const props = defineProps<{
    staticValue: boolean,
    confirmValue: boolean,
    message: alertMessageType
}>();
const emit = defineEmits(["change", "submit"]);

console.log(props);
function submit(){
  emit("submit");
}

function change(value: boolean){
  emit("change", value);
}

const staticValue = computed({
  get: () => props.staticValue,
  set: () => emit("change", false),
});
const confirmValue = computed({
  get:() => props.confirmValue,
  set: () => emit("change", false) 
});


</script>

<template>
    <div class="AlertWarningComponent">
        <div class="TypeStatic">
            <q-dialog v-model="staticValue" @hide="change(false)">
                <q-card>
                    <q-card-section>
                    <div class="text-h6">오류</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        {{props.message.message}}
                    </q-card-section>

                    <q-card-actions align="right">
                    <q-btn flat label="확인" color="primary" v-close-popup />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </div>
        <div class="TypeConfirm">
            <q-dialog v-model="confirmValue" persistent @hide="change(false)">
                <q-card>
                    <q-card-section class="row items-center">
                    <q-avatar icon="signal_wifi_off" color="primary" text-color="white" />
                    <div>
                        <div>
                            <span class="q-ml-sm">{{props.message.message}}</span>
                        </div>
                        <div>
                            <span class="q-ml-sm">{{props.message.removeTarget}}</span>
                        </div>
                    </div>
             
                    </q-card-section>
                    <q-card-actions align="right">
                    <q-btn flat label="취소" color="primary" v-close-popup />
                    <q-btn flat label="확인" color="primary" v-close-popup @click="submit" />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </div>
    </div>
</template>

<style lang="scss">
.AlertWarningComponent{
    .TypeStatic{
        
    }

    .TypeConfirm{

    }
}
</style>