/**
 * 묶음 공간 클래스
 * @class
 */

class CZone {
  siteSeq = 0; // 사이트 ID
  zoneSeq = 0; // 존 ID
  czoneTypeCode: string | null = null; // 묶음 존 유형 코드
  floorTypeCode: string | null = null; // 지상/지하 구분 코드
  
  constructor(siteSeq: number, zoneSeq: number, czoneTypeCode: string, floorTypeCode: string){
    this.siteSeq = siteSeq;
    this.zoneSeq = zoneSeq;
    this.czoneTypeCode = czoneTypeCode;
    this.floorTypeCode = floorTypeCode;
  }
}

export default CZone;
