<script setup lang="ts">
import {computed, ref} from "vue";
import { useMaterialsStore } from "@/stores/materials-store";
import type { QTableProps } from "quasar";
import ProfileFormComponent from "./ProfileFormComponent.vue";


const materialStore = useMaterialsStore();

const profileList = computed(() => {
  return materialStore.profileList;
});

const isSelect = ref(false);

const isSelectProfile = ref();
const isSelectRowIndex = ref();

function test(ev: Event, row: object, index: number){
  console.log(ev, row);
  if(isSelectRowIndex.value === index){
    isSelect.value = false;
    isSelectRowIndex.value = null;
  } else {
    isSelectProfile.value = row;
    isSelect.value = true;
    isSelectRowIndex.value = index;
  }

}

const column: QTableProps["columns"] = [
  {name: "usageProfileCode", label: "식별", field: "usageProfileCode", align: "left", required: false},
  {name: "usageProfileName", label: "명칭", field: "usageProfileName", align: "left", required: true},
  {name: "useStartTime", label: "시작시간", field: "useStartTime", align: "center"},
  {name: "useEndTime", label: "종료시간", field: "useEndTime", align: "center"},
  {name: "dailyUsageTime", label: "하루 사용시간", field: "dailyUsageTime", align: "center"},
  {name: "workSurfaceHeight", label: "작업면 높이", field: "workSurfaceHeight", align: "center"},
  {name: "vertifaceLightCoeff", label: "수직면 기준순응 계수", field: "vertifaceLightCoeff", align: "center"},
  {name: "annualUseDays", label: "연간사용일수", field: "annualUseDays", align: "center"},
  {name: "annualUseDayHours", label: "연간 낮 사용시간", field: "annualUseDayHours", align: "center"},
  {name: "annualUseNightHours", label: "연간 밤 사용시간", field: "annualUseNightHours", align: "center"},
  {name: "indoorIndex", label: "실내 인덱스", field: "indoorIndex", align: "center"},
  {name: "lightReductCoeff", label: "조명 감소 계수", field: "lightReductCoeff", align: "center"},
  {name: "lightRelativeAbsenceRate", label: "조명 상대적 부재율", field: "lightRelativeAbsenceRate", align: "center"},
  {name: "lightOperReductCoeff", label: "조명 운전 감소 계수", field: "lightOperReductCoeff", align: "center"},
  //   {name: "usageType", label: "용도 종류", field: "usageType", align: "center"},
  {name: "lightingHours", label: "조명 점등시간", field: "lightingHours", align: "center"},
];

const visibleColumnName = ["usageProfileName", "useStartTime", "useEndTime", "dailyUsageTime", "annualUseDays" ];

</script>

<template>
    <div class="ProfileComponent">
        <q-table
            class="col-4 profileListTable"
            :rows="profileList"
            :columns="column"
            :visible-columns="visibleColumnName"
            row-key="usageProfileCode"
            :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
            :rows-per-page-options="[0]" 
            :dark="false" 
            square 
            dense
            bordered
            hide-pagination
            @row-click="test"
        >

        </q-table>

        <ProfileFormComponent :is-select="isSelect" :profile-data="isSelectProfile"></ProfileFormComponent>
    </div>
</template>

<style lang="scss">
.ProfileComponent{
    display: flex;
    padding: 20px;
    .profileListTable{}
}
</style>