import AbstractNodeConfig from "./abstract-node-config";
import "reflect-metadata";
import { Type } from "class-transformer";

class ConfigGroup {

  public id?: string;
  public title?: string;

  @Type(() => AbstractNodeConfig)
  public configs: AbstractNodeConfig[] = [];
  constructor() {
    
  }  
}

export default ConfigGroup;