<script setup lang="ts">
import type PackageNodeConfig from "@/models/editor/nodes/config/package-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import type PortInfo from "@/models/editor/nodes/info/port-info";
import FlowPort from "@/components/Nodes/FlowPort.vue";
import { reactive } from "vue";
import { onMounted } from "vue";

const props = defineProps<{
  nodeInfo: AbstractNodeInfo,
}>();

const emitData = defineEmits<{
  (eventName: "portMouseDown", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void,
  (eventName: "portMouseUp", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void,
  (eventName: "portMouseOver", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void,
  (eventName: "portMouseOut", event: MouseEvent, nodeInfo: AbstractNodeInfo, port: PortInfo): void,
}>();

const nodeInfoRef = reactive(props.nodeInfo);

const sourceWidth = nodeInfoRef.nodeConfig.w * 0.8;

onMounted(() => {  
});

function onPortMouseDown(event: MouseEvent, port: PortInfo) {
  emitData("portMouseDown", event, props.nodeInfo, port);
}

function onPortMouseUp(event: MouseEvent, port: PortInfo) {
  emitData("portMouseUp", event, props.nodeInfo, port);
}

function onPortMouseOver(event: MouseEvent, port: PortInfo) {
  emitData("portMouseOver", event, props.nodeInfo, port);
}

function onPortMouseOut(event: MouseEvent, port: PortInfo) {
  emitData("portMouseOut", event, props.nodeInfo, port);
}

</script>
<template>
  <g :width="nodeInfoRef.nodeConfig.w"
    :height="nodeInfoRef.nodeConfig.h * (nodeInfoRef.nodeItems?.length ?? 0 + 1)">
    <rect :width="nodeInfoRef.nodeConfig.w" :height="nodeInfoRef.nodeConfig.h" fill="#FFFFFF" stroke="#000000"
      stroke-width="1">
    </rect>
    <g>
      <!-- 멀티 노드 헤더 -->
      <image :x="2" :y="2" :href="'scenario-nodes/' + nodeInfoRef.nodeConfig.imageUrl" :width="24" :height="24" />
      <text :x="34" :y="nodeInfoRef.nodeConfig.h / 2" dominant-baseline="middle" font-size="12" font-weight="bold"
        fill="#000000">
        {{ nodeInfoRef.name }}
      </text>
      <g class="sources" style="align:center;">      
        <svg v-for="(ns, i) in nodeInfoRef.nodeItems" :key="i" :width="sourceWidth" height="30" :x="nodeInfoRef.nodeConfig.w - sourceWidth"
              :y="nodeInfoRef.nodeConfig.h * (i + 1)">
          <g>
            <rect :width="sourceWidth" height="30" fill="#FFFFFF" stroke="#000000" stroke-width="1" />
            <image :x="2" :y="0" :href="'scenario-nodes/' + nodeInfoRef.nodeConfig.imageUrl" :width="24" :height="24" style="align-items: center;height: 100%;" />
            <text :x="34" :y="nodeInfoRef.nodeConfig.h / 2" dominant-baseline="middle" font-size="12" font-weight="bold" fill="#000000">
              {{ ns.name }}
            </text>                        
          </g>

          <!-- <flow-port v-for="(portInfo, i) in nodeInfoRef.ports" :key="i" :portInfo="portInfo" @portMouseDown="onPortMouseDown" @portMouseUp="onPortMouseUp" @portMouseOver="onPortMouseOver" @portMouseOut="onPortMouseOut" /> -->
        </svg>
      </g>      
    </g>
  </g>
</template>
<style lang="scss">
text {
  font-size: 10;
}
</style>