<script setup lang="ts">
import { useSettingStore } from "@/stores/setting-store";
import { computed, ref } from "vue";

const settingStore = useSettingStore();

settingStore.initSetting();

const userSetting = ref(settingStore.userSetting);

const props = defineProps<{
    setting: boolean
}>();

const emit = defineEmits(["settingClose"]);

const isSetting = computed({
  get: () => props.setting,
  set: () => emit("settingClose"),
});

</script>

<template>
    <div class="MainSetting">
        <q-dialog
        v-model="isSetting"
        >
        <q-card class="column " :style="{width: '500px', height: '500px'}">
            <q-card-section>
            <div class="text-h6">설정</div>
            </q-card-section>

            <q-card-section class="col q-pt-none">
                <div>
                    <label>3D 모델 헬퍼 활성화</label>
                    <q-toggle
                    v-model="userSetting.ModelHelper"
                    ></q-toggle>
                </div>
            </q-card-section>

            <q-card-actions align="right" class="text-teal settingBottom">
            <q-btn flat label="확인" v-close-popup />
            </q-card-actions>
        </q-card>
     </q-dialog>
    </div>
</template>

<style lang="scss">
    .MainSetting{
        .settingBottom{
            background-color: #919191;
        }
    }
</style>