/**
 * Link 정보 클래스
 * @class
*/
class LinkInfo {
  
  location: string;
  d: string;
  /**
   * @param {string} location 선 위치 (start, mid, end) - (현재 사용 안함)
   * @param {string} d path값
   */
  constructor(location = "", d = "") {
    this.location = location;
    this.d = d;
  }
}

export default LinkInfo;
