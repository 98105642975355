<script setup lang="ts">
import { ref, computed } from "vue";

import TopBarComponent from "@/components/common/TopBar/TopBarComponent.vue";
import type { QTableProps } from "quasar";

const name = ref("");
const description = ref("");

const rows = [
  { email: "이중 단열 유리", name: "30", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
  { email: "이중 단열 유리", name: "30", birthDate: "2", gender: "60", division: "UNL-R-9-30", regDate: "20220711" },
];

const columns: QTableProps["columns"] = [
  { name: "email", label: "유리 종류", field: "email", align: "left" },
  { name: "name", label: "SHGC[g]", field: "name", align: "left" },
  { name: "birthDate", label: "투과율", field: "birthDate", align: "left" },
  { name: "regDate", label: "제거", field: "regDate", align: "left" },
];

const rows1 = [
  { email: "LED 일자등", name: "30", birthDate: "2", gender: "60", regDate: "20220711" },
  { email: "LED 직부등", name: "30", birthDate: "2", gender: "60", regDate: "20220711" },
];

const columns1: QTableProps["columns"] = [
  { name: "email", label: "차양 종류", field: "email", align: "center" },
  { name: "name", label: "설치 위치", field: "name", align: "center" },
  { name: "birthDate", label: "차양 색상", field: "birthDate", align: "center" },
  { name: "gender", label: "차양 작동 방식", field: "gender", align: "center" },
  { name: "regDate", label: "제거", field: "regDate", align: "center" },
];

const tab = ref("deviceLight");
</script>

<template>
  <div class="WindoorContentsComponent">
    <div style="padding: 40px 40px 40px 0px">
      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 창호 명칭 : </span>
        <q-input label-color="black" outlined v-model="name" placeholder="창호 명칭" class="col-10" maxlength="26" />
      </div>

      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 창호 설명 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="창호 설명" class="col-10" maxlength="26" />
      </div>

      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 창호 타입 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="창호 타입" class="col-3" maxlength="26" />

        <span class="col-1" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 열관류율 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="열관류율" class="col-3" maxlength="26" />
      </div>

      <div style="margin: 30px 0 0 50px">
        <label style="font-size: 14px; font-weight: bold">[ 유리 정보 ]</label>
        <q-table :rows="rows" :columns="columns" row-key="name" virtual-scroll bordered style="margin: 10px 0 0px 0" hide-pagination>
          <template v-slot:header-cell="props">
            <q-th :props="props" style="font-weight: bolder; font-size: 13px">
              {{ props.col.label }}
            </q-th>
          </template>

          <template v-slot:body-cell-regDate="props">
            <q-td :props="props">
              <q-btn round icon="delete" size="0.9em" />
            </q-td>
          </template>
        </q-table>
      </div>

      <div style="margin: 30px 0 0 50px">
        <label style="font-size: 14px; font-weight: bold">[ 차양 정보 ]</label>
        <q-table :rows="rows1" :columns="columns1" row-key="name" virtual-scroll bordered style="margin: 10px 0 0px 0" hide-pagination>
          <template v-slot:header-cell="props">
            <q-th :props="props" style="font-weight: bolder; font-size: 13px">
              {{ props.col.label }}
            </q-th>
          </template>

          <template v-slot:body-cell-regDate="props">
            <q-td :props="props">
              <q-btn round icon="delete" size="0.9em" />
            </q-td>
          </template>
        </q-table>
      </div>

      <div style="margin: 50px 0 0 50px; border: 1px solid #ffffff; padding: 20px" class="borderSection">
        <label style="font-size: 15px; font-weight: bold" class="FormsubTitle">모델 리스트</label>
        <q-tabs v-model="tab" narrow-indicator align="left" active-color="primary" indicator-color="primary" style="margin: 0 0 10px 0">
          <q-tab name="deviceLight" label="창호" />
          <q-tab name="sensor" label="차양" />
          <q-tab name="door" label="문" />
        </q-tabs>

        <div>
          <q-tab-panels v-model="tab" animated transition-prev="jump-up" transition-next="jump-up" stretch shrink>
            <q-tab-panel name="deviceLight">
              <div>
                <!-- <q-table dense title="[테이블 1]" :rows="rows" :columns="columns" row-key="name" /> -->
                <q-table :rows="rows" :columns="columns" row-key="name" virtual-scroll bordered hide-pagination>
                  <template v-slot:header-cell="props">
                    <q-th :props="props" style="font-weight: bolder; font-size: 13px">
                      {{ props.col.label }}
                    </q-th>
                  </template>
                </q-table>
              </div>
            </q-tab-panel>

            <q-tab-panel name="sensor">
              <div>
                <!-- <q-table dense title="[테이블 2]" :rows="rows" :columns="columns" row-key="name" /> -->
                <q-table :rows="rows" :columns="columns" row-key="name" virtual-scroll bordered hide-pagination>
                  <template v-slot:header-cell="props">
                    <q-th :props="props" style="font-weight: bolder; font-size: 13px">
                      {{ props.col.label }}
                    </q-th>
                  </template>
                </q-table>
              </div>
            </q-tab-panel>

            <q-tab-panel name="door">
              <div>
                <!-- <q-table dense title="[테이블 1]" :rows="rows" :columns="columns" row-key="name" /> -->
                <q-table :rows="rows" :columns="columns" row-key="name" virtual-scroll bordered hide-pagination>
                  <template v-slot:header-cell="props">
                    <q-th :props="props" style="font-weight: bolder; font-size: 13px">
                      {{ props.col.label }}
                    </q-th>
                  </template>
                </q-table>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.WindoorContentsComponent {
  .FormsubTitle {
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }
  .contentsContainer {
    p:nth-child(1) {
      font-size: 15px;
      font-weight: bold;
      padding: 5px;
    }
  }
  .borderSection {
    border: 1px solid white;
    padding: 30px 30px 10px 30px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    label:nth-child(1) {
      position: absolute;
      top: -19px;
      background-color: #474c5b;
    }
  }
  .q-table__title {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
