<script setup lang="ts">
import { useDeviceStore } from "@/stores/device-store";
import DeviceAttrComponent from "./DeviceAttrComponent.vue";



const deviceStore = useDeviceStore();

const devAttrList = deviceStore.devAttrList;

</script>

<template>
    <div class="DeviceComponent">
        <DeviceAttrComponent :attrData="devAttrList"></DeviceAttrComponent>
    </div>
</template>

<style lang="scss">
.DeviceComponent{
    height: 100%;
}
</style>