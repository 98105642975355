import { Expose, Type } from "class-transformer";
import NodeTypeByEvent from "./node-type-by-events";
import "reflect-metadata";

/**
 * Scenario Node Type 클래스 (NODE_TYPES 테이블과 매핑)
 * @class
 */
class ScenarioNodeType {
  nodeTypeName?: string;
  parentNodeTypeCode?: string;
  eventOccurNodeYn?: string;
  eventUserDefineYn?: string;
  eventOccurCondTypeCode?: string;

  @Expose({ name: "inputPortCnt" })
    inputPortCount = 0;

  @Expose({ name: "outputPortCnt" })
    outputPortCount = 0;
  horizSize = 0;
  vertiSize = 0;
  dPointSettingYn?: number;
  mqttTopicSettingYn?: number;
  nodeTypeDesc?: string;
  sortOrder?: number;

  @Type(() => NodeTypeByEvent)
    nodeTypeByEvents: NodeTypeByEvent[] = [];

  constructor(readonly nodeTypeCode: string) {

  }
}

export default ScenarioNodeType;