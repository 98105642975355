/**
 * Profile 클래스
 * @class
 */
class User {
  userId?: number;
  name?: string;
  email?: string;
  telno?: string;
  status?: string;
  regDate?: string;
  regUserId?: string;
  modDate?: string;
  modUserId?: string;
  constructor() {
    // this.userId = userInfo.userId;
    // this.name = userInfo.name;
    // this.email = userInfo.email;
    // this.telno = userInfo.telno;
    // this.status = userInfo.status;
    // this.regDate = userInfo.regDate;
    // this.regUserId = userInfo.regUserId;
    // this.modDate = userInfo.modDate;
    // this.modUserId = userInfo.modUserId;
  }
}

export default User;
