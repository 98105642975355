import { NodeElementType } from "@/global/enums";
import type { OptionsType } from "@/global/types";
import type ScenarioNode from "@/models/entity/scenario-node";
import CommonUtils from "@/utils/common-util";
import type AbstractNodeConfig from "../config/abstract-node-config";
import type INodeSetting from "../setting/node-setting";
import type ScenarioConfigurePresenseSensorNodeSetting from "../setting/scenario-configure-presence-sensor-node-setting";
import type ScenarioExecutorNodeSetting from "../setting/scenario-executor-node-setting";
import type ScenarioSensitiveSensorDeviceNodeSetting from "../setting/scenario-presence-sensor-device-node-setting";
import AbstractNodeInfo from "./abstract-node-info";
import type { AbtractSelectionNodeItemEvent } from "./node-item-info";

class ScenarioNodeInfo extends AbstractNodeInfo {
  public scenarioNode: ScenarioNode;
  constructor( id: string, x: number, y: number, scenarioNode: ScenarioNode, nodeConfig: AbstractNodeConfig, nodeSetting: INodeSetting | null = null) {    
    super( id, x, y, nodeConfig, nodeSetting);    
    this.scenarioNode = scenarioNode;
  }

  update() {
    this.scenarioNode.nodeName = this.name;
  }

  getOptionsTypes() {

    let events: AbtractSelectionNodeItemEvent[] | null = null;

    const options: OptionsType[] = [];

    switch (this.nodeConfig.nodeElementType) {
      case NodeElementType.Scenario_Executor:
        events = (this.nodeSetting as ScenarioExecutorNodeSetting).events;
        break;
      case NodeElementType.Scenario_Presence_SensorDevice:
        events = (this.nodeSetting as ScenarioSensitiveSensorDeviceNodeSetting).events;
        break;
      case NodeElementType.Scenario_Configure_Presense_Sensor:
        events = (this.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).events;
        break;
    }

    if (!CommonUtils.isNullOrEmpty(events)) {
      for (const event of events) {
        options.push({ label: event.nodeTypeByEvent.eventName ?? "", value: event.nodeTypeByEvent.eventCode.toString() });
      }
    }

    return options;
  }

  getSelectionNodeItemEvents() {
    let events: AbtractSelectionNodeItemEvent[] | null = null;

    switch (this.nodeConfig.nodeElementType) {
      case NodeElementType.Scenario_Executor:
        events = (this.nodeSetting as ScenarioExecutorNodeSetting).events;
        break;
      case NodeElementType.Scenario_Presence_SensorDevice:
        events = (this.nodeSetting as ScenarioSensitiveSensorDeviceNodeSetting).events;
        break;
      case NodeElementType.Scenario_Configure_Presense_Sensor:
        events = (this.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).events;
        break;
    }

    return events;
  }

  getSelectedOptionValues() {
    const events = this.getSelectionNodeItemEvents();

    const options: string[] = [];

    if (!CommonUtils.isNullOrEmpty(events))
      for (const event of events) {

        if (!event.isChecked)
          continue;

        options.push(event.nodeTypeByEvent.eventCode.toString());
      }

    return options;
  }

  deleteSchedule() {

  }
}

export default ScenarioNodeInfo;