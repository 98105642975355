import type DeviceTypeModule from "./device-type-module";
import type ImageFile from "./image-file";

class LightDeviceType {
  devClsfyCode?: string;  
  devTypeName?: string;
  devAsetCode?: string;
  height = 0;
  horizSize = 0;
  imageFileSeq?: number;
  modDate?: string;
  modUserId?: number;
  modelDisplayType?: string;
  moduleCnt = 0;
  moduleCtrlMode?: string;
  regDate?: string;
  regUserId?: number;
  shapeType?: string;
  vertiSize = 0;
  imageFile?: ImageFile;
  modules?: DeviceTypeModule[];

  constructor(readonly devTypeCode: string) {

  }
}

export default LightDeviceType;