import { Type } from "class-transformer";
import "reflect-metadata";
import type GrpMember from "./grp_member";


/**
 * 제어 그룹 클래스
 * @class
 */
class CtrlGrp {
  members?: GrpMember[];
  grpDesc?: string;
  grpName?: string;
  grpSeq?: number;
  grpTypeCode?: string;
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;
  siteSeq?: number;
  zoneSeq?: number;  
}
export default CtrlGrp;
