class Code {
  code?:string;
  codeName?:string;
  grpCode?:string;
  otherInfo1?:string;
  otherInfo2?:string;
  otherInfo3?:string;
  otherInfo4?:string;
  otherInfo5?:string;
  pcode?:string;
  remarks?:string;
  sortOrder?:number;
  useYn?:string;
  
  constructor() {

  }
}

export default Code;