<script setup lang="ts">
import { ref, computed, onMounted, reactive, onUnmounted } from "vue";
// import EditModule from "@/composables/edit-module";

import NewUspaceModal from "@/components/common/TopBar/Modal/NewUspaceModal.vue";
import NoneSelectModal from "@/components/common/TopBar/Modal/NoneSelectModal.vue";
import NewLightGroup from "@/components/common/TopBar/NewLightGroup.vue";

import { Depth1_Kind, Depth2_Kind, EditFunc, NodeAlignMode, NodeEventType, NodeIntervalMode, TopBar_Action_Kind, ZoneTypeCode } from "@/global/enums";
import Menu from "@/models/menu";
import TopbarMenu from "@/settings/topbar-menu.json";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useMenuStore } from "@/stores/menu-store";
import { useEditStore } from "@/stores/edit-store";
import { useSceneStore } from "@/stores/scene-store";
import CommonUtils from "@/utils/common-util";
import { useQuasar } from "quasar";
import type Zone from "@/models/entity/zone";
import type Site from "@/models/site";
import { useScenarioStore } from "@/stores/scenario-store";
import { useMaterialsStore } from "@/stores/materials-store";
import { useUndoRedo } from "@/composables/undo-redo-manager";

const nodeEditorStore = useNodeEditorStore();
const editStore = useEditStore();
const menuStore = useMenuStore();
const sceneStore = useSceneStore();
const scenarioStore = useScenarioStore();
const materialStore = useMaterialsStore();
const undoRedo = useUndoRedo();

const noneModal = ref(false);
const RspaceModal = ref(false); /*  */
const CspaceModal = ref(false);
const UspaceModal = ref(false);

const lightGroup = ref(false);
const lightGroupModal = ref(false);

const sceneModal = ref(false);

const importDisabled = ref(false);

const $q = useQuasar();

// const selectMainKey = computed(() => {
//   return editModule?.SelectMainKey;
// });
// const selectFuncKey = computed(() => {
//   return editModule?.EditFuncKey;
// });

const mainMenu = computed(() => {
  return menuStore.menuList;
});

const zoneActionMenus: Menu[] = setTopbarMenu(TopbarMenu);

//const undoCount = ref(undoRedo.undoCount);

// let menu = new Menu();
// menu.icon = "ico_존추가_nor.png";
// menu.name = "존추가";
// menu.menuKind = Depth3_Kind.존추가;
// menu.isEnabled = true;

// zoneActionMenus.push(menu);

// menu = new Menu();
// menu.icon = "ico_조명자동추가_nor.png";
// menu.name = "조명자동추가";
// menu.menuKind = Depth3_Kind.조명자동추가;
// menu.isEnabled = true;
// zoneActionMenus.push(menu);

const zoneActionMenusRef = reactive(zoneActionMenus);

onMounted(() => {
  window.addEventListener("keydown", keydown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", keydown);
});

function keydown(event: KeyboardEvent) {
  if (!event.ctrlKey)
    return;

  const keyLower = event.key.toLowerCase();

  switch (keyLower) {
    case "z":
      editStore.undo();
      break;
    case "y":
      editStore.redo();
      break;
  }
}

function setTopbarMenu(menuList: Array<any>) {
  const menus: Menu[] = [];
  menuList.map((v) => {
    const menu = new Menu();
    menu.name = v.name;
    menu.isEnabled = v.isEnabled;
    menu.menuKind = v.menuKind;
    menu.key = v.key;

    if (v.path) {
      menu.path = v.path;
    }
    if (v.icon) {
      menu.icon = v.icon;
    }
    if (v.childMenu) {
      menu.childMenu = setTopbarMenu(v.childMenu) as Array<Menu>;
    }

    menus.push(menu);
  });

  return menus;
}

function modalValueChange(value: boolean, type: string) {
  // if (type === "R") {
  //   CspaceModal.value = value;
  // } else if (type === "C") {
  //   UspaceModal.value = value;
  // }
  UspaceModal.value = value;
  noneModal.value = false;
}

function keyCheck(menu: Menu) {
  mainMenu.value.map((v) => {
    if (v.path === editStore.SelectMainKey) {
      const result = funcKeyCheck(editStore.SelectMainKey as string, menu);
      console.log(result);
      return result;
    }
  });

  return true;
}

function funcKeyCheck(selectMainKey: string, menu: Menu) {
  console.log(menu.path, selectMainKey);
  if (menu.path === selectMainKey) {
    return false;
  }

  return true;
}

function selectSpaceCheck(BtnName: string) {
  // console.log(BtnName);
  // console.log("selectspacecheck", editStore.selectZoneInfo.zoneTypeCode);

  switch(BtnName){
    case "공간추가":{
      uzoneAddModal();
      setFuncKey(EditFunc.Zone);
      break;
    }
    case "그룹추가":{
      addGroup();
      setFuncKey(EditFunc.Group);
      break;
    }
    case "씬추가": {
      addScene();
      setFuncKey(EditFunc.Scene);
      break;
    }
  }
}

function onTopBarMenuClick(menu: Menu) {

  //메뉴 밸리데이션 체크
  if(!menuValidator(menu))
    return;

  menuStore.selectionTopBarSubMenu(menu);  
  
  //메뉴 액션 수행
  switch (menu.menuKind) {
    case TopBar_Action_Kind.공간추가:
      uzoneAddModal();
      break;
    case TopBar_Action_Kind.조명자동추가:
      break;
    case TopBar_Action_Kind.그룹추가:
      addGroup();
      break;
    case TopBar_Action_Kind.그룹멤버편집:
      modifyGroupMember(menu);
      break;
    case TopBar_Action_Kind.씬추가:
      addScene();
      break;
    case TopBar_Action_Kind.시나리오추가:      
      addNewScenario();      
      break;
    case TopBar_Action_Kind.시나리오삭제:
      delScenario();
      break;
    case TopBar_Action_Kind.용도프로필추가:
      materialStore.addProfile();
      break;
    case TopBar_Action_Kind.씬ONOFF:
      if(menu.isSelected === true) menu.isSelected = false;
      else menu.isSelected = true;
      editStore.reverseSceneGroupOnOFF();
      break;
  }
}

function menuValidator(menu: Menu) {
  try {
    let isValidation = true;

    if (menu.menuKind === TopBar_Action_Kind.공간추가) {

      if (CommonUtils.isNullOrEmpty(editStore.selectZoneInfo) || CommonUtils.isNullOrEmpty(editStore.selectZoneInfo.zoneTypeCode) || editStore.selectZoneInfo.zoneTypeCode === "") {
        noneModal.value = true;
        isValidation = false;
      }
      else {
        if (editStore.selectSpaceInfo?.zoneTypeCode === ZoneTypeCode.단위존)
          showErrorDialog("대표존 또는 묶음존을 먼저 선택하세요.");
      }
    } else if (menu.menuKind === TopBar_Action_Kind.조명자동추가 || menu.menuKind === TopBar_Action_Kind.그룹추가 || menu.menuKind === TopBar_Action_Kind.그룹멤버편집) {

      if (CommonUtils.isNullOrEmpty(editStore.selectSiteInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || editStore.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존) {
        showErrorDialog("단위존을 먼저 선택하세요.");
      }

      if (menu.menuKind === TopBar_Action_Kind.그룹멤버편집 && CommonUtils.isNullOrEmpty(editStore.selectGroupInfo)) {
        showErrorDialog("그룹 편집을 하려면 그룹을 선택하세요.");
      }
    }

    return isValidation;
  } catch {
    return false;
  }
}

function uzoneAddModal(){
  if ((editStore.selectZoneInfo as Zone).zoneTypeCode === "" || (editStore.selectZoneInfo as Zone).zoneTypeCode === null)
    noneModal.value = true;
  else
    UspaceModal.value = true;
}

function addGroup() {
  editStore.addNewGroup((editStore.selectSiteInfo as Site).siteSeq, (editStore.selectSpaceInfo as Zone).zoneSeq);  
}

function modifyGroupMember(menu: Menu) {  
  
  console.log(`modifyGroupMember Menu isSelected ==> ${menu.isSelected}`);
  
  if(!menu.isSelected)
    editStore.updateCurrentGroupMember();
  else
    editStore.displayGroupMembers();
}



function addScene(){
  sceneStore.addNewScene((editStore.selectSiteInfo as Site).siteSeq, (editStore.selectSpaceInfo as Zone).zoneSeq);
}

function addNewScenario() {
  if(editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존) {
    showErrorDialog("단위존을 선택하세요.");
    return;
  }

  scenarioStore.addNewScenario();
}

function delScenario() {

  if(editStore.selectSpaceInfo?.zoneTypeCode !== ZoneTypeCode.단위존) {
    showErrorDialog("단위존을 선택하세요.");
    return;
  }
        
  if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) || CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.scenarios) || editStore.selectSpaceInfo.scenarios.length <= 1) {
    showErrorDialog("시나리오를 삭제 할 수 없습니다. 시나리오는 1개 이상 존재해야 합니다.");
    return;
  }

  scenarioStore.delScenario();
}

function spaceDisabled() {
  if(CommonUtils.isNullOrEmpty(editStore.selectZoneInfo))
    return;

  if (editStore.selectZoneInfo.zoneTypeCode === "R" || editStore.selectZoneInfo.zoneTypeCode === "U") {
    return true;
  }

  return false;
}

function openGroupCreateModal() {
  lightGroup.value = false;
  lightGroupModal.value = true;
  console.log(lightGroup, lightGroupModal);
}

function setFuncKey(key: string) {
  editStore.setEditFuncKey(key);
}

function newGroupModalFalse() {
  lightGroupModal.value = false;
}

function newSceneModalFalse() {
  sceneModal.value = false;
}


function onNodesAlign(nodeAlignMode: NodeAlignMode) {
  nodeEditorStore.nodesAlign(nodeAlignMode);

  const nodeIds = nodeEditorStore.getSelectedNodes(false).map(n => n.id);

  if (menuStore.selectedMenu?.menuKind === Depth2_Kind.조명배치)
    editStore.updateDPoints(NodeEventType.Moved, nodeIds);    
  else if (menuStore.selectedMenu?.menuKind === Depth1_Kind.시나리오)
    scenarioStore.updateNodesPoint(nodeIds);
    
}

function onNodesInterval(nodeIntervalMode: NodeIntervalMode) {
  nodeEditorStore.nodesInterval(nodeIntervalMode);

  const nodeIds = nodeEditorStore.getSelectedNodes(false).map(n => n.id);
  
  if (menuStore.selectedMenu?.menuKind === Depth2_Kind.조명배치)
    editStore.updateDPoints(NodeEventType.Moved, nodeIds);
  else if (menuStore.selectedMenu?.menuKind === Depth1_Kind.시나리오)
    scenarioStore.updateNodesPoint(nodeIds);
}

function showErrorDialog(message: string) {
  $q.dialog({ title: "오류", message: message, ok: "OK" })
    .onOk(() => {
    })
    .onCancel(() => {
    })
    .onDismiss(() => {
    });

  throw new Error(message);
}

function onUndo() {
  editStore.undo();    
}

function onRedo() {
  editStore.redo();  
}

async function onSave() {
  if (CommonUtils.isNullOrEmpty(editStore.selectSiteInfo) || CommonUtils.isNullOrEmpty(editStore.selectZoneInfo))
    return;

  try {
    if (editStore.modelFileList.length > 0) {
      await editStore.modifyModelFileList();
    } else {
      await editStore.modifyZone(editStore.selectSiteInfo.siteSeq, editStore.selectZoneInfo);

    }

  } catch (err) {

    const error = err as unknown as Error;

    showErrorDialog(error.message);
  }
}
</script>

<template>
  <div class="TopBarComponent">
    <q-bar class="ContentTop">      
      <div class="topbarContentList" v-for="topbarMenu in menuStore.topbarList" :key="topbarMenu.menuKind">
        <div class="spaceFunc">
          <div class="spaceFuncLabel"><strong>{{ topbarMenu.name }}</strong></div>
          <div class="spaceFuncButton">
            <q-btn
              dense
              flat
              size="20px"
              v-for="m in topbarMenu.childMenu"
              :disable="menuStore.selectedMenu?.menuKind === m.key ? !m.isEnabled : m.isEnabled"
              :key="m.menuKind"
              @mouseover="m.isOver = true"
              @mouseout="m.isOver = false"
              @click="onTopBarMenuClick(m as Menu)"
              
            >            
              <q-tooltip anchor="bottom middle" self="top middle" :offset="[10, 10]">
                <strong>{{m.name + " " }}</strong>
                <string v-if="m.menuKind === TopBar_Action_Kind.씬ONOFF">현재 :{{ editStore.sceneGroupONOFF === true ? 'ON' : 'OFF' }}</string>
              </q-tooltip>
              <q-icon :name="`img:${m.image}`"  />
            </q-btn>
          </div>
        </div>
        <q-separator class="divider" vertical inset size="1px"/>

      </div>

      <q-space class="emptyFunctionList" />
      <q-separator class="divider" vertical inset />

      <div class="commonFunctionList">

        <div class="spaceFuncLabel"><strong>{{ "메뉴" }}</strong></div>
        <div class="menuFunc">
          <q-btn dense flat size="20px">
            <q-icon :name="'img:ico_menu_정렬_nor.png'" />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Left)">
                  <q-item-section>왼쪽 맞춤</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Center)">
                  <q-item-section>가운데 맞춤</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Right)">
                  <q-item-section>오른쪽 맞춤</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Top)">
                  <q-item-section>위쪽 맞춤</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Middle)">
                  <q-item-section>중간 맞춤</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onNodesAlign(NodeAlignMode.Bottom)">
                  <q-item-section>아래쪽 맞춤</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup @click="onNodesInterval(NodeIntervalMode.Horizontal)">
                  <q-item-section>가로간격 동일하게</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="onNodesInterval(NodeIntervalMode.Vertical)">
                  <q-item-section>세로간격 동일하게</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-btn dense flat size="20px" style="line-height: 10px" @click="onUndo" :disable="!undoRedo.isCanUndo.value">
            <q-icon :name="'img:ico_menu_undo_nor.png'" />            
            <label style="margin: 0px; font-size: 14px; color: black;">{{undoRedo.undoCount}}</label>
          </q-btn>                              
          <q-btn dense flat size="20px" style="line-height: 10px" @click="onRedo" :disable="!undoRedo.isCanRedo.value">
            <q-icon :name="'img:ico_menu_redo_nor.png'" />
            <label style="margin: 0px; font-size: 14px; color: black;">{{undoRedo.redoCount}}</label>
          </q-btn>
          <q-btn dense flat size="20px" @click="onSave">
            <q-icon :name="'img:ico_menu_저장_nor.png'" />
          </q-btn>
        </div>
      </div>
    </q-bar>

    <NewLightGroup v-if="lightGroup === true" @lightCancel="lightGroup = false" @groupModal="openGroupCreateModal"> </NewLightGroup>
    <NoneSelectModal :spaceModal="noneModal" :type="editStore.selectZoneInfo === null ? null : editStore.selectZoneInfo.zoneTypeCode" @update:spaceModal="modalValueChange"> </NoneSelectModal>
    <NewUspaceModal :spaceModal="UspaceModal" :type="(editStore.selectZoneInfo?.zoneTypeCode as string)" @update:spaceModal="modalValueChange"> </NewUspaceModal>


  </div>
</template>

<style lang="scss">
.TopBarComponent {
  background-color: #f2f4fb;
  .ContentTop {
    height: 67px;
    border-bottom: 1px solid #c1c1c1;
    padding: 0;

    .topbarContentList {
      display: flex;
      height: 100%;
      margin-left: 0;
      .spaceFunc {
        min-width: 100px;

        .spaceFuncLabel {
          height: 20px;
          min-height: 20px;
          text-align: center;
          strong{
            font-size: 12px;
            font-weight: bold;
            color: #404040;

          }
        }

        .disableLabel {
          strong{
            font-size: 12px;
            font-weight: bold;
            color: #b8bed0;
          }
        }

        .spaceFuncButton {
          width: 100%;
          justify-content: center;
          display: flex;
          min-height: 50px;
        }
      }
    }

    .commonFunctionList {
      margin: 0;
      
      .spaceFuncLabel {
          height: 20px;
          min-height: 20px;
          text-align: center;
          padding: 2px;
          strong{
            font-size: 12px;
            font-weight: bold;
            color: #404040;

          }
        }
      .menuFunc {
        display: flex;
        align-items: center;
        height: 50px;
        min-height: 50px;
      }
    }

    .emptyFunctionList {
      margin: 0px;
      width: 100%;
      height: 100%;
    }
  }

  .divider{
      background-color: #c1c1c1;

    }
}
</style>
