import type Code from "@/models/entity/code";
import { defineStore } from "pinia";
import type { OptionsType } from "@/global/types";
import CommonUtils from "@/utils/common-util";


export const useCommonStore = defineStore("commonCode", {
  state: () => ({
    commonList: [] as Code[],
    groupByCodeList: new Map<string | undefined, Code[]>(),
    errorMessage: null as string | null
  }),
  getters: {

    /**
     * 그룹코드로 코드 리스트를 가져온다.
     * @returns 
     */
    getCodeList() {
      return (grpCode: string) => {
        return this.groupByCodeList.get(grpCode) ?? [];
      };
    },    

    /**
     * 그룹코드로 코드 리스트를 OptionType 리스트로 변환하여 가져온다.
     * @returns 
     */
    getOptionTypes() {
      return (grpCode: string) => {
        const codeList = this.getCodeList(grpCode);
        return codeList?.map((c) => ({
          label: c.codeName,
          value: c.code,
        })) as OptionsType[];
      };
    },
    /**
     * 코드 이름을 반환
     * @returns 
     */
    getFindCodeToName() {
      return (grpCode: string, code: string | null) => {

        if(CommonUtils.isNullOrEmpty(code))
          return "";
          
        const result = this.commonList.find(v => v.grpCode === grpCode && v.code === code);
        return result?.codeName ?? "";
      };
    },
  },
  actions: {

    /**
     * 서버에서 코드 리스트를 불러온다.
     */
    async loadCodes() {
      const result = await this.apiServices.etcApiService.getCodes();
      this.setCommonCodes(result);
      console.log(`groupByCodeList =====> ${this.groupByCodeList}`);      
    },

    /**
     * 코드 리스트와 코드 그룹화를 수행하여 스토어에 저장
     * @param commonCodes
     */
    setCommonCodes(commonCodes: Code[]) {      
      this.commonList = commonCodes;
      this.groupByCodeList = CommonUtils.groupBy(this.commonList, c => c.grpCode);
    },

    /**
     * 현재 화면의 에러 메시지를 설정한다.
     * @param msg 
     */
    setErrorMessage(msg: string | null) {
      this.errorMessage = msg;
    }    
  }
});
