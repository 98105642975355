import { useEditStore } from "@/stores/edit-store";

class ZoneSmlWindoor{
  azimuthCode?:	string;
  blindEquipCode?:	string;
  bottomHeight?:	number;
  glassTypeCode?:	string;
  horizSize?:	number;
  siteSeq?:	number;
  vertiSize?:	number;
  windoorName?:	string;
  windoorQty?:	number;
  windoorSeq?:	number;
  zoneSeq?:	number;

  constructor(){
    this.siteSeq = useEditStore().selectSpaceInfo?.siteSeq;
    this.zoneSeq = useEditStore().selectSpaceInfo?.zoneSeq;
    this.windoorSeq = Math.floor(Math.random() * 10000 + Math.random() * 100);
  }
}  

export default ZoneSmlWindoor;