<script setup lang="ts">
import ScenarioLeftComponent from "@/components/Scenario/ScenarioLeftComponent.vue";
import ScenarioContentsComponent from "@/components/Scenario/ScenarioContentsComponent.vue";
import ScenarioRightComponent from "@/components/Scenario/ScenarioRightComponent.vue";
import { ref } from "vue";

const spliterLeftWidth = ref(10);
const spliterRightWidth = ref(15);
const spliterLeftDisabled = ref(false);
const spliterRightDisabled = ref(false);
const spliterLeftLimited = ref([10, 10]);
const spliterRightLimited = ref([15, 15]);


function LeftExpandedBtn() {
  if (spliterLeftDisabled.value === false) {
    spliterLeftWidth.value = 1;
    spliterLeftDisabled.value = true;
    spliterLeftLimited.value = [1, 1];
    (document.getElementsByClassName("leftTreeComponent")[0] as HTMLElement).style.minWidth = "5px";

  } else {
    spliterLeftWidth.value = 10;
    spliterLeftDisabled.value = false;
    spliterLeftLimited.value = [10, 10];
    (document.getElementsByClassName("leftTreeComponent")[0] as HTMLElement).style.minWidth = "192px";

  }
}

function RightExpandedBtn() {
  if (spliterRightDisabled.value === false) {
    spliterRightWidth.value = 1;
    spliterRightDisabled.value = true;
    spliterRightLimited.value = [1, 1];
    (document.getElementsByClassName("rightComponent")[0] as HTMLElement).style.minWidth = "5px";

  } else {
    spliterRightWidth.value = 15;
    spliterRightDisabled.value = false;
    spliterRightLimited.value = [15, 15];
    (document.getElementsByClassName("rightComponent")[0] as HTMLElement).style.minWidth = "288px";

  }
}
</script>

<template>
  <q-page class="ScenarioComponent">
    <q-splitter
      v-model="spliterLeftWidth"
      :disable="spliterLeftDisabled"
      class="splitterClass"
      :limits="spliterLeftLimited"
      separator-class="leftSeparator"
      before-class="leftTreeComponent"
    >
      <template v-slot:before>
        <ScenarioLeftComponent @expaned="LeftExpandedBtn"></ScenarioLeftComponent>
      </template>
      <template v-slot:after>
        <q-splitter
          v-model="spliterRightWidth"
          :disable="spliterRightDisabled"
          class="splitterClass"
          :limits="spliterRightLimited"
          reverse
          separator-class="rightSeparator"
          after-class="rightComponent"
        >
          <template v-slot:before>
            <ScenarioContentsComponent></ScenarioContentsComponent>
          </template>
          <template v-slot:after>
            <ScenarioRightComponent @expaned="RightExpandedBtn"></ScenarioRightComponent>
          </template>
        </q-splitter>
      </template>
    </q-splitter>
  </q-page>
</template>

<style lang="scss">
.ScenarioComponent{
  .splitterClass {
    background-color: #f2f4fb;
    height: calc(100vh - 54px);
    .leftTreeComponent{
      border-right: 1px solid #c1c1c1;
      min-width: 192px;
    }

    .rightComponent{
      border-left: 1px solid #c1c1c1;
      min-width: 288px;
    }

    .leftSeparator {
      width: 0 !important;

      .q-splitter__separator-area {
        @if (v-bind(spliterLeftDisabled)) {
          width: 1px !important;
          left: 0;
          right: 0;
        }
      }
    }

    .rightSeparator {
      width: 0 !important;
  
      .q-splitter__separator-area {
        @if v-bind(spliterRightDisabled) {
          width: 1px !important;
          left: 0;
          right: 0;
        }
      }
    }
  }
}

</style>
