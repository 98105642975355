import { HttpMethods } from "@/global/enums";
import Site from "@/models/site";
import type { RequestParameter } from "@/net/rest-client";
import type RestClient from "@/net/rest-client";
import type { Axios, AxiosResponse } from "axios";
import { inject } from "vue";
import type { VueCookies } from "vue-cookies";
import BaseApiService from "./base-api-service";
import { Buffer } from "buffer";
import { useUserStore } from "@/stores/user-store";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import Zone from "@/models/entity/zone";
import Code from "@/models/entity/code";
import type ImageFile from "@/models/entity/image-file";
import type RZone from "@/models/entity/rzone";


/**
 * 사이트 관련 서비스
 * @class
 */
class SiteZoneApiService extends BaseApiService {
  constructor(restClient: RestClient) {
    super(restClient);
  }

  async getSiteList() {
    const res = await this.restClient.call<Site[]>(HttpMethods.Get, "/v1/sites", undefined, Site);

    // const detailsSites: Site[] = [];
    // const lists = JSON.parse(res.data);
    // for (let i = 0; i < lists.length; i++) {
    //   const detail = await this.getSiteDetail(lists[i].siteSeq);
    //   detailsSites.push(detail);
    // }

    // JSON.parse(res.data).map(async (v: Site) => detailsSites.push(await this.getSiteDetail(v.siteSeq)));

    //return detailsSites;
    return res.data;
  }

  async getSiteDetail(siteSeq: number) {
    const res = await this.restClient.call(HttpMethods.Get, `/v1/sites/${siteSeq}`, undefined, Site);
    return res.data;
  }

  async AddSite(site: Site){
    console.log(site);
    const requsetBody = {
      boundsPath: "test",
      centerLat: Number(site.centerLat),
      centerLng: Number(site.centerLng),
      districtCode: site.districtCode,
      horizSize: Number(site.horizSize),
      imageFileSeq: Number(site.imageFileSeq),
      modDate: new Date(),
      modUserId: Number(useUserStore().user?.userId),
      regDate: new Date(),
      regUserId: Number(useUserStore().user?.userId),
      siteName: site.siteName,
      siteArea: site.siteArea,
      siteClsfyCode: site.siteClsfyCode,
      siteDesc: site.siteDesc,
      siteSeq: Number(site.siteSeq),
      userId: Number(useUserStore().user?.userId),
      vertiSize: Number(site.vertiSize),
    };

    const requestParams: RequestParameter = {
      body: JSON.stringify(requsetBody),
      headers: {"Content-Type": "application/json"}
    };
    console.log(requestParams);

    const res = await this.restClient.call<Site>(HttpMethods.Post, "/v1/sites", requestParams, Site);
    // const res = await axios.post("/v1/sites", {body: requsetBody});
    const result = res.data;
    return result;
  }
  /**
   * 대표 공간 추가 API
   * @param site 
   */
  async addRspace(site: Site){
    // const rzone = site.zones;
    const requestParams: RequestParameter = {
      body: JSON.stringify(site)
    };
    try{

      const res = await this.restClient.call(HttpMethods.Put, `/v1/sites/${site.siteSeq}`, requestParams);
      
      console.log(res.data);

    } catch {

      return [];
    }
  }

  /**
   * 
   * @param siteSeq
   * @param zone 
   * @returns 
   */
  async modifyZone(siteSeq: number, zone: Zone){
    console.log(zone);
    const requestParams: RequestParameter = {
      body: JSON.stringify(zone),
      headers: {"Content-Type": "application/json"}
    };

    const res = await this.restClient.call<Zone>(HttpMethods.Put, `/v1/sites/${siteSeq}/zones/${zone.zoneSeq}`, requestParams, Zone);
    console.log(res);
    return res;
  }



  /**
   * 사이트 정보 수정
   * @param site NOTE - 변경 사이트 정보
   * @returns 
   */
  async modifySites(site: Site){
    const requestParams: RequestParameter = {
      body: JSON.stringify(site),
      headers: {"Content-Type": "application/merge-patch+json"}
    };
    console.log(requestParams);

    const res = await this.restClient.call(HttpMethods.Patch, `/v1/sites/${site.siteSeq}`, requestParams);
    // const res = await axios.post("/v1/sites", {body: requsetBody});
    const result = res.data;
    return result;
  }
  /**
   * 사이트 삭제 API
   * @param siteSeq NOTE - 사이트 일련번호 
   * @returns 
   */
  async removeSite(siteSeq: number){
    try {
      await this.restClient.call<Code>(HttpMethods.Delete, `/v1/sites/${siteSeq}`, undefined, Code);

      return "done";
    }  catch(e){
      return "error";
    }
  }
  
  /**
   * 대표 공간 호출 API
   * @param siteSeq NOTE - 사이트 일련변호
   * @param zoneSeq NOTE - 대표 존 일련번호
   * @returns 
   */
  async getZoneList(siteSeq: number, zoneSeq: number) {
    const res = await this.restClient.call<Zone>(HttpMethods.Get, `/v1/sites/${siteSeq}/zones/${zoneSeq}`, undefined, Zone);
    // JSON.parse(res.data).map(async (v: Site) => detailsSites.push(await this.getSiteDetail(v.siteSeq)));

    return res.data;
  }
  /**
   * 대표 공간 추가 API
   * @param siteSeq NOTE - 사이트 일련번호
   * @param rzone NOTE - 추가 대표 존 데이터
   * @returns 
   */
  async addRzone(siteSeq: number, rzone: Zone){

    const requsetBody: RequestParameter = {
      body: JSON.stringify(rzone),
      headers: {
        "Content-type": "application/json"
      }
    };
    try{
      const res = await this.restClient.call<Zone>(HttpMethods.Post, `/v1/sites/${siteSeq}/zones`, requsetBody, Zone);

      const newRZone = res.data;

      newRZone.compos = [];
      newRZone.ctrlGrps = [];
      newRZone.dpoints = [];
      newRZone.faces = [];
      newRZone.scenarios = [];
      newRZone.scenes = [];

      newRZone.childZones.map(v => {
        v.compos = [];
        v.ctrlGrps = [];
        v.dpoints = [];
        v.faces = [];
        v.scenarios = [];
        v.scenes = [];

        v.area = newRZone.area;
        v.originX = newRZone.originX;
        v.originY = newRZone.originY;
        v.originZ = newRZone.originZ;

        v.vertiSize = newRZone.vertiSize;
        v.height = newRZone.height;
        v.horizSize = newRZone.horizSize;
        v.boundsPath = newRZone.boundsPath;

      });


      await this.modifyZone(newRZone.siteSeq, newRZone);

      return res.data;

    } catch {
      console.error("RZONE ADD FAILED");
      return {};
    }


  }

  /**
   * 대표 공간 삭제 API
   * @param siteSeq NOTE - 사이트 일련번호
   * @param zoneSeq NOTE - 삭제 대표 존 일련번호
   * @returns 
   */
  async removeRzone(siteSeq: number, zoneSeq: number){
    try{
      await this.restClient.call<Zone>(HttpMethods.Delete, `/v1/sites/${siteSeq}/zones/${zoneSeq}`,undefined, Zone);

      return "done";
    } catch (e){
      return "error";
    }

  }
}

export default SiteZoneApiService;

