<script setup lang="ts">
import { computed, ref } from "vue";

import ScenarioNodeComponent from "@/components/Scenario/ScenarioNodeComponent.vue";

// import LightTreeComponent from "@/components/common/LightTreeComponent.vue";
import LightListComponent from "@/components/common/LightListComponent.vue";
import GroupTreeComponent from "@/components/common/GroupTreeComponent.vue";
import SceneTreeComponent from "@/components/common/SceneTreeComponent.vue";
import MaterialListComponent from "@/components/common/MaterialListComponent.vue";
import AlertWarningComponent from "../common/AlertWarningComponent.vue";
import { Depth2_Kind } from "@/global/enums";
import type { MenuKind, alertMessageType } from "@/global/types";

import type Zone from "@/models/entity/zone";
import { useEditStore } from "@/stores/edit-store";
import { useMenuStore } from "@/stores/menu-store";
import CommonUtils from "@/utils/common-util";
import { useNodeEditorStore } from "@/stores/node-editor-store";

const props = defineProps<{
  zone: Zone;
  upperModel: string;
  subMenuKind: MenuKind | null | undefined;
}>();

const editStore = useEditStore();
const menuStore = useMenuStore();
const nodeEditorStore = useNodeEditorStore();

const bottomExpansionLabel = computed(() => {
  const label = ref("");

  if (props.subMenuKind === Depth2_Kind.조명배치) {
    label.value = "조명 요소";
  } else if (props.subMenuKind === Depth2_Kind.그룹) {
    label.value = "그룹 목록";
  } else if (props.subMenuKind === Depth2_Kind.씬) {
    label.value = "씬 목록";
  } else if (props.subMenuKind === Depth2_Kind.공간편집) {
    label.value = "재료 목록";
  }

  return label;
});

const expanded = ref(true);
const bottomExpanded = ref(true);

const isRemoveZone = ref(false);
const removeMessage = ref({
  message: "",
  removeTarget: "",
} as alertMessageType);

const selectedZoneSeqRef = computed(() => {
  return editStore.selectSpaceInfo?.zoneSeq ?? -1;
});

const menuKind = computed(() => props.subMenuKind);

const filter = ref("");
const filterRef = ref<null | { focus: () => null }>(null);

/**
 * 필터 리셋
 */
function resetFilter() {
  filter.value = "";
}

/**
 * 존 필터 로직
 * @param zone 
 * @param filter 
 */
function filterMethod(zone: Zone, filter: string) {
  const filt = filter.toLowerCase();

  if(CommonUtils.isNullOrEmpty(zone.zoneName))
    return true;
    
  const returnValue: boolean = zone.zoneName !== "" && zone.zoneName.toLowerCase().indexOf(filt) > -1;
  return returnValue;
}

/**
 * 트리에서 존 선택
 * @param zoneSeq 
 */
function selectZone(zoneSeq: number) {
  editStore.stopWatch();

  nodeEditorStore.clearNodes();
  editStore.setSelectSpaceInfo(zoneSeq);
  
  editStore.startWatch();
}

/**
 * 트리에서 존 삭제 버튼 클릭
 * @param zoneSeq 
 */
function deleteZone(zoneSeq: number) {
  const zoneName = editStore.findZone(zoneSeq, null)?.zoneName;

  removeMessage.value.message = "선택된 존이 삭제됩니다. 진행하시겠습니까?";
  removeMessage.value.removeTarget = `존 ${zoneName}`;
  isRemoveZone.value = true;
}

/**
 * 트리에서 존 삭제 수행
 */
function delZone(){
  editStore.delZone(editStore.selectSpaceInfo?.zoneSeq as number) as number;
}

function dialogValue(value: boolean){
  isRemoveZone.value = value;
}

/**
 * 트리에 보일 존의 아이콘을 가져온다.
 * @param zone 
 */
function getIcon(zone: Zone) {
  return menuStore.findZoneIconPath(zone);
}

</script>

<template>
  <div class="TreeComponent">
    <q-expansion-item
      v-model="expanded"
      expand-separator
      label="공간 선택"
      header-class="siteExpansion siteHeader"
      expand-icon-class="siteExpansionIcon"
    >
      <q-input
        class="siteFilter"
        :item-aligned="true"
        ref="filterRef"
        color="grey"
        label-color="black"
        :dark="false"
        square
        outlined
        bg-color="white"
        v-model="filter"
        :hide-bottom-space="true"   
        placeholder="Search"        
      >

      <!-- 필터 클리어 버튼 -->
      <!-- <template v-slot:append>
        <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
      </template> -->

      </q-input>
      <div class="spaceTree" v-if="!CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo)">
        
        <q-tree :dark="false" :nodes="[zone]" node-key="zoneSeq" label-key="zoneName" children-key="childZones" selected-color="red" text-color="black" default-expand-all no-selection-unset v-bind:selected="selectedZoneSeqRef" @update:selected="selectZone" :filter="filter" :filter-method="filterMethod" :style="{color: '#404040'}">
          <template v-slot:default-header="prop">
            <div class="row items-center">          
              <q-icon :name="`img:${getIcon(prop.node)}`" size="18px" class="q-mr-sm" />
            <div>{{ prop.node.zoneName }}</div>
            </div>
          </template>
        </q-tree>

        <div class="deleteBtnRow">
          <q-icon class="delBtn" name="delete" size="20px" @click="deleteZone(selectedZoneSeqRef)"></q-icon>
        </div>
      </div>
    </q-expansion-item>    
    <q-expansion-item
      class="expandItem"
      header-class="siteExpansion"
      expand-icon-class="siteExpansionIcon"
      v-if="upperModel === 'light'"
      v-model="bottomExpanded"
      expand-separator
      :label="bottomExpansionLabel.value"
    >
      
      <MaterialListComponent v-if="menuKind === Depth2_Kind.공간편집"></MaterialListComponent>
      <LightListComponent v-if="menuKind === Depth2_Kind.조명배치"></LightListComponent>
      <GroupTreeComponent v-if="menuKind === Depth2_Kind.그룹"></GroupTreeComponent>
      <SceneTreeComponent v-if="menuKind === Depth2_Kind.씬"></SceneTreeComponent>
    </q-expansion-item>

    <q-expansion-item
      class="expandItem"
      header-class="siteExpansion"
      expand-icon-class="siteExpansionIcon"
      v-if="upperModel === 'scenario'"
      expand-seperator
      label="시나리오 요소"
      default-opened
    >
      <ScenarioNodeComponent></ScenarioNodeComponent>
    </q-expansion-item>

    <!-- <q-expansion-item
      class="expandItem"
      header-class="siteExpansion"
      expand-icon-class="siteExpansionIcon"
      v-if="upperModel === 'simulation'"
      v-model="SimulationExpanded"
      dense
      expand-separator
      label="시뮬레이션"
    >
      <SimulationComponent></SimulationComponent>
    </q-expansion-item> -->

    <AlertWarningComponent :static-value="false" :confirm-value="isRemoveZone" :message="removeMessage" @submit="delZone" @change="dialogValue"></AlertWarningComponent>
  </div>
</template>

<style lang="scss">
.TreeComponent {
  .siteHeader.siteExpansion{
    border-left: 3px solid #3bb3ff;
  }
  .q-tree__img{
    height: 18px;
  }

  .siteExpansion {
    background-color: #f2f4fb;
    color: #404040;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;;
    border-radius: 0px;
    height: 39px;
    padding: 5px;
    .q-item__label {
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .siteExpansionIcon {
    color: #404040 !important;
  }

  .deleteBtnRow {
    display: flex;
    flex-direction: row-reverse;

    .delBtn {
      padding: 10px;
      cursor: pointer;
    }
  }

  .siteFilter {
    color: #404040;
    background-color: #e2e6f3;
    align-items: center;
    margin-bottom: 0px;
    padding: 5px 10px;
    .q-field__inner {
      .q-field__control {
        align-items: center;
      }
    }
  }
  .spaceTree {
    background-color: #e2e6f3;
    border-bottom: 1px solid #c1c1c1;
    padding-left: 15px;
    .treeHeader{
      font-size: 14px;
      height: 30px;
      display: grid;
      align-content: center;
      color: #404040;
    } 

    .q-tree__node-header{
      margin-top: 0px;
      height: 30px
    }
  }

  .expandItem {
    // .q-item {
    //   background-color: #e2e6f3;
    //   color: #888b95;
    //   border: 1px solid #888b95;
    //   height: 59px;
    // }
  }
}
</style>
