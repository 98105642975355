import type ScenarioNodeEvent from "./scenario-node-event";


/**
 * Scene Node Event 클래스
 * @class
 */
class ScenarioNode {
  siteSeq?: number;
  zoneSeq?: number;
  scenarioSeq?: number;
  nodeSeq?: number;
  nodeTypeCode?:string;
  nodeName?: string;
  nodeDesc?: string;
  originX?: number;
  originY?: number;
  dpointSeq?: number;
  asetName?: string;
  attrsMap?: string;
  targetTypeCode?: string;
  targetSeq?: number;
  mqttTopic?: string;
  regDate?: string;
  regUserId?: number;
  modDate?: string;
  modUserId?: number;          
  
  nodeEvents?: ScenarioNodeEvent[];      
}
      
export default ScenarioNode;
      