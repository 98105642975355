import type AbstractNodeConfig from "../config/abstract-node-config";
import type INodeSetting from "../setting/node-setting";
import AbstractNodeInfo from "./abstract-node-info";


class NodeInfo extends AbstractNodeInfo {
  constructor(id: string, x: number, y: number, nodeConfig: AbstractNodeConfig, nodeSetting: INodeSetting | null = null) {
    super(id, x, y, nodeConfig, nodeSetting);
  }
}

export default NodeInfo;