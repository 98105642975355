<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

// import Uspace from "@/models/uspace";
import { useMaterialsStore } from "@/stores/materials-store";
import type Zone from "@/models/entity/zone";
import type UZone from "@/models/entity/uzone";
import { useCommonStore } from "@/stores/common-store";
import { useEditStore } from "@/stores/edit-store";
import type ModelFile from "@/models/entity/model-file";
import CommonUtils from "@/utils/common-util";
import JSZip from "jszip";
import type * as THREE from "three";
import { SizeType, ZoneTypeCode } from "@/global/enums";

const materialStore = useMaterialsStore();
const editStore = useEditStore();
const jszip = new JSZip();

const props = defineProps<{
  selectSpace: Zone | null;
}>();


const profileOptions = computed(() => {
  return materialStore.profileOption;
});

function calcArea(){
  const aaa = document.getElementById("zonePathArea")?.getAttribute("d");
  console.log(aaa);
  const points = [];

  const point1 = aaa?.split("M")[1];
  console.log(point1);
  const point2 = point1?.split(" ") as string[];
  console.log(point2);

  for(let i = 1; i < point2?.length; i++){
    let forward;
    let backword;
    if(i - 1 === 0){
      forward = point2[i - 1];
      backword = point2[i].split("L")[0];
    } else if(i === point2.length - 1){
      forward = point2[i - 1].split("L")[1];
      backword = point2[i].split("z")[0];
    } else {
      forward = point2[i - 1].split("L")[1];
      backword = point2[i].split("L")[0];
    }

    points.push([forward, backword]);
  }
  console.log(points);
  // console.log(SVGPathCommander.shapeToPath(aaa));
  // uspaceInfo.value.area = uspaceInfo.value.horizSize * uspaceInfo.value.vertiSize;
  if(Object.keys(editStore.selectModelInfo as THREE.Object3D).length === 0){
    editStore.setSpaceModelSizePosition();
  }
}

const file = ref();

const modelInfoList = computed(() => {
  return editStore.ModelGroupInfoList;
});


onMounted(() => {
});


function inputModel() {
  const picker = document.querySelector("[name='modelPicker']") as HTMLElement;
  console.log(picker);
  picker?.click();
}

async function changeModel(){
  
  let model: Blob | null = null;
  const textureList: {name: string, data: Blob}[] = [];

  const zipFiles = await jszip.loadAsync(file.value);

  const zipFileName = Object.keys(zipFiles.files);
  // const zip = a.files.forEach(async (relativePath, zipEntry) => {
  //   await zipEntry.async("blob");
  // });

  for(let i = 0; i < zipFileName.length; i++){
    if(zipFileName[i].toUpperCase().includes(".FBX") === true){
      model = await zipFiles.files[zipFileName[i]].async("blob");
    } else {
      textureList.push({
        name: zipFileName[i],
        data: await zipFiles.files[zipFileName[i]].async("blob")
      });
    }
  }

  editStore.localEditFileInput(model as Blob, textureList, file.value);

  file.value = null;
}

/**
 * 공간 명칭 변경
 * @param zoneName 
 */
function onChangeZoneName(zoneName: string) {

  if(CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  props.selectSpace.zoneName = zoneName;
  console.log(`textChange => ${zoneName}`);
}

/**
 * 단위공간 원점 변경
 * @param value 
 * @param isY 
 */
function onChangeOrigin(value: number, isY = false) {

  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  if (isY)
    props.selectSpace.originY = Number(value);
  else
    props.selectSpace.originX = Number(value);
}

/**
 * 단위공간 사이즈 변경
 * @param value 
 * @param sizeType
 */
function onChangeSize(value: number, sizeType: SizeType) {
  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  switch (sizeType) {
    case SizeType.Horizontal:
      props.selectSpace.horizSize = Number(value);
      break;
    case SizeType.Vertical:
      props.selectSpace.vertiSize = Number(value);
      break;
    case SizeType.Height:

      if (CommonUtils.isNullOrEmpty(props.selectSpace.uzone))
        return;

      props.selectSpace.uzone.ceilHeight = Number(value);
      break;
  }

  props.selectSpace.area = props.selectSpace.horizSize * props.selectSpace.vertiSize;

  if (Object.keys(editStore.selectModelInfo as THREE.Object3D).length === 0) {
    editStore.setSpaceModelSizePosition();
  }
}

/**
 * 면적 변경
 * @param area 
 */
function onChangeArea(area: number) {
  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  props.selectSpace.area = Number(area);
}

</script>

<template>
  <div class="UspaceRightComponent">
    <q-expansion-item label="공간 정보" header-class="uspaceInfoExpansion" default-opened :dark="false" v-if="!CommonUtils.isNullOrEmpty(props.selectSpace) && !CommonUtils.isNullOrEmpty(props.selectSpace.uzone) && !CommonUtils.isNullOrEmpty(props.selectSpace.zoneTypeCode === ZoneTypeCode.단위존)">
      <q-card>
        <q-card-section class="uspaceRightContents">
            <label>명칭</label>
            <q-input 
            square bg-color="white" 
            :dark="false" type="text" 
            maxlength="30" 
            outlined 
            :model-value="props.selectSpace.zoneName"
            @change="onChangeZoneName($event)"
            placeholder="단위공간 명칭" 
            />          
            
            <label>용도프로필</label>
            <div class="row" :style="{alignItems: 'center'}">
              <q-select
              square
              class="col-7"
              bg-color="white"
              :dark="false"
              outlined
              filled
              v-model="props.selectSpace.uzone!.usageProfileCode"
              :options="profileOptions"
              :popup-content-style="{color: '#404040'}"
              hide-bottom-space
              options-dense
              emit-value
              map-options
              >
            </q-select>
              <q-checkbox size="30px" class="col-5" v-model="props.selectSpace.uzone!.outdoorYn" :dark="false" label="실외" keep-color color="black" :true-value="'Y'" :false-value="'N'" dense :style="{marginBottom: '10px', justifyContent: 'center'}"/>
            </div>

            <label>선택 모델 그룹</label>
            <div>
              <q-select
              square
              class="col-7"
              bg-color="white"
              :dark="false"
              outlined
              v-model="props.selectSpace.modelFileGrp"
              :options="modelInfoList"
              :popup-content-style="{color: '#404040'}"
              hide-bottom-space
              options-dense
              emit-value
              map-options
              :disable="Object.keys(editStore.selectModelInfo as THREE.Object3D).length === 0"
              >
              </q-select>
            </div>

            <div v-if="!CommonUtils.isNullOrEmpty(editStore.selectSiteInfo) && editStore.selectSiteInfo.siteClsfyCode === 'A001'">
                <label>모델 파일</label>
                <div class="row" :style="{alignItems: 'center'}">
                <q-input v-if="props.selectSpace.modelFile !== null" square bg-color="white" :dark="false" class="col-10" outlined
                  disable v-model="(props.selectSpace.modelFile as ModelFile).modelFileName">
                </q-input>
                <q-file v-show="props.selectSpace.modelFile === null" name="modelPicker" class="col-10" outlined square hide-hint
                :dark="false" bg-color="white" v-model="file" dense @update:model-value="changeModel" />
                <div class="col-2" :style="{ paddingTop: '5px', paddingBottom: '5px', marginBottom: '10px' }">
                  <q-btn class="fit" dense outline @click="inputModel">첨부</q-btn>
                </div>            
              </div>
            </div>
            
            <label>원점 [m]</label>
            <div class="row horizonBorderClass">
              <label class="col-3" :style="{margin: '0px'}">X</label>
              <q-input square bg-color="white" :dark="false" class="col-3" dense type="number" outlined :model-value="props.selectSpace.originX" @change="onChangeOrigin($event)" placeholder="X" :style="{marginBottom: '0px'}"/>
              
              <label class="col-3" :style="{margin: '0px'}">Y</label>
              <q-input square bg-color="white" :dark="false" class="col-3" dense type="number" outlined :model-value="props.selectSpace.originY" @change="onChangeOrigin($event, true)" placeholder="Y" :style="{marginBottom: '0px'}"/>
            </div>
            
            <label>크기 [m]</label>
            <div class="horizonBorderClass" :style="{padding:' 10px'}">
              <label class="col-3" style="margin-top: 6px">실 길이</label>
              <q-input square bg-color="white" :dark="false" class="col-3" type="number" outlined :model-value="props.selectSpace.horizSize" placeholder="가로" @change="onChangeSize($event, SizeType.Horizontal)" />
              <label class="col-3" style="margin-top: 6px">실 깊이</label>
              <q-input square bg-color="white" :dark="false" class="col-3" type="number" outlined :model-value="props.selectSpace.vertiSize" placeholder="세로" @change="onChangeSize($event, SizeType.Vertical)"/>
            </div>
            
            <label>면적</label>
            <div class="borderClass">
              <div>
                <label class="col-4">바닥 면적 [㎡]</label>
                
                <q-input square bg-color="white" :dark="false" class="col-4" type="number" outlined :model-value="props.selectSpace.area" @change="onChangeArea($event)" placeholder="면적"/>
                
                <label class="col-4">천장고 [m]</label>
                
                <q-input square bg-color="white" :dark="false" class="col-4" type="number" outlined :model-value="props.selectSpace.uzone.ceilHeight" @change="onChangeSize($event, SizeType.Height)" placeholder="천장고" />
              </div>
            </div>
            
            <label>야간 운영 방식</label>
            <div>
              <q-select
              square
              bg-color="white"
              :dark="false"
              outlined
              v-model="props.selectSpace.uzone.nightOperMode"
              :options="useCommonStore().getOptionTypes('nightOperMode')"
              :popup-content-style="{color: '#000000'}"              
              emit-value
              map-options
              />
            </div>
            <label>주말 운영 방식</label>
            <div>
              <q-select
              square
              bg-color="white"
              :dark="false"
              outlined
              v-model="props.selectSpace.uzone.weekendOperMode"
              :options="useCommonStore().getOptionTypes('weekendOperMode')"              
              :popup-content-style="{color: '#000000'}"
              emit-value
              map-options
              />
            </div>

        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<style lang="scss">
.UspaceRightComponent {
  .uspaceInfoExpansion {
    background-color: #e2e6f3;
    color: #404040;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 39px;
    .q-item__label {
      font-weight: bold;
    }
  }
  
  .uspaceRightContents {
    padding: 20px;
    background-color: #f2f4fb;
    color: #404040;
    label {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .q-field__native.items-center{
      white-space: nowrap;
      overflow: hidden;
      word-break: break-all;
    }
  }
  
  .q-field--auto-height .q-field__control,
  .q-field--auto-height .q-field__native {
    min-height: 30px;
  }
  
  .q-textarea .q-field__control {
    height: 75px;
  }
  
  .q-textarea .q-field__native {
    resize: none;
    padding-top: 10px;
    height: 75px;
  }
  
  label {
    margin-bottom: 0px;
    font-size: 13px;
  }
  
  .horizonBorderClass {
    padding: 5px 10px 5px 0px;
    margin-bottom: 10px;
    align-items: center;
    background-color: #e2e6f3;
    
    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }
    
    label {
      font-size: 12px;
      text-align: center;
    }
  }
  
  .borderClass {
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;
    background-color: #e2e6f3;
    
    
    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }
    
    .row {
      justify-content: space-around;
      align-items: center;
      
      label {
        font-size: 12px;
      }
    }
  }
}
</style>
