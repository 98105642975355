<script setup lang="ts">
import SimulationContentsComponent from "@/components/Simulation/SimulationContentsComponent.vue";
import SimulationLeftComponent from "@/components/Simulation/SimulationLeftComponent.vue";
import { useSimulationStore } from "@/stores/simulation-store";
import { ref } from "vue";

const simulationStore = useSimulationStore();
simulationStore.initData();

const spliterLeftWidth = ref(11);
const spliterLeftDisabled = ref(false);
const spliterLeftLimited = ref([11, 11]);


function LeftExpandedBtn() {
  if (spliterLeftDisabled.value === false) {
    spliterLeftWidth.value = 1;
    spliterLeftDisabled.value = true;
    spliterLeftLimited.value = [1, 1];
  } else {
    spliterLeftWidth.value = 11;
    spliterLeftDisabled.value = false;
    spliterLeftLimited.value = [11, 11];
  }
}


</script>

<template>
  <q-page class="SimulationComponent">
    <q-splitter
      v-model="spliterLeftWidth"
      :disable="spliterLeftDisabled"
      class="splitterClass"
      :limits="spliterLeftLimited"
      separator-class="leftSeparator"
      before-class="leftSeparatorBefore"
    >
      <template v-slot:before>
        <SimulationLeftComponent class="splitterLeftComponent" @expaned="LeftExpandedBtn"></SimulationLeftComponent>
      </template>

      <template v-slot:after>
        <SimulationContentsComponent class="col">
        </SimulationContentsComponent>
      </template>

    </q-splitter>

    
  </q-page>
</template>

<style lang="scss">
.SimulationComponent{
  .splitterClass {
    background-color: #f2f4fb;
    height: calc(100vh - 54px);
    .q-splitter__before{
      max-width: 196px;
    }

    .splitterLeftComponent{
      border-right: 1px solid #c1c1c1;
    }
    .leftTreeComponent{
      border-right: 1px solid #c1c1c1;
      min-width: 192px;
    }

    .rightComponent{
      border-left: 1px solid #c1c1c1;
      min-width: 288px;
    }

    .leftSeparator {
      width: 0 !important;

      .q-splitter__separator-area {
        @if (v-bind(spliterLeftDisabled)) {
          width: 1px !important;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
