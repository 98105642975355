import type { PortType } from "@/global/enums";

/**
 * 포트 정보 클래스
 * @class
 */
class PortInfo {
  isVisible = true;
  isSelected = false;  
  portType?: PortType;
  nodeItemId?: string;
  x = 0;
  y = 0;

  sizeWidth: number;
  sizeHeight: number;  
  constructor(sizeWidth: number, sizeHeight: number) {
    this.sizeWidth = sizeWidth;
    this.sizeHeight = sizeHeight;
  }
}

export default PortInfo;