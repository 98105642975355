<script setup lang="ts">
import * as d3 from "d3";
import { onMounted, onUnmounted, computed, nextTick, onUpdated } from "vue";
import AreaRect from "./AreaRect";
import { useEditStore } from "@/stores/edit-store";
import type Wall from "./Wall";
import type Zone from "@/models/entity/zone";
import { useMenuStore } from "@/stores/menu-store";

const props = defineProps<{
  width: number,
  height: number,
  vertical: number,
  baseData: Zone | null,  
}>();

const emitData = defineEmits(["update:setEditor"]);

const baseData = computed(() => {return props.baseData as Zone;});
const editStore = useEditStore();

const modelSize = computed(() => {
  return editStore.UzoneSizeData;
});
const modelPosition = computed(() => {
  return editStore.UzonePositionData;
});

const selectSpaceInfo = computed(() => {
  return editStore.selectSpaceInfo as Zone;
});

const zoneParameter = computed(() => {
  const stroke = "";
  const style = {
    fill: "#c1c1c1",
  };
  const x = (baseData.value.originX as number) || 0;
  const y = baseData.value.originY as number;
  const width = baseData.value.horizSize as number;
  const height = baseData.value.vertiSize as number;
  const id = baseData.value.zoneSeq as number;
  const name = baseData.value.zoneName as string;
  const spaceType = baseData.value.zoneTypeCode as string;

  return new AreaRect(stroke, style, 0, 0, width, height, id, spaceType, name, baseData.value, baseData);
});

const zoneWalls = computed(() => {
  const setWalls = new Array<Wall>();
  return setWalls;
});

console.log(document.getElementsByClassName("editArea")[0], document.getElementsByClassName("editArea")[0]?.clientWidth as number, editStore.zoom);

// const maxWidth = computed(() => {
//   return (modelSize.value.x as number) * editStore.zoom;
// });
// const maxHeight = computed(() => {
//   return (modelSize.value.z as number) * editStore.zoom;
// });
// const areaWidth = computed(() => {
//   return import.meta.env.VITE_LIGHT_DEPLOY_GRID_WIDTH;
// });

// const areaHeight = computed(() => {
//   return import.meta.env.VITE_LIGHT_DEPLOY_GRID_HEIGHT;
// });

// const uspaceAreaWidth = computed(() => {
//   let widthPer = "";  
//   if(baseData.value.horizSize > baseData.value.vertiSize){
//     widthPer = "100%";
//   } else {
//     widthPer = areaHeight.value.toString() + "px";
//   }

//   return widthPer;
// });

// const uspaceAreaHeight = computed(() => {
//   let heightPer = "";
//   if(baseData.value.horizSize > baseData.value.vertiSize){
//     heightPer = areaWidth.value.toString() + "px";
//   } else {
//     heightPer = "100%";
//   }

//   return heightPer;
// });

// function setZoneZoomValue(){
//   editStore.zoomReset();

//   const defaultWidth = Number(areaWidth.value) - 200; 
//   const defaultHeight = Number(areaHeight.value) - 200; 

//   console.log(defaultHeight, defaultWidth);
//   let count = 1;

//   if(maxWidth.value < defaultWidth && maxHeight.value < defaultHeight){
//     while (maxWidth.value < defaultWidth && maxHeight.value < defaultHeight) {
//       editStore.zoomIn();
//       count++;

//       if(maxWidth.value > defaultWidth || maxHeight.value > defaultHeight){
//         break;
//       }

//       if(count > 10000){
//         break;
//       }
//     }
//   } else {
//     while(maxWidth.value > defaultWidth || maxHeight.value > defaultHeight){
//       console.log(maxWidth.value, defaultWidth, maxHeight.value, defaultHeight);
//       editStore.zoomOut();
//       count++;

//       if(maxWidth.value < defaultWidth && maxHeight.value < defaultHeight){
//         break;
//       }
      
//       if(count > 5000){
//         break;
//       }

//     }
//   }
 
// }


onMounted(() => {
  // setZoneZoomValue();
  makeBasePanel();

  const width = document.getElementById("UAreaRect")?.getAttribute("width");
  const height = document.getElementById("UAreaRect")?.getAttribute("height");

  emitData("update:setEditor", width, height);

  // if(modelSize.value.x === 0 && modelSize.value.y === 0){
  //   setZoneZoomValue();

  // }

});

onUnmounted(() => {
  console.log("unmounted");
});

function makeBasePanel() {
  const svg = d3.select<SVGGElement, undefined>("#siteArea");
  // svg.insert("rect").attr("stroke", "white").attr("x", 0).attr("y", 0).attr("width", "100%").attr("height", "100%").style("fill", "#69b3a2");
}

const zone = computed(() => {
  return setZoneParameter(baseData.value) as AreaRect;
});

function setZoneParameter(zone: Zone) {
  const stroke = "";
  const style = {
    fill: "#919191",
  };
  const x = (zone.originX as number) || 0;
  const y = zone.originY as number;
  const width = zone.horizSize as number;
  const height = zone.vertiSize as number;
  const id = zone.zoneSeq as number;
  const name = zone.zoneName as string;
  const spaceType = zone.zoneTypeCode as string;

  const setZone = new AreaRect(stroke, style, x, y, width, height, id, spaceType, name, zone, baseData);
  nextTick(() => {
    setZone.eventSet();
  });

  return setZone;
}

</script>

<!-- :width="baseData.horizSize > baseData.vertiSize ? '100%' : `${100 / (baseData.vertiSize / baseData.horizSize)}%`"
:height="baseData.horizSize < baseData.vertiSize ? '100%' : `${100 / (baseData.horizSize / baseData.vertiSize)}%`" -->

<template>
  <div class="ZoneContent" v-if="baseData" :style="{width: '100%', height: '100%'}">
    <svg
      :x="0"
      :y="0"
      :width="modelSize.x * editStore.zoom"
      :height="modelSize.z * editStore.zoom"
      :style="{display: 'block', position: 'absolute'}"
    >
      <rect
        :id="`${baseData?.zoneTypeCode}AreaRect`"
        :width="'100%'"
        :height="'100%'"
        :x="0"
        :y="0"
        :style="{ fill: '#777777' }"
         
      >
      </rect>
      <text
        :id="`${baseData?.zoneTypeCode}AreaText`"
        :x="'50%'"
        :y="'5%'"
        :style="{
          textAnchor: 'middle',
          alignmentBaseline: 'central',
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'black',
        }"
      >
          <!-- {{ baseData?.zoneName }} -->
          {{Object.keys(editStore.selectModelInfo as THREE.Object3D).length === 0 ? editStore.selectSpaceInfo?.zoneName : editStore.selectZoneInfo?.zoneName}}
      </text>
    </svg>
  </div>
</template>

<!-- <template>
  <div class="ZoneContent q-pa-xs" :style="{ width: editStore.maxWidth + 'px', height: editStore.maxHeight + 'px' }">

    <svg id="siteArea" ref="siteArea" width="100%" height="100%" :key="1" :style="{ backgroundColor: '#313131' }" overflow="auto">
      <svg
        :id="`${zone.spaceType}${zone.id}`"
        :key="`zone${123}`"
        :width="editStore.calcWidth(editStore.areaWidth, Number(zone.width), editStore.maxWidth)"
        :height="editStore.calcHeight(editStore.areaHeight, Number(zone.height), editStore.maxHeight)"
        :x="editStore.calcX(editStore.areaWidth, Number(zone.x), editStore.maxWidth)"
        :y="editStore.calcY(editStore.areaHeight, Number(zone.y), editStore.maxHeight)"
      >
        <rect :id="`${zone.spaceType}AreaRect`" :width="'100%'" :height="'100%'" :x="0" :y="0" :style="{ fill: '#919191' }"></rect>
        <text
          :id="`${zone.spaceType}AreaText`"
          :x="'50%'"
          :y="'50%'"
          :style="{
            textAnchor: 'middle',
            alignmentBaseline: 'central',
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'black',
          }"
        >
          {{ zone.name }}
        </text>
      </svg>
    </svg>
  </div>
</template> -->

<style lang="scss">
.ZoneContent {
  position: absolute;

  #siteArea {
    width: 100%;
    height: 100%;
  }
}
</style>
