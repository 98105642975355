import AbstractActionAttrs from "./abstract-action-attrs";

/**
 * 조명 설정 속성 클래스
 * @class
 */
class LightConfigAttrs extends AbstractActionAttrs {
  on_off_tt = 0;                      //점소등 소요시간
  dimup_tt = 0;                       //최대밝기 도달시간
  dimdown_tt = 0;                     //최소밝기 도달시간
  on_hold_time = 0;                   //점등 유지시간
  off_hold_time = 0;                  //소등 유지시간
  off_delay_time = 0;                 //점등 대기시간
  on_delay_time = 0;                  //소등 대기시간
  color_tt = 0;                       //color, colortemp 변환 시간
  onlevel = 0;                        //ON 명령어 시 점등 레벨
  offlevel = 0;                       //OFF 명령 시 점등 레벨  
  level_rate = 0;                     //조명 출력 비율(%)
}

export default LightConfigAttrs;