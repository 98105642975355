import { defineStore } from "pinia";
import DeviceInfo from "@/models/device/device-info";

import TempDeviceType from "@/settings/tempDeviceType.json";
import type { DeviceShape } from "@/global/enums";
import type { QTableProps } from "quasar";
import type DevModel from "@/models/entity/dev_model";
import type LightDeviceType from "@/models/entity/light-device-type";
import type DevAset from "@/models/entity/dev-aset";
import type DevAttr from "@/models/entity/dev-attr";
import type DPoint from "@/models/entity/d-point";
import type DeviceTypeModule from "@/models/entity/device-type-module";
import type { devAsetAttrs } from "@/global/types";
import type SensorDevice from "@/models/entity/sensor-device";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import { useEditStore } from "./edit-store";
import type InDPoint from "@/models/group/in-d-point";

export const useDeviceStore = defineStore("deviceStore", {
  state: () => ({
    lightList: [] as LightDeviceType[],
    deviceModelList: [] as DevModel[],
    devAsetList: [] as DevAset[],
    devAttrList: [] as DevAttr[],
    devAset: {} as DevAset,
    selectDevModel: {} as DevModel,
    selectGrpDevTypeAsetCodeList: [] as string[],
    selectGrpDevTypeCode: "" as string,
    selectGrpDevAsetCode: "" as string,
    selectGrpDevAsetCodeList: [] as string[],
    selectGrpDevModuleTypeCode: "" as string,
    selectGrpDevModulesList: [] as string[],
    selectGrpDevAttrList: [] as DevAttr[]
  }),
  getters: {
    getDevTypeList(state) {
      return state.lightList;
    },
  },
  actions: {
    async load() {
      const DeviceTypes = await this.apiServices.deviceApiService.getDeviceTypes();

      // TempDeviceType.map((v) => {
      //   const light = new DeviceInfo(v.dev_type_code, v.dev_type_name);

      //   light.ShapeType = v.shape_type as DeviceShape;
      //   light.width = v.horiz_size;
      //   light.height = v.verti_size;
      //   light.depth = v.height_size;
      //   light.imagePath = v.image_file_seq;

      //   DeviceTypes.push(light);
      // });

      this.lightList = DeviceTypes;
    },
    setSelectDevModel(id: number){
      this.selectDevModel = this.deviceModelList.find(v => v.devModelSeq === id) as DevModel;
    },
    async getDevAsets(){
      this.devAsetList = await this.apiServices.deviceApiService.getDevAsets();
    },
    async getDevAttrs(){
      this.devAttrList = await this.apiServices.deviceApiService.getDevAttrs();
    },
    async getDevAsetCode(asetCode: string){
      this.devAset = await this.apiServices.deviceApiService.getDevAsetCode(asetCode);
    },
    setGrpDevTypeCode(group: CtrlGrp, dpointList = useEditStore().inDPointList){
      const groupDpointList = [] as InDPoint[];

      group.members?.map(gm => {
        dpointList.find(v => {
          if(v.dpointSeq === gm.dpointSeq){
            groupDpointList.push(v);
          }
        });
      });



      const groupDpointDevTypeList = [] as LightDeviceType[];

      groupDpointList.map(d => {
        this.lightList.find(v => {
          if(v.devTypeCode === d.devTypeCode){
            const include = groupDpointDevTypeList.findIndex(v => v.devTypeCode === d.devTypeCode);
            if(include === -1){
              if(v.devClsfyCode === "LD"){
                groupDpointDevTypeList.push(v);
              }
            }
          }
        });
      });

      // const groupDpointDevModelList = [] as DevModel[];

      // groupDpointDevTypeList.map(dt => {
      //   this.deviceModelList.find(v => {
      //     if(v.devTypeCode === dt.devTypeCode){
      //       groupDpointDevModelList.push(v);
      //     }
      //   });
      // });


      this.selectGrpDevAttrList = [] as DevAttr[];

      // this.selectGrpDevTypeCode = dpoint.devTypeCode as string;

      // const lightType = this.lightList.find(v => {
      //   if(v.devTypeCode === this.selectGrpDevTypeCode){
      //     return v;
      //   }
      // });

      // console.log(lightType?.modules, lightType);

      // this.selectGrpDevModuleTypeCode = (lightType?.modules as DeviceTypeModule[])[0].moduleTypeCode as string;

      // lightType?.modules?.map(v => {
      //   this.selectGrpDevModulesList.push(v.moduleTypeCode as string);
      // });


      groupDpointDevTypeList.map(dt => {
        
        const include = this.selectGrpDevTypeAsetCodeList.findIndex(v => v === dt.devAsetCode);

        if(include === -1){
          this.selectGrpDevTypeAsetCodeList.push(dt.devAsetCode as string);
        }

        // const modules = dt.modules as DeviceTypeModule[];

        // for(let i = 0; i < modules.length; i++){
        //   const include = this.selectGrpDevModulesList.findIndex(v => v === modules[i].devAsetCode);
        //   if(include === -1){
        //     this.selectGrpDevModulesList.push(modules[i].devAsetCode as string);
        //   }
        // }
      });

      // this.selectGrpDevModulesList = lightType?.modules?.map(v => v.devTypeCode as string) as string[];

      // const selectedDevAset =  this.devAsetList.find(v => {
      //   if(v.moduleTypeCode === this.selectGrpDevModuleTypeCode){
      //     return v;
      //   }
      // });

      console.log(this.selectGrpDevTypeAsetCodeList);

      const selectedDevAsetList = [] as devAsetAttrs[];

      this.selectGrpDevTypeAsetCodeList.map(gdm => {
        this.devAsetList.find(v => {
          if(v.devAsetCode === gdm){
            v.devAsetAttrs?.map(attr => {
              selectedDevAsetList.push(attr as devAsetAttrs);
            });
          }
        });
      });

      // this.selectGrpDevAsetCode = selectedDevAset?.devAsetCode as string;

      for(let i = 0; i < selectedDevAsetList.length; i++){
        const selectAsetAttr = selectedDevAsetList[i];
        const devAttr = this.devAttrList.find(v => {
          if(v.attrSeq === selectAsetAttr.attrSeq){
            return v;
          }
        });

        this.selectGrpDevAttrList.push(devAttr as DevAttr);
      }

      // for(let i = 0; i < (selectedDevAset?.devAsetAttrs as devAsetAttrs[]).length; i++){
      //   const selectAsetAttr = (selectedDevAset?.devAsetAttrs as devAsetAttrs[])[i];
      //   const devAttr = this.devAttrList.find(v => {
      //     if(v.attrSeq === selectAsetAttr.attrSeq){
      //       return v;
      //     }
      //   });
        
      //   this.selectGrpDevAttrList.push(devAttr as DevAttr);
      // }

      return this.selectGrpDevAttrList;
    },
    makeDeviceOption() {
      const rows: QTableProps[] = [];

      this.lightList.map((v: LightDeviceType) => {
        const row = {};
        for (const [key, value] of Object.entries(v)) {
          Object.defineProperty(row, key, {
            value: value,
          });
        }

        rows.push(row);

        // columns.map((t) => {
        //   Object.defineProperty(row, t.field, {
        //     value: Object.,
        //   });
        // });
      });

      return rows;
    },

    async getDeviceModels(){
      const result = await this.apiServices.deviceApiService.getDeviceModel();

      this.deviceModelList = result;

      return result;
    },

    async newDeviceModel(){
      const result = await this.apiServices.deviceApiService.addDevcieModel(null);

      console.log(result);
    
    }
  },
});
