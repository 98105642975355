<script setup lang="ts">
import { computed } from "vue";
import { useNodeEditorStore } from "@/stores/node-editor-store";


const nodeEditorStore = useNodeEditorStore();
const emitData = defineEmits(["lightCancel", "groupModal"]);

const selectGroupLight = computed(() => {
  return nodeEditorStore.getSelectedNodes(false);
});

</script>

<template>
  <div class="NewLightGroup">
    추가할 그룹들을 선택해주세요.
    <button v-if="selectGroupLight?.length !== 0" @click="emitData('groupModal')">생성</button>
    <button @click="emitData('lightCancel')">취소</button>
  </div>
</template>

<style lang="scss">
.NewLightGroup {
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: #000000;
  text-align: center;
}
</style>
