<script setup lang="ts">
import { SizeType, ZoneTypeCode } from "@/global/enums";
import type Zone from "@/models/entity/zone";
import { useCommonStore } from "@/stores/common-store";
import CommonUtils from "@/utils/common-util";

const commonStore = useCommonStore();

const props = defineProps<{
  selectSpace: Zone | null;
}>();

/**
 * 층 명칭 변경
 * @param floorName 
 */
function onChangeFloorName(floorName: string) {

  if(CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  props.selectSpace.zoneName = floorName;
}

/**
 * 복합공간 사이즈 변경
 * @param value 
 * @param sizeType
 */
function onChangeSize(value: number, sizeType: SizeType) {
  if (CommonUtils.isNullOrEmpty(props.selectSpace))
    return;

  switch (sizeType) {
    case SizeType.Horizontal:
      props.selectSpace.horizSize = Number(value);
      break;
    case SizeType.Vertical:
      props.selectSpace.vertiSize = Number(value);
      break;
    case SizeType.Height:
      props.selectSpace.height = Number(value);      
      break;
  }  
}

</script>

<template>
  <div class="CspaceRightComponent">
    <q-expansion-item label="층 정보" header-class="cspaceInfoExpansion" default-opened :dark="false" v-if="!CommonUtils.isNullOrEmpty(props.selectSpace) && !CommonUtils.isNullOrEmpty(props.selectSpace.czone) && props.selectSpace.zoneTypeCode === ZoneTypeCode.묶음존">
      <q-card>
        <q-card-section class="cspaceRightContents">
          <div>
            <label>명칭</label>
            <q-input square bg-color="white" :dark="false" type="text" maxlength="30" outlined :model-value="props.selectSpace.zoneName" @change="onChangeFloorName($event)" placeholder="층 명칭" />

            <!-- <label>설명</label>
            <q-input
              square
              bg-color="white"
              :dark="false"
              type="textarea"
              maxlength="50"
              outlined
              v-model="cspaceInfo!.zoneName"
              placeholder="사이트 설명"
            /> -->

            <label>공간 유형</label>
            <div class="row" :style="{justifyContent: 'start'}">
              <q-input square bg-color="white" :dark="false" :disable="true" type="text" maxlength="30" outlined :model-value="commonStore.getFindCodeToName('czoneTypeCode', props.selectSpace.czone.czoneTypeCode)" />
            </div>
            
            <div class="row">
              <label class="col-5">가로 [m]</label>
              <label class="col-5">세로 [m]</label>
            </div>
            <div class="row">
              <q-input square bg-color="white" :dark="false" class="col-5" type="number" outlined :model-value="props.selectSpace.horizSize" @change="onChangeSize($event, SizeType.Horizontal)" placeholder="가로" />
              <q-input square bg-color="white" :dark="false" class="col-5" type="number" outlined :model-value="props.selectSpace.vertiSize" @change="onChangeSize($event, SizeType.Vertical)" placeholder="세로" />
            </div>

            <div class="row">
              <label class="col-5">층고 [m]</label>
            </div>
            <div class="row">
              <q-input square bg-color="white" :dark="false" class="col-5" type="number" outlined :model-value="props.selectSpace.height" @change="onChangeSize($event, SizeType.Height)" placeholder="층고" />
            </div>

            <label>도면 이미지</label>
            <div class="row">
              <q-input square bg-color="white" :dark="false" class="col-9" outlined :model-value="props.selectSpace.planImageFileSeq" disable></q-input>
              <!-- <q-file v-model="cspaceInfo!.pathImg" class="col-2" outline style="margin-bottom: 10px">...</q-file> -->
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<style lang="scss">
.CspaceRightComponent {
  .cspaceInfoExpansion {
    background-color: #e2e6f3;
    color: #404040;
    font-size: 17px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 39px;
    .q-item__label {
      font-weight: bold;
    }
  }

  .cspaceRightContents {
    background-color: #f2f4fb;
    color: #404040;
    padding: 20px;
    label {
      font-weight: bold;
    }
  }

  .q-field--outlined .q-field__control {
    height: 30px;
    margin-bottom: 10px;
  }

  .q-textarea .q-field__control {
    height: 75px;
  }

  .q-textarea .q-field__native {
    resize: none;
    padding-top: 10px;
    height: 75px;
  }

  label {
    margin-bottom: 0px;
    font-size: 13px;
  }

  .row {
    justify-content: space-between;
    align-items: center;

    label {
      font-size: 13px;
    }
  }
}
</style>
