import type { MenuKind } from "@/global/types";
import { Exclude, Type } from "class-transformer";
import "reflect-metadata";

class Menu {
  public menuKind?: MenuKind;
  public isSelected?: boolean = false;
  public isEnabled?: boolean;
  public isUseToggle?: boolean;
  public isReactUndoRedo?: boolean;
  public name?: string;
  public icon?: string;
  public path?: string;
  public key?: MenuKind;

  @Exclude()
  public isOver?: boolean = false;

  @Type(() => Menu)
  public childMenu: Menu[] = [];

  constructor() { }

  get image(): string | undefined {
    if (this.isEnabled === false)
      return this.disabledIcon;
    else if (this.isSelected || this.isOver)
      return this.overIcon;
    else
      return this.icon;
  }

  get overIcon(): string | undefined {
    return this.getOverOrDisabledName(true);
  }

  get disabledIcon(): string | undefined {
    return this.getOverOrDisabledName(false);
  }

  private getOverOrDisabledName(isOver: boolean) {
    const spliteName = this.icon?.split(".");

    if (spliteName === undefined)
      return this.icon;

    const lastUnderBarIndex = spliteName[0].lastIndexOf("_");
    const pureName = spliteName[0].substring(0, lastUnderBarIndex);
    
    const postText = isOver ? (this.isSelected ? "selected" : "hover") : "dis";

    return `${pureName}_${postText}.${spliteName[1]}`;
  }
}

export default Menu;
