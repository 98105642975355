import type { TargetType } from "@/global/enums";
import type INodeSetting from "./node-setting";

class ScenarioDeviceNodeSetting implements INodeSetting{

  deviceSeq?: number;
  constructor() {    
  }
}

export default ScenarioDeviceNodeSetting;