import type DevModelEnergyConsume from "./dev_model_energy_consume";

class DevModel {
  consumpPower?: number;
  devModelName?: string;
  devModelSeq?: number;
  devTypeCode?: string;
  energyConsumes?: DevModelEnergyConsume[];
  imageFileSeq?: number;
  manufacturer?: string;
  modDate?: string;
  modUserId?: number;
  productCode?: string;
  regDate?: string;
  regUserId?: number;
  standbyPower?: number;
}
  
export default DevModel;