import type { HttpMethods } from "@/global/enums";
import CommonUtils from "@/utils/common-util";
import axios, { type AxiosRequestConfig, type AxiosRequestHeaders, type AxiosResponse, type AxiosInstance, type AxiosError } from "axios";
import { plainToClass, type ClassConstructor } from "class-transformer";
import type LoginInfo from "@/models/entity/login-info";
import { Mutex } from "async-mutex";
import { useAuthManager } from "@/composables/auth-manager";

/**
 * Rest Client
 */
class RestClient {
  private accessToken: string | null = null;
  private instance: AxiosInstance;
  private lock: Mutex = new Mutex();
  private authManager = useAuthManager();

  private callbackFn: ((msg: string | null) => void) | null = null;

  /**
   * 생성자
   * @param {string} baseUrl Rest Base Url
   * @param {number} timeout 응답 Timeout
   */
  constructor(baseUrl: string, timeout = 5000) {


    const config: AxiosRequestConfig = {
      //baseURL: `${baseUrl}`,
      timeout: timeout,
      headers: { Accept: "application/json, text/plain, */*" },
      //headers: { "Content-Type": "application/json", "Accept": "application/json", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": true },
      // withCredentials: true,      
    };

    //super(config);    

    this.instance = axios.create(config);

    //Request Interceptor
    this.instance.interceptors.request.use(
      async config => {
        console.log(`config -> ${config}`);

        if (config.url?.includes("imagefiles") === true && config.method === "get") {
          config.responseType = "blob";
        }

        if (config.url?.includes("modelfiles") === true && config.method === "get") {
          // if(config.url.includes("textures") === false){
          config.responseType = "blob";
          // }
        }

        if(config.url?.includes("signin/token") === true){
          config.params =  { token: this.accessToken };      
        }


        if (CommonUtils.isNullOrEmpty(config.headers))
          config.headers = {};

        if (CommonUtils.isNullOrEmpty(this.accessToken) === false)
          config.headers["Authorization"] = `Bearer ${this.accessToken}`;

        return config;
      },
      error => {
        console.log(`Rest client Request error Code-> ${error}`);
        alert("오류가 발생하였습니다");
        return Promise.reject(error);
      }
    );

    //Response Interceptor
    this.instance.interceptors.response.use(
      res => {
        return res;
      },
      async error => {

        //console.log(`Mutex IsLocked: ${this.lock.isLocked()}`);

        const isLock = this.lock.isLocked();

        await this.lock.acquire();

        try {
          if (error.response.status === 401 && this.authManager.getIsRetry()) {

            if (isLock) {
              return this.instance.request(error.config);
            } else {

              try {
                const refresh_token = this.authManager.getRefreshToken();

                if(CommonUtils.isNullOrEmpty(refresh_token))
                  throw new Error("Refresh Token Not Found.");

                const data = new FormData();
                data.append("token", refresh_token);

                console.log("Update Refresh Token!!");

                const res = await axios.post<LoginInfo>("/v1/signin/update-token", null, { params: { token: refresh_token } });
                this.setHeaderToken(res.data.accessToken, res.data.refreshToken);                
                return this.instance.request(error.config);
              }
              catch {
                console.log("Refresh Token Update Fail.");

                //헤더 및 쿠키 초기화
                this.setHeaderToken(null);
              }
            }
          }
       
          return Promise.reject(error);

        } catch {

          console.log("Response Error~~!!!");
          return Promise.reject(error);

        } finally {

          this.lock.release();
          console.log("Mutex Release!!!");

        }
      }
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async call<T>(method: HttpMethods, url: string, requestParams?: RequestParameter, cls?: ClassConstructor<Unboxed<T>>): Promise<AxiosResponse<T, any>> {
    //폼데이터가 비어 있지 않으면 폼데이터를 body에 넣음.
    try {
      const isForm = !CommonUtils.isNullOrEmpty(requestParams?.form);
      const res = await this.instance.request({
        method: method,
        url: url,
        params: requestParams?.params,
        headers: requestParams?.headers,
        data: isForm ? requestParams?.form : requestParams?.body,
      });

      if (CommonUtils.isNullOrEmpty(cls))
        return res;

      // const json = res.data;
      // const obj = JSON.parse(json);

      const obj = res.data;
      res.data = plainToClass(cls, obj);

      return res;
    } catch (error) {
      const axiosError = error as unknown as Error as AxiosError;
      this.setErrorMessage(error);
      throw new Error(`Api Call Error => ${axiosError.message}`);
    }
  }

  setHeaderToken(accessToken: string | null, refreshToken?: string) {
    this.accessToken = accessToken;
    
    console.log(accessToken, refreshToken);
    if (CommonUtils.isNullOrEmpty(accessToken)) {
      this.authManager.clearRefreshToken();
      this.authManager.setIsRetry(false);
    } else {
      if (!CommonUtils.isNullOrEmpty(refreshToken)) {
        this.authManager.setRefreshToken(refreshToken);
      }
      
      this.authManager.setIsRetry(true);
    }
  }

  setErrorCallback(callbackFn: (msg: string | null) => void) {
    this.callbackFn = callbackFn;    
  }

  setErrorMessage(error: unknown) {

    if (!(error instanceof Error))
      return;              

    const msg: string | null = error.message;

    if (CommonUtils.isNullOrEmpty(this.callbackFn))
      return;

    this.callbackFn(msg);
  }
  /*
  async call<T>(c: { new(): Unboxed<T> }, method: HttpMethods, url: string, requestParams?: RequestParameter): Promise<AxiosResponse<T, any>> {
 
    const res = await this.request({ method: method, url: url, params: requestParams?.params, headers: requestParams?.headers, data: requestParams?.body });
 
    res.data = this.toClass<T>(c, res.data);
    return res;
  }
 
  private toClass<T>(c: { new (): Unboxed<T> }, json: string) {
    const obj = JSON.parse(json);
    return plainToInstance(c, obj);
  }
  */
}

type Unboxed<T> = T extends (infer U)[] ? U : T;

type RequestParameter = {
  headers?: AxiosRequestHeaders;
  body?: string | object; //Json
  form?: FormData; //FormData
  params?: Record<string, string | number | boolean | null>;
};

export default RestClient;

export type { RequestParameter };
