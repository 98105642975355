<script setup lang="ts">
// ANCHOR - MAIN LEFT
// 좌측 대 메뉴
// 조명공간, 시나리오, 시뮬레이션, 재료

import { ref, reactive, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { Depth1_Kind, Depth2_Kind, EditorMode, MenuType } from "@/global/enums";
import Menu from "@/models/menu";
import { useMenuStore } from "@/stores/menu-store";
import { useEditStore } from "@/stores/edit-store";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import CommonUtils from "@/utils/common-util";
import MainSetting from "@/components/Main/MainSetting.vue";
import MainInfo from "@/components/Main/MainInfo.vue";

const editStore = useEditStore();
const menuStore = useMenuStore();
const nodeEditorStore = useNodeEditorStore();
const isSetting = ref(false);
const isInfo = ref(false);

const test = reactive(menuStore);

const router = useRouter();

// const mainMenus: Menu[] = setMenu(menuStore.menuList as Array<Menu>);
//const maintMenuRef = reactive(mainMenus);

const activeColor = ref([] as Array<string>);

const etcMenuList: Menu[] = getEtcMenuList();


// const LeftInfoMenu = [
//   {
//     menuKind: "설정",
//     isEnabled: true,
//     name: "설정",
//     icon: "ico_set_nor.png",
//     //path: "setting",
//   },
//   {
//     menuKind: "정보",
//     isEnabled: true,
//     name: "정보",
//     icon: "ico_info_nor.png",
//     //path: "infomation",
//   },
// ];

// const infoMenu: Array<Menu> = setMenu(LeftInfoMenu as Array<Menu>);

// function setMenu(menuList: Array<Menu>) {
//   const menus: Menu[] = [];
//   menuList.map((v) => {
//     const menu = new Menu();
//     menu.name = v.name;
//     menu.menuKind = v.menuKind;
//     menu.icon = v.icon;
//     menu.path = v.path;
//     menu.isEnabled = true;
//     if (v.childMenu) {
//       if (v.childMenu.length > 0) {
//         console.log("isIn");
//         menu.childMenu = setMenu(v.childMenu);
//       }
//     }

//     menus.push(menu);
//   });

//   return menus;
// }

// function mainMenuSelect(list: Menu) {
//   maintMenuRef.map((v) => (v.isSelected = false));
//   list.isSelected = true;

//   const mainMenuBtn = document.getElementsByClassName("MainMenuBtn");

//   for (let i = 0; i < mainMenuBtn.length; i++) {
//     const el = mainMenuBtn[i] as HTMLElement;
//     if (el.className.includes("SelectMainMenuBorder")) {
//       el.className = el.className.split("SelectMainMenuBorder")[0];
//     }
//   }

//   // for (let i = 0; i < mainMenuBtn.length; i++) {\}
//   for (let i = 0; i < mainMenuBtn.length; i++) {
//     const findBtn = mainMenuBtn[i] as HTMLElement;
//     // mainMenuBtnconsole.log(mainMenuBtn[i].innerText.split("\n"));
//     if (findBtn.innerText.split("\n")[0] === list.name) {
//       findBtn.className += " SelectMainMenuBorder";
//       break;
//     }
//   }

//   nodeEditorStore.setSelectionNode(null);
//   editStore.setSelectSpaceInfo(null);

//   if (list.childMenu.length <= 0) {
//     router.push({ name: list.path });
//   }
// }

onBeforeMount(() => {

  //로컬 저장소에서 메뉴를 가져온다.
  const menu = menuStore.getStorageMenu();
    
  if (CommonUtils.isNullOrEmpty(menu))
    return;

  onSelectedMenu(menu);
});

function getEtcMenuList() {
  const etcMenuList: Menu[] = [];

  const settingMenu = new Menu();
  settingMenu.menuKind = Depth1_Kind.설정;
  settingMenu.isEnabled = true;
  settingMenu.name = "설정";
  settingMenu.icon = "ico_set_nor.png";

  const infoMenu = new Menu();
  infoMenu.menuKind = Depth1_Kind.정보;
  infoMenu.isEnabled = true;
  infoMenu.name = "정보";
  infoMenu.icon = "ico_info_nor.png";

  etcMenuList.push(settingMenu);
  etcMenuList.push(infoMenu);

  return etcMenuList;
}

function onSelectedMenu(menu: Menu) {  
  if (CommonUtils.isNullOrEmpty(menu))
    return;

  if (CommonUtils.isNullOrEmpty(menu.menuKind))
    return;

  editStore.stopWatch();
  menuStore.selectionMenu(menu.menuKind);    
  editStore.startWatch();
}

function classChecker(isOver: boolean, isEnabled: boolean, isSelected: boolean){

  let className = "";

  if(isSelected === true){
    className += "isSelected ";
  }

  if(isEnabled === true){
    className += "isEnabled ";
  }

  if(isOver === true){
    className += "isOver ";
  }

  return className;

}

function onSelectionEtcMenu(menu: Menu) {
  //TODO: 팝업으로 프로그램 설정창 또는 프로그램 정보 띄움.

  console.log(menu);

  if(menu.menuKind === Depth1_Kind.설정){
    isSetting.value = true;
  } else if(menu.menuKind === Depth1_Kind.정보){
    isInfo.value = true;
  }

}


function settingClose(){
  isSetting.value = false;
}

function infoClose(){
  isInfo.value = false;
}

/*
function MainPathOnChild(idx: number, path: string | undefined, inPath: string, menuList: Array<Menu>) {
  menuList.map((v) => (v.isSelected = false));
  menuList[idx].isSelected = !menuList[idx].isSelected;
  for (let i = 0; i < activeColor.value.length; i++) {
    activeColor.value[i] = "grey-1";
  }

  activeColor.value[idx] = "blue-8";

  editStore.setSelectMainKey(path);
  pageStore.setEditStateName(inPath);
  editStore.setEditFuncKey(inPath as string);

  const subMenuList = document.getElementsByClassName("SubMenuBtn");

  for (let i = 0; i < subMenuList.length; i++) {
    const btn = subMenuList[i] as HTMLElement;
    btn.style.backgroundColor = "#0f1837";
  }

  for (let i = 0; i < subMenuList.length; i++) {
    const findBtn = subMenuList[i] as HTMLElement;
    if (findBtn.innerText === menuList[idx].name) {
      findBtn.style.backgroundColor = "#f8f8f820";
    }
  }

  nodeEditorStore.setSelectionNode(null);
  // editStore.setSelectSpaceInfo(null);

  router.push({ name: path });
}
*/
</script>

<template>
    <div>
      <q-expansion-item
        v-for="menu in menuStore.menuList"
        :key="menu.menuKind"
        dense
        dense-toggle
        :expand-icon="'arrow_drop_down'"
        group="MainMenuGroup"
        :icon="`img:${menu.image}`"
        :header-class="`MainMenuBtn ${classChecker(menu.isOver as boolean, menu.isEnabled as boolean, menu.isSelected as boolean)} ${menu.isSelected === true ? 'SelectMainMenuBorder' : ''}`"
        :expand-icon-class="`MainMenuExpand ${menu.childMenu.length > 0 ? '' : 'isHide'}`"
        :style="{ width: '100%', fontSize: '10px' }"
        @mouseover="menu.isOver = true"
        @mouseout="menu.isOver = false"
        @click="onSelectedMenu(menu as Menu)"
      >
        <template v-slot:header>
          <div>
            <q-icon size="24px" :name="`img:${menu.image}`" />
            <div class="GPL__side-btn__label">{{ menu.name }}</div>
          </div>
        </template>
        <q-btn
          v-for="subMenu in menu.childMenu"
          :key="subMenu.menuKind"
          :class="`SubMenuBtn ${classChecker(subMenu.isOver as boolean, subMenu.isEnabled as boolean, subMenu.isSelected as boolean)}`"
          @mouseover="subMenu.isOver = true"
          @mouseout="subMenu.isOver = false"
          @click="onSelectedMenu(subMenu as Menu)"
        >
          <div class="column" :style="{ width: '100%', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', lineHeight: 'normal' }">
            <q-icon size="24px" :name="`img:${subMenu.image}`" />
            <div :style="{marginTop: '5px'}">{{ subMenu.name }}</div>
          </div>
        </q-btn>
      </q-expansion-item>
    </div>

    <!-- <div class="q-pt-sm q-px-sm column">
      <q-btn
        v-for="(list, i) in testMenuRef"
        :key="list.menuKind"
        :class="`leftListButton`"
        round
        flat
        stack
        no-caps
        size="30px"
        :text-color="activeColor[i]"
        class="GPL__side-btn"
        :disable="!list.isEnabled"
        @mouseover="list.isOver = true"
        @mouseout="list.isOver = false"
        @click="MainPath(i, list.path)"
      >
        <q-icon size="40px" :name="`img:${list.image}`" />
        <div class="GPL__side-btn__label">{{ list.name }}</div>
      </q-btn>


    </div> -->
    <div class="column infoArea">
      <q-separator class="bg-black"/>
      <div class="column infoButtons">
        <q-btn
          v-for="(menu, i) in etcMenuList"
          :key="`infoList${i}`"
          class="settingBtn"
          @mouseover="menu.isOver = true"
          @mouseout="menu.isOver = false"
          @click="onSelectionEtcMenu(menu as Menu)"
          unelevated
          square
          :glossy="false"
        >
          <q-icon size="24px" :name="`img:${menu.image}`" color="grey-1" />
        </q-btn>
      </div>
      <MainSetting :setting="isSetting" @settingClose="settingClose"></MainSetting>
      <MainInfo :info="isInfo" @infoClose="infoClose"></MainInfo>
    </div>

</template>

<style lang="scss">
.MainLeft {
  background-color: #0f1837;
  overflow: hidden !important;

  .enabledText{
    color: #dbe3ff;
  }

  .selectedText{
    color: #3bb3ff;
  }

  .MainMenuBtn {
    padding: 0;
    display: block;
    position: relative;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 10px;
    height: 66px;
    color: #515f93;
    
    &.isEnabled {
      color: #515f93;
    }

    &.isOver {
      color: #dbe3ff;
    }

    &.isSelected {
      color: #3bb3ff;
    }


    .GPL__side-btn__label{
      font-size: 10px;
    }

    &.SelectMainMenuBorder {
      // border-left-color: #3bb3ff;
      background-image: linear-gradient(to right, #3bb3ff 4px, transparent 4px);
    }

    .MainMenuExpand {
      position: absolute;
      font-size: 24px;
      rotate: -45deg;
      right: -6px;
      bottom: -6px;
      padding: 0;
      transform: none;

      &.isHide{
        display: none;
      }

      .q-expansion-item__toggle-icon {
        font-size: 24px;
        transform: none;
      }
    }
  }

  .SubMenuBtn {
    background-color: #0f1837;
    color: #515f93;
    width: 100%;
    align-items: center;
    font-size: 10px;

    &.isEnabled {
      color: #515f93;
    }

    &.isOver {
      color: #3bb3ff;
    }

    &.isSelected {
      color: #dbe3ff;
    }

  }

  .infoArea {
    width: 100%;

    .infoButtons {
      padding: 15px;
    }
    .infoButtons:hover {
      font-size: 70px;
    }

    .settingBtn {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
</style>
