import AbstractActionAttrs from "./abstract-action-attrs";

/**
 * 조명 제어 속성 클래스
 * @class
 */
class LightAttrs extends AbstractActionAttrs {
  onoff = 0;                      //ONOFF
  level = 0;                      //디밍 레벨
  colortemp = 4000;               //색온도
  tt = 100;                       //Transition Time
}

export default LightAttrs;