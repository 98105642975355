
<script setup lang="ts">
import type { OptionsType } from "@/global/types";
import ZoneSmlWindoor from "@/models/entity/zone-sml-windoor";
import { useCommonStore } from "@/stores/common-store";
import { useSimulationStore } from "@/stores/simulation-store";
import CommonUtils from "@/utils/common-util";
import type { QTable, QTrProps } from "quasar";
import { computed, ref } from "vue";

const simulationStore = useSimulationStore();
const commonStore = useCommonStore();

/**
*  시뮬레이션 정보
*/
const smlData = computed(() => {
  return simulationStore.selectZoneSml;
});

const isWindoorAdd = ref(false);
const isWindoorModify = ref(true);

/**
*  대표 창호 선택 옵션
*/
const mainWindoorSelect = computed(() => {
  const lists = smlData.value.zoneSmlWindoors;
    
  const result = [] as OptionsType[];
  if(lists !== null){
    lists?.forEach(v => {
      result.push({
        label: v.windoorName as string,
        value: v.windoorSeq as number,        
      });
    });
  }
  return result;
});

function getOptionCode(code: string){
  return commonStore.getOptionTypes(code);
}

/**
*  창호 리스트 테이블 Column 명
*/
const column = ref([
  {name: "index", label: "No.", align:"center" as const, field: "index"},
  {name: "창호명", label: "창호명", align:"center" as const, field: "windoorName"},
  {name: "방위", label: "방위", align:"center" as const, field: "azimuthCode"},
  {name: "길이", label: "길이[m]", align:"center" as const, field: "horizSize"},
  {name: "높이", label: "높이[m]", align:"center" as const, field: "vertiSize"},
  {name: "유리종류", label: "유리 종류", align:"center" as const, field: "glassTypeCode"},
  {name: "하단높이", label: "하단 높이[m]", align:"center" as const, field: "bottomHeight"},
  {name: "개수", label: "개수", align:"center" as const, field: "windoorQty"},
  {name: "차양작동방법", label: "차양 작동 방법", align:"center" as const, field: "blindEquipCode"},
]);

const selectedRow = ref([] as ZoneSmlWindoor[]);



const newWindoor = ref(new ZoneSmlWindoor());
const modifyWindoor = ref({} as ZoneSmlWindoor);
const windoorRef = ref({} as QTable);

/**
*  창호 추가
*/
function addWindoor(){
  //   console.log(smlData);
    
  const {isEmpty} = CommonUtils.isObjectValueEmpty(newWindoor.value);
  if(isEmpty){
    return;
  }
    
  smlData.value.zoneSmlWindoors?.push(newWindoor.value);
  resetNewWindoor();
}

/**
*  창호 추가 트리거
*/
function addWindoorTrigger(){
  windoorRef.value.selected?.splice(0);
    
  const rowList = document.querySelectorAll("[id*=\"windoorRow\"]");
    
  rowList.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });
    
  isWindoorModify.value = false;
  isWindoorAdd.value = true;
  modifyWindoor.value = {} as ZoneSmlWindoor;
    
}

/**
* 창호 수정
*/
function changeWindoor(){
  const windoorData = smlData.value.zoneSmlWindoors as ZoneSmlWindoor[];
    
  for(let i = 0; i < windoorData.length; i++){
    if(windoorData[i].windoorSeq === modifyWindoor.value.windoorSeq){
      windoorData[i] = modifyWindoor.value;
      break;
    }
  }
    
  isWindoorModify.value = false;
    
  modifyWindoor.value = {} as ZoneSmlWindoor;
}

/**
*  창호 추가 정보 초기화
*/
function resetNewWindoor(){
  //   isWindoorAdd.value = false;
    
  newWindoor.value = new ZoneSmlWindoor();
}

function getSelectedString () {
  return selectedRow.value.length === 0 ? "" : `${selectedRow.value.length}개가 선택되었습니다.`;
}

/**
*  선택 창호 삭제
*/
function deleteRow(){
  if(selectedRow.value.length <= 0){
    alert("삭제할 창호를 선택해주세요.");
    return;
  }
    
  const zoneSmlWindoorList = smlData.value.zoneSmlWindoors as ZoneSmlWindoor[];
    
  selectedRow.value.map(v => {
    for(let i = 0; i < zoneSmlWindoorList.length; i++){
      if(zoneSmlWindoorList[i].windoorSeq === v.windoorSeq){
        resetMainWindoor(zoneSmlWindoorList[i]);
        zoneSmlWindoorList.splice(i, 1);
        break;
      }
    }
  });
    
  selectedRow.value = [] as ZoneSmlWindoor[];
  modifyWindoor.value = {} as ZoneSmlWindoor;
} 

/**
* 대표 창호 정보 초기화
* @param windoorInfo  // 창호 정보
*/
function resetMainWindoor(windoorInfo: ZoneSmlWindoor){
  if(windoorInfo.windoorSeq === smlData.value.reprWindoorSeq){
    smlData.value.reprWindoorSeq = undefined;
    smlData.value.atriumApplyYn = "N";
    smlData.value.dblSkinApplyYn = "N";
    smlData.value.atriumDepth = undefined;
    smlData.value.atriumHeight = undefined;
    smlData.value.atriumWidth = undefined;
    smlData.value.horizFinCode = "FA00";
    smlData.value.horizOverhangCode = "OA00";
    smlData.value.vertiObstacleCode = "HA00";
  }
}

/**
* 창호 정보 변경
* @param row  // 선택된 창호 정보
* @param props  // 창호 테이블 정보
*/
function changeWindoorInfo(row: ZoneSmlWindoor, props: QTrProps["props"]){
  console.log(props, windoorRef);
  isWindoorAdd.value = false;
  windoorRef.value.selected?.splice(0);
    
  if(props.selected === false){
    props.selected = true;
  }
    
  const windoorRows = document.querySelectorAll("[id*=\"windoorRow\"]");
    
  for(let i = 0; i < windoorRows.length; i++){
    const windoorRow = windoorRows[i] as HTMLElement;
    if(windoorRow.id === `windoorRow${row.windoorSeq}`){
      windoorRow.style.backgroundColor = "#e2e6f3";
      isWindoorModify.value = true;
      modifyWindoor.value = CommonUtils.deepClone(row);
    } else {
      windoorRow.style.backgroundColor = "#ffffff";
    }
  }
}

/**
* 창호 정보 수정 초기화
*/
function changeWindoorCancel(){
  //   isWindoorModify.value = false;
  const windoorRows = document.querySelectorAll("[id*=\"windoorRow\"]");
    
  windoorRows.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });
    
  modifyWindoor.value = {} as ZoneSmlWindoor;
}

</script>

<template>
    <div class="SimulationWindoorsComponent row">
        <div class="SimulationWindoorsLeft col-xs-12 col-md-7">
            <div class="headerLabel">
                <label>창호 정보</label>
            </div>
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="SimulationWindoorsLeftContents" >
                <div class="row windoorListBtn">
                    <label>창호 리스트</label>
                    <div>
                        <q-btn flat square icon="add" @click="addWindoorTrigger"></q-btn>
                        <q-btn flat square icon="remove" @click="deleteRow"></q-btn>
                    </div>
                </div>
                <div>
                    <q-table 
                    flat
                    square
                    ref="windoorRef"
                    :columns="column" 
                    :rows="smlData.zoneSmlWindoors || []" 
                    :row-key="'windoorSeq'"
                    :no-data-label="'등록된 창호가 없습니다.'" 
                    :dark="false" 
                    :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
                    :rows-per-page-options="[0]" 
                    :selected-rows-label="getSelectedString"
                    selection="multiple"
                    v-model:selected="selectedRow"
                    :table-header-class="'SimulationWindoorsTableHeader'"
                    class="SimulationWindoorsTable"
                    hide-pagination>
                    <template v-slot:header-selection="props">
                        <td>
                            <q-checkbox size="xs" v-model="props.selected"></q-checkbox>
                        </td>
                    </template>
                    <template v-slot:body="props">
                        <tr :id="`windoorRow${props.row.windoorSeq}`" :style="{textAlign: 'center', cursor:'pointer'}" @click="changeWindoorInfo(props.row, props)">
                            <td>
                                <q-checkbox size="xs" v-model="props.selected"></q-checkbox>
                            </td>
                            <td>{{props.rowIndex + 1}}</td>
                            <td>{{props.row.windoorName}}</td>
                            <td>{{commonStore.getFindCodeToName('azimuthCode',props.row.azimuthCode) }}</td>
                            <td>{{props.row.horizSize}}</td>
                            <td>{{props.row.vertiSize}}</td>
                            <td>{{commonStore.getFindCodeToName('glassTypeCode',props.row.glassTypeCode)}}</td>
                            <td>{{props.row.bottomHeight}}</td>
                            <td>{{props.row.windoorQty}}</td>
                            <td>{{commonStore.getFindCodeToName('blindEquipCode',props.row.blindEquipCode)}}</td>
                        </tr>
                    </template>
                </q-table>
                <!-- <q-btn @click="deleteRow">삭제</q-btn> -->
            </div>
        </div>
        
        <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
        <div v-if="isWindoorAdd === true" class="SimulationWindoorsLeftContentsInput">
            
            <label>창호 상세 정보</label>
            <div class="row col-12">
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">창호명</label>
                    <q-input class="col-8" square dense outlined v-model="newWindoor.windoorName" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">방위</label>
                    <q-select class="col-8" square dense outlined v-model="newWindoor.azimuthCode" :options="getOptionCode('azimuthCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">유리 종류</label>
                    <q-select class="col-8" square dense outlined v-model="newWindoor.glassTypeCode" :options="getOptionCode('glassTypeCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">차양 작동 방법</label>
                    <q-select class="col-8" square dense outlined v-model="newWindoor.blindEquipCode" :options="getOptionCode('blindEquipCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">가로 크기[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="newWindoor.horizSize" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">세로 크기[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="newWindoor.vertiSize" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">하단 높이[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="newWindoor.bottomHeight" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">개수</label>
                    <q-input class="col-8" type="number" square dense outlined :dark="false" v-model.number="newWindoor.windoorQty" bg-color="white"></q-input>
                </div>
            </div>
            <div class="row justify-end addWindoorBtn">
                <q-btn flat square class="addbtn" @click="addWindoor" :style="{margin: '5px'}">추가</q-btn>
                <q-btn flat square @click="resetNewWindoor" :style="{margin: '5px'}">취소</q-btn>
            </div>
        </div>
        <div v-if="isWindoorModify === true" class="SimulationWindoorsLeftContentsInput">
            
            <label>창호 상세 정보</label>
            <div class="row col-12">
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">창호명</label>
                    <q-input class="col-8" square dense outlined v-model="modifyWindoor.windoorName" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">방위</label>
                    <q-select class="col-8" square dense outlined v-model="modifyWindoor.azimuthCode" :options="getOptionCode('azimuthCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">유리 종류</label>
                    <q-select class="col-8" square dense outlined v-model="modifyWindoor.glassTypeCode" :options="getOptionCode('glassTypeCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">차양 작동 방법</label>
                    <q-select class="col-8" square dense outlined v-model="modifyWindoor.blindEquipCode" :options="getOptionCode('blindEquipCode')" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">가로 크기[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="modifyWindoor.horizSize" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">세로 크기[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="modifyWindoor.vertiSize" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">하단 높이[m]</label>
                    <q-input class="col-8" type="number" square dense outlined v-model.number="modifyWindoor.bottomHeight" :dark="false" bg-color="white"></q-input>
                </div>
                <div class="SimulationWindoorsRow row col-xs-12 col-md-6">
                    <label class="col-4">개수</label>
                    <q-input class="col-8" type="number" square dense outlined :dark="false" v-model.number="modifyWindoor.windoorQty" bg-color="white"></q-input>
                </div>
            </div>
            <div class="row justify-end addWindoorBtn">
                <q-btn flat square class="addbtn" @click="changeWindoor" :style="{margin: '5px'}">수정</q-btn>
                <q-btn flat square @click="changeWindoorCancel" :style="{margin: '5px'}">취소</q-btn>
            </div>
        </div>
    </div>
    
    <div class="SimulationWindoorsRight col-xs-12 col-md-5">
        <div class="headerLabel">
            <label>대표 창호 정보</label>
        </div>
        <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
        
        <div class="SimulationWindoorRightContents">
            
            <div class="col-12 q-py-sm q-px-md">
                <label>대표 창호</label>
                <div class="SimulationWindoorRightContentsRow row">
                    <q-select class="col-4" square dense outlined :dark="false" v-model="smlData.reprWindoorSeq" :options="mainWindoorSelect"  bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                    <q-checkbox size="xs" v-model="smlData.dblSkinApplyYn" :true-value="'Y'" :false-value="'N'" :label="'이중 외피 적용'"></q-checkbox>
                </div>
            </div>
            
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            
            <div class="col-12 q-py-sm q-px-md">
                <div class="SimulationInHeaderLabel">
                    <label>음영 각도[˚]</label>
                </div>
                
                <div class="SimulationWindoorRightContentsRow row">
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>주변 건물</label>
                        <q-select square dense outlined :dark="false" v-model="smlData.horizFinCode" :options="getOptionCode('horizFinCode')" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                    </div>
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>수평(상부)</label>
                        <q-select square dense outlined :dark="false" v-model="smlData.horizOverhangCode" :options="getOptionCode('horizOverhangCode')" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                    </div>
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>수직(측면)</label>
                        <q-select square dense outlined :dark="false" v-model="smlData.vertiObstacleCode" :options="getOptionCode('vertiObstacleCode')" bg-color="white" :popup-content-style="{color: '#404040'}" map-options emit-value></q-select>
                    </div>
                </div>
            </div>
            
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="col-12 q-py-xs q-px-md">
                <div class="SimulationInHeaderLabel">
                    <q-checkbox size="xs" v-model="smlData.atriumApplyYn" :true-value="'Y'" :false-value="'N'" :label="'중정/아트리움 정보'"></q-checkbox>
                </div>
                <div v-if="smlData.atriumApplyYn === 'Y'" class="SimulationWindoorRightContentsRow row">
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>폭[m]</label>
                        <q-input square dense type="number" outlined :dark="false" v-model.number="smlData.atriumWidth" bg-color="white"></q-input>
                    </div>
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>깊이[m]</label>
                        <q-input square dense type="number" outlined :dark="false" v-model.number="smlData.atriumDepth" bg-color="white"></q-input>
                    </div>
                    <div class="col-4 SimulationWindoorRightContentsItem">
                        <label>수직[m]</label>
                        <q-input square dense type="number" outlined :dark="false" v-model.number="smlData.atriumHeight" bg-color="white"></q-input>
                    </div>
                </div>
            </div>
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="col-12 row q-py-xs q-px-md ">
                <div class="col-4 sampleImg">
                    <q-img :src="'/windoor1.png'" width="150px" height="150px" :fit="'contain'" :style="{width: '100%'}"></q-img>
                    <label>주변 건물</label>
                </div>
                <div class="col-4 sampleImg">
                    <q-img :src="'/windoor2.png'" width="150px" height="150px" :fit="'contain'" :style="{width: '100%'}"></q-img>
                    <label>수평(상부)</label>
                </div>
                <div class="col-4 sampleImg">
                    <q-img :src="'/windoor4.png'" width="150px" height="150px" :fit="'contain'" :style="{width: '100%'}"></q-img>
                    <label>중정/아트리움</label>
                </div>
                <div class="col-4 sampleImg">
                    <q-img :src="'/windoor3.png'" width="150px" height="150px" :fit="'contain'" :style="{width: '100%'}"></q-img>
                    <label>수직(측면)</label>
                </div>
            </div>
            
            <!-- <div class="col-12 q-py-xs q-px-md SimulationShapeContents">
                도형들
            </div> -->
        </div>
    </div>
</div>
</template>

<style lang="scss">
.SimulationWindoorsComponent{
    background-color: #f2f4fb;
    color: #404040;
    height: 100%;
    
    .SimulationWindoorsLeft{
        background-color: #e2e6f3;
        border-right: 1px solid #c1c1c1;
        font-size: 13px;
        .headerLabel{
            padding: 10px 15px 10px 15px;
            background-color: #f2f4fb;
            height: 40px;
            label{
                font-size: 15px;
                font-weight: bold;
            }
        }
        .SimulationWindoorsLeftContents{
            padding: 0px 15px 15px 15px;
            .SimulationWindoorsTable{
                th{
                    font-size: 13px;
                }
                td{
                    font-size: 12px;
                }
            }
            .SimulationWindoorsTableHeader{
                background-color: #f2f4fb;
                font-size: 13px;
            }
            .windoorListBtn{
                padding: 10px 0px 10px 0px;
                justify-content: space-between;
                align-items: center;
                button{
                    background-color: #172041;
                    margin-left: 5px;
                    color: #ffffff;
                    
                }
            }
            label{
                font-size: 14px;
                font-weight: bold;
            }
        }
        
        .SimulationWindoorsLeftContentsInput{
            padding: 15px;
            label{
                font-size: 13px;
                font-weight: bold;
            }
            
            .addWindoorBtn{
                button{
                    background-color: #172041;
                    color: #ffffff;
                }
                .addbtn{
                    background-color: #3bb3ff;
                }
            }
            
            .SimulationWindoorsRow{
                align-items: center;
                padding: 5px;
                
                .q-field{
                    margin-bottom: 0px;
                }
            }
        }
    }
    
    .SimulationWindoorsRight{
        background-color: #e2e6f3;
        .headerLabel{
            padding: 10px 15px 10px 15px;
            background-color: #f2f4fb;
            height: 40px;
            label{
                font-size: 15px;
                font-weight: bold;
            }
        }
        .SimulationWindoorRightContents{
            .SimulationInHeaderLabel{
                padding: 5px 0px;
            }
            .SimulationWindoorRightContentsRow{
                
                .SimulationWindoorRightContentsItem{
                    padding: 5px 10px 5px 0px;
                }
            }
            
            label{
                font-size: 13px;
                font-weight: bold;
            }
            
        }
        
        .sampleImg{
            text-align: center;
            padding: 10px;
            img{
                padding: 5px;
            }
            label{
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
    
}
</style>