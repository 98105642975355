export enum MenuType {
  Site = 10,
  Scenario = 20,
}

export enum ViewType {
  SecondDimension = "2D",
  ThirdDimension = "3D",
}

export enum EditFunc {
  Zone = "zone",
  LightAuto = "light",
  Group = "group",
  Scene = "scene",
}

export enum EditorMode {
  None,
  ReadyMove,
  ObjectMoving,
  DrawBoxMoving,
  Joining,
  Pasting,
  GroupMode,
}

export enum SvgButtonVisibility {
  Hidden = "hidden",
  Visible = "visible",
}

export enum NodeStatus {
  Normal,
  Selected,
}

export enum ComponentKind {
  Device,
  Node,
}

export enum InitState {
  NotLoad,  
  Completed,
}

/**
 * 장비종류
 */
export enum DeviceKind {
  Light = "LD",
  Sensor = "SD",
  Gateway = "GW",
}

/**
 * 대상 타입
 */
export enum TargetType {
  Device = "Device",
  Group = "Group",
}

export enum DeviceShape {
  CIRCLE = "CIRCLE",
  SQUARE = "SQUARE",
}

export enum LightType {
  천장등,
  테이블스탠드,
  플로어스탠드,
  벽등,
  외벽등,
  정원등,
  계단매입등,
  바닥등,
}

export enum LightControlType {
  OnOff = "OnOff",
  Dimming = "Dimming",
}

/**
 * 노드 타입
 */
export enum NodeType {
  Image = "Image",
  Text = "Text",
  Point = "Point",
  Link = "Link",
  Scenario = "Scenario",
  Package = "Package",
  Shape = "Shape"
}

/**
 * 노드 요소 타입
 */
export enum NodeElementType {
  Text = "Text",
  Device = "Device",
  Link = "Link",
  Scenario_Executor = "SCEX",
  Scenario_Scheduler = "SCHD",
  Scenario_Presence_SensorDevice = "SNPR",  
  Scenario_ExternalChannel = "EXCH",
  Scenario_TimePeriod = "TMBR",
  Scenario_Condition = "CNBR",
  Scenario_SequenceExecute = "SQEX",
  Scenario_ControlLight = "LGCN",
  Scenario_ControlScene = "SCCN",
  Scenario_Configure_Light = "LGCF",
  Scenario_Configure_Presense_Sensor = "SNCF",  
  Scenario_DeviceGroup = "GRUP",
  Scenario_Device = "DEVC",
}

export enum NodeAlignMode {
  Left,
  Center,
  Right,
  Top,
  Middle,
  Bottom,
}

export enum NodeIntervalMode {
  Horizontal,
  Vertical,
}

export enum ScenarioExecutorEventType {
  Init = "Init",
  Start= "Start",
  Pause= "Pause",
  Resume= "Resume",
  Stop= "Stop",
  Final= "Final",
}


//enum값은 dev_typec_code와 동일하게함.
export enum SensorType {
  Presence = "PRESENSE-SENSOR",
  ILLumiation = "ILLUMI-SENSOR",
}

// export enum ScenarioSensorEventType {
//   P = "P",        //인지
//   T = "T",        //인지 없음 변경 전
//   N = "N"         //인지 없음  
// }

export enum PresenceEventType {
  P = "P",        //인지
  T = "T",        //인지 없음 변경 전
  N = "N"         //인지 없음
}

export enum PhotoresistorEventType {
  U = "U",        //상한
  D = "D",        //하한
}


export enum ExeActionKind {
  Sequence = "sequence",
  Parallel = "parallel",
}

/**
 * 세팅파일 저장위치
 */
export enum SettingLocationKind {
  File,
  LocalStorage,
}

/**
 * HTTP 메소드
 */
export enum HttpMethods {
  Get = "get",
  Post = "post",
  Put = "put",
  Delete = "delete",
  Patch = "patch",
  Head = "head",
}

/**
 * 방향
 */
export enum Direction {
  Left,
  Right,
  Up,
  Down,
}

export enum PortType {
  In,
  Out,
}

export enum PersistItemKeys {
  SiteSeq = "siteSeq",
  RSpaceSeq  = "r_spaceSeq",
  Site = "site",
  RSpace  = "r_space",
  StorageSpace = "storage_space",
  Menu = "menu",
  CommonCodes = "common_codes",
}

export enum AuthTokenType {
  AccessToken = "access_token",
  RefreshToken = "refresh_token",
  IsRetry = "is_retry",
}

export enum Depth1_Kind {
  조명공간 = "조명공간",
  시나리오 = "시나리오",
  시뮬레이션 = "시뮬레이션",
  기본구성 = "기본구성",
  설정 = "설정",
  정보 = "정보",
}

export enum Depth2_Kind {
  조명배치 = "조명배치",
  그룹 = "그룹",
  씬 = "씬",
  공간편집 = "공간편집",
  조명 = "조명",
  창호 = "창호",
  벽체 = "벽체",
  용도프로필 = "용도프로필",
}

export enum Depth3_Kind {  
  //뎁스3_1 = "뎁스3_1",
  //뎁스3_2 = "뎁스3_2",
}

export enum TopBar_Action_Kind {
  공간추가 = "공간추가",
  조명자동추가 = "조명자동추가",
  그룹추가 = "그룹추가",
  그룹멤버편집 = "그룹멤버편집",
  씬추가 = "씬추가",
  시나리오추가 = "시나리오추가",
  시나리오삭제 = "시나리오삭제",
  시나리오실행 = "시나리오실행",
  시뮬레이션실행 = "시뮬레이션실행",
  트랙레코드비교분석 = "트랙레코드비교분석",
  조명추가 = "조명추가",
  벽체추가 = "벽체추가",
  창호추가 = "창호추가",
  씬ONOFF = "씬ONOFF",
  용도프로필추가 = "용도프로필추가",
}

export enum ZoneTypeCode {
  대표존 = "R",
  묶음존 = "C",
  단위존 = "U",
}

export enum SizeType {
  Horizontal,
  Vertical,
  Height
}

export enum NodeEventType {
  Drop,
  Added,
  Removed,
  Moved,  
  Copying,  
  Paste,
}

export enum Day {
  Sun = "일",
  Mon = "월",
  Tue = "화",
  Wed = "수",
  Thu = "목",
  Fri = "금",
  Sat = "토",
}

export enum  TimeType {
  Sunrise = "SR_TIME",      //일출 시간
  SunSet = "SS_TIME",       //일몰 시간
  Custom = "Custom"         //사용자 지정 시간
}
