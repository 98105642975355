import { defineStore } from "pinia";
import Profile from "../models/profile";

export interface optionsForm {
  value?: string;
  label?: string;
  color: string;
}

export const useMaterialsStore = defineStore("materials", {
  state: () => ({
    materialsKey: "" as string,
    // NOTE - 용도프로필 리스트
    profileList: [] as Array<Profile>,
    selectProfileID: null as string | null,
    selectProfileInfo: {} as Profile,
    profileOption: [] as optionsForm[]
  }),
  getters: {
    getProfileList(state) {
      return state.profileList;
    },
    getProfileID(state) {
      return state.selectProfileID;
    },
    getSelectProfile(state) {
      return state.profileList.find((profile) => profile.usageProfileCode === state.selectProfileID) as Profile;
    },
    getProfileOptions(state){
      return state.profileOption;
    }
  },
  actions: {
    // NOTE - 모든 재료 정보 받기
    init(){
      this.getUsageProfileApi();
    },

    // NOTE - 용도프로필 데이터 받기
    async getUsageProfileApi(){
      const res = await this.apiServices.etcApiService.getUsageProfileCode();

      this.profileList = res as Profile[]; 

      this.profileOption = this.makeProfileOptions();
    },

    getProfileData(profileID: string){
      for(let i = 0; i < this.profileList.length; i++){
        if(this.profileList[i].usageProfileCode === profileID){
          return this.profileList[i];
        }
      }

      return {} as Profile;
    },

    setSelectProfileData(profileID: string){
      for(let i = 0; i < this.profileList.length; i++){
        if(this.profileList[i].usageProfileCode === profileID){
          this.selectProfileID = profileID;
          this.selectProfileInfo = this.profileList[i];
        }
      }

      // return {} as Profile;
    },

    addProfile() {
      this.profileList.push(new Profile);
      console.log("저장 버튼 선택");
    },

    setProfileID(profileId: string) {
      this.selectProfileID = profileId;
    },

    removeProfile(profileId: string) {
      const startIndex = this.profileList.findIndex((e) => e.usageProfileCode === profileId);
      this.profileList.splice(startIndex, 1);
      this.selectProfileID = null;
      this.selectProfileInfo = {} as Profile;
      console.log("삭제");
    },

    makeProfileOptions() {
      console.log("options make");
      const profileList = this.profileList;

      const options: Array<optionsForm> = [];

      profileList?.forEach((profile: Profile) => {
        options.push({
          value: profile.usageProfileCode,
          label: profile.usageProfileName,
          color: "dark"
        });
      });

      return options;
    },

    addProfileItem(profile: Profile) {
      this.profileList.push(profile);

      console.log("용도프로필 추가 버튼");
    },
  }
});
