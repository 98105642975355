<script setup lang="ts">
import { ref, computed } from "vue";

import TopBarComponent from "@/components/common/TopBar/TopBarComponent.vue";

import type { QTableProps } from "quasar";

const name = ref("");
const description = ref("");

const rows = [
  { email: "이중 단열 유리", name: "30", birthDate: "2", regDate1: "60", regDate2: "0", regDate: "20220711" },
  { email: "이중 단열 유리", name: "30", birthDate: "2", regDate1: "60", regDate2: "0", regDate: "20220711" },
];

const columns: QTableProps["columns"] = [
  { name: "email", label: "벽체 재료명", field: "email", align: "center" },
  { name: "name", label: "속성", field: "name", align: "center" },
  { name: "birthDate", label: "두께[m]", field: "birthDate", align: "center" },
  { name: "regDate1", label: "열전도율[W/(m·k)]", field: "regDate", align: "center" },
  { name: "regDate2", label: "열저항[㎡·k/W]", field: "regDate", align: "center" },
  { name: "regDate", label: "제거", field: "regDate", align: "center" },
];

const rows1 = [
  { email: "1", name: "콘크리트(1:2:4)", birthDate: "마감", gender: "0.038", division: "0", regDate: "20220711" },
  { email: "2", name: "시멘트모르타르(1:3)", birthDate: "마감", gender: "0.029", division: "0", regDate: "20220711" },
  { email: "3", name: "콘크리트(1:2:4)", birthDate: "마감", gender: "0.038", division: "0", regDate: "20220711" },
  { email: "4", name: "시멘트모르타르(1:3)", birthDate: "마감", gender: "0.029", division: "0", regDate: "20220711" },
];

const columns1: QTableProps["columns"] = [
  { name: "email", label: "번호", field: "email", align: "center" },
  { name: "name", label: "재료명", field: "name", align: "center" },
  { name: "birthDate", label: "속성", field: "birthDate", align: "center" },
  { name: "gender", label: "열전도율[W/(m·k)]", field: "gender", align: "center" },
  { name: "division", label: "열저항[㎡·k/W]", field: "division", align: "center" },
  { name: "regDate", label: "추가", field: "regDate", align: "center" },
];

const tab = ref("deviceLight");
</script>

<template>
  <div class="WallContentsComponent">
    <div style="padding: 40px 40px 40px 0px">
      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 벽체 명칭 : </span>
        <q-input label-color="black" outlined v-model="name" placeholder="벽체 명칭" class="col-10" maxlength="26" />
      </div>

      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 벽체 설명 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="벽체 설명" class="col-10" maxlength="26" />
      </div>

      <div class="row">
        <span class="col-2" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 벽체 타입 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="벽체 타입" class="col-3" maxlength="26" />

        <span class="col-1" style="margin: 14px 0 0 0; text-align: right; padding: 0 25px 0 0"> 열관류율 : </span>
        <q-input label-color="black" outlined v-model="description" placeholder="열관류율" class="col-3" maxlength="26" />
      </div>

      <div style="margin: 30px 0 0 50px">
        <label style="font-size: 14px; font-weight: bold">[ 구성 정보 ]</label>
        <q-table :rows="rows" :columns="columns" row-key="name" virtual-scroll bordered style="margin: 10px 0 0px 0" hide-pagination>
          <template v-slot:header-cell="props">
            <q-th :props="props" style="font-weight: bolder; font-size: 13px">
              {{ props.col.label }}
            </q-th>
          </template>

          <template v-slot:body-cell-regDate="props">
            <q-td :props="props">
              <q-btn round icon="delete" size="0.9em" />
            </q-td>
          </template>
        </q-table>
      </div>

      <div style="margin: 50px 0 0 50px; border: 1px solid #ffffff; padding: 20px" class="borderSection">
        <label style="font-size: 15px; font-weight: bold" class="FormsubTitle">재료 리스트</label>

        <q-table :rows="rows1" :columns="columns1" row-key="name" virtual-scroll bordered style="margin: 10px 0 0px 0" hide-pagination>
          <template v-slot:header-cell="props">
            <q-th :props="props" style="font-weight: bolder; font-size: 13px">
              {{ props.col.label }}
            </q-th>
          </template>

          <template v-slot:body-cell-regDate="props">
            <q-td :props="props">
              <q-btn round icon="add" size="0.9em" />
            </q-td>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.WallContentsComponent {
  .FormsubTitle {
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .contentsContainer {
    p:nth-child(1) {
      font-size: 15px;
      font-weight: bold;
      padding: 5px;
    }
  }

  .borderSection {
    border: 1px solid white;
    padding: 30px 30px 10px 30px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    label:nth-child(1) {
      position: absolute;
      top: -19px;
      background-color: #474c5b;
    }
  }

  .q-table__title {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
