class GrpMember{
  dpointSeq?: number;
  grpSeq?: number;
  memberSeq?: number;
  memberTypeCode?: string;
  modDate?: string;
  modUserId?: number;
  moduleSeq?: number;
  regDate?: string;
  regUserId?: number;
  siteSeq?: number;
  zoneSeq?: number;

  constructor(siteSeq: number, zoneSeq: number, grpSeq: number, dpointSeq: number, moduleSeq: number | undefined, memberTypeCode: string, userID: number){
    this.dpointSeq = dpointSeq;
    this.grpSeq = grpSeq;
    this.memberSeq = Math.floor(Math.random() * 10000 + 10000);
    this.modDate = new Date().toISOString();
    this.modUserId = userID;
    this.moduleSeq = moduleSeq;
    this.memberTypeCode = memberTypeCode;
    this.regDate = new Date().toISOString();
    this.regUserId = userID;
    this.siteSeq = siteSeq;
    this.zoneSeq = zoneSeq;
  }
}

export default GrpMember;