<script setup lang="ts">
import type LinkNodeConfig from "@/models/editor/nodes/config/link-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import type LinkNodeInfo from "@/models/editor/nodes/info/link-node-info";
import { reactive } from "vue";
import { onMounted } from "vue";

const props = defineProps<{
  nodeInfo: AbstractNodeInfo,
}>();

const nodeInfoRef = reactive(props.nodeInfo as LinkNodeInfo);
const nodeConfig = nodeInfoRef.nodeConfig as LinkNodeConfig;

onMounted(() => {      
});

</script>
<template>
  <g pointer-events="stroke">

    <!-- 기본 선 -->
    <path v-for="(link, i) of nodeInfoRef.getLinks()" :key="i" :d="link.d" class="base_line" stroke-width="3" stroke="#ff7f0e" style="fill: transparent;" />
  </g>
</template>
<style lang="scss">
</style>