import type { MenuKind } from "@/global/types";
import type Zone from "./entity/zone";

class SnapShot {
  menuKind: MenuKind | null = null;  
  zone: Zone | null = null;
  selectZoneSeq: number | null = null;
  selectZoneType: string | null = null;
  scenarioSeq: number | null  = null;
  sceneSeq: number | null = null;
  sceneSelectGrpSeq: number | null = null;

  constructor() {

  }
}

export default SnapShot;