

/**
 * Scene flow 클래스
 * @class
 */
class ScenarioFlow {
  // siteSeq: number;
  // zoneSeq: number;
  // scenarioSeq: number;  
  // flowSeq: number;
  eventCode?: string;
  flowParameterMap?: string;  
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;  
  sourceNodeSeq?: number;
  targetNodeSeq?: number;
  constructor(readonly siteSeq: number, readonly zoneSeq: number, readonly scenarioSeq: number, readonly flowSeq: number) {

  }
}
  
export default ScenarioFlow;
  