<script setup lang="ts">
import {ref, onMounted, computed, onUpdated} from "vue";
import SVGPathCommander from "svg-path-commander";
import CommonUtils from "@/utils/common-util";
import SpaceShape from "@/settings/spaceshape.json";

const props = defineProps<{
  shapeType: string,
  shapeParameter: {x1: number, x2: number, y1: number, y2: number},
  shapeImg: string,
  polygonPath: {x:number, y:number}[]
}>();

const emits = defineEmits(["SetBoundPath"]);

const shapeType = computed(() => {
  return props.shapeType;
});

const canvasData = ref(null as HTMLCanvasElement | null);
const ctx = ref();

const pathValue = ref();

const shapeParameterList = computed(() => {
  return props.shapeParameter;
});

const polygonPathValue = computed(() => {
  let pathString = "";
  for(let i = 0; i < props.polygonPath.length; i++){
    pathString += `${props.polygonPath[i].x},${props.polygonPath[i].y} `;
  }  

  const splitPoint = pathString.split(/\s+|,/);
  const x0 = splitPoint.shift();
  const y0 = splitPoint.shift();

  const pathData = `M${x0},${y0}L` + splitPoint.join(" ") + "z";

  console.log(pathData);

  return pathString;

});



onMounted(() => {
  // canvasData.value = document.getElementById("shapeCanvas") as HTMLCanvasElement;
  // ctx.value = canvasData.value.getContext("2d");
});

onUpdated(() => {
  // clearPath();
  // if(shapeType.value === "rect") defaultShapeDraw();
  // if(shapeType.value === "angleRect") drawShape();
});

// function clearPath(){
//   ctx.value.clearRect(0, 0, canvasData.value?.width, canvasData.value?.height);
// }

// function defaultShapeDraw(){
//   ctx.value.fillStyle = "#f00";
//   ctx.value.beginPath();
//   ctx.value.moveTo(0, 0);
//   ctx.value.lineTo(0, shapeParameterList.value.y1);
//   ctx.value.lineTo(shapeParameterList.value.x1, shapeParameterList.value.y1);
//   ctx.value.lineTo(shapeParameterList.value.x1, 0);
//   ctx.value.closePath();
//   ctx.value.fill();
// }

// function drawShape(){
//   ctx.value.fillStyle = "#0f0";
//   ctx.value.beginPath();
//   ctx.value.moveTo(0,0);
//   ctx.value.lineTo(0,shapeParameterList.value.y1);
//   ctx.value.lineTo(shapeParameterList.value.x1,shapeParameterList.value.y1);
//   ctx.value.lineTo(shapeParameterList.value.x1,shapeParameterList.value.y2);
//   ctx.value.lineTo(shapeParameterList.value.x2,shapeParameterList.value.y2);
//   ctx.value.lineTo(shapeParameterList.value.x2,0);
//   ctx.value.closePath();
//   ctx.value.fill();
// }

</script>

<template>
  <div class="ShapeCanvas">
    <div class="ShapeTypeCheck row">
      <div class="col-5">
      <q-img :src="shapeImg" :fit="'contain'"></q-img>
      <!-- <div class="ShapeParameter">
        <div class="ParameterRow row">
          <label>X : </label>
          <q-input type="number" v-model="shapeParameterList.x1" :dark="false" dense outlined></q-input>
          <label>Y : </label>
          <q-input type="number" v-model="shapeParameterList.y1" :dark="false" dense outlined></q-input>
        </div>
      </div> -->
    </div>
    <div class="col-2">

    </div>
    <div class="col-5">
      <div>
        미리보기
      </div>
      <svg v-if="shapeType === 'L-shape'">
      <polygon
        id="polygon"
        :points="`0,0 0,${shapeParameterList.y1} ${shapeParameterList.x1},${shapeParameterList.y1} ${shapeParameterList.x1},${shapeParameterList.y1 - shapeParameterList.y2} ${shapeParameterList.x1 - shapeParameterList.x2},${shapeParameterList.y1 - shapeParameterList.y2} ${shapeParameterList.x1 - shapeParameterList.x2},0`"
        fill="red"
      >
      </polygon>
    </svg>
    <svg v-else-if="shapeType === 'A-shape'">
      <polygon
        id="polygon"
        :points="`0,0 ${shapeParameterList.x1},0 ${shapeParameterList.x1},${shapeParameterList.y1} ${shapeParameterList.x2},${shapeParameterList.y1} ${shapeParameterList.x2},${shapeParameterList.y2} 0,${shapeParameterList.y2}`"
        fill="red"
      >
      </polygon>
    </svg>
    <svg v-else-if="shapeType === 'polygon'">
      
      <polygon
        id="polygon"
        :points="polygonPathValue"
        fill="red"
      >
      </polygon>
    </svg>
    <svg v-else>
      <polygon
        id="polygon"
        :points="`0,0 0,${shapeParameterList.y1} ${shapeParameterList.x1},${shapeParameterList.y1} ${shapeParameterList.x1},${shapeParameterList.y1 - shapeParameterList.y2} ${shapeParameterList.x1 - shapeParameterList.x2},${shapeParameterList.y1 - shapeParameterList.y2} ${shapeParameterList.x1 - shapeParameterList.x2},0`"
        fill="red"
      >
      </polygon>
    </svg>
    </div>
    </div>
 
    <!-- <button @click="test">convert</button> -->
    <!-- <canvas id="shapeCanvas"></canvas> -->
 
  </div>
</template>

<style lang="scss">
.ShapeCanvas{
  width: 100%;
  .ShapeParameter{
    
  }
}
</style>