<script setup lang="ts">
import type DevAttr from "@/models/entity/dev-attr";
import { useCommonStore } from "@/stores/common-store";
import type { optionsForm } from "@/stores/materials-store";

const commonStore = useCommonStore();

const props = defineProps<{
    devAttrData: DevAttr
}>();





</script>

<template>
    <div class="DeviceAttrFormComponent">
        <q-form v-if="props.devAttrData !== undefined">
            <div>
                <label>ID</label>
                <q-input v-model="props.devAttrData.baseAttrSeq" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Name</label>
                <q-input v-model="props.devAttrData.attrName" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>DataType</label>
                <q-input v-model="props.devAttrData.attrType" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Unit</label>
                <q-input v-model="props.devAttrData.unit" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Resource</label>
                <!-- <q-input v-model="props.devAttrData.attrGrpCode" square outlined dense :dark="false"></q-input> -->
                <q-select v-model="props.devAttrData.attrGrpCode" :options="commonStore.getCodeList('attrGrpCode')"
                square outlined dense :dark="false" map-options
                :popup-content-style="{color: '#404040'}"
                :bg-color="'white'"
                ></q-select>
            </div>
            <div>
                <label>Default</label>
                <q-input v-model="props.devAttrData.defaultVal" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <q-checkbox v-model="props.devAttrData.readOnlyYn" label="Read Only" :true-value="'Y'" :false-value="'N'"/>
            </div>
            <div>
                <label>Min</label>
                <q-input v-model="props.devAttrData.minVal" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Max</label>
                <q-input v-model="props.devAttrData.maxVal" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Step</label>
                <q-input v-model="props.devAttrData.step" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Max Length</label>
                <q-input v-model="props.devAttrData.maxLen" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Option Value</label>
                <q-input v-model="props.devAttrData.attrType" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
            <div>
                <label>Description</label>
                <q-input v-model="props.devAttrData.attrDesc" square outlined dense :dark="false" :bg-color="'white'"></q-input>
            </div>
        </q-form>
    </div>
</template>

<style lang="scss">
.DeviceAttrFormComponent{
    color: #404040;
    padding: 25px;
}
</style>