

class SimulationResult {
  consumes: [{
        diffVal: number;
        month: string;
        predConsume: number;
        realUsage: number;
    }] = [{diffVal: 0, month: "", predConsume: 0, realUsage: 0}];
  cvrmse?: number;
  mbe?: number;
}

export default SimulationResult;