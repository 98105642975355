<script setup lang="ts">
import { NodeElementType, ExeActionKind, SensorType, NodeType } from "@/global/enums";
import { onMounted, reactive, ref, watchEffect, watch, type Ref, computed } from "vue";
import type ScenarioScheduleNodeSetting from "@/models/editor/nodes/setting/scenario-schedule-node-setting";
import type { ScenarioSchedule } from "@/models/editor/nodes/setting/scenario-schedule-node-setting";

import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useScenarioStore } from "@/stores/scenario-store";
import { useQuasar } from "quasar";

import type { OptionsType } from "@/global/types";
import CommonUtils from "@/utils/common-util";
import NodeProps from "@/models/scenario/node-props";
import AddScheduleModal from "@/components/Scenario/Modal/AddScheduleModal.vue";
import AddTimeBranchModal from "@/components/Scenario/Modal/AddTimeBranchModal.vue";
import type ScenarioTimeBranchNodeSetting from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import type ScenarioSequenceExecuteNodeSetting from "@/models/editor/nodes/setting/scenario-sequence-execute-node-setting";
import type SensorDevice from "@/models/entity/sensor-device";
import type ScenarioControlLightNodeSetting from "@/models/editor/nodes/setting/scenario-control-light-node-setting";
import type ScenarioConfigurePresenseSensorNodeSetting from "@/models/editor/nodes/setting/scenario-configure-presence-sensor-node-setting";
import type ScenarioNodeInfo from "@/models/editor/nodes/info/scenario-node-info";
import type { TimeBranchEvent } from "@/models/editor/nodes/setting/scenario-time-branch-node-setting";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { useEditStore } from "@/stores/edit-store";
import type ScenarioPresenceSensorDeviceNodeSetting from "@/models/editor/nodes/setting/scenario-presence-sensor-device-node-setting";
import type DPoint from "@/models/entity/d-point";
import type SensorDeviceInfo from "@/models/device/sensor-device-info";
import { TimeType, TargetType, LightControlType } from "@/global/enums";
import type AbstractActionAttrs from "@/models/attrs-maps/abstract-action-attrs";
import type ScenarioControlSceneNodeSetting from "@/models/editor/nodes/setting/scenario-control-scene-node-setting";
import type ScenarioConfigureLightNodeSetting from "@/models/editor/nodes/setting/scenario-configure-light-node-setting";

const editStore = useEditStore();
const nodeEditorStore = useNodeEditorStore();
const scenarioStore = useScenarioStore();

const selectedOptions = ref<string[]>([]);
const options: OptionsType[] = reactive([]);

const sensorTypes: OptionsType[] = reactive([
  { label: "인지", value: SensorType.Presence },
  { label: "조도", value: SensorType.ILLumiation },
]);
const sensorActionModes: OptionsType[] = reactive([
  { label: "액션 실행", value: 0 },
  { label: "이벤트 보고", value: 1 },
  { label: "액션 실행 및 이벤트 보고", value: 2 },
]);

// const sceneOptions = computed(() => {
//   getSceneOptionTypes    
// });

const selectedSensorOption = ref(sensorTypes[0] as OptionsType);
const selectedSensorActionModeOption = ref(sensorActionModes[0] as OptionsType);

const selectedSceneOption = ref<OptionsType | null>(null);
const selectedSensorDevice = ref<SensorDeviceInfo | null>(null);

const sensorDevices: SensorDevice[] = [];
const sensorDevicesOptions: OptionsType[] = reactive([]);

const testValue = ref(0);
const val = ref();
const radio = ref();

const trueValue = ref(true);

const nodeProps = reactive(new NodeProps());

const isEdit = ref(false);
const isScheduleModalOpen = ref(false);
const isTimeBranchEventModalOpen = ref(false);
const isNew = ref(false);
const $q = useQuasar();

const scheduleRef = ref<ScenarioSchedule | null>(null);
const timeBranchEventRef = ref<TimeBranchEvent | null>(null);

const selectedTaskCode = ref<string | null>(null);

const deviceModelNameRef = ref("");



onMounted(() => {
  watchEffect(async () => {
    if (
      CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo) ||
    CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo.nodeConfig) ||
    CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo.nodeSetting)
    ) {
      deviceModelNameRef.value = "";
      nodeProps.clear();
      return;
    }
            
    const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;    
    
    console.log(`노드 선택  ====> id: ${nodeInfo.id}, name: ${nodeInfo.name}`);
    const optionsTypes = nodeInfo.getOptionsTypes();
    options.splice(0);

    for(const optionsType of optionsTypes) {
      options.push(optionsType);
    }    

    nodeProps.name = nodeInfo.name;
    nodeProps.desc = nodeInfo.nodeConfig.description;
    nodeProps.type = nodeInfo.nodeConfig.name;   
    
    selectedOptions.value = nodeInfo.getSelectedOptionValues();    
    
    if(nodeInfo.nodeConfig.nodeElementType !== NodeElementType.Scenario_Presence_SensorDevice && nodeInfo.nodeConfig.nodeElementType !== NodeElementType.Scenario_Configure_Presense_Sensor)
      return;
    
    const sensorDevices = scenarioStore.getSensorDevices(SensorType.Presence);
    deviceModelNameRef.value = sensorDevices.find(s => s.dpointSeq === nodeInfo.scenarioNode.dpointSeq)?.devModelName ?? "";
  });
});

/**
 * 노드 이름 변경
 * @param nodeName 
 */
function onChangeNodeName(nodeName: string) {
  scenarioStore.updateSelectedNodeName(nodeName);
}


/**
 * 선택 노드 아이템의 체크 상태에 따른 이벤트 업데이트
 */
function onChangeSelectionNodeItemEvent() {
  if (CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo))
    return;

  const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;

  const events = nodeInfo.getSelectionNodeItemEvents();

  if (CommonUtils.isNullOrEmpty(events))
    return;

  scenarioStore.updateSelectionNodeItemEvents(nodeInfo, selectedOptions.value, events);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterSensorDevice(value: string, update: any) {
  update(() => {
    const needle = value.toLowerCase();
    
    sensorDevicesOptions.splice(0);
    
    const filterData = sensorDevices.filter((v) => v.name.toLowerCase().indexOf(needle) > -1);
    
    for (const d of filterData) {
      sensorDevicesOptions.push(d);
    }
  });
}

function onAddSchedule() {
  //scheduleRef.value = new ScenarioSchedule(CommonUtils.generateUUID(), "", "00:00");
  scheduleRef.value = null;
  isScheduleModalOpen.value = true;
  isNew.value = true;
}

function onEditSchedule(schedule: ScenarioSchedule) {
  
  scheduleRef.value = schedule;
  isScheduleModalOpen.value = true;
  isNew.value = false;
}

function onDeleteSchedule(schedule: ScenarioSchedule) {

  $q.dialog({ title: "스케줄 삭제", message: "해당 스케줄을 삭제하시겠습니까?", ok: "OK", cancel: "Cancel" })
    .onOk(() => {
      const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;
      scenarioStore.removeNodeItemEvent(nodeInfo, schedule.code);                  
    })
    .onCancel(() => {
    })
    .onDismiss(() => {
    });  

}

/**
 * 시간대 분기 이벤트 추가
 */
function onAddTimeBranch() {
  
  timeBranchEventRef.value = null;
  isTimeBranchEventModalOpen.value = true;
  isNew.value = true;
}

/**
 * 시간대 분기 이벤트 수정
 * @param timeBranchEvent 
 */
function onEditTimeBranchEvent(timeBranchEvent: TimeBranchEvent) {
  timeBranchEventRef.value = timeBranchEvent;
  isTimeBranchEventModalOpen.value = true;
  isNew.value = false;
}

/**
 * 시간대 분기 이벤트 삭제
 * @param timeBranchEvent 
 */
function onDeleteTimeBranchEvent(timeBranchEvent: TimeBranchEvent) {
  $q.dialog({ title: "이벤트 삭제", message: "해당 이벤트를 삭제하시겠습니까?", ok: "OK", cancel: "Cancel" })
    .onOk(() => {
      const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;
      scenarioStore.removeNodeItemEvent(nodeInfo, timeBranchEvent.code);

      console.log("이벤트 삭제 실행");
    })
    .onCancel(() => {
    })
    .onDismiss(() => {
    });
}

function onChangeSequence(isDown = true) {

  if(CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo))
    return;

  const nodeSetting = nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioSequenceExecuteNodeSetting;

  if(CommonUtils.isNullOrEmpty(nodeSetting) || CommonUtils.isNullOrEmpty(selectedTaskCode.value))
    return;  

  nodeSetting.updateSequence(selectedTaskCode.value, isDown);
}

function onUpdateSensorDevice() {
  
  
  if(CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo))
    return;

  const nodeInfo = nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo;

  const nodeSetting = nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioPresenceSensorDeviceNodeSetting;

  if (CommonUtils.isNullOrEmpty(nodeSetting))
    return;

  const dPointSeq = selectedSensorDevice.value?.dpointSeq ?? null;
  scenarioStore.updateNodeSetting(nodeInfo, [dPointSeq]);

  //const dPointSeq = selectedSensorDevice.value?.dpointSeq ?? null;
  //nodeSetting.setDPointSeq(dPointSeq);

  //nodeEditorStore.selectionNodeInfo.node
}

function onChangeAttrs() {

  if(CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo))
    return;

  scenarioStore.updateAttrsMap(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo);    
}

</script>

<template>
  <div class="ScenarioNodeSettingComponent" v-if="!CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo)">
    
    <q-expansion-item header-class="ScenarioNodeInfoExpansion" expand-icon-class="ScnearioNodeInfoExpansionIcon" :dark="false" dense expand-separator default-opened label="시나리오 노드 정보">
      <div class="ScnearioNodeInfoContents">
        <label>명칭</label>
        <q-input
        type="text"
        maxlength="30"
        outlined
        :model-value="nodeEditorStore.selectionNodeInfo.name"
        square
        :dark="false"
        bg-color="white"
        placeholder="노드 명칭"
        @change="onChangeNodeName($event)"        
        />
        
        <label>제공자 타입</label>
        <q-input square :dark="false" bg-color="white" type="text" maxlength="30" outlined :model-value="nodeEditorStore.selectionNodeInfo.nodeConfig.name" :disable="true" />
        
        <label>설명</label>
        <q-input
        square
        :dark="false"
        bg-color="white"
        maxlength="50"
        outlined
        dense
        :model-value="nodeEditorStore.selectionNodeInfo.nodeConfig.description"
        :disable="true"
        placeholder="노드 설명"
        />
      </div>
      
      <q-separator />
      
      <!-- 시나리오 실행자  -->
      <div
      class="q-pa-sm ScenarioNodeExcuter"
      v-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Executor"
      >
      <div class="row">
        <label>이벤트 정보</label>
      </div>
      <div class="row groupBox">
        <q-option-group
        :dark="false"
        size="xs"
        color="grey-7"
        :options="options"
        type="checkbox"
        v-model="selectedOptions"
        @update:model-value="onChangeSelectionNodeItemEvent"
        />
      </div>
    </div>
    
    <!-- 스케줄러 -->
    <div
    class="q-pa-sm ScenarioNodeSchedule"
    v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Scheduler"
    >
    
    <label>스케줄 정보</label>
    <div class="row-* groupBox q-mt-sm">
      <div class="row justify-between ScenarioScheduleBtn" v-if="!isEdit">
        <q-btn unelevated square @click="isEdit = !isEdit">편집</q-btn>
        <q-btn unelevated square icon="add" @click="onAddSchedule" style="font-size: 0.6em"></q-btn>
      </div>
      
      <div class="row justify-between ScenarioScheduleBtn" v-if="isEdit">
        <q-btn unelevated square @click="isEdit = !isEdit">완료</q-btn>
      </div>
      
      <div class="row-*">
        <q-list separator>
          <q-item
          class="ScnearioNodeScheduleItems"
          :dark="false"
          v-for="(schedule, i) of (nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioScheduleNodeSetting).schedules"
          :key="i"
          >
          <q-item-section side>
            <q-item-label :style="{fontSize: '20px'}">{{ i + 1 }}</q-item-label>
          </q-item-section>
          
          <q-item-section>
            <q-item-label>{{ schedule.name }}</q-item-label>
            <q-item-label style="font-size: 11px;" lines="2">{{ schedule.time }}, {{ schedule.getDayOfWeeksText() }}</q-item-label>
          </q-item-section>
          <q-item-section side style="padding: 0px" v-if="isEdit">
            <div class="ScenarioNodeScheduleItemsButton">
              <q-btn round unelevated icon="edit" size="xs" :dark="false" @click="onEditSchedule(schedule)"></q-btn>
              <q-btn round unelevated icon="delete" size="xs" :dark="false" @click="onDeleteSchedule(schedule)"></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
  
  
  
</div>

<!-- 센서(인지) 디바이스 -->
<div
class="q-pa-sm ScenarioNodeSensorDevice"
v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Presence_SensorDevice"
>
<label>센서 디바이스 정보</label>



<div class="row  q-mt-sm">
  <label>관제점</label>
</div>

<div class="row-*">
  <q-select
  square
  :dark="false"
  bg-color="white"
  outlined
  dense
  use-input
  fill-input
  hide-selected
  hide-bottom-space
  v-model="(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo).scenarioNode.dpointSeq"  
  :popup-content-style="{color: '#404040'}"
  options-dense
  emit-value
  map-options
  input-debounce="0"
  @filter="filterSensorDevice"
  :options="scenarioStore.getSensorDevices(SensorType.Presence)"  
  option-label="dpointName"
  option-value="dpointSeq"
  >
  <template v-slot:no-option>
    <q-item>
      <q-item-section class="text-grey"> No results </q-item-section>
    </q-item>
  </template>
</q-select>
</div>

<div class="row-* q-mt-sm">
  <label>모델명</label>
</div>
<div class="row-*">
  <q-input square :dark="false" bg-color="white" type="text" maxlength="30" outlined v-model="deviceModelNameRef" :disable="true" />
</div>

<div class="row q-mt-sm">
  <label>이벤트 정보</label>
</div>

<div class="row-* groupBox">
  <q-option-group
  :dark="false"
  color="grey-7"
  size="xs"
  :options="options"
  type="checkbox"
  v-model="selectedOptions"
  @update:model-value="onChangeSelectionNodeItemEvent"
  />
</div>

<!-- <div style="border: 
  1px solid #ffffff;padding: 10px;">          
  
  <q-list bordered separator>
    <q-item>
      <q-item-section side>
        <q-item-label>1</q-item-label>
      </q-item-section>
      
      <q-item-section>
        <q-item-label>이벤트 정보</q-item-label>
        <q-item-label style="font-size: 11px; padding: 1px;" lines="2"></q-item-label>
      </q-item-section>
      <q-item-section side style="padding: 0px;" v-if="isEdit">
        <div>
          <q-btn round icon="edit" size="xs"></q-btn>
          <q-btn round icon="delete" size="xs"></q-btn>
        </div>
      </q-item-section>
    </q-item>
  </q-list>
</div> -->
</div>

<!-- 분기 (시간대) -->
<div class="q-pa-sm ScenarioTimePeriod" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_TimePeriod">
  <label>시간대 정보</label>
  
  <div class="row-* groupBox q-mt-sm ScenarioTimePeriodSchedule">
    <div class="row justify-between ScenarioTimePeriodScheduleBtn" v-if="!isEdit">
      <q-btn square unelevated @click="isEdit = !isEdit">편집</q-btn>
      <q-btn square unelevated icon="add" @click="onAddTimeBranch" style="font-size: 0.6em"></q-btn>
    </div>
    <div class="row justify-between ScenarioTimePeriodScheduleBtn" v-if="isEdit">
      <q-btn square unelevated @click="isEdit = !isEdit">완료</q-btn>
    </div>
    
    <div class="row-*">
      <q-list>
        <q-item
        class="ScnearioTimePeriodScheduleItems"
        v-for="(timeEvent, i) of (nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioTimeBranchNodeSetting).timeEvents"
        :key="i"
        >
        <q-item-section side :dark="false">
          <q-item-label :style="{fontSize: '20px'}">{{ i + 1 }}</q-item-label>
        </q-item-section>
        
        <q-item-section>
          <q-item-label>{{ timeEvent.name }}</q-item-label>
          <q-item-label style="font-size: 11px;" lines="2">{{ timeEvent.startTimeType === TimeType.Custom ? timeEvent.startTime : timeEvent.startTimeType }} - {{ timeEvent.endTimeType === TimeType.Custom ? timeEvent.endTime : timeEvent.endTimeType }}</q-item-label>
        </q-item-section>
        <q-item-section side style="padding: 0px" v-if="isEdit">
          <div>
            <q-btn round unelevated icon="edit" size="xs" :dark="false" style="background-color: #172041;" @click="onEditTimeBranchEvent(timeEvent)"></q-btn>
            <q-btn round unelevated icon="delete" size="xs" :dark="false" style="background-color: #172041;" @click="onDeleteTimeBranchEvent(timeEvent)"></q-btn>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
  
</div>
</div>

<!-- Action Task (순차 실행) -->
<div class="q-pa-sm ScenarioActionTask" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_SequenceExecute">
  <div class="row">
    <label>실행 타입</label>
  </div>
  
  <div class="row q-mt-sm ScenarioActionTaskSchedule">
    <label>시간대 정보</label>
  </div>
  
  <div class="groupBox q-pa-sm">
    <div class="col">
      <div class="row justify-between ScenarioActionTaskScheduleBtn">
        <q-btn square unelevated icon="arrow_upward" size="xs" @click="onChangeSequence(false)" />
        <q-btn square unelevated icon="arrow_downward" size="xs" @click="onChangeSequence()"/>
      </div>
    </div>
    
    <q-list :dark="false">
      <q-item
      clickable
      v-ripple      
      v-for="(task, i) of (nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioSequenceExecuteNodeSetting).tasks"
      :key="i"
      class="ScenarioActionTaskScheduleItems"
      :active="selectedTaskCode === task.code"
      @click="selectedTaskCode = task.code"
      active-class="selected-task"
      >
      <q-item-section side>
        <q-item-label>{{ i + 1 }}</q-item-label>
      </q-item-section>
      
      <q-item-section>
        <q-item-label>{{ task.name }}</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</div>
</div>

<!-- Control Light -->
<div class="q-pa-sm ScenarioLightControl" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_ControlLight">
  <div class="row">
    <label>조명 제어 정보</label>
  </div>
  <div class="row">    
    <q-checkbox v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).selectionAttrs[0].isSelected" @update:model-value="onChangeAttrs()" size="xs" label="제어 방법" dense :true-value="true" :false-value="false"/>
  </div>

  <div class="q-pa-sm groupBox">

    <div class="row">      
      <div class="col-5">
        <q-radio label="ON/OFF" :val="LightControlType.OnOff" v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).controlType" @update:model-value="onChangeAttrs()" size="xs" :dark="false" dense/>        
      </div>
      <div class="col">
        <q-toggle
          v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).attrs.onoff"
          :true-value=1
          :false-value=0
          color="green"
          size="sm"
          :dark="false"
          dense
          :disable="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).controlType !== LightControlType.OnOff"
          @update:model-value="onChangeAttrs()"
          />
      </div>
    </div>

    <div class="row q-pt-md">
      <div class="col-5">
        <q-radio class="col-6" label="디밍 레벨" :val="LightControlType.Dimming" v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).controlType" @update:model-value="onChangeAttrs()" size="xs" :dark="false" dense/>
      </div>
      <div class="col-4" :style="{display: 'flex'}">
        <q-slider
        class="q-mt-sx"
        v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).attrs.level"
        markers
        dense
        :min="0"
        :max="100"
        :step="1"
        color="blue"
        track-color="white"
        track-size="5px"
        thumb-size="10px"
        thumb-path="M0 0h10v20H0Z"
        :disable="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).controlType !== LightControlType.Dimming"
        @update:model-value="onChangeAttrs()"
        />

      </div>
      <div class="col-2 column items-end">
        {{ (nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).attrs.level}}%
      </div>
    </div>
  </div>
  
  <div class="row q-pt-md">
    <div class="col-10">
      <q-checkbox v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).selectionAttrs[1].isSelected" @update:model-value="onChangeAttrs()" size="xs" label="Transition Time[100ms]" dense :true-value="true" :false-value="false" />
    </div>
    <div class="col-2">
      <q-input
      square
      :disable="val"
      :dark="false"
      bg-color="white"
      type="number"
      maxlength="20"
      outlined
      dense
      v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).attrs.tt"
      @update:model-value="onChangeAttrs()"
      />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <q-checkbox v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).selectionAttrs[2].isSelected" @update:model-value="onChangeAttrs()" size="xs" label="색온도[K]" dense :true-value="true" :false-value="false"/>
    </div>
    <div class="col">
      <q-input
      square
      :disable="val"
      :dark="false"
      bg-color="white"
      type="number"
      maxlength="20"
      outlined
      dense
      v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlLightNodeSetting).attrs.colortemp"
      @update:model-value="onChangeAttrs()"
      />
    </div>
  </div>
</div>

<!-- Control Scene -->
<div class="q-pa-sm ScenarioSceneControl" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_ControlScene">
  <div class="row">
    <label>씬 제어 정보</label>
  </div>
  <div class="row q-mt-sm">
    <label>씬</label>
  </div>
  
  <q-select outlined square dense use-input fill-input hide-selected hide-bottom-space v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioControlSceneNodeSetting).attrs.cur_Scene" @update:model-value="onChangeAttrs()" :options="scenarioStore.getSceneOptionTypes" options-dense emit-value map-options option-label="label" option-value="value" :dark="false" bg-color="white" :popup-content-style="{color: '#404040'}"/>
</div>

<!-- Configure Light -->
<div class="q-pa-sm ScenarioLightSettingInfo" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Configure_Light">
  <div class="row"> 
    <label>조명 설정 정보</label>
  </div>
  <div class="row items-center ScenarioLightSettingInputRow">
    <div class="col-9">
      <label>점소등 소요 시간[100ms]</label>
    </div>
    <div class="col-3">
      <q-input square type="number" :dark="false" bg-color="white" maxlength="30" outlined dense v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.on_off_tt" @update:model-value="onChangeAttrs()" :input-style="{marginBottom: '0px'}" />
    </div>
  </div>
  
  <div class="row items-center ScenarioLightSettingInputRow">
    <div class="col-9">
      <label>색온도 변환 시간[100ms]</label>
    </div>
    <div class="col-3">
      <q-input square type="number" :dark="false" bg-color="white" maxlength="30" outlined dense hide-bottom-space v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.color_tt" @update:model-value="onChangeAttrs()" />
    </div>
  </div>
  
  <div class="row items-center ScenarioLightSettingInputRow">
    <div class="col-9">
      <label>조명 출력 비율[%]</label>
    </div>
    <div class="col-3">
      <q-input square type="number" :dark="false" bg-color="white" maxlength="30" outlined dense hide-bottom-space v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.level_rate" @update:model-value="onChangeAttrs()"/>
    </div>
  </div>
  
  <div class="row q-mt-sm">
    <label>목표밝기 도달 시간 설정[100ms]</label>
  </div>
  
  <div class="q-pa-sm column justify-center ScenarioLightSettingGrid" style="border: #ffffff solid 1px">
    <div class="row">
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>디밍업 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.dimup_tt" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>디밍다운 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.dimdown_tt" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row q-mt-sm">
    <label>유지 시간 설정[100ms]</label>
  </div>
  
  <div class="q-pa-sm column justify-center ScenarioLightSettingGrid" style="border: #ffffff solid 1px">
    <div class="row"> 
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>점등 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.on_hold_time" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>소등 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.off_hold_time" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row q-mt-sm">
    <label>대기 시간 설정[100ms]</label>
  </div>
  
  <div class="q-pa-sm column justify-center ScenarioLightSettingGrid" style="border: #ffffff solid 1px">
    <div class="row">
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>점등 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.on_delay_time" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>소등 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.off_delay_time" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row q-mt-sm">
    <label>레벨</label>
  </div>
  
  <div class="q-pa-sm column justify-center ScenarioLightSettingGrid" style="border: #ffffff solid 1px">
    <div class="row">
      <div class="col groupBox">
        <div class="items-center">
          <div class="col-6">
            <label>점등 시</label>
          </div>
          <div class="col self-center ScenarioLightSettingInputRow" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.onlevel" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
      <div class="col groupBox">
        <div class="items-center ScenarioLightSettingInputRow">
          <div class="col-6">
            <label>소등 시</label>
          </div>
          <div class="col self-center" style="margin-right: 10px">
            <q-input square :dark="false" bg-color="white" type="number" maxlength="30" outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigureLightNodeSetting).attrs.offlevel" @update:model-value="onChangeAttrs()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Configure 인지 센서 -->
<div class="q-pa-sm ScenarioSensorInfo" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Configure_Presense_Sensor">
  <div class="row">
    <label>인지 센서 설정 정보</label>
  </div>
  <div class="row q-mt-sm">
    <q-checkbox v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.status" @update:model-value="onChangeAttrs()" label="센서 활성화" size="xs" dense :true-value=1 :false-value=0 />
  </div>
  
  <div class="row q-mt-sm">
    <label>동작모드</label>
  </div>
  <div class="row-*">
    <q-select
    square
    :dark="false"
    bg-color="white"
    outlined
    dense
    use-input
    fill-input
    hide-selected
    hide-bottom-space
    v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.opmode"
    :popup-content-style="{color: '#404040'}"
    options-dense
    emit-value
    map-options
    input-debounce="0"    
    :options="sensorActionModes"
    option-label="label"
    option-value="value"  
    @update:model-value="onChangeAttrs()"
    >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template>
  </q-select>
  </div>
  
  <!-- 인지센서 설정 -->
  <div class="col">
    <div class="row q-mt-sm">
      <label>측정 주기 설정[100ms]</label>
    </div>
    
    <div class="q-pa-sm column justify-center groupBox">
      <div class="row">
        <div class="col">
          <div class="items-center">
            <div class="col-6">
              <label style="font-size: 11px">인지 전</label>
            </div>
            <div class="col self-center" style="margin-right: 10px">
              <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.mperiod" @update:model-value="onChangeAttrs()"/>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="items-center">
            <div class="col-6">
              <label style="font-size: 11px">인지 후</label>
            </div>
            <div class="col self-center" style="margin-right: 10px">
              <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.pperiod" @update:model-value="onChangeAttrs()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row q-mt-sm">
      <label>유지 시간 설정[100ms]</label>
    </div>
    
    <div class="q-pa-sm column justify-center groupBox">
      <div class="row">
        <div class="col">
          <div class="items-center">
            <div class="col-6">
              <label style="font-size: 11px">인지</label>
            </div>
            <div class="col self-center" style="margin-right: 10px">
              <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.agingtime" @update:model-value="onChangeAttrs()"/>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="items-center">
            <div class="col-6">
              <label style="font-size: 11px">Timeout</label>
            </div>
            <div class="col self-center" style="margin-right: 10px">
              <q-input square :dark="false" bg-color="white" type="number" maxlength="30" dense outlined v-model="(nodeEditorStore.selectionNodeInfo.nodeSetting as ScenarioConfigurePresenseSensorNodeSetting).attrs.before_agingtime" @update:model-value="onChangeAttrs()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row q-mt-sm">
    <label>액션 설정</label>
  </div>

  <div class="row groupBox">
    <!-- <q-option-group size="xs" :options="options" type="checkbox" v-model="selectedOptions" @update:model-value="onChangeSelectionNodeItemEvent" /> -->
    <q-option-group
  :dark="false"
  color="grey-7"
  size="xs"
  :options="options"
  type="checkbox"
  v-model="selectedOptions"
  @update:model-value="onChangeSelectionNodeItemEvent"
  />
  </div>
</div>

<!-- Device Group -->
<div class="q-pa-sm ScenarioDeviceGroupInfo" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_DeviceGroup">
  <div class="row">
    <label>그룹 정보</label>
  </div>
  <div class="row q-mt-sm">
    <label>그룹</label>
  </div>
  <div class="row-*">
    <q-select outlined dense square :dark="false" bg-color="white" emit-value map-options v-model="(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo).scenarioNode.targetSeq" @update:model-value="(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo).scenarioNode.targetTypeCode = TargetType.Group" :options="scenarioStore.getGroups()" option-label="grpName" option-value="grpSeq" :popup-content-style="{color: '#404040'}" />
  </div>
</div>

<!-- Device -->
<div class="q-pa-sm ScenarioDeviceInfo" v-else-if="nodeEditorStore.selectionNodeInfo.nodeConfig.nodeElementType === NodeElementType.Scenario_Device">
  <div class="row">
    <label>디바이스 정보</label>
  </div>
  <div class="row q-mt-sm">
    <label>디바이스</label>
  </div>
  <div class="row-*">
    <q-select outlined dense square :dark="false" bg-color="white" emit-value map-options v-model="(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo).scenarioNode.targetSeq" @update:model-value="(nodeEditorStore.selectionNodeInfo as ScenarioNodeInfo).scenarioNode.targetTypeCode = TargetType.Device" :options="scenarioStore.getDevices()" option-label="dpointName" option-value="dpointSeq" :popup-content-style="{color: '#404040'}" />
  </div>
</div>
</q-expansion-item>

<!-- 노드 관련 팝업들 -->
<add-schedule-modal v-model="scheduleRef" :isOpen="isScheduleModalOpen" :isNew="isNew" @update:isOpen="isScheduleModalOpen = false" ></add-schedule-modal>
<add-time-branch-modal v-model="timeBranchEventRef" :isOpen="isTimeBranchEventModalOpen" :isNew="isNew" @update:isOpen="isTimeBranchEventModalOpen = false" ></add-time-branch-modal>
</div>
</template>

<style lang="scss">
.ScenarioNodeSettingComponent {
  .ScnearioNodeInfoExpansionIcon{
    color: #404040;
  }
  
  .ScenarioNodeInfoExpansion {
    background-color: #e2e6f3;
    color: #000000;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 39px;
    .q-item__label {
      font-weight: bold;
    }
  }
  
  .ScnearioNodeInfoContents {
    padding: 20px 20px 0px 20px;
    background-color: #f2f4fb;
    color: #404040;
    label {
      font-weight: bold;
    }
  }
  
  .ScenarioNodeExcuter {
    padding: 20px;
    background-color: #f2f4fb;
    .groupBox {
      background-color: #e2e6f3;
      color: #404040;
    }
    label {
      font-weight: bold;
    }
  }
  
  .ScenarioNodeSchedule {
    background-color: #f2f4fb;
    padding: 20px;
    
    .groupBox{
      padding: 0;
    }
    
    .ScenarioScheduleBtn{
      margin-bottom: 5px;
      button{
        background-color: #172041;
        color: #f2f4fb;
      }
    }
    
    label {
      font-weight: bold;
    }
    .ScnearioNodeScheduleItems { 
      border-bottom: 1px solid #c1c1c1;
      height: 30px;
      padding: 0px 10px 0px 10px;
      font-size: 12px;
      min-height: 40px;
      &:nth-child(1){
        border-top: 1px solid #c1c1c1;
      }
      .q-item__label {
        color: #404040;
      }
      
      .ScenarioNodeScheduleItemsButton{
        button{
          background-color: #172041;
          
          .q-btn__content{
            color: #f2f4fb;
          }
        }
      }
    }
  }
  .ScenarioNodeSensorDevice {
    padding: 20px;
    background-color: #f2f4fb;
    .groupBox {
      background-color: #e2e6f3;
      color: #404040;
    }
    label {
      font-weight: bold;
    }
  }
  .ScenarioTimePeriod{
    padding: 20px;
    label {
      font-weight: bold;
    }
    
    .ScenarioTimePeriodSchedule{
      background-color: #f2f4fb;
      padding: 0px;
      .ScenarioTimePeriodScheduleBtn{
        padding: 0px;
        margin-bottom: 5px;
        button{
          background-color: #172041;
          color: #f2f4fb;
        }
      }
      .groupBox {
        margin-top: 5px;
        color: #404040;
      }
      label {
        font-weight: bold;
      }
      .ScnearioTimePeriodScheduleItems{
        border-bottom: 1px solid #c1c1c1;
        height: 30px;
        padding: 0px 10px 0px 10px;
        font-size: 12px;
        min-height: 40px;
        &:nth-child(1){
          border-top: 1px solid #c1c1c1;
        }
        .q-item__label {
          color: #404040;
        }
      }
    }
  }
  
  .ScenarioActionTask{
    padding: 20px;
    
    label{
      font-weight: bold;
    }
    
    .groupBox{
      margin-top: 10px;
      padding: 0;
    }
    
    .ScenarioActionTaskSchedule{
      
      
    }
    
    .ScenarioActionTaskScheduleBtn{
      padding: 0px;
      margin-bottom: 5px;
      button{
        background-color: #172041;
        color: #f2f4fb;
        padding: 10px;
      }
    }
    
    .ScenarioActionTaskScheduleItems{
      border-bottom: 1px solid #c1c1c1;
      height: 30px;
      padding: 0px 10px 0px 10px;
      font-size: 12px;
      min-height: 40px;
      &:nth-child(1){
        border-top: 1px solid #c1c1c1;
      }
      .q-item__label {
        color: #404040;
      }
    }
  }
  
  .ScenarioLightControl{
    padding: 20px;
    
    label {
      font-weight: bold;
    }
    
    .ScenarioLightControlOnOff{
      align-items: center;
      padding: 10px 0px 10px 0px;
    }
    
    
    .ScenarioLightControlDimming{
      align-items: center;
      padding: 10px 0px 10px 0px;
    }

    .groupBox{
      background-color: #e2e6f3;
      color: #404040;
      padding: 10px;
      border: 0px;
    }
  }
  
  .ScenarioSceneControl{
    padding: 20px;
    
    label{
      font-weight: bold;
    }
  }
  
  .ScenarioLightSettingInfo{
    padding: 20px;
    
    label {
      font-weight: bold;
    }
    
    .groupBox{
      background-color: #e2e6f3;
      color: #404040;
      padding: 10px;
      border: 0px;
    }
    
    .ScenarioLightSettingInputRow{
      padding: 5px 0px 5px 0px;
      
      label{
        font-size: 12px;
      }
      
      .q-input{
        margin-bottom: 0px;
      }
    }
    
    .ScenarioLightSettingGrid{
      padding: 0px;
      border: 0px !important;
      
      .groupBox{
        padding: 5px 10px 5px 10px;
      }
    }
  }
  
  .ScenarioSensorInfo{
    padding: 20px;
    
    label{
      font-weight: bold;
    }
    
    .groupBox{
      background-color: #e2e6f3;
      color: #404040;
      padding: 5px 10px 5px 10px;
      border: 0px;
    }
  }
  
  .ScenarioDeviceGroupInfo{
    padding: 20px;
    
    label{
      font-weight: bold;
    }
  }
  
  .ScenarioDeviceInfo{
    padding: 20px;
    
    label{
      font-weight: bold;
    }
  }
}


.selected-task {
  color: white;
  background: #F2C037;
}

// .setting-box {
  //   border: 1px solid #ffffff;
  //   padding: 10px;
  //   margin: 0 0 20px 0;
  // }
  
</style>
