<script setup lang="ts">
// ANCHOR - MainHeader
// 상단 바
// 사이트 명, 로고, 접속자

import { useEditStore } from "@/stores/edit-store";
import { useMenuStore } from "@/stores/menu-store";
import { useUserStore } from "@/stores/user-store";
import { useQuasar } from "quasar";
import { ref } from "vue";
import { useRouter } from "vue-router";

const confirm = ref(false);
const router = useRouter();
const menuStore = useMenuStore();
const userStore = useUserStore();
const editStore = useEditStore();


const $q = useQuasar();

function toggleLeftDrawer() {
  editStore.setSelectSpaceInfo(null);
  router.push({ name: "site" });
  // leftDrawerOpen.value = !leftDrawerOpen.value;
}

function showLogoutDialog() {
  if (router.currentRoute.value.name?.toString() === "site")
    logout();
  else
    confirm.value = true;
}

async function logout() {  
    
  await userStore.logout();
  router.push({ name: "login" });
}
</script>

<template>
  <q-header class="text-white-8 MainHeader" >
    <q-toolbar class="GPL__toolbar">
      <div v-if="router.currentRoute.value.name?.toString() !== 'site'" :style="{ display: 'flex' }" class="col-xs-1 col-md-5">
        <q-btn v-if="$q.screen.sm" flat round dense icon="menu" class="q-mx-sm" @click="menuStore.toggleMainLeft()"/>
        <q-separator v-if="$q.screen.sm" dark vertical inset />

        <q-btn flat dense round @click="toggleLeftDrawer" aria-label="Menu" icon="arrow_back_ios" class="q-mx-sm" />

        <q-toolbar-title v-if="$q.screen.gt.sm" shrink class="row items-center no-wrap">
          <span class="q-ml-sm MainHeaderLabel">{{`${editStore.selectSiteInfo?.siteName} > ${editStore.selectZoneInfo?.zoneName}`}}</span>
        </q-toolbar-title>
      </div>
      <div v-else class="col-xs-2 col-md-5">
      </div>

      <q-space />
      <div class="col-xs-5 col-sm-2 col-md-2" :style="{ textAlign: 'center' }">
        <q-img src="kbetlogo.png" fetchpriority="high" :fit="`scale-down`" class="logoImage" width="280px" height="64px"></q-img>
      </div>

      <q-space />

      <div class="q-gutter-sm row items-center no-wrap col-5 justify-end">
        <q-btn round flat> 관리자 </q-btn>

        <q-btn round flat>
          <q-avatar size="40px">
            <q-icon size="40px" :name="'img:ico_관리자_nor.png'" />
          </q-avatar>
          <q-tooltip>Account</q-tooltip>
        </q-btn>

        <q-btn dense flat outline color="white-8">
          <q-icon size="40px" :name="'img:ico_more_nor.png'" />
          <q-menu>
            <q-list dense>
              <q-item clickable v-close-popup @click="showLogoutDialog">
                <q-item-section>로그아웃</q-item-section>
              </q-item>
            </q-list>
          </q-menu>

          <q-tooltip>메뉴</q-tooltip>
        </q-btn>
      </div>
    </q-toolbar>
  </q-header>
  
    <!-- 로그아웃 팝업 -->
    <q-dialog v-model="confirm" persistent>
    <q-card style="width: 300px; background: white; color: black">
      <q-card-section>
        <div class="text-h7">로그아웃</div>
      </q-card-section>

      <q-card-section class="q-pt-none"> 로그아웃을 하시겠습니까? 로그아웃 하기 전에 변경 사항을 저장하세요.</q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="로그아웃" color="primary" v-close-popup @click="logout"/>
        <q-btn flat label="취소" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.MainHeader {
  background-color: #0f1837 !important;
  .GPL__toolbar{
    height: 54px;
  }
  .MainHeaderLabel{
    font-size: 16pt;
    font-weight: bold;
    color: #dbe3ff;
  }
}
</style>
