<script setup lang="ts">
import * as d3 from "d3";
import { ref, onMounted, onUnmounted, computed, onUpdated, onBeforeUpdate, nextTick, onBeforeMount, type PropType } from "vue";
import AreaRect from "./AreaRect";
import Wall from "./Wall";
import { Direction } from "@/global/enums";
import { useEditStore } from "@/stores/edit-store";
import type Zone from "@/models/entity/zone";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import type DPoint from "@/models/entity/d-point";
import CommonUtils from "@/utils/common-util";

const props = defineProps<{
  width: number,
  height: number,
  vertical: number,
  baseData: Zone | null,
  baseWidth: number,
  baseHeight: number,
}>();

const emitData = defineEmits(["update:setEditor"]);

const siteArea = ref(null);

const editStore = useEditStore();
const nodeEditorStore = useNodeEditorStore();


const backImage = ref("");

const baseData = computed(() => props.baseData as Zone);

const maxWidth = computed(() => {
  return (baseData.value.horizSize as number) * editStore.zoom;
});
const maxHeight = computed(() => {
  return (baseData.value.vertiSize as number) * editStore.zoom;
});
const areaWidth = computed(() => {
  console.log(document.getElementsByClassName("editArea"), document.getElementsByClassName("editArea")[0]?.clientWidth, editStore.zoom);
  return (document.getElementsByClassName("editArea")[0]?.clientWidth as number) * editStore.zoom;
});

const areaHeight = computed(() => {
  return (document.getElementsByClassName("editArea")[0]?.clientHeight as number) * editStore.zoom;
});

onUpdated(() => {
  // setZoneZoomValue();
});

const zoneParameterList = computed(() => {
  let AreaRectList = new Array<AreaRect>();
  if(baseData.value.childZones){
    AreaRectList = baseData.value.childZones.map((v) => setZoneParameter(v)) as Array<AreaRect>;
  }

  return AreaRectList;
});

function setZoneZoomValue(){
  editStore.zoomReset();
  const defaultWidth = areaWidth.value;
  const defaultHeight = areaHeight.value;
  let count = 1;
  if(maxWidth.value < defaultWidth || maxHeight.value < defaultHeight){
    while (maxWidth.value < defaultWidth && maxHeight.value < defaultHeight) {
      editStore.zoomIn();
      count++;
      if(count > 10000){
        break;
      }
    }
  } else if(maxWidth.value > defaultWidth || maxHeight.value > defaultHeight){
    if(maxWidth.value > maxHeight.value){
      while(maxWidth.value > defaultWidth){
        editStore.zoomOut();
        count++;
        if(count > 5000){
          break;
        }
      }
    } else if(maxWidth.value < maxHeight.value){
      while(maxHeight.value > defaultWidth){
        editStore.zoomOut();
        count++;
        if(count > 5000){
          break;
        }
      }
    }
  }
 
}


onBeforeUpdate(() => {
  // childZoneList.value.map((v) => setZoneParameter(v));
});

onMounted(() => {
  // setZoneZoomValue();

  makeBasePanel();

  // test();

  emitData("update:setEditor", maxWidth.value, maxHeight.value);
  // childZoneList.value.map((v) => setZoneParameter(v));
});

onUnmounted(() => {
  console.log("unmounted");
});

// NOTE - 첨부 이미지 변환 FILE -> BLOB
async function convertImage(e: File) {
  // const file = e;
  // const reader = new FileReader();
  // const blob = reader.readAsDataURL(file);
  // console.log(blob);
  // return blob;

  const name = e.name;
  const blob = new Blob([new Uint8Array(await e.arrayBuffer())], { type: e.type });
  const url = URL.createObjectURL(blob);

  backImage.value = url;
  return url.toString();
}

function allClear() {
  const svg = d3.select("#siteArea").selectAll("*");
  svg.remove();
}

function makeBasePanel() {
  const svg = d3.select<SVGGElement, undefined>("#siteArea");
  // svg.insert("rect").attr("stroke", "white").attr("x", 0).attr("y", 0).attr("width", "100%").attr("height", "100%").style("fill", "#69b3a2");
  svg.on("click", (e: any) => {
    const childzone = zoneParameterList.value;

    console.log(childzone, zoneParameterList, e.target);

    if (e.target.id === "siteArea") {
      console.log(e.target.id);
      for (let i = 0; i < childzone.length; i++) {
        childzone[i].resetClick();
      }
      // const info = {
      //   id: baseData.value?.id as number,
      //   spaceType: baseData.value?.onType as string,
      //   spaceInfo: baseData.value as Zone,
      // };

      // editModule?.setEditorSpaceInfo(InEditorData?.value);
      editStore.setSelectSpaceInfo(baseData.value.zoneSeq);
      editStore.setEditSpaceSelectType("");
      editStore.setRightSpaceInfo(null);
      // editStore.setSelectSpaceType(baseData.value.zoneTypeCode as string);
    } else {
      for (let i = 0; i < childzone.length; i++) {
        if (Number(e.target.parentNode.id.split(`${childzone[i].spaceType}`)[1]) !== childzone[i].id) {
          console.log(`cancel ${childzone[i].id}`);
          childzone[i].resetClick();
        }
      }
    }
  });
}

function setZoneParameter(zone: Zone) {
  const stroke = "";
  const style = {
    fill: "#919191",
  };
  const x = (zone.originX as number) || 0;
  const y = zone.originY as number;
  const width = zone.horizSize as number;
  const height = zone.vertiSize as number;
  const id = zone.zoneSeq as number;
  const name = zone.zoneName as string;
  const spaceType = zone.zoneTypeCode as string;

  const setZone = new AreaRect(stroke, style, x, y, width, height, id, spaceType, name, zone, baseData);
  nextTick(() => {
    setZone.eventSet();
  });

  return setZone;
}

function setZoneWalls(zone: AreaRect) {
  const walls = new Array<Wall>();

  walls.push(new Wall("LeftWall", zone, Direction.Left));
  walls.push(new Wall("RightWall", zone, Direction.Right));
  walls.push(new Wall("UpWall", zone, Direction.Up));
  walls.push(new Wall("DownWall", zone, Direction.Down));

  nextTick(() => {
    walls.map((v) => v.eventSet());
  });

  return walls;
}

function test(){
  if(CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo))
    return;
    
  const lightList = editStore.selectSpaceInfo.dpoints as DPoint[];
  console.log(lightList);

  for(let i = 0; i < lightList?.length; i++){
    const config = nodeEditorStore.getNodeConfigs.find(n=> n.id === lightList[i].devTypeCode) as LightNodeConfig;
    console.log(config);
    // const x = lightList[i].centerX as number * (props.zoneBase?.zoom.value as number);
    // const y = (lightList[i].centerZ as number * (props.zoneBase?.zoom.value as number)) + 200;

    const x = lightList[i].centerX as number;
    const y = (lightList[i].centerZ as number + 200);

    nodeEditorStore.createNode(x, y, config);
  }
}
</script>

<template>
  <div class="ZoneContent" :style="{ width: editStore.maxWidth + 'px', height: editStore.maxHeight + 'px' }">
    <!-- {{ InEditorData }} -->
   
    <!-- <button @click="allClear">remove</button> -->
    <!-- {{props.zoneBase?.zoom.value.toFixed(1)}} -->
    <svg id="siteArea" ref="siteArea" width="100%" height="100%" :key="1" :style="{ backgroundColor: '#313131' }" overflow="auto">
      <img v-if="backImage !== ''" :src="backImage" alt="" />
      <svg
        v-for="(zone, i) in zoneParameterList"
        :id="`${zone.spaceType}${zone.id}`"
        :key="`zone${i}`"
        :width="editStore.calcWidth(areaWidth, Number(zone.width), maxWidth)"
        :height="editStore.calcHeight(areaHeight, Number(zone.height), maxHeight)"
        :x="editStore.calcX(areaWidth, Number(zone.x), maxWidth)"
        :y="editStore.calcY(areaHeight, Number(zone.y), maxHeight)"
      >
        <rect :id="`${zone.spaceType}AreaRect`" :width="'100%'" :height="'100%'" :x="0" :y="0" :style="{ fill: '#919191' }"></rect>
        <text
          :id="`${zone.spaceType}AreaText`"
          :x="'50%'"
        :y="'50%'"
          :style="{
            textAnchor: 'middle',
            alignmentBaseline: 'central',
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'black',
          }"
        >
          {{ zone.name }}
        </text>
      </svg>
      <!-- <svg
        v-for="zone in zoneParameterList"
        :key="`${zone.id}WallList`"
        :width="props.zoneBase?.calcWidth(areaWidth, Number(zone.width), maxWidth)"
        :height="props.zoneBase?.calcHeight(areaHeight, Number(zone.height), maxHeight)"
        :x="props.zoneBase?.calcX(areaWidth, Number(zone.x), maxWidth)"
        :y="props.zoneBase?.calcY(areaHeight, Number(zone.y), maxHeight)"
      >
        <svg :id="`${zone.id}spaceWalls`" :x="0" :y="0" :width="'100%'" :height="'100%'">
          <rect
            v-for="wall in setZoneWalls(zone)"
            :key="wall.id"
            :id="wall.name.toString()"
            :width="Number(wall.width) * props.zoneBase?.zoom?.value!"
            :height="Number(wall.height) * props.zoneBase?.zoom?.value!"
            :x="Number(wall.x) * props.zoneBase?.zoom?.value!"
            :y="Number(wall.y) * props.zoneBase?.zoom?.value!"
            :style="{
              fill: '#ffffff',
              color: '#000000',
            }"
          >
            {{ wall.width }}
            {{ wall.height }}
            {{ wall.x }}
            {{ props.zoneBase?.zoom.value! }}
            {{ wall.y }}
          </rect>
        </svg>
      </svg> -->
    </svg>
  </div>
</template>

<style lang="scss">
.ZoneContent {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  #siteArea {
    width: 100%;
    height: 100%;
  }
}
</style>
