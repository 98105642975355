import { Day } from "@/global/enums";
import type { DayofWeek } from "@/global/types";
import type NodeTypeByEvent from "@/models/entity/node-type-by-events";
import CommonUtils from "@/utils/common-util";
import { NodeItem, type INodeItemEvent } from "../info/node-item-info";
import parser, { type CronFields, type MonthRange, type HourRange, type SixtyRange, type DayOfTheMonthRange, type DayOfTheWeekRange } from "cron-parser";
import type { IPackageNodeSetting } from "./node-setting";

/**
 * 시나리오 실행자 설정 클래스
 * @class
 */
class ScenarioScheduleNodeSetting implements IPackageNodeSetting {

  public schedules: ScenarioSchedule[] = [];

  constructor() {    

    // const schedule = new ScenarioSchedule("주간 시작", "08:00");
    // schedule.dayOfWeeks[1].isChecked = true;
    // schedule.dayOfWeeks[2].isChecked = true;
    //this.schedules.push(schedule);    
    // this.schedules.push(new ScenarioSchedule("야간 시작", "18:00"));
  }

  getNodeItems(): NodeItem[] {

    const nodeItems: NodeItem[] = [];

    for (let i = 0; i < this.schedules.length; i++) {
      const schedule = this.schedules[i];

      const nodeItem = new NodeItem(i.toString(), schedule.name ?? "", "");
      nodeItems.push(nodeItem);
    }

    return nodeItems;
  }

  addAndReplaceSchedule(schedule: ScenarioSchedule) {
    let index = this.schedules.findIndex(s => s.code === schedule.code);
    if (index > -1) {
      this.schedules.splice(index, 1);
    } else {
      index = this.schedules.length;
    }

    this.schedules.splice(index, 0, schedule);
  }

  deleteSchedule(code: string) {
    const index = this.schedules.findIndex(s => s.code === code);
    this.schedules.splice(index, 1);
  }
}

class ScenarioSchedule implements INodeItemEvent {
  public code: string;
  public name: string;
  public time: string;
  public dayOfWeeks: DayofWeek[] = [];

  constructor(code: string, name: string, time: string, dayOfWeeks: DayofWeek[] | null = null) {
    this.code = code;
    this.name = name;
    this.time = time;

    if (CommonUtils.isNullOrEmpty(dayOfWeeks)) {
      this.dayOfWeeks.push({ day: Day.Sun, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Mon, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Tue, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Wed, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Thu, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Fri, isChecked: false });
      this.dayOfWeeks.push({ day: Day.Sat, isChecked: false });
    }
    else {
      this.dayOfWeeks = dayOfWeeks;
    }
  }

  getDayOfWeeksText() {

    let dayOfWeeksText = "";

    for (const dayOfWeek of this.dayOfWeeks) {
      if (!dayOfWeek.isChecked)
        continue;

      if (dayOfWeeksText.length > 0)
        dayOfWeeksText += " ";

      dayOfWeeksText += dayOfWeek.day;
    }

    return dayOfWeeksText;
  }

  /**
 * 이벤트 발생조건 스트링(cron 문장)으로 변환한다.
 * @returns 
 */
  makeEventOccurCond() {

    const [hourString, minuteString] = this.time.split(":");
    const [hour, minute] = [Number(hourString) as HourRange, Number(minuteString) as SixtyRange];

    const hr: HourRange[] = [];
    hr.push(hour);

    const min: SixtyRange[] = [];
    min.push(minute);

    const weeks = this.dayOfWeeks.map(d => d.isChecked ? this.dayOfWeeks.findIndex(w => w.day === d.day) : -1).filter(x => x > -1) as DayOfTheWeekRange[];

    const fields: CronFields = {
      hour: hr,
      minute: min,
      dayOfWeek: weeks,
      dayOfMonth: CommonUtils.range(1, 31) as DayOfTheMonthRange[],
      second: [0],
      month: CommonUtils.range(1, 12) as MonthRange[],
    };

    const cronExpression = parser.fieldsToExpression(fields);
    return cronExpression.stringify();
  }

  /**
   * 이벤트 발생조건 스트링을 TimeBranchEvent에 맞는 값으로 리턴
   * @param eventOccurCond 
   * @returns 
   */
  static solveEventOccurCond(eventOccurCond: string): [string, DayofWeek[]] {
    const p = parser.parseExpression(eventOccurCond);            
    const fs = p.fields;
    const time = `${CommonUtils.fillZero(2, fs.hour.toString())}:${CommonUtils.fillZero(2, fs.minute.toString())}`;

    const days = Object.values(Day);
    const dayOfWeeks: DayofWeek[] = [];
    
    for(const day of days) {

      const dow = { day: day, isChecked: false };
    
      for(const dowNumber of fs.dayOfWeek) {
    
        const d: Day = Object.values(Day)[dowNumber];
    
        if(day !== d)
          continue;    
    
        dow.isChecked = true;
      }
    
      dayOfWeeks.push(dow);
    }

    return [`${time}`, dayOfWeeks];
  }
}


export default ScenarioScheduleNodeSetting;
export { ScenarioSchedule };