<script setup lang="ts">
import * as d3 from "d3";
import { ref, onMounted, computed, onUpdated, nextTick } from "vue";
import AreaRect from "./AreaRect";
import Site from "@/models/site";
import Wall from "./Wall";
import { Direction } from "@/global/enums";
import { useEditStore } from "@/stores/edit-store";
import type Zone from "@/models/entity/zone";

const props = defineProps<{
  width: number;
  height: number;
  vertical: number;
  baseData: Zone | null;
  baseWidth: number;
  baseHeight: number;
}>();

console.log(document.getElementsByClassName("editArea"));

const emitData = defineEmits(["update:setEditor"]);

const siteArea = ref(null);

const editStore = useEditStore();

const baseData = computed(() => props.baseData as Zone);

const maxWidth = computed(() => {
  return (baseData.value.horizSize as number) * editStore.zoom;
});
const maxHeight = computed(() => {
  return (baseData.value.vertiSize as number) * editStore.zoom;
});
const areaWidth = computed(() => {
  // console.log(document.getElementsByClassName("editArea"));
  return (document.getElementsByClassName("editArea")[0]?.clientWidth as number) * editStore.zoom;
});

const areaHeight = computed(() => {
  return (document.getElementsByClassName("editArea")[0]?.clientHeight as number) * editStore.zoom;
});

// const areaWidth = computed(() => {
//   return (props.areaWidth as number) * (props.zoneBase?.zoom?.value as number);
// });
// const areaHeight = computed(() => {
//   return (props.areaHeight as number) * (props.zoneBase?.zoom?.value as number);
// });

const keyNumber = ref(1);


const zoneParameterList = computed(() => {
  let AreaRectList = new Array<AreaRect>();
  if(baseData.value.childZones){
    AreaRectList = baseData.value.childZones.map((v) => setZoneParameter(v)) as Array<AreaRect>;

  }


  return AreaRectList;
});

// const zoneParameterList = ref(childZoneList?.value?.map((v) => setZoneParameter(v)) as Array<AreaRect>);

onMounted(() => {
  // setZoneZoomValue();
  makeBasePanel();
  // childZoneList.value.map((v) => setZoneParameter(v));
  emitData("update:setEditor", maxWidth.value, maxHeight.value);

});

function setZoneZoomValue(){
  editStore.zoomReset();
  const defaultWidth = areaWidth.value;
  const defaultHeight = areaHeight.value;
  let count = 1;
  if(maxWidth.value < defaultWidth || maxHeight.value < defaultHeight){
    while (maxWidth.value < defaultWidth && maxHeight.value < defaultHeight) {
      editStore.zoomIn();
      count++;
      if(count > 10000){
        break;
      }
    }
  } else if(maxWidth.value > defaultWidth || maxHeight.value > defaultHeight){
    if(maxWidth.value > maxHeight.value){
      while(maxWidth.value > defaultWidth){
        editStore.zoomOut();
        count++;
        if(count > 5000){
          break;
        }
      }
    } else if(maxWidth.value < maxHeight.value){
      while(maxHeight.value > defaultWidth){
        editStore.zoomOut();
        count++;
        if(count > 5000){
          break;
        }
      }
    }
  }
 
}

function allClear() {
  const svg = d3.select("#siteArea").selectAll("*");
  svg.remove();
}

function makeBasePanel() {
  const svg = d3.select<SVGGElement, undefined>("#siteArea");
  // svg.insert("rect").attr("stroke", "white").attr("x", 0).attr("y", 0).attr("width", "100%").attr("height", "100%").style("fill", "#69b3a2");
  console.log(zoneParameterList);
  svg.on("click", (e: any) => {
    const childzone = zoneParameterList.value;

    if (e?.target?.id === "siteArea") {
      for (let i = 0; i < childzone.length; i++) {
        childzone[i].resetClick();
      }
    
      // editModule?.setEditorSpaceInfo(InEditorData?.value);
      editStore.setSelectSpaceInfo(baseData.value.zoneSeq);
    } else {
      for (let i = 0; i < childzone.length; i++) {
        if (Number(e.target.parentNode.id.split(`${childzone[i].spaceType}`)[1]) !== childzone[i].id) {
          console.log(`cancel ${childzone[i].id}`);
          childzone[i].resetClick();
        }
      }
    }
  });
}

function setZoneParameter(zone: Zone) {
  const stroke = "";
  const style = {
    fill: "#919191",
  };
  const x = (zone.originX as number) || 0;
  const y = zone.originY as number;
  const width = zone.horizSize as number;
  const height = zone.vertiSize as number;
  const id = zone.zoneSeq as number;
  const name = zone.zoneName as string;
  const spaceType = zone.zoneTypeCode as string;

  const setZone = new AreaRect(stroke, style, x, y, width, height, id, spaceType, name, zone, baseData);

  nextTick(() => {
    setZone.eventSet();
  });

  return setZone;
}

function setZoneWalls(zone: AreaRect) {
  const walls = new Array<Wall>();

  walls.push(new Wall("LeftWall", zone, Direction.Left));
  walls.push(new Wall("RightWall", zone, Direction.Right));
  walls.push(new Wall("UpWall", zone, Direction.Up));
  walls.push(new Wall("DownWall", zone, Direction.Down));

  nextTick(() => {
    walls.map((v) => v.eventSet());
  });

  return walls;
}
</script>

<template>
  <!-- <div class="ZoneContent" :style="{ width: maxWidth + 'px', height: maxHeight + 'px' }"> -->
    <div class="ZoneContent" :style="{ width: editStore.maxWidth + 'px', height: editStore.maxHeight + 'px' }">

    <!-- {{ InEditorData }} -->
    <!-- <button @click="allClear">remove</button> -->
    <svg id="siteArea" ref="siteArea" width="100%" height="90%" :key="1" :style="{ backgroundColor: '#313131' }">

      <rect
        :id="`${baseData.zoneTypeCode}AreaRect`"
        :width="'100%'"
        :height="'100%'"
        :x="0"
        :y="0"
        :style="{ fill: '#919191' }"
      />
      <text
          :id="`${baseData.zoneTypeCode}AreaText`"
          :x="'50%'"
          :y="'50%'"
          :style="{
            textAnchor: 'middle',
            alignmentBaseline: 'central',
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'black',
          }"
        >
        {{baseData.zoneName}}
        </text>

        <!-- <rect :id="`${zone.spaceType}AreaRect`" :width="'100%'" :height="'100%'" :x="0" :y="0" :style="{ fill: '#919191' }"></rect> -->


      <!-- <svg
        v-for="(zone, i) in zoneParameterList"
        :id="`${zone.spaceType}${zone.id}`"
        :key="`zone${i}`"
        :width="editStore.calcWidth(areaWidth as number, Number(zone.width), maxWidth)"
        :height="editStore.calcHeight(areaHeight as number, Number(zone.height), maxHeight)"
        :x="editStore.calcX(areaWidth as number, Number(zone.x), maxWidth)"
        :y="editStore.calcY(areaHeight as number, Number(zone.y), maxHeight)"
      >
        <rect :id="`${zone.spaceType}AreaRect`" :width="'100%'" :height="'100%'" :x="0" :y="0" :style="{ fill: '#919191' }"></rect>
        <text
          :id="`${zone.spaceType}AreaText`"
          :x="'50%'"
          :y="'50%'"
          :style="{
            textAnchor: 'middle',
            alignmentBaseline: 'central',
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'black',
          }"
        >
          {{ zone.name }}
        </text>
      </svg> -->

      <!-- <svg>
        <rect id="topWall" :width="'90%'" :height="20" :x="10" :y="-10" :style="{ fill: '#f0f0f0' }"></rect>
        <rect id="rightWall"></rect>
        <rect id="bottomWall"></rect>
        <rect id="leftWall"></rect>
      </svg> -->
    </svg>
  </div>
</template>

<style lang="scss">
.ZoneContent {
  position: absolute;
  #siteArea {
    width: 100%;
    height: 100%;
  }
}
</style>
