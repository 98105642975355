import CommonUtils from "@/utils/common-util";
import DPoint from "../entity/d-point";

class InDPoint extends DPoint{
  nodeID?: string;

  constructor(dPoint: DPoint, nodeID?: string){
    
    super(dPoint.siteSeq, dPoint.zoneSeq, dPoint.dpointSeq, dPoint.regUserId);
    
    this.devTypeCode = dPoint.devTypeCode;
    this.rotate = dPoint.rotate;
    this.centerX = dPoint.centerX;
    this.centerY = dPoint.centerY;
    this.centerZ = dPoint.centerZ;
    this.regUserId = dPoint.regUserId;
    this.modUserId = dPoint.modUserId;
    this.dpointName = dPoint.dpointName;
    this.regDate = dPoint.regDate;
    this.modDate = dPoint.modDate;    
    this.mpoints = CommonUtils.deepClone(dPoint.mpoints);
    
    this.nodeID = nodeID;        
  }
}  

export default InDPoint;