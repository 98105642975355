
class ZoneSmlAdjust{
  adjustHolidayCnt?: number;
  adjustMonth?: string;
  dayAdjustHour?: number;
  nightAdjustHour?: number;
  faultRate?: number;
  siteSeq?: number;
  zoneSeq?: number;
}

export default ZoneSmlAdjust;