<template>
  <q-layout view="hHh Lpr lFr">
    <q-header elevated>
      <q-toolbar class="bg-black shadow-up-2">
        <q-img style="width: 40px; height: 40px;"
          src="http://www.kbet.or.kr/wp-content/uploads/2019/05/KBeT-%ED%95%9C%EA%B5%AD%EA%B1%B4%EB%AC%BC%EC%97%90%EB%84%88%EC%A7%80%EA%B8%B0%EC%88%A0%EC%9B%90-%EA%B8%B0%EB%B3%B8-%EB%A1%9C%EA%B3%A0.jpg" />
        <q-btn flat dense round icon="navigate_before" aria-label="Menu" />
        <q-btn flat dense round icon="menu" aria-label="Menu" @click="toggleLeftDrawer" class="lt-sm" />
        <q-toolbar-title>
          스마트 조명 플랫폼
        </q-toolbar-title>

        <q-toggle color="primary" :false-value="false" :true-value="true" v-model="isDark" :left-label="true"
          :label="`${isDark ? 'Dark' : 'Light'}`" @update:model-value="onUpdateScreenMode" />

        <q-btn round icon="notifications" size="sm" style="margin-right: 16px;">
          <q-badge floating color="red" rounded />
        </q-btn>

        <q-separator vertical inset />

        <q-card style="background-color: transparent;" :bordered="false" :flat="true" class="gt-xs">
          <q-item>
            <q-item-section>
              <q-item-label>Admin</q-item-label>
            </q-item-section>

            <q-item-section avatar>
              <q-avatar>
                <img src="https://randomuser.me/api/portraits/men/85.jpg">
              </q-avatar>
            </q-item-section>
          </q-item>
        </q-card>

        <q-btn-dropdown flat round dense dropdown-icon="more_vert">
          <q-list>
            <q-item clickable v-close-popup>
              <q-item-section>프로필 수정</q-item-section>
            </q-item>
            <q-separator inset />
            <q-item clickable v-close-popup>
              <q-item-section>로그아웃</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <!-- <q-fab glossy icon="more_vert" hide-icon direction="down"  >
          <q-fab-action color="amber" text-color="black" icon="mail" />
          <q-fab-action color="amber" text-color="black" icon="alarm" />
        </q-fab> -->
      </q-toolbar>
    </q-header>

    <!-- 왼쪽 대메뉴 -->
    <q-drawer v-model="leftDrawerOpen" show-if-above bordered :mini="false" :width="100" :breakpoint="0">
      <div class="fit q-pt-xl q-px-sm column">
        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="photo" />
          <div class="mb-label">조명공간</div>
        </q-btn>

        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="collections_bookmark" />
          <div class="mb-label">시나리오</div>
        </q-btn>

        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="assistant" />
          <div class="mb-label">시뮬레이션</div>
          <q-badge floating color="red" text-color="white" style="top: 8px; right: 16px">
            1
          </q-badge>
        </q-btn>

        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="group" />
          <div class="mb-label">기본구성</div>
        </q-btn>

        <q-item class="col-grow" />
        <q-separator />

        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="settings" />
        </q-btn>
        <q-btn round flat stack no-caps size="26px">
          <q-icon size="22px" name="info_outline" />
        </q-btn>
      </div>
    </q-drawer>

    <q-page-container>

      <div class="row">

        <!--  왼쪽 패널 -->
        <div class="col q-pa-xs bg-grey-10" style="max-width: 300px;">
          <q-btn-toggle v-model="model" push glossy dense spread no-caps class="q-pl-sm q-pr-sm" :options="[
            { value: 'one', slot: 'one' },
            { value: 'two', slot: 'two' },
            { value: 'three', slot: 'three' },
            { value: 'four', slot: 'four' }
          ]">
            <template v-slot:one>
              <div class="row items-center no-wrap">
                <q-icon center name="lightbulb_outline" />
              </div>
            </template>
            <template v-slot:two>
              <div class="row items-center no-wrap">
                <q-icon center name="workspaces_outline" />
              </div>
            </template>
            <template v-slot:three>
              <div class="row items-center no-wrap">
                <q-icon center name="burst_mode" />
              </div>
            </template>
            <template v-slot:four>
              <div class="row items-center no-wrap">
                <q-icon center name="format_shapes" />
              </div>
            </template>
          </q-btn-toggle>

          <q-scroll-area class="q-pa-sm window-height" :visible="true">
            <q-list bordered class="rounded-borders">
              <q-expansion-item dense expand-separator default-opened label="사이트 네이게이션">
                <q-card>
                  <q-card-section>
                    <q-input bottom-slots dense v-model="searchText" maxlength="12">

                      <template v-slot:append>
                        <q-icon v-if="searchText !== ''" name="close" @click="searchText = ''" class="cursor-pointer" />
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </q-card-section>

                  <q-card-section>
                    <q-tree :nodes="sites" node-key="label" default-expand-all v-model:selected="selectedSite" />
                  </q-card-section>

                  <q-card-section align="right">
                    <q-btn round color="primary" icon="delete" size="sm" />
                  </q-card-section>

                </q-card>
              </q-expansion-item>

              <q-expansion-item dense expand-separator default-opened label="조명">
                <q-card>
                  <q-card-section>

                    <q-input bottom-slots dense v-model="searchText" maxlength="12">
                      <template v-slot:append>
                        <q-icon v-if="searchText !== ''" name="close" @click="searchText = ''" class="cursor-pointer" />
                        <q-icon name="search" />
                      </template>
                    </q-input>

                  </q-card-section>

                  <q-card-section>
                    <q-tree :nodes="lightDevices" node-key="label" default-expand-all />
                  </q-card-section>

                </q-card>
              </q-expansion-item>

              <q-expansion-item dense expand-separator default-opened label="조명">
                <q-card>
                  <q-card-section>

                    <q-input bottom-slots dense v-model="searchText" maxlength="12">
                      <template v-slot:append>
                        <q-icon v-if="searchText !== ''" name="close" @click="searchText = ''" class="cursor-pointer" />
                        <q-icon name="search" />
                      </template>
                    </q-input>

                  </q-card-section>

                  <q-card-section>
                    <q-tree :nodes="lightDevices" node-key="label" default-expand-all />
                  </q-card-section>

                </q-card>
              </q-expansion-item>
            </q-list>

          </q-scroll-area>

        </div>

        <!-- Contents + 우측 패널 -->
        <div class="col">
          <q-splitter v-model="rightPanelSetting.widthRatio" :limits="[80, 100]">

            <!--  Contents 패널 -->
            <template v-slot:before>
              <div>
                <q-bar class="q-pa-none">
                  <q-btn flat icon="shopping_cart" />
                  <q-btn flat icon="shopping_cart" />
                  <q-btn flat icon="shopping_cart" />
                  <q-separator vertical inset />
                  <q-btn flat icon="shopping_cart" />
                  <q-btn flat icon="shopping_cart" />
                  <q-separator vertical inset />
                  <q-btn flat icon="shopping_cart" />
                  <q-btn flat icon="shopping_cart" />
                  <q-btn flat icon="shopping_cart" />
                  <q-separator vertical inset />
                  <q-space />

                  <q-separator vertical inset />
                  <q-btn flat icon="widgets">
                    <q-menu>
                      <q-list style="min-width: 100px">
                        <q-item clickable v-close-popup>
                          <q-item-section>왼쪽 정렬</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup>
                          <q-item-section>중간 정렬</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup>
                          <q-item-section>오른쪽 정렬</q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item clickable v-close-popup>
                          <q-item-section>가로 간격 동일</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup>
                          <q-item-section>세로 간격 동일</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                  <q-btn flat icon="undo" />
                  <q-btn flat icon="redo" />
                  <q-btn flat icon="save" />
                  <!-- <q-separator vertical inset class="q-ma-xs" />
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/>
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/>
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/>
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/> -->
                  <!-- <q-separator vertical inset class="q-ma-xs" />
                  <q-btn color="primary" icon="shopping_cart" />
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/>
                  <q-btn color="primary" icon="shopping_cart" class="col-2" size="sm"/> -->
                </q-bar>

                <q-page>
                  <router-view />
                </q-page>
              </div>
            </template>

            <template v-slot:separator>
              <q-btn round color="primary" :icon="rightPanelSetting.icon" @click="onRightCollapse" />
            </template>

            <!--  우측 패널 -->
            <template v-slot:after>
              <q-page padding>
                <!-- <h1>Right Panel</h1> -->
                <router-view class="right-bar" name="RightBar"/>
              </q-page>
            </template>
          </q-splitter>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useQuasar } from "quasar";
import Site from "@/models/site";

const $q = useQuasar();

const searchText = ref("");
const leftDrawerOpen = ref(false);
const isDark = ref(true);
const selectedSite = ref(null);

const leftPanelSetting = reactive({
  icon: "navigate_before",
  isCollapse: false,
  widthRatio: 20
});

const rightPanelSetting = reactive({
  icon: "navigate_next",
  isCollapse: false,
  widthRatio: 80
});

const essentialLinks = [
  {
    title: "Docs",
    caption: "quasar.dev",
    icon: "school",
    link: "https://quasar.dev"
  },
  {
    title: "Github",
    caption: "github.com/quasarframework",
    icon: "code",
    link: "https://github.com/quasarframework"
  },
  {
    title: "Discord Chat Channel",
    caption: "chat.quasar.dev",
    icon: "chat",
    link: "https://chat.quasar.dev"
  },
  {
    title: "Forum",
    caption: "forum.quasar.dev",
    icon: "record_voice_over",
    link: "https://forum.quasar.dev"
  },
  {
    title: "Twitter",
    caption: "@quasarframework",
    icon: "rss_feed",
    link: "https://twitter.quasar.dev"
  },
  {
    title: "Facebook",
    caption: "@QuasarFramework",
    icon: "public",
    link: "https://facebook.quasar.dev"
  },
  {
    title: "Quasar Awesome",
    caption: "Community Quasar projects",
    icon: "favorite",
    link: "https://awesome.quasar.dev"
  }
];

const sites = [
  {
    label: "웅진타워",
    icon: "domain",
    children: [
      {
        label: "건물1",
        icon: "domain",
        children: [
          {
            label: "지하1층",
            icon: "domain",
            children: [
              {
                label: "지하1층 공간",
                icon: "domain",
              }
            ]
          },
          {
            label: "지하2층",
            icon: "domain",
            children: [
              {
                label: "지하2층 공간",
                icon: "domain",
              }
            ]
          }
        ]
      }
    ]
  }
];
const lightDevices = [
  {
    label: "오스람",
    children: [
      {
        label: "오스람-1"
      },
      {
        label: "오스람-2"
      },
    ]
  },
  {
    label: "필립스",
    children: [
      {
        label: "필립스-1"
      },
      {
        label: "필립스-2"
      },
    ]
  },
];

const model = ref("one");


onMounted(() => {
  //onUpdateScreenMode();  
});

const onChangeTheme = () => {

  console.log(`isDark active: ${$q.dark.isActive}, mode: ${$q.dark.mode}`);

  //$q.dark.set(!$q.dark.isActive);
  $q.dark.toggle();
};

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
};

const onLeftCollapse = () => {
  if (leftPanelSetting.isCollapse) {
    leftPanelSetting.widthRatio = 20;
    leftPanelSetting.icon = "navigate_before";
  }
  else {
    leftPanelSetting.widthRatio = 0;
    leftPanelSetting.icon = "navigate_next";
  }

  leftPanelSetting.isCollapse = !leftPanelSetting.isCollapse;
};

const onRightCollapse = () => {
  if (rightPanelSetting.isCollapse) {
    rightPanelSetting.widthRatio = 80;
    rightPanelSetting.icon = "navigate_next";
  }
  else {
    rightPanelSetting.widthRatio = 120;
    rightPanelSetting.icon = "navigate_before";
  }

  rightPanelSetting.isCollapse = !rightPanelSetting.isCollapse;
};

const onUpdateScreenMode = () => {

  if (isDark.value)
    $q.dark.set(true);
  else
    $q.dark.set(false);    
};

</script>
<style lang="scss">
  .mb-label {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: .01785714em;
    font-weight: 500;
  }
</style>