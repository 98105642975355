<script setup lang="ts">
import { computed, ref, onUpdated, onMounted, reactive } from "vue";


const props = defineProps<{
  inputType?: string,
  attrName?: string,
  attrDesc?: string,
  minVal?: number,
  maxVal?: number,
  maxLen?: number | null,
  defaultVal?: string| number | null,
  step?: number,
  unit?: string,
  readOnlyYn?: string,
  inValue?: string | number | null,
  radioValue: string,
}>();

const emit = defineEmits(["updateModel", "radioUpdate"]);
const colortemp = ["2200", "2500", "2700", "3000", "3500", "4000", "4500", "5000", "5700", "6500"];

const propsValue = computed({
  get: () => {
    let valueCheck;

    if(props.attrName === "colortemp"){
      valueCheck = colortemp.findIndex(v => v === props.inValue?.toString()) as number;
    } else if(props.attrName === "level"){
      valueCheck = props.inValue as number;
    } else {
      valueCheck = props.inValue;
    }

    return valueCheck;
  },
  set: (value) => {
    if(props.attrName === "colortemp"){
      values(colortemp[value as number], props.attrName as string);
    } else {
      values(value, props.attrName as string);
    }
  }
});


onMounted(() => {
  let firstValue;
  if(props.attrName === "colortemp"){
    firstValue = colortemp.findIndex(v => v === props.inValue?.toString());
  } else {
    firstValue = props.inValue;
  }

  propsValue.value = firstValue;
});

const radioCheck = computed({
  get: () => props.radioValue,
  set: (value) => emit("radioUpdate", value),
});


const sliderValue = computed({
  get: () => propsValue.value as number,
  set: (value) => {
    propsValue.value = value;
    return value;
  }
});

const colorLabelData = computed({
  get: () => colortemp[sliderValue.value],
  set: (value) => value, 
});

function values(value: number | string | null | undefined, target: string){
  // if(typeof value === "number") if(value < 0) return; 

  // let setValue;

  // if(props.attrName === "colortemp"){
  //   setValue = colortemp[value as number];
  // } else {
  //   setValue = value;
  // }

  emit("updateModel", {value, target});
}

function colorTempInput(value: string | number | null){
  const colorIdx = colortemp.findIndex(v => v === value?.toString());

  if(colorIdx === -1) return;

  sliderValue.value = colorIdx;
  values(value, "colortemp");
}

function changeSliderValue(value: any){
  propsValue.value = value;
}
</script>

<template>
  <div class="SceneControlInputComponent">
    <div v-if="props.attrName==='level'">
      <label>{{`${props.attrDesc} ${props.unit !== null && props.unit !== undefined ? '(' + props.unit + ')' : ''}`}}</label>
      <q-field
        class="sliderRow"
        :dark="false"
        :hint="`최소: ${props.minVal} 최대: ${props.maxVal}`"
        borderless
        :style="{marginBottom: '10px'}"
        v-model="inValue"
      >
        <template v-slot:control>
          <q-slider
            class="col-8"
            :style="{padding: '0px 10px 0px 10px', alignSelf: 'center', width: '15', height: '15'}"
            dense
            :model-value="Number(propsValue)"
            :dark="false"
            :min="props.minVal"
            :max="props.maxVal"
            :step="props.step"
            :thumb-path="'M5 0h10v20H5Z'"
            :thumb-size="'10px'"
            snap
            @change="changeSliderValue"
          >
          </q-slider>
          <q-input
            class="col-4"
            :style="{padding: '0px 10px 0px 10px'}"
            outlined
            dense
            square
            bg-color="white"
            :type="'number'"
            :step="props.step"
            :maxlength="props.maxLen as number"
            :dark="false"
            v-model.number="propsValue"
            >
          </q-input>
        </template>
      </q-field>
    </div>

    <div v-else-if="props.attrName==='colortemp'" class="sliderColorTemp">
      <label>{{`${props.attrDesc} ${props.unit !== null && props.unit !== undefined ? '(' + props.unit + ')' : ''}`}}</label>
      <q-field
        class="sliderRow"
        :dark="false"
        :hint="`최소: ${colortemp[0]} 최대: ${colortemp[colortemp.length - 1]}`"
        borderless
        :style="{marginBottom: '10px'}"
        v-model="inValue"
      >
        <template v-slot:control>
          <div class="row" :style="{width:'100%'}">
            <q-slider
              class="col-8"
              :style="{padding: '0px 10px 0px 10px', alignSelf: 'center', width: '15', height: '15'}"
              dense
              snap
              :model-value=" Number(propsValue)"
              :dark="false"
              :min="0"
              :max="colortemp.length-1"
              :step="1"
              :thumb-path="'M5 0h10v20H5Z'"
              :thumb-size="'10px'"
              @change="(value: number) => {propsValue = value}"
            >
            </q-slider>
            <q-select
              class="col-4"
              :style="{padding: '0px 10px 0px 10px'}"
              outlined
              dense
              square
              bg-color="white"
              :type="'number'"
              :min="2200"
              :max="6500"
              :step="100"
              :maxlength="props.maxLen as number"
              :options="colortemp"
              :popup-content-style="{color: '#000000'}"
              :dark="false"
              v-model="colorLabelData"
              @update:model-value="colorTempInput"
              >
            </q-select>
          </div>
        </template>
      </q-field>
    </div>

    <div v-else-if="props.inputType === 'text'">
      <label>{{`${props.attrDesc}${props.unit !== null && props.unit !== undefined ? props.unit : ''}`}}</label>
      <q-input
        outlined
        dense
        square
        bg-color="white"
        :type="'text'"
        :step="props.step"
        :maxlength="props.maxLen as number"
        :dark="false"
        v-model="inValue"
        @update:model-value="values(inValue, props.attrName as string)"
        >
      </q-input>
    </div>

  
  <div v-else-if="props.inputType === 'number'">
    <!-- <q-radio v-if="props.attrDesc === '디밍 레벨'" dense v-model="radioCheck" val="DIMMING" :style="{marginRight: '10px'}"/> -->

    <label>{{`${props.attrDesc}` + (props.unit !== '' ? ` (${props.unit})` : '')}}</label>
    <div>
      <q-input
      outlined
      bottom-slots
      dense
      square
      bg-color="white"
      :type="'number'"
      :max="props.maxVal"
      :min="props.minVal"
      :step="props.step"
      :dark="false"
      v-model.number="inValue"
      @update:model-value="values(inValue, props.attrName as string)"
      :style="{marginTop: '10px'}"
      >
      <template v-slot:hint>
        {{`최소: ${props.minVal} 최대: ${props.maxVal}`}}
      </template>
      </q-input>
    </div>
  </div>

  <div v-else-if="props.inputType === 'toggle'" class="q-pb-md">
    <q-radio dense v-model="radioCheck" val="ONOFF" :style="{marginRight: '10px'}"/>

    <label>{{`${props.attrDesc}` + (props.unit !== '' ? ` (${props.unit})` : '')}}</label>
    <q-toggle 
      class="q-px-md" 
      v-model="inValue" 
      color="#3bb3ff" 
      size="sm" 
      :dark="false" 
      dense 
      :true-value="1" 
      :false-value="0"
      @update:model-value="values(inValue, props.attrName as string)"    
      :disable="radioCheck !== 'ONOFF'"
    />
  </div>
</div>
</template>

<style lang="scss">
.SceneControlInputComponent{
  .sliderRow{
   
    .q-input{
      margin-bottom: 0px;
    }

    .q-field__control .q-field__control-container{
        padding-left: 5px;
      }
  }

  .sliderColorTemp{
    .sliderRow{
      .q-field__control .q-field__append{
        border-left: 0px;
        width: 20px;
        height: 20px;
      }

      
    }    
  }
}

</style>