import type SceneSetting from "./scene-setting";


/**
 * Scene 클래스
 * @class
 */
class Scene {
  modDate?: string;
  modUserId?: number;
  regDate?: string;
  regUserId?: number;
  sceneDesc?: string;
  sceneName?: string;
  sceneSeq?: number;
  sceneSettings?: SceneSetting[];
  siteSeq?: number;
  zoneSeq?: number;
}

export default Scene;
