import { Type } from "class-transformer";
import Site from "./site";

class SiteSetting {
  public lastUpdateUserId?: string;

  @Type(() => Date)
  public lastUpdateDate?: Date;

  @Type(() => Site)
  public site?: Site;

  constructor() {}
}

export default SiteSetting;
