<script setup lang="ts">
import {onUnmounted } from "vue";
import { useEditStore } from "@/stores/edit-store"; 

const editStore = useEditStore();

onUnmounted(() => {
  console.log("::: main content unmounted::::");
  editStore.tmpRemoveZoneData = [];
});


</script>

<template>
  <q-page-container class="MainContents">
    <router-view class="MainContentsRouter" />
  </q-page-container>
</template>

<style lang="scss">
.MainContents {
  .MainContentsRouter {}
}
</style>
