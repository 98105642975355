import type Zone from "@/models/entity/zone";
import { useEditStore } from "@/stores/edit-store";
import CommonUtils from "@/utils/common-util";
import * as d3 from "d3";
import SVGPathCommander from "svg-path-commander";

class AreaRect {
  public id: number;
  public spaceType: string;
  public svg: any;
  public stroke: string;
  public style: object;
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public name: string;
  public spaceInfo: Zone;
  public defaultInfo: any;
  public isFocus: boolean;
  public rectSvg: any;
  public enable: boolean;
  public isWall: boolean;
  public ConnectArea: AreaRect[];

  constructor(
    stroke: string,
    style: object,
    x: number,
    y: number,
    width: number,
    height: number,
    id: number,
    spaceType: string,
    name: string,
    spaceInfo: Zone,
    defaultInfo: any
  ) {
    this.stroke = stroke;
    this.style = style;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.id = id;
    this.spaceType = spaceType;
    this.name = name;
    this.spaceInfo = spaceInfo;
    this.defaultInfo = defaultInfo;
    this.enable = false;
    this.isWall = false;
    this.isFocus = false;
    

    this.ConnectArea = [];

    // this.rectSvg = d3.create("svg");

    // this.rectSvg
    //   .attr("class", "RspaceRect")
    //   .attr("id", `Rspace${this.id}`)
    //   .attr("x", this.x)
    //   .attr("y", this.y)
    //   .attr("width", this.width)
    //   .attr("height", this.height);
  }

  // drawRect(editModule: EditModule | undefined) {
  // const styleAttr = Object.entries(this.style);
  // console.log(this.svg);
  // const rect = this.rectSvg
  //   .append("rect")
  //   .attr("id", "RspaceAreaRect")
  //   .attr("x", 0)
  //   .attr("y", 0)
  //   .attr("stroke", this.stroke)
  //   .attr("width", this.width)
  //   .attr("height", this.height);

  // const subject = this.rectSvg
  //   .append("text")
  //   .attr("id", "RspaceAreaName")
  //   .attr("x", this.width / 2)
  //   .attr("y", this.height / 2)
  //   .style("text-anchor", "middle")
  //   .style("alignment-baseline", "central")
  //   .style("font-size", "15px")
  //   .style("font-weight", "bold")
  //   .style("color", "black")
  //   .text(this.name);

  // for (let i = 0; i < styleAttr.length; i++) {
  //   rect.style(styleAttr[i][0], styleAttr[i][1]);
  // }

  // this
  // console.log(this.svg, this.rectSvg);
  // this.svg.append(() => this.rectSvg.node());
  // }

  // checkConnectArea(area: AreaRect){
  //   if(this.x + this)
  // }

  eventSet() {
    const editStore = useEditStore();

    const svg = d3.select("#siteArea").selectChildren(`#${this.spaceType}${this.id}`);
    const rect = d3.select("#siteArea").selectChildren(`#${this.spaceType}${this.id}`).selectChild(`#${this.spaceType}AreaRect`);

    const svgNode = svg.node() as SVGSVGElement;
    const rectNode = rect.node() as SVGSVGElement;
    
    // const aaa = {
    //   type: "rect",
    //   x: this.x + 50,
    //   y: this.y + 50,
    //   width: this.width,
    //   height: this.height
    // };

    // const test = SVGPathCommander.shapeToPath(aaa);
    // console.log(test);

    // document.getElementById(`${this.spaceType}${this.id}`)?.append(test);
    // svg.append(test);

    const a = svgNode?.getBBox();
    const b = rectNode?.getBBox();

    // const c = rectNode.getBoundingClientRect();

    // const a = w?.getBoundingClientRect();
    // console.log(a, b, c);
    // console.log(rect, rectNode);
    // console.log(b.height);

    // const topWall = svg
    //   .append("rect")
    //   .attr("x", 10)
    //   .attr("y", -10)
    //   .attr("width", b?.width - 20)
    //   .attr("height", 20)
    //   .style("fill", "#f0f0f0");
    // const rightWall = svg
    //   .append("rect")
    //   .attr("x", a.width - 10)
    //   .attr("y", 10)
    //   .attr("width", 20)
    //   .attr("height", b.height - 20)
    //   .style("fill", "#f0f0f0");
    // const bottomWall = svg
    //   .append("rect")
    //   .attr("x", 10)
    //   .attr("y", b.height - 10)
    //   .attr("width", b?.width - 20)
    //   .attr("height", 20)
    //   .style("fill", "#f0f0f0");
    // const leftWall = svg
    //   .append("rect")
    //   .attr("x", -10)
    //   .attr("y", 10)
    //   .attr("width", 20)
    //   .attr("height", b.height - 20)
    //   .style("fill", "#f0f0f0");

    // topWall.on("mouseover", (e) => {
    //   console.log(e, "wallSelect", topWall.style);
    //   this.isWall = true;
    //   if (this.isFocus === false) {
    //     topWall.style("fill", "#0000ff");
    //   }
    // });

    // topWall.on("mouseout", (e) => {
    //   console.log(e, "wallSelect");
    //   this.isWall = false;
    //   if (this.isFocus === false) {
    //     topWall.style("fill", "#f0f0f0");
    //   }
    // });

    // topWall.on("click", (e) => {
    //   this.resetClick();

    //   this.isFocus = true;
    //   this.enable = true;

    //   topWall.style("fill", "#0000ff");
    // });
    // let px, py, vx, vy, offsetX, offsetY;

    // const maxVelocity = 2;
    // const color = d3.scaleOrdinal().range(d3.schemeCategory10);

    // const nodes = Array.apply(null, Array(20)).map(function (_, i) {
    //   const size = Math.random() * 60 + 20;
    //   const velocity = Math.random() * 2 + 1;
    //   const angle = Math.random() * 360;

    //   return {
    //     x: Math.random() * (960 - size),
    //     y: Math.random() * (500 - size),
    //     vx: velocity * Math.cos((angle * Math.PI) / 180),
    //     vy: velocity * Math.sin((angle * Math.PI) / 180),
    //     size: size,
    //     fill: color(i),
    //   };
    // });

    // function dragStarted(d, event) {
    //   console.log(d, event);
    //   vx = 0;
    //   vy = 0;
    //   offsetX = (px = event.x) - (d.fx = d.x);
    //   offsetY = (py = event.y) - (d.fy = d.y);
    // }

    // function dragged(d, event) {
    //   console.log(d, event);

    //   vx = event.x - px;
    //   vy = event.y - py;
    //   console.log(event.x, event.y, offsetX, offsetY, d.size);
    //   d.fx = Math.max(Math.min((px = event.x) - offsetX, 960 - 100), 0);
    //   d.fy = Math.max(Math.min((py = event.y) - offsetY, 500 - 100), 0);
    // }

    // function dragEnded(d, event) {
    //   console.log(d, event);

    //   const vScalingFactor = maxVelocity / Math.max(Math.sqrt(vx * vx + vy * vy), maxVelocity);
    //   d.fx = null;
    //   d.fy = null;
    //   d.vx = vx * vScalingFactor;
    //   d.vy = vy * vScalingFactor;
    // }

    // const rects = svg
    //   .attr("width", 960)
    //   .attr("height", 500)
    //   .selectAll("rect")
    //   .data(nodes)
    //   .enter()
    //   .append("rect")
    //   .style("fill", function (d) {
    //     return d.fill;
    //   })
    //   .attr("width", function (d) {
    //     return d.size;
    //   })
    //   .attr("height", function (d) {
    //     return d.size;
    //   })
    //   .attr("x", function (d) {
    //     return d.x;
    //   })
    //   .attr("y", function (d) {
    //     return d.y;
    //   })
    //   .on("dragstart", dragStarted)
    //   .on("drag", dragged)
    //   .on("dragend", dragEnded);

    // const boxForce = boundedBox()
    //   .bounds([
    //     [0, 0],
    //     [960, 500],
    //   ])
    //   .size(function (d) {
    //     return [d.size, d.size];
    //   });

    // const collisionForce = rectCollide().size(function (d) {
    //   return [d.size, d.size];
    // });

    // d3.forceSimulation().velocityDecay(0).alphaTarget(1).on("tick", ticked).force("box", boxForce).force("collision", collisionForce).nodes(nodes);

    // function rectCollide() {
    //   let nodes, sizes, masses;
    //   let size = constant([0, 0]);
    //   let strength = 1;
    //   let iterations = 1;

    //   function force() {
    //     let node, size, mass, xi, yi;
    //     let i = -1;
    //     while (++i < iterations) {
    //       iterate();
    //     }

    //     function iterate() {
    //       let j = -1;
    //       const tree = d3.quadtree(nodes, xCenter, yCenter).visitAfter(prepare);

    //       while (++j < nodes.length) {
    //         node = nodes[j];
    //         size = sizes[j];
    //         mass = masses[j];
    //         xi = xCenter(node);
    //         yi = yCenter(node);

    //         tree.visit(apply);
    //       }
    //     }

    //     function apply(quad, x0, y0, x1, y1) {
    //       const data = quad.data;
    //       const xSize = (size[0] + quad.size[0]) / 2;
    //       const ySize = (size[1] + quad.size[1]) / 2;
    //       if (data) {
    //         if (data.index <= node.index) {
    //           return;
    //         }

    //         let x = xi - xCenter(data);
    //         let y = yi - yCenter(data);
    //         const xd = Math.abs(x) - xSize;
    //         const yd = Math.abs(y) - ySize;

    //         if (xd < 0 && yd < 0) {
    //           const l = Math.sqrt(x * x + y * y);
    //           const m = masses[data.index] / (mass + masses[data.index]);

    //           if (Math.abs(xd) < Math.abs(yd)) {
    //             node.vx -= (x *= (xd / l) * strength) * m;
    //             data.vx += x * (1 - m);
    //           } else {
    //             node.vy -= (y *= (yd / l) * strength) * m;
    //             data.vy += y * (1 - m);
    //           }
    //         }
    //       }

    //       return x0 > xi + xSize || y0 > yi + ySize || x1 < xi - xSize || y1 < yi - ySize;
    //     }

    //     function prepare(quad) {
    //       if (quad.data) {
    //         quad.size = sizes[quad.data.index];
    //       } else {
    //         quad.size = [0, 0];
    //         let i = -1;
    //         while (++i < 4) {
    //           if (quad[i] && quad[i].size) {
    //             quad.size[0] = Math.max(quad.size[0], quad[i].size[0]);
    //             quad.size[1] = Math.max(quad.size[1], quad[i].size[1]);
    //           }
    //         }
    //       }
    //     }
    //   }

    //   function xCenter(d) {
    //     return d.x + d.vx + sizes[d.index][0] / 2;
    //   }
    //   function yCenter(d) {
    //     return d.y + d.vy + sizes[d.index][1] / 2;
    //   }

    //   force.initialize = function (_) {
    //     sizes = (nodes = _).map(size);
    //     masses = sizes.map(function (d) {
    //       return d[0] * d[1];
    //     });
    //   };

    //   force.size = function (_) {
    //     return arguments.length ? ((size = typeof _ === "function" ? _ : constant(_)), force) : size;
    //   };

    //   force.strength = function (_) {
    //     return arguments.length ? ((strength = +_), force) : strength;
    //   };

    //   force.iterations = function (_) {
    //     return arguments.length ? ((iterations = +_), force) : iterations;
    //   };

    //   return force;
    // }

    // function constant(_) {
    //   return function () {
    //     return _;
    //   };
    // }

    // function boundedBox() {
    //   let nodes, sizes;
    //   let bounds;
    //   let size = constant([0, 0]);

    //   function force() {
    //     let node, size;
    //     let xi, x0, x1, yi, y0, y1;
    //     let i = -1;
    //     while (++i < nodes.length) {
    //       node = nodes[i];
    //       size = sizes[i];
    //       xi = node.x + node.vx;
    //       x0 = bounds[0][0] - xi;
    //       x1 = bounds[1][0] - (xi + size[0]);
    //       yi = node.y + node.vy;
    //       y0 = bounds[0][1] - yi;
    //       y1 = bounds[1][1] - (yi + size[1]);
    //       if (x0 > 0 || x1 < 0) {
    //         node.x += node.vx;
    //         node.vx = -node.vx;
    //         if (node.vx < x0) {
    //           node.x += x0 - node.vx;
    //         }
    //         if (node.vx > x1) {
    //           node.x += x1 - node.vx;
    //         }
    //       }
    //       if (y0 > 0 || y1 < 0) {
    //         node.y += node.vy;
    //         node.vy = -node.vy;
    //         if (node.vy < y0) {
    //           node.vy += y0 - node.vy;
    //         }
    //         if (node.vy > y1) {
    //           node.vy += y1 - node.vy;
    //         }
    //       }
    //     }
    //   }

    //   force.initialize = function (_) {
    //     sizes = (nodes = _).map(size);
    //   };

    //   force.bounds = function (_) {
    //     return arguments.length ? ((bounds = _), force) : bounds;
    //   };

    //   force.size = function (_) {
    //     return arguments.length ? ((size = typeof _ === "function" ? _ : constant(_)), force) : size;
    //   };

    //   return force;
    // }

    // function ticked() {
    //   rects
    //     .attr("x", function (d) {
    //       return d.x;
    //     })
    //     .attr("y", function (d) {
    //       return d.y;
    //     });
    // }

    svg.on("mouseenter", (e) => {
      console.log(e, "areaSelect");
      if (this.isFocus === false) {
        rect.style("fill", "#ffff00");
      }
    });

    svg.on("mouseleave", (e) => {
      console.log(e);
      if (this.isFocus === false) {
        rect.style("fill", "#919191");
      }
    });

    // svg.on("mouseout", () => {
    //   if (this.isFocus === false) {
    //     rect.style("fill", "#919191");
    //   }
    // });

    svg.on("click", (e) => {
      this.resetClick();

      // console.log(this.spaceInfo instanceof Rspace);
      // editStore.setRightSpaceInfo(CommonUtils.deepClone(this.spaceInfo) as Zone);
      editStore.setRightSpaceInfo(editStore.findZone(this.spaceInfo.zoneSeq, editStore.selectZoneInfo?.childZones as Zone[]));
      editStore.setEditSpaceSelectType("inZone");
      // editStore.setSelectSpaceType(this.spaceInfo.zoneTypeCode as string);
      // editModule?.setEditorSpaceInfo(this.spacpeInfo);
      this.isFocus = true;
      this.enable = true;

      rect.style("fill", "#ffff00");
      //   console.log(this.id, e.target.value, e.target.id, e);
    });
  }

  resetClick() {
    const child = d3.select(`#${this.spaceType}${this.id}`).selectChild(`#${this.spaceType}AreaRect`);

    const styleAttr = Object.entries(this.style);

    for (let i = 0; i < styleAttr.length; i++) {
      child.style(styleAttr[i][0], styleAttr[i][1]);
    }

    this.isFocus = false;
    this.enable = false;

    // useEditStore().setZoneZoomValue();
  }
}

export default AreaRect;
