import { LightControlType } from "@/global/enums";
import { SelectionAttr } from "@/models/attrs-maps/abstract-action-attrs";
import LightAttrs from "@/models/attrs-maps/light-attrs";
import CommonUtils from "@/utils/common-util";
import { AbstractActionNodeSetting } from "./node-setting";

class ScenarioControlLightNodeSetting extends AbstractActionNodeSetting {
  attrs: LightAttrs;
  selectionAttrs: Array<SelectionAttr> = [];
  controlType: LightControlType = LightControlType.OnOff;
  constructor() {
    super();

    this.attrs = new LightAttrs();
    this.selectionAttrs.push(new SelectionAttr(false, "control"));
    this.selectionAttrs.push(new SelectionAttr(false, "tt"));
    this.selectionAttrs.push(new SelectionAttr(false, "colortemp"));
  }

  public solveAttrs(attrsMap: string) {
    try {
      const o = CommonUtils.jsonDeserialize(LightAttrs, attrsMap) as LightAttrs;
      this.attrs = o;
      
      this.controlType = attrsMap.includes("\"level\"") ? LightControlType.Dimming : LightControlType.OnOff;
      this.selectionAttrs[0].isSelected = attrsMap.includes("\"onoff\"") || attrsMap.includes("\"level\"");
      this.selectionAttrs[1].isSelected = attrsMap.includes("\"tt\"");
      this.selectionAttrs[2].isSelected = attrsMap.includes("\"colortemp\"");

    } catch (e) {
      console.log("Solve Attrs Exception");
    }
  }

  override toAttrsMap() {

    const n: string[] = [];

    if(this.selectionAttrs[0].isSelected) {

      if(this.controlType === LightControlType.OnOff)
        n.push("onoff");
      else
        n.push("level");
    }

    if (this.selectionAttrs[1].isSelected)
      n.push("tt");

    if (this.selectionAttrs[2].isSelected)
      n.push("colortemp");

    let isAllDeselected = true;

    for (const attr of this.selectionAttrs) {

      if (!attr.isSelected)
        continue;

      //하나라도 선택된것이 있으면 isAllDeselected = false로 설정      
      isAllDeselected = false;

      if (attr.name === "control") {
        if (this.controlType === LightControlType.OnOff)
          n.push("onoff");
        else
          n.push("level");
      } else
        n.push(attr.name);
    }

    if(isAllDeselected)
      return null;

    const json = JSON.stringify(this.attrs, n);
    return json;
  }
}

export default ScenarioControlLightNodeSetting;