import AbstractActionAttrs from "./abstract-action-attrs";

/**
 * 씬 제어 속성 클래스
 * @class
 */
class SceneAttrs extends AbstractActionAttrs {
  cur_Scene = 0;                      //현재 수행 Scene
}

export default SceneAttrs;