<script setup lang="ts">
// ANCHOR - MainComponent
// 전체 화면 구성을 포함

import MainHeader from "./MainHeader.vue";
import MainLeft from "./MainLeft.vue";
import MainContents from "./MainContents.vue";
import { onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, watch, watchEffect, type WatchStopHandle } from "vue";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { useUserStore } from "@/stores/user-store";
import CommonUtils from "@/utils/common-util";
//import { onBeforeRouteLeave } from "vue-router";

import { useRouter } from "vue-router";
import { useEditStore } from "@/stores/edit-store";
import { useMenuStore } from "@/stores/menu-store";
import { useScenarioStore } from "@/stores/scenario-store";
import { useAuthManager } from "@/composables/auth-manager";
import SnapShot from "@/models/snapshot";
import type Menu from "@/models/menu";


const editStore = useEditStore();
const userStore = useUserStore();
const menuStore = useMenuStore();
const scenarioStore = useScenarioStore();

const authManager = useAuthManager();
const router = useRouter();

let refreshTokenWatchStopHandle: WatchStopHandle | null = null;
let watchStopHandle: WatchStopHandle | null = null;

onBeforeMount(async () => {

  //앱 실행중 토큰 만료 감지
  refreshTokenWatchStopHandle = watch(authManager.isExistRefreshToken, () => {
    checkAuth();
  });

  userStore.setRefreshTokenWatchStopHandle(refreshTokenWatchStopHandle);

  if (router.currentRoute.value.name?.toString() === "site")
    return;

  try {
    await editStore.initLoad();
  } catch {
    checkAuth();
  }
});


onMounted(async () => {
  window.addEventListener("beforeunload", unLoadEvent);

});

onUnmounted(() => {

  if(CommonUtils.isNullOrEmpty(watchStopHandle))
    return;

  watchStopHandle();
  watchStopHandle = null;
});

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", unLoadEvent);

  if (CommonUtils.isNullOrEmpty(refreshTokenWatchStopHandle))
    return;

  refreshTokenWatchStopHandle();
  refreshTokenWatchStopHandle = null;
});

onBeforeRouteLeave((to, from, next) => {

  const user = userStore.getUser;
  if (CommonUtils.isNullOrEmpty(user)) {
    next();
    return;
  }

  if (router.currentRoute.value.name?.toString() === "site") {
    next();
    return;
  } 

  if (showLeaveDialog())
    next();
});

onBeforeRouteUpdate((to, from, next) => {
  
  if (to.name === "site") {
    if (showLeaveDialog())
      next();
  }
  else
    next();
});

function checkAuth() {
  if (authManager.isExistRefreshToken.value)
    return;

  alert("인증 정보가 없습니다. 로그인 페이지로 이동합니다.");
  router.push("/");
}

function showLeaveDialog() {
  //인증 정보가 없어서 페이지 이동인 경우 팝업 출력 안함.
  if (CommonUtils.isNullOrEmpty(refreshTokenWatchStopHandle))
    return;

  return confirm("이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.");
}

function unLoadEvent(event: any) {

  if (CommonUtils.isNullOrEmpty(authManager.getRefreshToken()))
    return;

  if (router.currentRoute.value.name?.toString() === "site")
    return;

  event.preventDefault();
  event.returnValue = "";
}

</script>

<template>
  <q-layout view="hHh LpR lFr" class="bg-black-1">
    <MainHeader></MainHeader>
    <div v-if="router.currentRoute.value.name?.toString() !== 'site'">
      <q-drawer v-if="$q.screen.sm" v-model="menuStore.isMainLeftOn" overlay side="left" :width="96" :breakpoint="700" class="column justify-between MainLeft">
        <MainLeft></MainLeft>
      </q-drawer>
      <q-drawer v-else show-if-above side="left" :width="96" :breakpoint="700" class="column justify-between MainLeft">
        <MainLeft></MainLeft>
      </q-drawer>
      <MainContents></MainContents>
    </div>
    <div v-else>
      <MainContents></MainContents>
    </div>
  </q-layout>
  
</template>

<style lang="scss">

</style>
