
import { PortType, TimeType } from "@/global/enums";
import type { LinkPoint, stringObject } from "@/global/types";
import { instanceToInstance, plainToInstance, type ClassConstructor } from "class-transformer";
import { v1 as uuidv1 } from "uuid";
import short from "short-uuid";

class CommonUtils {
  /**
   * ID 를 생성한다. (중간에 비어 있는 수가 있으면 비어 있는 수를 리턴)
   * @param numbers 
   * @returns 
   */
  static getNewId(numbers: number[]) {

    if(numbers.length <= 0)
      return 1;

    numbers.sort((a, b) => {
      return a - b;
    });

    //빈값 찾기
    for (let i = 0; i < numbers.length; i++) {
      const num = i + 1;

      if (num !== numbers[i])
        return num;
    }

    return numbers[numbers.length - 1] + 1;
  }

  /**
   * ID(Max)를 생성한다.
   * @param numbers 
   */
  static getNewMaxId(numbers: number[]) {
    if(numbers.length <= 0)
      return 1;
                
    return Math.max.apply(null, numbers) + 1;
  }


  /**
   * UUID를 생성한다.
   * @returns uuid
   */
  static generateUUID() {
    /*
   let options = {
  node: // 바이트값 6개
  clockseq: // 클럭 시퀀스 (0 - 0x3fff 사이 값)
  msecs: // 밀리초
  nsecs: // 나노초
  random: // 16개의 랜덤 바이트값
  rng: // random 변수를 대체할 16개의 랜덤 바이트값을 반환하는 함수
} 
*/        
    //return uuid.v1();        
    //return uuidv1();
    return short.generate();
  }

  /**
   * 객체를 복제한다.
   * @param obj 객체 (클래스)
   * @returns 복제된 객체
   */
  static deepClone<T>(obj: T) {
    return instanceToInstance<T>(obj);
  }

  static toJson(obj: object) {
    return JSON.stringify(obj, null, 2);
  }

  /**
   * 객체가 비어 있는지 체크 (비어 있으면 true)
   * @param obj 
   * @returns 
   */
  static isNullOrEmpty<T>(obj: T | null | undefined): obj is null | undefined {
    return obj === null || obj === undefined;
  }  

  
  static isObjectValueEmpty(obj: stringObject){
    let key = "";
    let isEmpty = false;
    const keys = Object.keys(obj);

    keys.map(k => {
      if(obj[k] === "" || obj[k] === null || obj[k] === undefined){
        key = k;        
        isEmpty = true;
        return;
      }
    });

    console.log(isEmpty);

    
    if(isEmpty){
      alert("빈 값이 존재합니다.");
    } 

    return {key, isEmpty};
  }

  static nameof<T>(name: keyof T) {
    return name;
  }

  /**
   * Json -> 역직렬화
   * @param cls 타입
   * @param json Json
   * @returns 
   */
  static jsonDeserialize<T>(cls: ClassConstructor<T>, json: string): T | T[] {
    try {
      const deserializeObj: T = JSON.parse(json);

      return plainToInstance(cls, deserializeObj);

    } catch (e) {
      console.log(e);
      throw new Error("Method not implemented.");
    }
  }

  static jsonSerialize<T>(obj: T | null) {

    if(this.isNullOrEmpty(obj))
      return "";

    return JSON.stringify(obj, null, 2);    
  }

  /**
   * 배열을 그룹화 한다.
   * @param array 배열
   * @param grouper 그룹할 항목
   * @returns 
   */
  static groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      const key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        const g = store.get(key);

        if (!this.isNullOrEmpty(g))
          g.push(item);
      }
      return store;
    }, new Map<K, V[]>());
  }

  /**
   * 포트 타입으로 시작 포인트와 종료 포인트의 결과를 리턴 (포트가 OUT 일경우 시작 포인트)
   * @param startLinkPoint 
   * @param endLinkPoint 
   * @returns [시작 노드 아이디, 종료 노드 아이디, 시작노드 아이템 인덱스]
   */
  static getCorrectLinkData(startLinkPoint: LinkPoint, endLinkPoint: LinkPoint): [string, string, string?] {

    let srcNodeId: string;
    let nodeItemId: string | undefined;


    if(startLinkPoint.portType === PortType.Out) {
      srcNodeId = startLinkPoint.nodeId as string;
      nodeItemId = startLinkPoint.nodeItemId;
    } 
    else {
      srcNodeId = endLinkPoint.nodeId as string;
      nodeItemId = endLinkPoint.nodeItemId;
    } 

    const targetNodeId = endLinkPoint.portType === PortType.In ? endLinkPoint.nodeId : startLinkPoint.nodeId;     
    
    return [srcNodeId, targetNodeId as string, nodeItemId];
  }
    
  static toDateString(typeType: TimeType, dateTime: string) {
    let dateString: string| null = null;
    switch (typeType) {
      case TimeType.Sunrise:        
      case TimeType.SunSet:
        dateString = typeType;
        break;
      default:
        dateString = dateTime.toString();
        break;
    }
    return dateString;
  }

  static toDateOccurString() {

  }

  static range(start: number, end: number) {    
    return Array.from(Array(end - start + 1).keys()).map(x => x + start);
  }

  /**
   * 자리수
   * @param length 자리수
   * @param valueString 값
   * @returns 
   */
  static fillZero(length: number, valueString: string) {
    return valueString.length >= length ? valueString : new Array(length - valueString.length + 1).join("0") + valueString;
  }
}

// export function isNullOrUndefined<T>(obj: T): obj is null | undefined {
//   return obj === null || obj === undefined;
// }

export default CommonUtils;