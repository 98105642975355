import type MPoint from "./m-point";

class DPoint {
  siteSeq: number;
  zoneSeq: number;
  dpointSeq: number;
  dpointName?: string;
  devTypeCode?: string;
  centerX?: number;
  centerY?: number;
  centerZ?: number;
  mpoints?: MPoint[];
  rotate?: number;
  regDate?: string;
  regUserId: number;
  modDate?: string;
  modUserId: number;

  constructor(siteSeq: number, zoneSeq: number, dPointSeq: number, userID: number) {
    this.siteSeq = siteSeq;
    this.zoneSeq = zoneSeq;
    this.dpointSeq = dPointSeq;    
    this.mpoints = [] as MPoint[];
    this.regUserId = userID;
    this.modUserId = userID;
  }
}

export default DPoint;