import { createApp, inject } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
// import Modules from "./composables/modules/modules";
import VueApexCharts from "vue3-apexcharts";

import "./assets/main.css";

import { Quasar, Loading, Dialog, Notify } from "quasar";

// Import icon libraries
import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";

import "@/style/main.scss";
import ApiServices from "./composables/api/api-services";
import VueCookies from "vue-cookies";
import PageInterceptor from "./global/page-interceptor";
import PersistStateManager from "./composables/persist-state-manager";

const app = createApp(App);

const siteUrl = import.meta.env.MODE === "production" ? import.meta.env.VITE_SITE_URL : import.meta.env.VITE_DEV_SITE_URL;
console.log(`Mode -> ${import.meta.env.MODE}, SiteUrl -> ${siteUrl}`);


app.use(VueCookies, {expires: "7d", secure: import.meta.env.MODE === "production" ? false : true, domain: siteUrl});


const apiService = new ApiServices();
const persistStateManager = new PersistStateManager();
const cookies = VueCookies.VueCookies;

const pinia = createPinia();
pinia.use(({ store }) => {
  store.apiServices = apiService;
  store.cookies = cookies;
  store.persistStateManager = persistStateManager;
});

const pageInterceptor = new PageInterceptor();

router.beforeEach(pageInterceptor.beforeHandle);
router.afterEach(pageInterceptor.afterHandle);

app.use(pinia);
app.use(router);
app.use(VueApexCharts);
app.use(Quasar, {
  plugins: {
    Loading,
    Dialog,
    Notify
  },
  lang: "langKR",
  config: {
    dark: true,
    extras: [
      "nanum-gothic"
    ],
    notify: { /* look at QuasarConfOptions from the API card */ },    
  },
});

//VueCookies.install(app, {expires: "7d"});


// app.use(VueCookies, {
//   expire: "7d"
// });

//VueCookies.install(app);

// modules
// const modules = new Modules();
// app.provide("$modules", modules);


apiService.setErrorCallback();
app.mount("#app");
