<script setup lang="ts">
import { useEditStore } from "@/stores/edit-store";
import ZoneByPath from "./ZoneByPath";
import { computed, onMounted, ref, onUpdated } from "vue";
import CommonUtils from "@/utils/common-util";
import SVGPathCommander from "svg-path-commander";



const emitData = defineEmits(["update:setEditor"]);

const editStore = useEditStore();

const viewBoxWidth = ref();
const viewBoxHeight = ref();

const zoomData = computed(() => {
  return editStore.zoom;  
});

const selectSpace = computed(() => {
  return editStore.selectSpaceInfo;
});

const selectBoundPath = computed(() => {
  return selectSpace.value?.boundsPath as string;
});

const modelSize = computed(() => {
  return editStore.selectModelSize;
});

onMounted(() => {
  console.log("Zone Path is Mounted ::::::");
  const width = document.getElementById("UAreaRect")?.getAttribute("width");
  const height = document.getElementById("UAreaRect")?.getAttribute("height");
  
  viewBoxWidth.value = CommonUtils.deepClone(editStore.UzoneSizeData.x * editStore.zoom);
  viewBoxHeight.value = CommonUtils.deepClone(editStore.UzoneSizeData.z * editStore.zoom);
  console.log(width, height, viewBoxWidth, viewBoxHeight);
  emitData("update:setEditor", width, height);
});

function test(){
  // const a = document.getElementById("zonePathArea");
  // console.log(a?.getBoundingClientRect());
  // console.log(a.get)s
  
}

</script>


<template>
  <!-- 실시간 변경 반영 처리 -->
  <!-- 존 명칭 위치  -->
  <!-- 존 좌표 수정 영역 찾기 -->
  <div class="ZonePath" :style="{width: '100%', height: '100%'}">

    <svg
    :x="`${selectSpace?.originX}`"
    :y="`${selectSpace?.originY}`"
    :width="(editStore.UzoneSizeData.x * editStore.zoom) + Number(selectSpace?.originX)"
    :height="(editStore.UzoneSizeData.z * editStore.zoom) + Number(selectSpace?.originY)"
    :viewBox="`-${selectSpace?.originX} -${selectSpace?.originY} ${Number(viewBoxWidth) + Number(selectSpace?.originX)} ${Number(viewBoxHeight) + Number(selectSpace?.originY)}`"
    class="zonePathSvg"
    preserveAspectRatio="none"
    :style="{backgroundColor: 'grey'}"
    >
    <path 
    id="zonePathArea" 
    :fill="'yellow'" 
    :d="selectBoundPath"
    :style="{transform: `scale(${viewBoxWidth})`}"
    ></path>
 
  </svg>
   <svg
    :x="`${selectSpace?.originX}`"
    :y="`${selectSpace?.originY}`"
    :width="editStore.UzoneSizeData.x * editStore.zoom"
    :height="editStore.UzoneSizeData.z * editStore.zoom"
    class="textSvg"
   >
    <text
    :x="`${(editStore.UzoneSizeData.x * editStore.zoom / 2) + Number(selectSpace?.originX)}`"
    :y="`${10 + (editStore.UzoneSizeData.z * editStore.zoom / 10) + Number(selectSpace?.originY)}`"
    :style="{
      textAnchor: 'middle',
      alignmentBaseline: 'central',
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'black',
    }"
    >{{selectSpace?.zoneName}}</text>
   </svg>

  <!-- <q-btn @click="test">test</q-btn> -->
</div>
</template>

<style lang="scss">
.ZonePath{
  position: absolute;
  .zonePathSvg{
    position: absolute;
    .textSvg{
      position: absolute;
    }
    #zonePathArea{
      // transform: scale(v-bind(zoomData));
    }
  }
  
}
</style>