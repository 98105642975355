<script setup lang="ts">
import { onMounted } from "vue";
import draggable from "vuedraggable";
import type PackageNodeConfig from "@/models/editor/nodes/config/package-node-config";
import { useScenarioStore } from "@/stores/scenario-store";

const scenarioStore = useScenarioStore();

onMounted(async() => {
});


function onDragStart(event: DragEvent): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedNodeConfig = event.target.__draggable_context.element as PackageNodeConfig;
  event.dataTransfer?.setData("selectedNodeConfigId", selectedNodeConfig.id);
  console.log(`onDragStart -> ${selectedNodeConfig.id}`);
}

function canMove() {
  return false;
}

function onDragEnd(event: any): void {}
</script>

<template>
  <div class="ScenarioNodeComponent">
    <q-list>
      <q-expansion-item
      v-for="el in scenarioStore.scenarioConfigGroups"
      :header-class="`el${el.id} nodeHeader`"
      :expand-icon-class="`elIcon`"
      :label="el.title"
      :key="el.id"
      :header-inset-level="0.2"
      :dark="false"
      dense
      expand-separator
      default-opened
      >
      <q-list bordered class="rounded-borders nodeList">
        <draggable v-model="el.configs" item-key="id" class="list-group" :move="canMove" @dragstart="onDragStart" @dragend="onDragEnd">
          <template #item="{ element }">
            <q-item dark bordered class="node-list-group">
              <q-item-section class="col-3" avatar>
                <!-- <q-icon color="primary" name="bluetooth" /> -->
                <q-img style="width: 24px; height: 24px;" :src="'scenario-nodes/' + element.imageUrl" />
              </q-item-section>
              <q-item-section class="col-9">{{ element.name }}</q-item-section>
            </q-item>
          </template>
        </draggable>
      </q-list>
    </q-expansion-item>
  </q-list>
</div>
</template>

<style lang="scss">

.ScenarioNodeComponent {
  .nodeHeader{
    color: #404040;
    border-bottom: 1px solid #c1c1c1;
  }
  
  .elIcon {
    color: #404040 !important;
  }
  
  .nodeList {
    background-color: #e2e6f3;
    border-bottom: 1px solid #c1c1c1;
    border: none;
    .node-list-group {
      background-color: #e2e6f3;
      border-bottom: 1px solid#c1c1c1;
      color: #404040;
      font-weight: bold !important;
      font-size: 14px;
    }
  }
}
</style>
