

/**
 * Scene Node Event 클래스
 * @class
 */
class ScenarioNodeEvent {
  siteSeq?: number;
  zoneSeq?: number;
  scenarioSeq?: number;
  nodeSeq?: number;
  eventCode?: string;
  eventName?: string;
  eventOccurCond?: string;
  regDate?: string;
  regUserId?: number;
  modDate?: string;
  modUserId?: number;          
}
    
export default ScenarioNodeEvent;
    