<script setup lang="ts">
import { onMounted, computed, reactive, watch, onBeforeMount, inject } from "vue";
import DrawCanvas from "@/components/Temp/DrawCanvas.vue";
import draggable from "vuedraggable";
import { EditorInfo, GridSetting } from "@/models/editor/editor-setting";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import baseNode from "@/components/Nodes/BaseNode.vue";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useLightStore } from "@/stores/light-store";

const nodeEditorStore = useNodeEditorStore();
const lightStore = useLightStore();

const gridSetting = new GridSetting(800, 800);
const editorInfo = reactive(new EditorInfo(gridSetting));

onBeforeMount(() => {});

onMounted(async () => {

  // const api = new RestClient2("http://192.168.10.11:8084/");
  // const result = await api.client.request({
  //   method: "GET",
  //   url: "/v1/codes",
    
  // });
  // console.log(result);
  

  lightStore.loadDeviceElementGroups();
});

function onDragStart(event: DragEvent): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedNodeConfig = event.target.__draggable_context.element as LightNodeConfig;
  event.dataTransfer?.setData("selectedNodeConfigId", selectedNodeConfig.id);
  console.log(`onDragStart -> ${selectedNodeConfig.id}`);
}

function canMove() {
  console.log("canMove");
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onDragEnd(event: any): void {
  //console.log(`onDragEnd-> ${event.dataTransfer}`);
  //const eventJson = JSON.stringify(event);
  console.log(`onDragEnd-> ${event}`);
  // const index = e.oldIndex as number;
  // const nodeConfig = nodeItems[index];

  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore
  // const event = e.originalEvent as DragEvent;

  // const x = event.offsetX - nodeConfig.w / 2;
  // const y = event.offsetY - nodeConfig.h / 2;

  // console.log(`onDragEnd -> x: ${x}, y: ${y}`);

  // createLightNode(x, y, nodeConfig);
}

function onChangeScale(isUp: boolean): void {
  if (isUp) editorInfo.scale += 0.1;
  else editorInfo.scale -= 0.1;
}

function onItemDrop(event: DragEvent) {
  const id = event.dataTransfer?.getData("selectedNodeConfigId");

  const config = nodeEditorStore.getNodeConfigs.find(n => n.id === id) as LightNodeConfig;

  const x = event.offsetX / editorInfo.scale - config.w / 2;
  const y = event.offsetY / editorInfo.scale - config.h / 2;

  //노드 생성
  nodeEditorStore.createNode(x, y, config);

  console.log(
    `onItemDrop!!! event id-> ${id}, x: ${x}, y: ${y}, offsetX: ${event.offsetX}, offsetY: ${event.offsetY}, pageX: ${event.pageX}, pageY: ${event.pageY}`
  );

  const items = event.dataTransfer?.items;
  console.log(`onItemDrop!!! items -> ${items}`);
}
</script>

<template>
  <div class="q-pa-md">
    <div class="row">
      <div class="col-3" style="width: 220px; min-width: 220px">
        <q-form style="margin: 5px">
          <q-input filled v-model.number="editorInfo.gridSetting.majorGridSize" type="number" label="majorGridSize" />
          <q-input filled v-model.number="editorInfo.gridSetting.minorGridSize" type="number" label="minorGridSize" />

          <q-input filled v-model="editorInfo.gridSetting.majorGridStrokeColor" label="majorStrokeColor">
            <template v-slot:append>
              <q-icon name="colorize" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-color v-model="editorInfo.gridSetting.majorGridStrokeColor" />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input filled v-model="editorInfo.gridSetting.minorGridStrokeColor" label="minorStrokeColor" style="border-bottom: outset">
            <template v-slot:append>
              <q-icon name="colorize" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-color v-model="editorInfo.gridSetting.minorGridStrokeColor" />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input filled v-model.number="editorInfo.gridSetting.majorGridStrokeSize" type="number" label="majorGridStrokeSize" />
          <q-input filled v-model.number="editorInfo.gridSetting.minorGridStrokeSize" type="number" label="minorGridStrokeSize" />

          <div class="q-pa-md q-gutter-sm">
            <q-btn color="primary" label="Scale +" @click="onChangeScale(true)" />
            <q-btn color="primary" label="Scale -" @click="onChangeScale(false)" />
            <h6>{{ editorInfo.scale }}</h6>
          </div>

          <div class="q-pa-md q-gutter-sm"></div>

          <q-expansion-item expand-separator :default-opened="true" label="조명 리스트">
            <q-scroll-area class="q-pa-sm window-height" :visible="true">
              <q-list bordered class="rounded-borders">
                <!-- <draggable v-model="nodeItems" item-key="id" class="list-group" :move="canMove" @start="onDragStart"
                  @end="onDragEnd"> -->
                <draggable
                  v-model="nodeEditorStore.nodeConfigs"
                  item-key="id"
                  class="list-group"
                  :move="canMove"
                  @dragstart="onDragStart"
                  @dragend="onDragEnd"
                >
                  <template #item="{ element }">
                    <q-item dark bordered style="background-color: black; border: 1px solid rgba(255, 255, 255, 0.4)">
                      <q-item-section avatar>
                        <!-- <q-icon color="primary" name="bluetooth" /> -->
                        <q-img :src="element.imageUrl" />
                      </q-item-section>
                      <q-item-section>{{ element.name }}</q-item-section>
                    </q-item>
                  </template>
                </draggable>
              </q-list>
            </q-scroll-area>
          </q-expansion-item>
        </q-form>
      </div>
      <div class="col-7">
        <draw-canvas v-model="editorInfo" @drop="onItemDrop">
          <template v-slot:real="{ onNodeMouseDown, onNodeMouseUp }">
            <base-node
              v-for="node in nodeEditorStore.nodeInfos"
              :nodeInfo="(node as AbstractNodeInfo)"
              :key="node.id"
              @nodeMouseDown="onNodeMouseDown"
              @nodeMouseUp="onNodeMouseUp"
            />
          </template>
        </draw-canvas>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.q-input {
  margin-bottom: 10px;
}
</style>
