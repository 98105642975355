import LightConfigAttrs from "@/models/attrs-maps/light-config-attrs";
import CommonUtils from "@/utils/common-util";
import { AbstractActionNodeSetting } from "./node-setting";

class ScenarioConfigureLightNodeSetting extends AbstractActionNodeSetting {
  attrs: LightConfigAttrs;

  constructor() {
    super();
    this.attrs = new LightConfigAttrs();
  }

  public solveAttrs(attrsMap: string): void {
    try {
      const o = CommonUtils.jsonDeserialize(LightConfigAttrs, attrsMap) as LightConfigAttrs;
      this.attrs = o;
    } catch (e) {
    }
  }
}
export default ScenarioConfigureLightNodeSetting;