/**
 * Profile 클래스
 * @class
*/
class Profile {
  usageProfileCode?: string;
  usageProfileName?: string;
  useStartTime?: string;
  useEndTime?: string;
  dailyUsageTime?: number;
  annualUseDays?: number;
  annualUseDayHours?: number;
  annualUseNightHours?: number;
  reqIlluminance?: number;
  workSurfaceHeight?: number;
  lightReductCoeff?: number;
  lightRelativeAbsenceRate?: number;
  indoorIndex?: number;
  lightOperReductCoeff?: number;
  vertifaceLightCoeff?: number;
  usageType?: number;
  lightingHours?: number;
}

export default Profile;