import type { LinkPoint } from "@/global/types";
import CommonUtils from "@/utils/common-util";
import type AbstractNodeConfig from "../config/abstract-node-config";
import AbstractNodeInfo from "./abstract-node-info";
import type LinkInfo from "./link-info";

/**
 * Link 노드 정보 클래스
 * @class
*/
class LinkNodeInfo extends AbstractNodeInfo {
  private links: LinkInfo[] | null = null;
  public srcNodeId?: string;  
  public targetNodeId?: string; 
  public srcNodeItemId?: string;

  constructor (id: string, x: number, y: number, nodeConfig: AbstractNodeConfig) {
    super(id, x, y, nodeConfig);

  }

  getLinks() {
    return this.links;
  }

  addLink(linkInfo: LinkInfo, startLinkPoint: LinkPoint, endLinkPoint: LinkPoint) {
    
    if(CommonUtils.isNullOrEmpty(this.links))
      this.links = [];    
    
    if(CommonUtils.isNullOrEmpty(startLinkPoint.nodeId) || CommonUtils.isNullOrEmpty(endLinkPoint.nodeId))
      return;        


    const linkData = CommonUtils.getCorrectLinkData(startLinkPoint, endLinkPoint);
      
    this.srcNodeId = linkData[0];
    this.targetNodeId = linkData[1];      
    this.srcNodeItemId = linkData[2];  
            
    this.links?.push(linkInfo);
  }

  clearLink() {
    this.links?.splice(0);
  }

  updateLink(linkInfo: LinkInfo) {
    this.clearLink();
    this.links?.push(linkInfo);
  }
}

export default LinkNodeInfo;
