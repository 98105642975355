import { defineStore } from "pinia";

// 임시 조명 정보
import lightObj from "@/settings/tempLight.json";
import CommonUtils from "@/utils/common-util";
import { DeviceKind, DeviceShape, LightType, NodeElementType, NodeType } from "@/global/enums";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import type DPoint from "@/models/entity/d-point";
import type { LightAutoDeployCount } from "@/global/types";
import ConfigGroup from "@/models/editor/nodes/config/config-group";
import { useCommonStore } from "./common-store";

class LightForm {
  id?: number;
  name?: string;
  description?: string;
  devicetype?: number;
  width?: number;
  height?: number;
  depth?: number;
}

export const useLightStore = defineStore("light", {
  state: () => ({
    codes: [],
    appinfo: null,
    lightInfo: [] as Array<LightForm>,
    selectDpointInfo: {} as DPoint,
    lightAutoDeployCount: { horizontalCount: 4, verticalCount: 1 } as LightAutoDeployCount,
    deviceConfigGroups: [] as ConfigGroup[],
    filterDeviceElementGroups: [] as ConfigGroup[],
    autoDeployDeviceConfig: null as LightNodeConfig | null,
  }),
  getters: {
    getCodes(state) {
      return state.codes;
    },
    getLight(state) {
      return state.lightInfo;
    },
  },
  actions: {
    setSelectDpointInfo(dpoint: DPoint) {
      this.selectDpointInfo = dpoint;
    },

    updateFilter(configName: string) {
      this.filterDeviceElementGroups.splice(0);
      const f = configName === null ? "" : configName.toString();  
    
      for(const eg of this.deviceConfigGroups) {
    
        const elementGroup = new ConfigGroup();
        elementGroup.id = eg.id;
        elementGroup.title = eg.title;
    
        for(const config of eg.configs) {
          if(config.name.includes(f) === false)
            continue;
          
          elementGroup.configs.push(config);
        }
    
        this.filterDeviceElementGroups.push(elementGroup);
      }  
    },

    async fetchLight() {
      const json = JSON.stringify(lightObj, null, 2);
      const lightForms = CommonUtils.jsonDeserialize(LightForm, json) as LightForm[];
      this.lightInfo = lightForms;
    },

    async loadDeviceElementGroups() {
      const nodeEditorStore = useNodeEditorStore();
      nodeEditorStore.clearNodeConfigs();

      const commonStore = useCommonStore();
      const devtypeCodes = commonStore.getCodeList("devClsfyCode");

      this.deviceConfigGroups = await this.apiServices.deviceApiService.getDeviceConfigGroups(devtypeCodes);

      for (const le of this.deviceConfigGroups) {
        for (const config of le.configs) {
          nodeEditorStore.addNodeConfig(config);
        }
      }

      this.updateFilter("");
      
    },
  },
});
