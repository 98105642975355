
import { HttpMethods } from "@/global/enums";
import SimulationResult from "@/models/entity/simulation-result";
import type ZoneSimul from "@/models/entity/zone-sml-type";
import type ZoneSimulParameter from "@/models/entity/zone-simul-parameter";
import type { RequestParameter } from "@/net/rest-client";
import type RestClient from "@/net/rest-client";
import BaseApiService from "./base-api-service";

class SimulationApiService extends BaseApiService{
  constructor(restClient: RestClient) {
    super(restClient);    
  }

  async getZoneSmlData(siteSeq: number, zoneSeq: number) {
    const res = await this.restClient.call<ZoneSimul>(HttpMethods.Get, `/v1/sites/${siteSeq}/zones/${zoneSeq}/simuls`);

    return res.data;
  }

  async getSimulationData(siteSeq: number, zoneSeq: number) {
    const res = await this.restClient.call<ZoneSimulParameter>(HttpMethods.Get, `/v1/sites/${siteSeq}/zones/${zoneSeq}/simuls`);

    return res.data;
  }

  async setSimulationData(siteSeq: number, zoneSeq: number, reqData: ZoneSimulParameter){
    // (reqData.zoneSimul as ZoneSimul).maintainProcCode = "BASIC";

    const requestParameter : RequestParameter ={
      body: JSON.stringify(reqData),
      headers: {"Content-type": "application/json"}
    };

    const res = await this.restClient.call<SimulationResult>(HttpMethods.Post, `/v1/sites/${siteSeq}/zones/${zoneSeq}/simuls`, requestParameter, SimulationResult);

    return res.data;
  }
}

export default SimulationApiService;