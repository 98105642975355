import AbstractActionAttrs from "./abstract-action-attrs";

/**
 * 센서 설정 속성 클래스
 * @class
 */
class PresenseSensorConfigAttrs extends AbstractActionAttrs {
  status = 0;                      //센서 상태
  opmode = 0;                      //센서 이벤트 발생 시 Actuator 실행 모드
  mperiod = 0;                     //인지 전 인지센서 측정주기
  pperiod = 0;                     //인지 후 인지유지시간
  agingtime = 0;                   //인지 후 인지유지시간
  before_agingtime = 0;            //인지유지시간 종료 통지 시간  
}

export default PresenseSensorConfigAttrs;