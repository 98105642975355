import { useAuthManager } from "@/composables/auth-manager";
import { useUserStore } from "@/stores/user-store";
import CommonUtils from "@/utils/common-util";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

/**
 * PageInterceptor 클래스 
 * @class
 */
class PageInterceptor {
  constructor() {

  }

  public async beforeHandle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    console.log(`■■■ BeforeHandle -> To: ${to.fullPath}, From: ${from.fullPath}, Query: ${to.query.logoutYn}`);
    if (to.name === "login") {
      const userStore = useUserStore();
      const isUpdate = await userStore.updateToken();

      if (!isUpdate) {
        next();
      } else {
        next("/site");
      }
      return;
    }

    //next();

    const authManager = useAuthManager();
    console.log(authManager.getRefreshToken());

    if (CommonUtils.isNullOrEmpty(authManager.getRefreshToken())) {
      alert("인증 정보가 없습니다. 로그인 페이지로 이동합니다.");
      next("/");
    }
    else {
      next();
    }
  }

  public afterHandle(to: RouteLocationNormalized, from: RouteLocationNormalized): boolean {
    console.log(`▲ AfterHandle -> To: ${to.fullPath}, From: ${from.fullPath}`);
    return true;
  }
}

export default PageInterceptor;