import { PersistItemKeys } from "@/global/enums";
import type { StorageSpace } from "@/global/types";
import Code from "@/models/entity/code";
import Zone from "@/models/entity/zone";
import Menu from "@/models/menu";
import Site from "@/models/site";
import CommonUtils from "@/utils/common-util";

class PersistStateManager {
  
  constructor() {
  }

  setInitData(siteSeq: number, rSpaceSeq: number) {
    localStorage.setItem(PersistItemKeys.SiteSeq, siteSeq.toString());
    localStorage.setItem(PersistItemKeys.RSpaceSeq, rSpaceSeq.toString());  
  }

  setSite(site: Site) {
    const siteJson = CommonUtils.jsonSerialize<Site>(site);
    localStorage.setItem(PersistItemKeys.Site, siteJson);
  }

  setRSpace(rspace: Zone) {
    const rspaceJson = CommonUtils.jsonSerialize<Zone>(rspace);    
    localStorage.setItem(PersistItemKeys.RSpace, rspaceJson);
  }

  setSelectedSpace(space: Zone | null) {

    if (CommonUtils.isNullOrEmpty(space))
      localStorage.setItem(PersistItemKeys.StorageSpace, "");
    else {
      const storageSpace: StorageSpace = {
        zoneSeq: space.zoneSeq,
        zoneName: space.zoneName,
        zoneTypeCode: space.zoneTypeCode,
      };

      const storageSpaceJson = CommonUtils.jsonSerialize<StorageSpace>(storageSpace);
      localStorage.setItem(PersistItemKeys.StorageSpace, storageSpaceJson);
    }
  }
  setCommonCodes(codes: Code[]) {
    const codesJson = CommonUtils.jsonSerialize<Code[]>(codes);
    localStorage.setItem(PersistItemKeys.CommonCodes, codesJson);
  }
  setMenu(menu: Menu | null) {
    const menuJson = CommonUtils.jsonSerialize<Menu>(menu);
    localStorage.setItem(PersistItemKeys.Menu, menuJson);
  }

  getSiteSeq() {
    return Number(localStorage.getItem(PersistItemKeys.SiteSeq));
  }

  getRSpaceSeq() {
    return Number(localStorage.getItem(PersistItemKeys.RSpaceSeq));
  }

  getStorageSpace() {
    const storageSpaceJson = localStorage.getItem(PersistItemKeys.StorageSpace) ?? null;

    if (CommonUtils.isNullOrEmpty(storageSpaceJson))
      return null;

    return CommonUtils.jsonDeserialize(Zone, storageSpaceJson) as StorageSpace;
  }
  getCommonCodes() {

    const codesJson = localStorage.getItem(PersistItemKeys.CommonCodes);

    if (CommonUtils.isNullOrEmpty(codesJson))
      return null;

    return CommonUtils.jsonDeserialize(Code, codesJson) as Code[];
  }

  /**
   * 로컬스토리지에서 사이트를 가져온다.
   * @returns Site
   */
  getSite(): Site | null {
    const siteJson = localStorage.getItem(PersistItemKeys.Site);

    if (CommonUtils.isNullOrEmpty(siteJson))
      return null;

    return CommonUtils.jsonDeserialize(Site, siteJson) as Site;
  }

  /**
   * 로컬스토리지에서 대표공간을 가져온다.
   * @returns RSpace
   */
  getRSpace(): Zone | null {
    const rspaceDetailJson = localStorage.getItem(PersistItemKeys.RSpace);

    if (CommonUtils.isNullOrEmpty(rspaceDetailJson))
      return null;

    return CommonUtils.jsonDeserialize(Zone, rspaceDetailJson) as Zone;
  }

  getMenu(): Menu | null {
    const menuJson = localStorage.getItem(PersistItemKeys.Menu) ?? null;

    if (CommonUtils.isNullOrEmpty(menuJson))
      return null;

    return CommonUtils.jsonDeserialize(Menu, menuJson) as Menu;
  }

  clearInitData() {
    localStorage.clear();
  }

  // getZone(isOrigin = false) {
  //   if (isOrigin)
  //     return this.originZone;
  //   else
  //     return this.editZone;
  // }

  // setZone(zone: Zone | null) {
  //   if (CommonUtils.isNullOrEmpty(zone))
  //     return;

  //   const zoneJson = CommonUtils.toJson(zone);
        
  //   localStorage.setItem(SavedZoneType.Origin, zoneJson);
  //   localStorage.setItem(SavedZoneType.Edit, zoneJson);
  // }
}

export default PersistStateManager;