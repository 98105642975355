import { Type } from "class-transformer";
import "reflect-metadata";
import Zone from "@/models/entity/zone";
import type ImageFile from "@/models/entity/image-file";

/**
 * Site 클래스
 * @class
 */
class Site {
  siteSeq = Math.floor(Math.random() * 100 + 100);
  siteName?: string;
  siteDesc?: string;
  districtCode?: string;
  imageFile?: ImageFile;
  imageFileSeq: number | null = null;
  horizSize = 0;
  vertiSize = 0;
  siteArea: number | null = 0;
  centerLat: number | null = 0;
  centerLng: number | null = 0;
  boundsPath: string | null = "";
  regDate?: string;
  regUserId = 0;
  modDate?: string;
  modUserId = 0;
  siteClsfyCode: string | null = "";

  @Type(() => Zone)
    zones: Array<Zone> = new Array<Zone>();
}

export default Site;
