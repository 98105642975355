<!-- 이정민 과장 테스트 View -->
<script setup lang="ts">
import { inject, onMounted, ref } from "vue";
import CommonDialog from "../../components/Temp/CommonDialog.vue";
import settingObj from "@/settings/tempSetting.json";
import { Depth1_Kind, Depth2_Kind } from "@/global/enums";
import CommonUtils from "@/utils/common-util";
import { useSettingStore } from "@/stores/setting-store";
import { useMenuStore } from "@/stores/menu-store";

const isOpen = ref(false);

const settingStore = useSettingStore();
const menuStore = useMenuStore();

const status1 = ref([] as string[]);
const status2 = ref([] as string[]);

onMounted(async () => {

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const id = CommonUtils.getNewId(numbers);
  console.log(`New ID --> ${id} `);

  //메뉴 리스트 가져오기.
  //console.log(`MenuList -> ${JSON.stringify(menuModule.menuList, null, 2)}`);

  //해당 메뉴 가져오기
  const menu = menuStore.getMenu(Depth1_Kind.시나리오);

  console.log(`Menu Name: ${menu?.name}, ${menu?.isSelected}`);

  menuStore.selectionMenu(Depth1_Kind.조명공간);

  const mainMenu = menuStore.menuList;
  const menu2 = menuStore.getMenu(Depth1_Kind.조명공간);
  const subMenu = menu2?.childMenu;

  const json = JSON.stringify(settingObj, null, 2);
  const setting = settingStore.deserialize(json);

  const siteSetting = await settingStore.load();
});

function openPopup() {
  const prevIsOpen = isOpen.value;
  isOpen.value = !isOpen.value;
  console.log(`PrevIsOpen: ${prevIsOpen} ---> IsOpen: ${isOpen.value}`);
}

function handler1(mutationRecords: any) {
  status1.value = [];
  for (const index in mutationRecords) {
    const record = mutationRecords[index];
    const info = `type: ${record.type}, nodes added: ${record.addedNodes.length > 0 ? "true" : "false"}, nodes removed: ${
      record.removedNodes.length > 0 ? "true" : "false"
    }, oldValue: ${record.oldValue}`;
    status1.value.push(info);
  }
}

function handler2(mutationRecords: any) {
  status2.value = [];
  for (const index in mutationRecords) {
    const record = mutationRecords[index];
    const info = `type: ${record.type}, nodes added: ${record.addedNodes.length > 0 ? "true" : "false"}, nodes removed: ${
      record.removedNodes.length > 0 ? "true" : "false"
    }, oldValue: ${record.oldValue}`;
    status2.value.push(info);
  }
}

// store the id of the draggable element
function onDragStart(e: any) {
  e.dataTransfer.setData("text", e.target.id);
  e.dataTransfer.dropEffect = "move";
}

function onDragEnter(e: any) {
  // don't drop on other draggables
  if (e.target.draggable !== true) {
    e.target.classList.add("drag-enter");
  }
}

function onDragLeave(e: any) {
  e.target.classList.remove("drag-enter");
}

function onDragOver(e: any) {
  e.preventDefault();
}

function onDrop(e: any) {
  e.preventDefault();

  // don't drop on other draggables
  if (e.target.draggable === true) {
    return;
  }

  const draggedId = e.dataTransfer.getData("text");
  const draggedEl = document.getElementById(draggedId);

  if (draggedEl === null) return;

  // check if original parent node
  if (draggedEl.parentNode === e.target) {
    e.target.classList.remove("drag-enter");
    return;
  }

  if (draggedEl.parentNode === null) return;

  // make the exchange
  draggedEl.parentNode.removeChild(draggedEl);
  e.target.appendChild(draggedEl);
  e.target.classList.remove("drag-enter");
}
</script>
<template>
  <!-- <div>
    <q-btn unelevated color="light-green-7" size="sm" label="popup" @click="openPopup" />
    <common-dialog v-model="isOpen"> </common-dialog>
  </div> -->
  <div>
    <div class="row no-wrap justify-around q-px-md q-pt-md">
      <div
        v-mutation="handler1"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        @drop="onDrop"
        class="drop-target rounded-borders overflow-hidden"
      >
        <div id="box1" draggable="true" @dragstart="onDragStart" class="box navy" />
        <div id="box2" draggable="true" @dragstart="onDragStart" class="box red" />
        <div id="box3" draggable="true" @dragstart="onDragStart" class="box green" />
        <div id="box4" draggable="true" @dragstart="onDragStart" class="box orange" />
        <div id="box5" draggable="true" @dragstart="onDragStart" class="box navy" />
        <div id="box6" draggable="true" @dragstart="onDragStart" class="box red" />
        <div id="box7" draggable="true" @dragstart="onDragStart" class="box green" />
        <div id="box8" draggable="true" @dragstart="onDragStart" class="box orange" />
      </div>

      <div
        v-mutation="handler2"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        @drop="onDrop"
        class="drop-target rounded-borders overflow-hidden"
      />
    </div>

    <div class="row justify-around items-start">
      <div class="col row justify-center q-pa-md">
        <div class="text-subtitle1">Mutation Info</div>
        <div v-for="status in status1" :key="status">
          {{ status }}
        </div>
      </div>

      <div class="col row justify-center q-pa-md">
        <div class="text-subtitle1">Mutation Info</div>
        <div v-for="status in status2" :key="status">
          {{ status }}
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <style lang="scss"></style> -->

<style scoped lang="sass">
.drop-target
  height: 400px
  width: 200px
  min-width: 200px
  background-color: gainsboro

.drag-enter
  outline-style: dashed

.box
  width: 100px
  height: 100px
  float: left
  cursor: pointer

@media only screen and (max-width: 500px)
  .drop-target
    height: 200px
    width: 100px
    min-width: 100px
    background-color: gainsboro

  .box
    width: 50px
    height: 50px

.box:nth-child(3)
  clear: both

.navy
  background-color: navy

.red
  background-color: firebrick

.green
  background-color: darkgreen

.orange
  background-color: orange
</style>
