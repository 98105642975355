<script setup lang="ts">
import { ViewType, ZoneTypeCode } from "@/global/enums";
import type { TreeItem } from "@/global/types";
import { useEditStore } from "@/stores/edit-store";
import { useLightStore } from "@/stores/light-store";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import CommonUtils from "@/utils/common-util";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import AlertWarningComponent from "@/components/common/AlertWarningComponent.vue";
import type { alertMessageType } from "@/global/types";
import type InDPoint from "@/models/group/in-d-point";

const editStore = useEditStore();

const isRemoveGroup = ref(false);
const removeMessage = ref({
  message: "",
  removeTarget: "",
} as alertMessageType);

const groupItems = computed(() => {

  const rootNode = [
    {
      label: "조명 그룹",
      children: [] as TreeItem[],
    },
  ];

  if (!CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo) && editStore.selectSpaceInfo.zoneTypeCode === ZoneTypeCode.단위존) {

    if (!CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo.ctrlGrps)) {

      for (const ctrlGroup of editStore.selectSpaceInfo.ctrlGrps) {

        const treeItem: TreeItem = {
          label: ctrlGroup.grpName ?? "",
          key: ctrlGroup.grpSeq?.toString(),
        };

        rootNode[0].children.push(treeItem);
      }      
    }
  }

  return rootNode;
});

const selectLightValue = ref("");

const filter = ref("");
const filterRef = ref<null | { focus: () => null }>(null);

const lightStore = useLightStore();

onMounted(async () => {
  await lightStore.loadDeviceElementGroups();    
});

function resetFilter() {
  filter.value = "";
}

function filterMethod(node: TreeItem, filter: string) {
  const filt = filter.toLowerCase();
  const returnValue: boolean = node.label !== "" && node.label.toLowerCase().indexOf(filt) > -1;
  return returnValue;
}

function selectionGroupItem(id: number | null) {
  const groupBoxList = document.querySelectorAll("[class*=GroupBox]");

  groupBoxList.forEach(v => {
    const el = v as HTMLElement;
    console.log(el.className);
    if(el.getAttribute("class")?.includes(id?.toString() as string) === true){
      el.setAttribute("class", el.getAttribute("class") + " selectBox");
    } else {
      el.setAttribute("class", el.getAttribute("class")?.split(" ")[0] as string);
    }
  });

  // editStore.clearNodeEL();
  editStore.setSelectGroupInfo(id); 
  // selectGroupLightUpper(editStore.modelViewType);
}



function dialogValue(value: boolean){
  isRemoveGroup.value = value;
}

function removeGroupMessage(idx: number){
  removeMessage.value.message = "선택된 그룹이 삭제됩니다. 진행하시겠습니까?";
  removeMessage.value.removeTarget = `그룹 ${idx}`;
  isRemoveGroup.value = true;
}

function removeGroup(idx: number){
  editStore.removeGroup(idx);
}
</script>

<template>
  <div class="GroupTreeComponent">
    <q-input
        class="groupFilter"
        :item-aligned="true"
        ref="filterRef"
        color="grey"
        label-color="black"
        :dark="false"
        square
        outlined
        bg-color="white"
        v-model="filter"
        :hide-bottom-space="true"   
        placeholder="Search"
        
      />
<!-- 
    <q-input
      class="groupFilter"
      ref="filterRef"
      color="grey"
      label-color="black"
      :hide-bottom-space="true"
      :dark="false"
      filled
      dense
      v-model="filter"
    >
      <template v-slot:prepend>
        <q-icon name="search" />
      </template>
      <template v-slot:append>
        <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
      </template>
    </q-input> -->
    <q-tree
      :nodes="groupItems"
      class="groupTree"
      ref="treeview"
      node-key="key"
      text-color="black"
      :filter="filter"
      :filter-method="filterMethod"
      v-model:selected="selectLightValue"      
      @update:selected="selectionGroupItem"
      selected-color="red"
      default-expand-all
      no-selection-unset
    ></q-tree>
    <div class="deleteBtnRow">
      <q-icon class="delBtn" name="delete" size="20px" @click="removeGroupMessage(Number(selectLightValue))"></q-icon>
    </div>
    <AlertWarningComponent :static-value="false" :confirm-value="isRemoveGroup" :message="removeMessage" @submit="removeGroup(Number(selectLightValue))" @change="dialogValue"></AlertWarningComponent>
  </div>
</template>

<style lang="scss">
.GroupTreeComponent {
  background-color: #e2e6f3;
  .groupFilter {
    color: #404040;
    background-color: #e2e6f3;
    align-items: center;
    margin-bottom: 0px;
    padding: 8px 12px;
    .q-field__inner {
      .q-field__control {
        align-items: center;
      }
    }
  }
  .groupTree {
    font-size: 12px;
    background-color: #e2e6f3;
    .q-tree__node-header{
      margin-top: 0px;
    }
  }
}
</style>
