<script setup lang="ts">
import { onMounted } from "vue";
import { useScenarioStore } from "@/stores/scenario-store";
import CommonUtils from "@/utils/common-util";

const scenarioStore = useScenarioStore();

onMounted(() => {
});


function onChangeScenarioName(scenarioName: string) {

  if (CommonUtils.isNullOrEmpty(scenarioStore.selectScenarioInfo))
    return;

  scenarioStore.selectScenarioInfo.scenarioName = scenarioName;
}

function onChangeScenarioDesc(scenarioDesc: string) {

  if (CommonUtils.isNullOrEmpty(scenarioStore.selectScenarioInfo))
    return;

  scenarioStore.selectScenarioInfo.scenarioDesc = scenarioDesc;
}

</script>

<template>
  <div class="ScenarioInfoComponent">
    <q-expansion-item label="시나리오 정보" header-class="ScenarioInfoExpansion" expand-icon-class="ScnearioInfoExpansionIcon"
      default-opened :dark="false" v-if="scenarioStore.selectScenarioInfo !== null">
      <q-card>
        <q-card-section class="ScnearioRightContents">          
            <label>명칭</label>
            <q-input square :dark="false" bg-color="white" type="text" maxlength="30" outlined :model-value="scenarioStore.selectScenarioInfo!.scenarioName" placeholder="시나리오 명칭" @change="onChangeScenarioName($event)" />
            <label>설명</label>
            <q-input square :dark="false" bg-color="white" type="text" maxlength="50" outlined :model-value="scenarioStore.selectScenarioInfo!.scenarioDesc" placeholder="시나리오 설명" @change="onChangeScenarioDesc($event)"/>          
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<style lang="scss">
.ScenarioInfoComponent {
  border-bottom: 1px solid #c1c1c1;

  .ScenarioInfoExpansion {
    background-color: #e2e6f3;
    color: #404040;
    font-size: 15px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 39px;

    .q-item__label {
      font-weight: bold;
    }
  }

  .ScnearioInfoExpansionIcon {
    color: #404040;
  }

  .ScnearioRightContents {
    background-color: #f2f4fb;
    color: black;

    label {
      font-weight: bold;
    }
  }

  .q-field--auto-height .q-field__control,
  .q-field--auto-height .q-field__native {
    min-height: 30px;
  }
}
</style>
