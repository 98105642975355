import type { DeviceShape, LightType, NodeElementType, NodeType } from "@/global/enums";
import AbstractNodeConfig from "./abstract-node-config";

/**
 * 조명 노드 설정 클래스
 * @class
 */
class LightNodeConfig extends AbstractNodeConfig {
  public readonly lightType;
  public consumpPower;
  public standbyPower;
  public devClsfyCode;

  constructor(id: string, name: string, nodeType: NodeType, nodeElementType: NodeElementType, w: number, h: number, inPortCount: number, outPortCount: number, lightType?: string, description?: string, imageUrl?: string, shapeType?: DeviceShape, consumpPower?: number, standbyPower?: number, devClsfyCode?: string) {
    super(id, name, nodeType, nodeElementType, w, h, inPortCount, outPortCount, description, imageUrl, shapeType);

    this.lightType = lightType;
    this.consumpPower = consumpPower;
    this.standbyPower = standbyPower;
    this.devClsfyCode = devClsfyCode;
  }
}

export default LightNodeConfig;