<script setup lang="ts">
import type PackageNodeConfig from "@/models/editor/nodes/config/package-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import { reactive } from "vue";
import { onMounted } from "vue";

const props = defineProps<{
  nodeInfo: AbstractNodeInfo,
}>();

const nodeInfoRef = reactive(props.nodeInfo);
const nodeConfig = nodeInfoRef.nodeConfig as PackageNodeConfig;


onMounted(() => {
});
</script>

<template>

  <g :width="nodeInfoRef.nodeConfig.w" :height="nodeInfoRef.nodeConfig.h">
    <rect :width="nodeInfoRef.nodeConfig.w" :height="nodeInfoRef.nodeConfig.h" fill="#FFFFFF" stroke="#000000"
      stroke-width="1">
    </rect>
    <image :x="0" :y="0" :href="'scenario-nodes/' + nodeInfoRef.nodeConfig.imageUrl" :width="30" :height="30" />
    <text :x="34" :y="nodeInfoRef.nodeConfig.h / 2" dominant-baseline="middle" font-size="12" font-weight="bold"
      fill="#000000">
      {{ nodeInfoRef.name }}
    </text>
  </g>
  
</template>
<style lang="scss">
text {
  font-size: 10;
}
</style>