class ImageFile {
  imageFileName?: string;
  imageFileSeq?: number;
  imageFileType?: string;
  regDate: string;
  regUserId: number;
  saveFileName: string;
  imageUrl: string | undefined;

  constructor(imageFileName: string, imageFileSeq: number, imageFileType: string, regDate: string, regUserId: number, saveFileName: string) {
    this.imageFileName = imageFileName;
    this.imageFileSeq = imageFileSeq;
    this.imageFileType = imageFileType;
    this.regDate = regDate;
    this.regUserId = regUserId;
    this.saveFileName = saveFileName;
  }
}

export default ImageFile;
