<script setup lang="ts">
import SceneControlInputComponent from "@/components/common/SceneControlInputComponent.vue";
import type { OptionsType, stringObject } from "@/global/types";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import type DPoint from "@/models/entity/d-point";
import type DevAttr from "@/models/entity/dev-attr";
import type GrpMember from "@/models/entity/grp_member";
import type SceneSetting from "@/models/entity/scene-setting";
import { useDeviceStore } from "@/stores/device-store";
import { useEditStore } from "@/stores/edit-store";
import { useSceneStore } from "@/stores/scene-store";
import { computed, ref, onMounted, onUnmounted } from "vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";
import type InDPoint from "@/models/group/in-d-point";
import ColorTemp from "@/settings/colortemp.json";

const sceneStore = useSceneStore();
const editStore = useEditStore();
const deviceStore = useDeviceStore();

const selectGroupID = computed(() => {
  // resetParameter(sceneStore.SceneSettingGrpSeq as number);
  return sceneStore.SceneSettingGrpSeq;
});

const groupList = computed(() => {
  return editStore.selectSpaceInfo?.ctrlGrps;
});

const selectGroupInfo = computed(() => {
  return groupList.value?.find(v => v.grpSeq === selectGroupID.value);
});

const selectSceneSetting = computed(() => {
  return sceneStore.selectSceneSetting;
});

const previewOnOff = computed(() => {
  return lightMapValue.value["onoff"] !== undefined ? lightMapValue.value["onoff"] : null;
});

const previewDimming = computed(() => {
  return lightMapValue.value["level"] !== undefined ? lightMapValue.value["level"] as number : 0;
});

const previewTemp = computed(() => {
  return lightMapValue.value["colortemp"] !== undefined ? lightMapValue.value["colortemp"] : 6000;
});
// const selectDpoint = computed(() => {
//   return editStore.inDPointList?.find(v => v.dpointSeq === ((selectGroupInfo.value as CtrlGrp).members as GrpMember[])[0].dpointSeq);
// });

const previewObject = computed(() => {
  return {
    camera: null as THREE.PerspectiveCamera | null,
    light: null as THREE.RectAreaLight | THREE.SpotLight | null,
  };
});

const selectRadio = ref("");

const groupSelectOptions = computed(() => {
  const options = [] as OptionsType[];
  
  groupList.value?.map(v => {
    const option = {} as OptionsType;
    
    option.label = v.grpName as string;
    option.value = v.grpSeq as number;     
    options.push(option);
  });
  
  return options;
});

const attrValue = computed(() => {
  return sceneStore.attrMap;
});

const lightMapValue = computed(() => {
  // console.log("update light map value ::::::::");
  // const map = selectSceneSetting.value?.lightAttrsMap;
  // if(map === "" || map === null || map === undefined){
  //   const newMap = {} as stringObject;

  //   deviceStore.selectGrpDevAttrList.forEach(v => {
  //     newMap[v.attrName as string] = v.defaultVal;
  //   });
  //   return newMap;
  // }
  // const formatJson = JSON.parse(map as string);
  // return formatJson;
  return sceneStore.selectSceneLightAttrsMap as stringObject;
});

// console.log(attrValue);
// const newSceneSetting = ref(new SceneSetting());

const DimmingOptions = computed(() => {
  const options = [] as OptionsType[];

  for(let i = 0; i <= 20; i++){
    const option = {} as OptionsType;

    option.label = `${i * 5}%`;
    option.value = i * 5;
    options.push(option);
  }

  return options;
});

// const x = document.getElementById("scenePreviewContents")?.clientWidth;
// const y = document.getElementById("scenePreviewContents")?.clientHeight;
const x = 200;
const y = 200;

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 75, (x as number) / (y as number), 0.1, 10000 );
camera.position.set(5, 7, 5);
const renderer = new THREE.WebGLRenderer();
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.shadowMap.enabled = true;
renderer.toneMapping = THREE.ReinhardToneMapping;

renderer.setPixelRatio( window.devicePixelRatio );

// renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer.physicallyCorrectLights = true;
// renderer.autoClear = false;

const controls = new OrbitControls( camera, renderer.domElement );

controls.update();

scene.add(camera);

previewObject.value.camera = camera;

function resizeRendererToDisplaySize(renderer: THREE.WebGLRenderer) {
  const canvas = renderer.domElement;
  const pixelRatio = window.devicePixelRatio;
  const width  = canvas.clientWidth  * pixelRatio | 0;
  const height = canvas.clientHeight * pixelRatio | 0;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}


function animate() {
  requestAnimationFrame(animate);

  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }

  controls.update();
  renderer.render( scene, camera );
}

function previewSceneLoad(){

  // const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
  // scene.add( directionalLight );
  const geometry = new THREE.BoxGeometry( 10, 1, 10);
  const material = new THREE.MeshStandardMaterial( {color: 0xffffff} );
  const cube = new THREE.Mesh( geometry, material );
  
  cube.position.set(5, 0, 0);

  scene.add(cube);

  const box = new THREE.BoxHelper( cube, 0xffff00 );
  scene.add(box);

  const rectLight = new THREE.RectAreaLight( 0xffffff, previewDimming.value, 1, 1);

  previewObject.value.light = rectLight;

  rectLight.position.set( 5, 2, 0 );
  rectLight.lookAt( 5, 0, 0 );
  scene.add( rectLight );

  const rectLightHelper = new RectAreaLightHelper( rectLight );
  rectLight.add( rectLightHelper );

  camera.position.set(5, 1, 0);
  camera.lookAt(rectLight.position);

}

onMounted(() => {
  // renderer.setSize( x as number, y as number );
  // document.getElementById("previewScene")?.appendChild( renderer.domElement );
  // previewSceneLoad();
  // animate();
});

const attrMapTypeList = ref({
  onoff: "toggle",
  level: "number",
  colortemp: "number",
  hue: "number",
  saturation: "number",
  brightness: "number",
  x: "number",
  y: "number",
  r: "number",
  g: "number",
  b: "number",
  tt: "number",
  cur_scene: "number",
  on_off_tt: "number",
  dimup_tt: "number",
  dimdown_tt: "number",
  on_hold_time: "number",
  off_hold_time: "number",
  off_delay_time: "number",
  on_delay_time: "number",
  color_tt: "number",
  onlevel: "number",
  offlevel: "number",
  pperiod: "number",
  agingtime: "number",
  before_agingtime: "number",
  pcontrol: "text",
  tcontrol: "text",
  ncontrol: "text",
  presence: "number",
  aperiod: "number",
  base_illuminance: "number",
  high_illuminance: "number",
  low_illuminance: "number",
  adjust_rate: "number",
  hlcontrol: "text",
  llcontrol: "text",
  illuminance: "number",
  high_temperature: "number",
  low_temperature: "number",
  htcontrol: "???",
  ltcontrol: "number",
  temperature: "number",
  status: "number",
  opmode: "number",
  mperiod: "number",

} as stringObject);

// function resetParameter(value: number){
//   // console.log(value);
//   sceneStore.setSceneSettingGrpSeq(value);
//   editStore.setSelectGroupInfo(Number(value));
//   attrValue.value = deviceStore.setGrpDevTypeCode(selectGroupInfo.value as CtrlGrp);
    
//   for(let i = 0; i < Object.keys(lightMapValue.value).length; i++){
//     const key = Object.keys(lightMapValue.value)[i];

//     if(key === undefined) return;

//     const props = {
//       value: lightMapValue.value[key],
//       target: key,
//     };

//     attrValueSet(props);
//   }

//   if(editStore.sceneGroupONOFF === false){
//     const allGroups = document.querySelectorAll("[class*=groupBox]");
//     (allGroups as unknown as HTMLElement[]).forEach(el => {
//       el.style.display = "none";
//     });

//     const selectGroup = document.getElementsByClassName(`groupBox${value}`);
//     (selectGroup[0] as HTMLElement).style.display = "block";
//   }
//   // for(let i = 0; i < (attrValue.value as DevAttr[]).length; i++){
//   //   const attr = (attrValue.value as DevAttr[])[i];
//   //   console.log(lightMapValue.value[attr.attrName as string]);
//   //   if(attr.maxLen !== null && attr.maxLen !== undefined){

//   //     lightMapValue.value[attr.attrName as string] = "" as string;
//   //   } else {
//   //     lightMapValue.value[attr.attrName as string] = 0 as number;
//   //   }
//   // }
// }

function attrValueSet(props: {value: string | number | null | undefined, target: string}){
  console.log(props.value, props.target);

  lightMapValue.value[props.target] = props.value;

  (selectSceneSetting.value as SceneSetting).lightAttrsMap = JSON.stringify(lightMapValue.value);

  console.log(lightMapValue.value);

  // console.log(selectSceneSetting.value?.lightAttrsMap);

  // console.log(lightMapValue);
  // console.log(props.target);
  const value = props.value as number;

  const selectGroupMember = selectGroupInfo.value?.members as GrpMember[];
  const memberDpoint = [] as InDPoint[];

  for(let i = 0; i < selectGroupMember.length; i++){
    const dpoint = editStore.inDPointList.find(v => v.dpointSeq === selectGroupMember[i].dpointSeq);
    memberDpoint.push(dpoint as InDPoint);
  }


  if(props.target === "level"){
    for(let i = 0; i < memberDpoint.length; i++){
      const dpointNode = (document.getElementById(memberDpoint[i].nodeID as string) as HTMLElement).children[0] as HTMLElement;
      dpointNode.style.filter = `brightness(${value * 0.01})`;
    }
  } else if(props.target === "colortemp"){
    const colortemp = ColorTemp as {[x:string]: string};
    for(let i = 0; i < memberDpoint.length; i++){
      const dpointNode = (document.getElementById(memberDpoint[i].nodeID as string) as HTMLElement).children[0] as HTMLElement;
      dpointNode.style.fill = `rgb${colortemp[value]}`;
    }
  }
  
  return props.value;
}

function selectRadioUpdate(value: string){
  selectRadio.value = value;
}

const cx = ref(0);
const cy = ref(0);
const cz = ref(0);

function setCamera(){
  camera.position.set(cx.value, cy.value, cz.value);
  camera.lookAt(5,0,0);
}

</script>

<template>
  <div class="SceneControlRightComponent">
    <q-expansion-item label="제어 정보" header-class="SceneControlExpansion" default-opened :dark="false">
      <q-card>
        <q-card-section class="sceneControlContents">
          <q-form>
            <label>선택된 그룹 정보</label>
            <div class="sceneProperty" :style="{ marginBottom: '20px' }">
              <q-select
                v-model="selectGroupID"
                :options="groupSelectOptions"
                square
                dense 
                outlined
                :dark="false"   
                bg-color="white"
                label-color="black" 
                :popup-content-style="{color: '#000000'}" 
                map-options 
                emit-value
                @update:model-value="sceneStore.setSceneParamter"
              >
            </q-select>
          </div>
          <div v-if="selectGroupID !== null" >
            <label>씬 제어 요소</label>
            <div class="SceneControlInputWrapper">
              <SceneControlInputComponent
              v-for="(attr, i) in attrValue" 
              :key="`${i}`"
              :input-type="attrMapTypeList[attr.attrName as string] as string"
              :attr-name="attr.attrName"
              :attr-desc="attr.attrDesc"
              :min-val="attr.minVal"
              :max-val="attr.maxVal"
              :max-len="attr.maxLen"
              :default-val="attr.defaultVal"
              :step="attr.step"
              :unit="attr.unit"
              :read-only-yn="attr.readOnlyYn"
              :in-value="lightMapValue[attr.attrName as string] !== null ? lightMapValue[attr.attrName as string] : null"
              :radio-value="selectRadio"
              @updateModel="sceneStore.sceneAttrValueSet"
              @radioUpdate="selectRadioUpdate"
              >
      
              
              </SceneControlInputComponent>
            </div>
           

          
            <!-- <div class="sceneProperty">
              <label>ON / OFF</label>
              <div class="row" :style="{justifyContent: 'space-around', marginBottom: '10px', marginTop: '10px'}">
                <q-radio label="ON" val="on" v-model="attrMapList.onoff" size="sm" :dark="false" dense/>
                <q-radio label="OFF" val="off" v-model="attrMapList.onoff" size="sm" :dark="false" dense/>
              </div>

              <label>디밍 레벨</label>

              <q-select
              square
              outlined
              bg-color="white"
              :dark="false"
              :options="DimmingOptions"
              v-model="attrMapList.level"
              @update:model-value="attrValueSet"
              use-input
              :placeholder="attrMapList.level === null ? '디밍 레벨' : ''"
              :popup-content-style="{color: '#000000'}"
              >
            >
            
            </q-select>
              
              <label>색온도</label>
              <q-input
              square
              bg-color="white"
              :dark="false"
              type="number"
              maxlength="10"
              outlined
              v-model="attrMapList.colortemp"
              placeholder="색온도"
              @update:model-value="attrValueSet"

              <label>색상</label>
              <div class="row">
                <div class="col-4 q-px-sm">
                  <label>R</label>
                  <q-input
                  square
                  bg-color="white"
                  :dark="false"
                  type="text"
                  maxlength="10"
                  outlined
                  v-model="attrMapList.r"
                  placeholder="R"
                  @update:model-value="attrValueSet"
                  />
                  
                </div>
                <div class="col-4 q-px-sm">
                  <label>G</label>
                  <q-input
                  square
                  bg-color="white"
                  :dark="false"
                  type="text"
                  maxlength="10"
                  outlined
                  v-model="attrMapList.g"
                  placeholder="G"
                  @update:model-value="attrValueSet"
                  />
                  
                </div>
                <div class="col-4 q-px-sm">
                  <label>B</label>
                  <q-input
                  square
                  bg-color="white"
                  :dark="false"
                  type="text"
                  maxlength="10"
                  outlined
                  v-model="attrMapList.b"
                  placeholder="B"
                  @update:model-value="attrValueSet"
                  />
                </div>
              </div>
             

              
              <label></label>
            </div> -->
          </div>
          
          
        </q-form>
      </q-card-section>
    </q-card>
  </q-expansion-item>
  
  <!--
  <q-expansion-item label="미리보기" header-class="SceneControlExpansion" default-opened :dark="false">
    <q-card>
      <q-card-section id="scenePreviewContents" class="sceneControlContents">
        <q-form>
          <label>씬 미리보기</label>
          <div id="previewScene" :style="{width: '200px', height: '200px'}"></div>
        </q-form>

       
      </q-card-section>
    </q-card>
  </q-expansion-item>
  -->
</div>
</template>

<style lang="scss">
.SceneControlRightComponent {
  .SceneControlInputWrapper{
    padding: 10px;
    background-color: #e2e6f3;
  }
  .SceneControlExpansion {
    background-color: #e2e6f3;
    
    color: #404040;
    font-size: 15px;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    height: 40px;
    .q-item__label {
      font-weight: bold;
    }
  }
  .sceneControlContents {
    background-color: #f2f4fb;
    color: #404040;
    label {
      font-weight: bold;
    }
  }
  .sceneProperty {
    padding: 10px;
    background-color: #e2e6f3;
    
    .scenePropertyItem {
      min-height: 20px;
      padding: 6px 10px;
      background-color: #f2f4fb;
      .q-item__section {
        font-size: 13px;
      }
    }

    label{
      margin-bottom: 10px;
    }
  }
  
  .horizonBorderClass {
    padding: 5px 10px 5px 0px;
    margin-bottom: 0px;
    
    .q-field--outlined .q-field__control {
      height: 30px;
      margin-bottom: 0px;
    }
    
    label {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
