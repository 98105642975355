/**
 * 단위 공간 클래스
 * @class
 */

class UZone {
  siteSeq = 0; // 사이트 ID
  zoneSeq = 0; // 존 ID
  uzoneTypeCode = "0A"; // 단위 존 유형 코드
  usageProfileCode: string | null = null; // 용도 프로필 코드
  ceilHeight = 10; // 천장고
  daylightUseYn?: string;
  depthOfLight?: number;
  lengthOfLight?: number;
  nightOperMode?: string;
  outdoorYn?: string;
  weekendOperMode?: string;
  windoorArea?: number;
  windoorTopMaxHeight?: number;
}

export default UZone;
