/**
 * Stack 클래스
 * @class
*/
class Stack<T> {
  private data = new Array<T>();
  private capacity = 0;

  constructor(capacity = 500) {
    this.capacity = capacity;
  }

  /**
     * NOTE - 스택에서 요소를 담는다.   
     * @param {object} 스택에 담을 객체
     */
  push(v: T) {
    if (this.capacity != 0 && this.getSize() == this.capacity)
      this.data.shift();     //스택 사이즈를 넘어가면 첫번째 요소를 제거한다.

    this.data.push(v);
  }

  /**
     * 스택에서 마지막 요소를 리턴하고 마지막 요소를 스택에서 삭제한다.
     * @return {object} 스택 요소 객체
     */
  pop() {
    return this.data.pop() || null;
  }

  /**
     * 스택을 비운다.   
     */
  clear() {
    this.data = [];
  }

  /**
     * 스택의 마지막 요소를 리턴한다.
     * @return {object} 스택 요소 객체
     */
  peek() {
    return this.data[this.data.length - 1];
  }

  /**
     * 스택 사이즈를 리턴한다.   
     * @return {number} Stack Size
     */
  getSize() {
    return this.data.length;
  }
}

export default Stack;