class NodeProps {
  name?: string;
  type?: string;
  desc?: string;
  constructor() {
  }

  clear() {
    this.name = undefined;
    this.type = undefined;
    this.desc = undefined;
  }
}

export default NodeProps;