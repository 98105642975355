<script setup lang="ts">
import type { alertMessageType } from "@/global/types";
import { useEditStore } from "@/stores/edit-store";
import { useLightStore } from "@/stores/light-store";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useSceneStore } from "@/stores/scene-store";
import { computed, inject, onMounted, ref } from "vue";
import AlertWarningComponent from "./AlertWarningComponent.vue";

interface nodeObject {
  label: string;
  key?: string;
  disabled?: boolean;
  children?: [nodeObject];
}

const isRemoveScene = ref(false);
const removeMessage = ref({
  message: "",
  removeTarget: "",
} as alertMessageType);


const editStore = useEditStore();
const sceneStore = useSceneStore();

const treeview = ref();

const SceneTree = computed(() => {
  const node = [
    {
      label: "조명 씬",
      children: [] as Array<nodeObject>,
    },
  ];
    
  for (let i = 0; i < sceneStore.AllSceneInfo?.length; i++) {
    const Form = {
      label: sceneStore.AllSceneInfo[i].sceneName,
      key: sceneStore.AllSceneInfo[i].sceneSeq,
    } as nodeObject;
      
    node[0].children?.push(Form);
  }
    
  return node;
});
  
const selectSceneValue = computed(() => {
  return sceneStore.selectSceneNodeId;
});
  
const filter = ref("");
const filterRef = ref<null | { focus: () => null }>(null);
    
const lightStore = useLightStore();
    
onMounted(async () => {
  await lightStore.loadDeviceElementGroups();
});
    
function resetFilter() {
  filter.value = "";
}


// onUnmounted(() => {
//   console.log("::: sceneControl UnMounted :::");
//   const documents = document.getElementById("drawItemGroup") as HTMLElement;

//   const lightNodes = documents.children;

//   for(let i = 0; i < lightNodes.length; i++){
//     const node = lightNodes[i] as HTMLElement;
//     node.remove();
//   }

// });
    
function filterMethod(node: nodeObject, filter: string) {
  const filt = filter.toLowerCase();
  const returnValue: boolean = node.label !== "" && node.label.toLowerCase().indexOf(filt) > -1;
  return returnValue;
}
    
function selectTree(id: number) {
  editStore.setSelectGroupInfo(null);
  sceneStore.setSceneSettingGrpSeq(null);

  if(id !== null){
    sceneStore?.setSelectSceneInfo(id);
  }
}

function removeScene(idx: number){
  sceneStore.removeScene(idx);
}

function removeSceneMessage(idx: number){
  removeMessage.value.message = "선택된 씬이 삭제됩니다. 진행하시겠습니까?";
  removeMessage.value.removeTarget = `씬 ${idx}`;
  isRemoveScene.value = true;
}

function dialogValue(value: boolean){
  isRemoveScene.value = value;
}

</script>
  
<template>
  <div class="SceneTreeComponent">
    <q-input
    class="sceneFilter"
    :item-aligned="true"
    ref="filterRef"
    color="grey"
    label-color="black"
    :dark="false"
    square
    outlined
    bg-color="white"
    v-model="filter"
    :hide-bottom-space="true"   
    placeholder="Search"
    />
    <!--       
      <q-input
      class="sceneFilter"
      ref="filterRef"
      color="grey"
      label-color="black"
      :hide-bottom-space="true"
      :dark="false"
      filled
      dense
      v-model="filter"
      >
      <template v-slot:prepend>
        <q-icon name="search" />
      </template>
      <template v-slot:append>
        <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
      </template>
    </q-input> -->
    <q-tree
    :nodes="SceneTree"
    class="sceneTree"
    ref="treeview"
    node-key="key"
    text-color="black"
    v-model:selected="selectSceneValue"
    :filter="filter"
    :filter-method="filterMethod"
    @update:selected="selectTree"
    selected-color="red"
    default-expand-all
    no-selection-unset
    ></q-tree>
    <div class="deleteBtnRow">
      <q-icon class="delBtn" name="delete" size="20px" @click="removeSceneMessage(Number(selectSceneValue))"></q-icon>
    </div>
    <AlertWarningComponent :static-value="false" :confirm-value="isRemoveScene" :message="removeMessage" @submit="removeScene(Number(selectSceneValue))" @change="dialogValue"></AlertWarningComponent>
  </div>
</template>

<style lang="scss">
.SceneTreeComponent {
  background-color: #e2e6f3;
  .sceneFilter {
    color: #404040;
    background-color: #e2e6f3;
    align-items: center;
    margin-bottom: 0px;
    padding: 8px 12px;
    .q-field__inner {
      .q-field__control {
        align-items: center;
      }
    }
  }
  .sceneTree {
    font-size: 12px;
    background-color: #e2e6f3;
    .q-tree__node-header{
      margin-top: 0px;
    }
  }
}
</style>
