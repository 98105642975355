<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps<{
    info: boolean
}>();

const emit = defineEmits(["infoClose"]);

const isInfo = computed({
  get: () => props.info,
  set: () => emit("infoClose"),
});

</script>

<template>
    <div class="MainSetting">
        <q-dialog
        v-model="isInfo"
        >
        <q-card class="column " :style="{width: '500px', height: '500px'}">
            <q-card-section>
            <div class="text-h6">정보</div>
            </q-card-section>

            <q-card-section class="col q-pt-none">
                <div>
                    <label>정보.........</label>
               
                </div>
            </q-card-section>

            <q-card-actions align="right" class="bg-white text-teal">
            <q-btn flat label="확인" v-close-popup />
            </q-card-actions>
        </q-card>
     </q-dialog>
    </div>
</template>

<style lang="scss">
    
</style>