<script setup lang="ts">
import { ref } from "vue";
import TreeComponent from "@/components/common/TreeComponent.vue";
import { useEditStore } from "@/stores/edit-store";
import { useMenuStore } from "@/stores/menu-store";
import type Zone from "@/models/entity/zone";

const editStore = useEditStore();
const menuStore = useMenuStore();

const isLeftPageExpanded = ref(false);
const emitData = defineEmits(["expaned"]);

function LeftPageExpandedToggle() {
  isLeftPageExpanded.value = !isLeftPageExpanded.value;
  emitData("expaned");
}

</script>

<template>
  <q-scroll-area class="fit" :dark="false">
    <div class="column SimulationLeftComponent" v-if="isLeftPageExpanded === true">
      <q-btn square class="expansionIcon" :align="'right'" :unelevated="true" @click="LeftPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_r.png'" />
      </q-btn>
      <div class="borderTemp simulationLeftTreeComponent"></div>
    </div>
    <div v-else class="column SimulationLeftComponent">
      <q-btn square class="expansionIcon" :align="'right'" :unelevated="true" @click="LeftPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_l.png'" />
      </q-btn>
      <div>        
        <TreeComponent :zone="editStore.selectZoneInfo as Zone" :upper-model="`simulation`" :subMenuKind="menuStore.selectedMenu?.menuKind"></TreeComponent>
      </div>
    </div>
  </q-scroll-area>
</template>

<style lang="scss">
.SimulationLeftComponent {
  background-color: #f2f4fb;
  // border: 1px solid #ffffff;
  color: black;


  .expansionIcon {
    height: 19px;
    min-height: 19px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    font-size: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .borderTemp {
    height: 60px;
    min-height: 60px;
    border-bottom: 0px solid #c1c1c1 !important;
    border-radius: 0px;
  }

 
}
</style>
