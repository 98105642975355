

<script setup lang="ts">
/**
*   시뮬레이션 공통 탭 부분
*/

import { computed, onBeforeMount, onMounted, ref } from "vue";
import type {alertMessageType, OptionsType, SimulationCommonParameter} from "@/global/types";
import { useSimulationStore } from "@/stores/simulation-store";
import type Zone from "@/models/entity/zone";
import { useEditStore } from "@/stores/edit-store";
import { useMaterialsStore } from "@/stores/materials-store";
import { useCommonStore } from "@/stores/common-store";
import type Profile from "@/models/profile";
import type ZoneSimulParameter from "@/models/entity/zone-simul-parameter";
import ZoneSmlAdjust from "@/models/entity/zone-sml-adjust";
import type { QTableProps } from "quasar";
import AlertWarningComponent from "../common/AlertWarningComponent.vue";




const simulationStore = useSimulationStore();
const materialStore = useMaterialsStore();
const commonStore = useCommonStore();

const selectedProfileInfo = ref({} as Profile);
const selectedYear = ref(2022);

const isAlert = ref(false);
const alertMessage = ref({} as alertMessageType);

/**
*   시뮬레이션 정보
*/
const simulationForm = computed(() => {
  getProfileData(simulationStore.selectZoneSml.usageProfileCode as string);
  return simulationStore.selectZoneSml;
});

// const simulationParameter = computed(() => {
//   return simulationForm.value as SimulationCommonParameter || {} as SimulationCommonParameter;
// });
    
/**
 *  조정 월 변수
 */
const selectedZoneByAdjust = ref(new ZoneSmlAdjust());
// const selectedZoneByAdjust = computed(() => {
//   return simulationParameter.value.zoneByAdjusts as ZoneByAdjusts[];
// });

/**
 * 년도별 조정 월 리스트
 */
const adjustList = computed(() => {
  const list = [] as ZoneSmlAdjust[];

  for(let i = 0; i < 12; i++){
    const adjust = new ZoneSmlAdjust;
    adjust.adjustMonth = `${selectedYear.value}-${i+1 >= 10 ? `${i+1}` : `0${i+1}`}`;
    adjust.siteSeq = simulationForm.value.siteSeq;
    adjust.zoneSeq = simulationForm.value.zoneSeq;
    list.push(adjust);
  }

  if(simulationForm.value.zoneSmlAdjusts){
    const zoneSmlAdjusts = simulationForm.value.zoneSmlAdjusts;
    for(let i = 0; i < zoneSmlAdjusts.length; i++){
        
      const smlAdjustYear = Number(zoneSmlAdjusts[i].adjustMonth?.split("-")[0]);
      if(smlAdjustYear === selectedYear.value){
        const smlAdjustMonth = zoneSmlAdjusts[i].adjustMonth?.split("-")[1];
      
        list[Number(smlAdjustMonth) - 1].adjustHolidayCnt = zoneSmlAdjusts[i].adjustHolidayCnt;
        list[Number(smlAdjustMonth) - 1].dayAdjustHour = zoneSmlAdjusts[i].dayAdjustHour;
        list[Number(smlAdjustMonth) - 1].nightAdjustHour = zoneSmlAdjusts[i].nightAdjustHour;
        list[Number(smlAdjustMonth) - 1].faultRate = zoneSmlAdjusts[i].faultRate;
      }

    }
  }  
  return list;
});
              
            
onBeforeMount(async () => {
  await materialStore.getUsageProfileApi();
});
            
onMounted(() => {
});

/**
*   공통 코드 정보
*/
async function getProfileData(profileCode: string){
  console.log("=======change value=============");
  const result = materialStore.getProfileData(profileCode);
                
  selectedProfileInfo.value = result as Profile;
}
            
function getOptionTypes(grpName: string){
  return commonStore.getOptionTypes(grpName);
}


/**
 *  테이블 Column 정보
 */
const column = ref([
  {name: "name", label: "조정월", align:"center" as const, field: "adjustMonth"},
  {name: "holiday", label: "휴일수", align: "center" as const, field: "adjustHolidayCnt"},
  {name: "ControlTime", label: "주간시간", align: "center" as const, field: "dayAdjustHour"},
  {name: "ControlTime", label: "야간시간", align: "center" as const, field: "nightAdjustHour"},
  {name: "faultRate", label: "고장율", align: "center" as const, field: "faultRate"}
]);
      
/**
 *  테이블 데이터 정보
 */
const row = computed(() => {
  const form = [];

  for(let i = 0; i < adjustList.value.length; i++){
    form.push({
      "adjustMonth": adjustList.value[i].adjustMonth,
      "adjustHolidayCnt": adjustList.value[i].adjustHolidayCnt,
      "dayAdjustHour": adjustList.value[i].dayAdjustHour,
      "nightAdjustHour": adjustList.value[i].nightAdjustHour,
      "faultRate": adjustList.value[i].faultRate
    });
  }
                
  return form;
});
            
// async function addAdjusts(){
//   if(!selectedZoneByAdjust.value.adjustHolidayCnt || !selectedZoneByAdjust.value.adjustMonth || !selectedZoneByAdjust.value.adjustUseHour){
//     return;
//   }
                
//   selectedZoneByAdjust.value.siteSeq = simulationForm.value.siteSeq as number;
//   selectedZoneByAdjust.value.zoneSeq = simulationForm.value.zoneSeq as number;
//   selectedZoneByAdjust.value.adjustMonth = `${selectedYear.value}-${selectedZoneByAdjust.value.adjustMonth}`;
                
//   for(let i = 0; i < (simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[]).length; i++){
//     const inZoneByAdjust = (simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[])[i];
//     console.log(selectedZoneByAdjust, inZoneByAdjust);
//     if(selectedZoneByAdjust.value.adjustMonth === inZoneByAdjust.adjustMonth){
//       selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
//       return;
//     }
//   }
                
//   simulationForm.value.zoneSmlAdjusts?.push(selectedZoneByAdjust.value);
//   simulationForm.value.zoneSmlAdjusts?.sort((a: ZoneSmlAdjust, b: ZoneSmlAdjust) => {
//     if(Number(a.adjustMonth) > Number(b.adjustMonth)){
//       return 1;
//     }
                    
//     if(Number(a.adjustMonth) < Number(b.adjustMonth)){
//       return -1;
//     }
                    
//     return 0;
//   });
                
//   //   await useEditStore().modifyZone()
                
//   selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
// }

/**
 *  조정 월 수정
 */
async function modifyAdjusts(){
  const adjustYear = selectedZoneByAdjust.value.adjustMonth?.split("-")[0];
  const adjustMonth = selectedZoneByAdjust.value.adjustMonth?.split("-")[1];

  const dayTime = selectedZoneByAdjust.value.dayAdjustHour;
  const nightTime = selectedZoneByAdjust.value.nightAdjustHour;

  const days = new Date(Number(adjustYear), Number(adjustMonth), 0).getDate();
  //   console.log(days * ((selectedProfileInfo.value as Profile).dailyUsageTime || 0), (dayTime || 0) + (nightTime || 0));    

 
  /**
  *   조정 월 계산
  */
  if(Number(days) < (selectedZoneByAdjust.value.adjustHolidayCnt || 0)){
    alertMessage.value.message = "휴일 수를 초과하셨습니다.";
    isAlert.value = true;
    return;
  }

  /**
  *  조정 시간 계산
  */
  if(days * ((selectedProfileInfo.value as Profile).dailyUsageTime || 0) <  (dayTime || 0) + (nightTime || 0)){
    alertMessage.value.message = "조정 시간이 이용 가능 시간보다 많습니다.";
    isAlert.value = true;
    return;
  }

  const modifyIdx = (simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[]).findIndex(v => v.adjustMonth === selectedZoneByAdjust.value.adjustMonth);

  if(modifyIdx === -1){
    simulationForm.value.zoneSmlAdjusts?.push(selectedZoneByAdjust.value);
    selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
  } else {
    for(let i = 0; i < (simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[]).length; i++){
      if((simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[])[i].adjustMonth === selectedZoneByAdjust.value.adjustMonth){
        (simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[])[i] = selectedZoneByAdjust.value;
        break;
      }
    }
    selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
  }

  const rowList = document.querySelectorAll("[id*=\"adjustRow\"]");

  rowList.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });
}


/**
 * 선택 조정 월 리스트
 */
const selectedRow = ref([] as ZoneSmlAdjust[]);
    

/**
 *  연도 SelectOption
 */
const yearsOption = computed(() => {
  const form = [] as OptionsType[];
  for(let i= 2000; i <= 2030; i++){
    form.push({
      label: i.toString(),
      value: Number(i),
      
    });
  }
                
  return form;
});  
            

function getSelectedString () {
  return selectedRow.value.length === 0 ? "" : `${selectedRow.value.length}개가 선택되었습니다.`;
}
            
// function deleteRow(){
//   console.log(selectedRow, simulationForm.value.zoneSmlAdjusts);
                
//   const zoneSmlAdjustsList =(simulationForm.value.zoneSmlAdjusts as ZoneSmlAdjust[]);
//   selectedRow.value.map(v => {
//     for(let i = 0; i < zoneSmlAdjustsList.length; i++){
//       if(zoneSmlAdjustsList[i].adjustMonth === v.adjustMonth){
//         zoneSmlAdjustsList.splice(i, 1);
//         break;
//       }
//     }
//   });

//   selectedRow.value = [] as ZoneSmlAdjust[];
// }                        

/**
 * 조정 월 선택 
 * @param index //조정 월 index
 */
function rowSelect(index: number){
  const rowList = document.querySelectorAll("[id*=\"adjustRow\"]");

  for(let i = 0; i < rowList.length; i++){
    const row = rowList[i] as HTMLElement;

    if(row.id === `adjustRow${index}`){
      
      row.style.backgroundColor = "#e2e6f3";
      selectedZoneByAdjust.value = adjustList.value[index];
  
    } else {
      row.style.backgroundColor = "#ffffff";
    }
  }  
}

/**
 *  조정 월 연도 변경
 */
function changeYear(){
  const rowList = document.querySelectorAll("[id*=\"adjustRow\"]");

  rowList.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });

  selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
}

/**
 *  조정 월 수정 취소
 */
function modifyAdjustsCancel(){
  const rowList = document.querySelectorAll("[id*=\"adjustRow\"]");

  rowList.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });

  selectedZoneByAdjust.value = {} as ZoneSmlAdjust;
}

function alertFalse(value: boolean){
  isAlert.value = value;
}
</script>

<template>
<div class="SimulationCommonComponent row">
    <AlertWarningComponent :confirm-value="false" :static-value="isAlert" :message="alertMessage" @change="alertFalse"></AlertWarningComponent>
    <div class="SimulationCommonLeft col-xs-12 col-md">
        <div class="SimulationCommonLeftContents row">
            <div class="headerLabel">
                <label >존 정보</label>
            </div>
            
            <div class="col-12 q-py-sm q-px-md">
                <label>존 명칭</label>
                <q-input
                    v-model="simulationForm.zoneName"
                    :dark="false"
                    dense
                    outlined
                    square
                    label-color="black"
                    bg-color="white"
                    popup-content-class="selectPopUpInputForm"
                    disable
                >
                </q-input>
            
            </div>
            
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="col-12 q-py-sm q-px-md">
                <label>지역</label>
                <div class="row">
                    <div class="col-md-12 col-lg-8">
                        <q-select v-model="simulationForm.districtCode" :options="getOptionTypes('districtCode')" dense :dark="false" outlined square bg-color="white" :popup-content-style="{color:'#000000'}" emit-value map-options disable></q-select>
                    </div>
                    
                    <div class="col-md-12 col-lg-4">
                        <q-checkbox v-model="simulationForm.outdoorYn" label="실외 존" :true-value="'Y'" :false-value="'N'" disable/>
                    </div>
                </div>
            </div>
            
            <div class="col-12 q-py-sm q-px-md">
                <label>용도프로필</label>
                <q-select v-model="simulationForm.usageProfileCode" :options="materialStore.profileOption" dense :dark="false" outlined square bg-color="white" :popup-content-style="{color:'#000000'}" emit-value map-options disable></q-select>
            </div>
            <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                <label>가로 크기[m]</label>
                <q-input v-model="simulationForm.lengthOfLight" dense :dark="false" outlined square bg-color="white" disable></q-input>
            </div>
            <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                <label>세로 크기[m]</label>
                <q-input v-model="simulationForm.depthOfLight" dense :dark="false" outlined square bg-color="white" disable></q-input>
            </div>
            <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                <label>면적[㎡]</label>
                <q-input v-model="simulationForm.area" dense :dark="false" outlined square bg-color="white" disable></q-input>
            </div>
            
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                <label>야간 운영 방식</label>
                <!-- <q-input v-model="simulationForm.nightOper" dense :dark="false" outlined square bg-color="white"></q-input> -->
                <q-select disable v-model="simulationForm.nightOperMode" :options="getOptionTypes('nightOperMode')" dense :dark="false" :popup-content-style="{color:'#000000'}" outlined square bg-color="white" map-options></q-select>
            </div>
            
            <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                <label>주말 운영 방식</label>
                <!-- <q-input v-model="simulationForm.weekendOper" dense :dark="false" outlined square bg-color="white"></q-input> -->
                <q-select disable v-model="simulationForm.weekendOperMode" :options="getOptionTypes('weekendOperMode')" dense :dark="false" :popup-content-style="{color:'#000000'}" outlined square bg-color="white" map-options></q-select>
                
            </div>
            
            <!-- <div class="col-12">
                <q-checkbox v-model="simulationForm.daylightUseYn" label="주광 이용 여부" :true-value="'Y'" :false-value="'N'" disable/>
            </div> -->
            
            <!-- <div v-if="simulationParameter.daylightUseYn" class="col-12 row" :style="{border: '1px solid #313131'}">
                <div class="col-md-12 col-lg-4">
                    <label>실 깊이[m]</label>
                    <q-input disable v-model="simulationParameter.depthOfLight" dense :dark="false" outlined square bg-color="white"></q-input>
                </div>
                <div class="col-md-12 col-lg-4">
                    <label>실 길이[m]</label>
                    <q-input disable v-model="simulationParameter.lengthOfLight" dense :dark="false" outlined square bg-color="white"></q-input>
                </div>
                <div class="col-md-12 col-lg-4">
                    <label>실 면적[㎡]</label>
                    <q-input disable v-model="simulationParameter.windoorArea" dense :dark="false" outlined square bg-color="white"></q-input>
                </div>
            </div> -->
            
        </div>
    </div>
    <div class="SimulationCommonMiddle col-xs-12 col-md">
        <div class="SimulationCommonMiddleContents row">
            <div class="headerLabel">
                <label>용도 프로필 정보</label>
            </div>
            
            
            <div class="col-12">
                <label class="q-py-sm q-px-md">일일 이용 시간(* 실외는 비 이용 시간)</label>
                <div class="col-12 row">
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>시작</label>
                        <q-input disable v-model="selectedProfileInfo.useStartTime" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>종료</label>
                        <q-input disable v-model="selectedProfileInfo.useEndTime" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>이용 시간[h/d]</label>
                        <q-input disable v-model="selectedProfileInfo.dailyUsageTime" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                </div>
            </div>
            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>

            <!-- <div class="col-12">
                <label class="q-py-sm q-px-md">연간 이용 일수/시간</label>
                <div class="col-12 row" >
                    <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                        <label>연간 이용 일수[d/a]</label>
                        <q-input disable v-model="selectedProfileInfo.annualUseDays" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                        <label>연간 낮 이용 시간[h/a]</label>
                        <q-input disable v-model="selectedProfileInfo.annualUseDayHours" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-4 q-py-sm q-px-md">
                        <label>연간 밤 이용 시간[h/a]</label>
                        <q-input disable v-model="selectedProfileInfo.annualUseNightHours" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                </div>
            </div>

            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator> -->
            
            <div class="col-12">
                <label class="q-py-sm q-px-md">조명 계수</label>
                <div class="col-12 row" >
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>조명 점등 시간[h/d]</label>
                        <q-input disable v-model="selectedProfileInfo.lightingHours" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>요구 조도[lux]</label>
                        <q-input disable v-model="selectedProfileInfo.reqIlluminance" dense :dark="false" outlined square bg-color="white"></q-input>
                        
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>작업면 높이[m]</label>
                        <q-input disable v-model="selectedProfileInfo.workSurfaceHeight" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>조명 감소 계수[kA]</label>
                        <q-input disable v-model="selectedProfileInfo.lightReductCoeff" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>조명 상대적 부재율[CA]</label>
                        <q-input disable v-model="selectedProfileInfo.lightRelativeAbsenceRate" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>실내인덱스[k]</label>
                        <q-input disable v-model="selectedProfileInfo.indoorIndex" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>조명 운전 감소 계수[Ft]</label>
                        <q-input disable v-model="selectedProfileInfo.lightOperReductCoeff" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                    <div class="col-md-12 col-lg-6 q-py-sm q-px-md">
                        <label>수직면 기준 순응 계수[kVB]</label>
                        <q-input disable v-model="selectedProfileInfo.vertifaceLightCoeff" dense :dark="false" outlined square bg-color="white"></q-input>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="SimulationCommonRight col-xs-12 col-md-3">
        <div class="SimulationCommonRightContents row">
            <div class="headerLabel">
                <label>조정 정보</label>
            </div>

            <!-- <div class="col-12 row actionBtn q-px-md q-py-sm">
                <label>휴일 정보</label>
                <div class="row">
                    <q-btn square icon="add"></q-btn>
                    <q-btn square icon="remove" @click="deleteRow"></q-btn>
                </div>
            </div> -->
            
            <div class="col-12 row q-py-sm q-px-md" :style="{alignItems: 'center'}">
                <label :style="{marginRight: '10px'}">연도 : </label>
                <!-- <q-input v-model="selectedYear" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}"></q-input> -->
                <q-select class="monthInfoYear col-6" v-model="selectedYear" :options="yearsOption" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" :popup-content-style="{color: '#000000'}" map-options emit-value @update:model-value="changeYear"></q-select>
            </div>
            
            <div class="col-12 q-py-sm q-px-md">
                <q-table 
                :grid="$q.screen.md" 
                square
                class="col-12 adjustTable" 
                :columns="column" 
                :rows="row" 
                :row-key="'adjustMonth'"
                :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
                :rows-per-page-options="[0]" 
                :selected-rows-label="getSelectedString"
                dense 
                no-data-label="데이터가 존재하지 않습니다." 
                :table-header-style="{backgroundColor: '#f2f4fb', fontSize: '13px'}"
                :dark="false" 
                hide-pagination>
                
                    <template v-slot:body="props">
                        <tr :id="`adjustRow${props.rowIndex}`" :style="{textAlign: 'center', cursor: 'pointer'}" @click="rowSelect(props.rowIndex)">
                            <td>{{props.row.adjustMonth}}</td>
                            <td>{{props.row.adjustHolidayCnt}}</td>
                            <td>{{props.row.dayAdjustHour}}</td>
                            <td>{{props.row.nightAdjustHour}}</td>
                            <td>{{props.row.faultRate}}</td>
                        </tr>
                    </template>
                </q-table>
            
            </div>

            <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
            
            <div class="col-12 row">
                <!-- <div class="col-12 q-py-sm q-px-md" :style="{alignItems: 'center'}">
                    <label>조정 월 : </label>
                    <q-select v-model="selectedZoneByAdjust.adjustMonth" :options="adjustMonth" dense :dark="false" outlined square bg-color="white" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
                </div> -->
                <div class="col-6 q-py-sm q-px-md" :style="{alignItems: 'center'}">
                    <label>조정 휴일 수 [d/m] </label>
                    <q-input type="number" v-model.number="selectedZoneByAdjust.adjustHolidayCnt" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" :disable="selectedZoneByAdjust.adjustMonth === undefined"></q-input>
                </div>
                <div class="col-6 q-py-sm q-px-md" :style="{alignItems: 'center'}">
                    <label>고장율 [%] </label>
                    <q-input type="number" v-model.number="selectedZoneByAdjust.faultRate" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" :disable="selectedZoneByAdjust.adjustMonth === undefined"></q-input>
                </div>
                <div class="col-6 q-py-sm q-px-md" :style="{alignItems: 'center'}">
                    <label>주간 시간 [h/m] </label>
                    <q-input type="number" v-model.number="selectedZoneByAdjust.dayAdjustHour" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" :disable="selectedZoneByAdjust.adjustMonth === undefined"></q-input>
                </div>
                <div class="col-6 q-py-sm q-px-md" :style="{alignItems: 'center'}">
                    <label>야간 시간 [h/m] </label>
                    <q-input type="number" v-model.number="selectedZoneByAdjust.nightAdjustHour" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" :disable="selectedZoneByAdjust.adjustMonth === undefined"></q-input>
                </div>
                
                
                <div class="col-12 row justify-end q-py-sm q-px-md">
                    <q-btn flat class="addbtn" square @click="modifyAdjusts" :disable="selectedZoneByAdjust.adjustMonth === undefined">수정</q-btn>
                    <q-btn flat class="delbtn" square @click="modifyAdjustsCancel">취소</q-btn>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
    
<style lang="scss">
.SimulationCommonComponent{
    background-color: #f2f4fb;
    color: #404040 !important;
    height: 100%;
    .addbtn{
        width: 70px;
        margin-left: 10px;
        background-color: #3bb3ff;
        color: #ffffff;
    }
    .delbtn{
        width: 70px;
        margin-left: 10px;
        background-color: #172041;
        color: #ffffff;
        
    }
    .SimulationCommonLeft{
        max-width: 596px !important;
        height: 100%;
        border-right: 1px solid #c1c1c1;
        background-color: #e2e6f3;
        .SimulationCommonLeftContents{
            height: 100%;
            align-content: flex-start;
            font-size: 13px;
            .selectInputForm{
                color: black !important;
            }
            label{
                font-weight: bold;
            }

            .headerLabel{              
                background-color: #f2f4fb;
                height: 40px;
                color: #404040;
                border-bottom: 1px solid #c1c1c1;
                width: 100%;
                padding: 10px 15px 10px 15px;
                
                label{
                  font-size: 15px;
                  font-weight: bold;
                }
            }
            
            .SimulationCommonTable{
                background-color:#f2f4fb;
                color: black;
            }
            
            .SimulationCommonTableHeader{
                background-color: #c1c1c1;
            }
        }
        
        .infoBtn{
            width: 80px;
            margin-left: 10px;
        }
        
    }
    
    .SimulationCommonMiddle{
        max-width: 596px !important;
        height: 100%;
        border-right: 1px solid #c1c1c1;
        .SimulationCommonMiddleContents{
            height: 100%;
            align-content: flex-start;
            background-color: #e2e6f3;
            font-size: 13px;
            label{
                font-weight: bold;
                display: block;
            }
        }
        
        .headerLabel{
            background-color: #f2f4fb;
            color: #404040;
            border-bottom: 1px solid #c1c1c1;
            width: 100%;
            height: 40px;
            padding: 10px 15px 10px 15px;
            label{
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
    
    .SimulationCommonRight{
        min-width: 478px !important;
        height: 100%;
        .SimulationCommonRightContents{
            height: 100%;
            align-content: flex-start;
            background-color: #e2e6f3;
            font-size: 13px;
            label{
                font-weight: bold;
            }

            .actionBtn{
                justify-content: space-between;
                align-items: center;
            }

            .monthInfoYear{
                
            }
        }

        .adjustTable{
            th{
                font-size: 13px;
            }
            td{
                font-size: 11px;
            }
        }
        
        .headerLabel{
            background-color: #f2f4fb;
            color: #404040;
            border-bottom: 1px solid #c1c1c1;
            width: 100%;
            height: 40px;
            padding: 10px 15px 10px 15px;
            label{
                font-size: 15px;
                font-weight: bold;
            }
        }
            
    }
}
</style>