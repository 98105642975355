<script setup lang="ts">
import { ref } from "vue";
import type DevAttr from "@/models/entity/dev-attr";
import { useCommonStore } from "@/stores/common-store";
import type { QTableProps } from "quasar";
import DeviceAttrFormComponent from "./DeviceAttrFormComponent.vue";


const props = defineProps<{
    attrData: DevAttr[]
}>();

const commonStore = useCommonStore();

const selectRowData = ref();

const columnKey = Object.keys(props.attrData[0]);

const column: QTableProps["columns"] = [...columnKey.filter(key => ["modDate", "modUserId", "regDate", "regUserId"].includes(key) === false).map(v => {return {name: v, field: v, label: v, align: "center"};})] as QTableProps["columns"];



// [
//   {name: "baseAttrSeq", field: "baseAttrSeq", label: "Seq", align: "center" },
//   {name: "attrName", field: "attrName", label: "name", align: "center"},
//   {name: "attrType", field: "attrType", label: "type", align: "center"},
//   {name: "attrGrpCode", field: "attrGrpCode", label: "grpCode", align: "center"},
//   {name: "defaultVal", field: "defaultVal", label: "default", align: "center"},
//   {name: "maxLen", field: "maxLen", label: "maxLen", align: "center"},
//   {name: "maxVal", field: "maxVal", label: "max", align: "center"},
//   {name: "minVal", field: "minVal", label: "min", align: "center"},
//   {name: "step", field: "step", label: "step", align: "center"},
//   {name: "unit", field: "unit", label: "unit", align: "center"},
//   {name: "attrDesc", field: "attrDesc", label: "attrDesc", align: "center"},
// ];

function selectRow(ev: Event, row: object, index: number){
  selectRowData.value = row;   
}

</script>

<template>
    <div class="DeviceAttrComponent row">
        <div class="col-10" :style="{display: 'grid', padding: '15px', height: '-webkit-fill-available'}">
            <div class="DeviceAttrTable">
                <q-table
                :columns="column"
                :rows="props.attrData"
                row-key="baseAttrSeq"
                :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
                :rows-per-page-options="[0]" 
                :dark="false" 
                square 
                dense
                bordered
                hide-pagination
                @row-click="selectRow"
                >
                    <template v-slot:body-cell-attrGrpCode="props">
                        <!-- {{props}} -->
                        <q-td :style="{textAlign: 'center'}">
                            {{commonStore.getFindCodeToName('attrGrpCode', props.row.attrGrpCode)}}
                        </q-td>
                    </template>

                </q-table>
            </div>
            <div :style="{padding: '10px', display: 'flex', justifyContent: 'end'}">
                <q-btn square outline :dark="false" text-color="black">추가</q-btn>
                <q-btn square outline :dark="false">삭제</q-btn>
            </div>
        </div>

        <DeviceAttrFormComponent class="col-2" isSelect="true" :dev-attr-data="selectRowData"></DeviceAttrFormComponent>
    </div>
</template>

<style lang="scss">
.DeviceAttrComponent{
    display: flex;
    background-color: #f2f4fb;
    height: calc(100vh - 60px);
    padding: 20px;
    .DeviceAttrTable{
        overflow-y: scroll;
    }
}   
</style>