import { defineStore } from "pinia";
import Scene from "@/models/entity/scene";

import { useEditStore } from "./edit-store";
import { useUserStore } from "./user-store";
import SceneSetting from "@/models/entity/scene-setting";
import type { stringObject } from "@/global/types";
import type InDPoint from "@/models/group/in-d-point";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import ColorTemp from "@/settings/colortemp.json";
import { useDeviceStore } from "./device-store";
import type DevAttr from "@/models/entity/dev-attr";
import type GrpMember from "@/models/entity/grp_member";

export const useSceneStore = defineStore("sceneStore", {
  state: () => ({
    AllSceneInfo: [] as Scene[],
    SelectSceneInfo: null as Scene | null,
    SelectSceneSeq: null as number | null,
    selectSceneSetting: null as SceneSetting | null,
    SceneSettingGrpSeq: null as number | null,
    selectSceneLightAttrsMap: null as stringObject| null,
    selectSceneNodeId: null as number | null,
    attrMap: null as DevAttr[] | null,
  }),
  getters: {
    getSelectSceneSetting(state){
      return state.selectSceneSetting;
    }
  },
  actions: {
    initData(){
      this.AllSceneInfo = useEditStore().selectSpaceInfo?.scenes as Scene[];
      this.SelectSceneInfo = null;
    },
    setSelectSceneTreeNodeId(value: number){
      this.selectSceneNodeId = value;
    },
    resetScene(){
      this.AllSceneInfo = [] as Scene[];
      this.SelectSceneInfo = null as Scene| null;
      this.selectSceneSetting = null as SceneSetting | null;
      this.SceneSettingGrpSeq = null as number | null;
    },
    setSceneSettingGrpSeq(groupID: number | null){
      this.SceneSettingGrpSeq = groupID;

      if(groupID !== null){
        useEditStore().snapShot.sceneSelectGrpSeq = groupID;

        this.setSelectSceneSetting(groupID);
      }
    },
    setSelectSceneSetting(groupID: number){
      this.selectSceneSetting = this.SelectSceneInfo?.sceneSettings?.find(v => {
        if(v.grpSeq === groupID){
          return v;
        }
      }) || null;

      if(this.selectSceneSetting === null){
        this.selectSceneSetting = this.newSceneSetting(groupID);
      }

      this.selectSceneLightAttrsMap = this.setSelectSceneLightAttrsMap();
      
    },
    setSelectSceneLightAttrsMap(){
      const map = this.selectSceneSetting?.lightAttrsMap;
      if(map === "" || map === null || map === undefined){
        const newMap = {} as stringObject;
    
        useDeviceStore().selectGrpDevAttrList.forEach(v => {
          newMap[v.attrName as string] = v.defaultVal;
        });
        return newMap;
      }
      const formatJson = JSON.parse(map as string);
      return formatJson;
    },
    setSelectSceneInfo(sceneID: number | null) {
      console.log("::::: set select Scene Info :::::");

      if(sceneID === null){
        this.SelectSceneInfo = null;
        return;
      }

      this.setSelectSceneTreeNodeId(sceneID);

      this.SelectSceneSeq = sceneID;
      
      useEditStore().snapShot.sceneSeq = sceneID;

      const selectScene = this.AllSceneInfo.find((scene) => scene.sceneSeq === sceneID) as Scene;
      this.SelectSceneInfo = selectScene;

      const selectSceneSettingList = this.SelectSceneInfo.sceneSettings as SceneSetting[];
      for(let i = 0; i < selectSceneSettingList.length; i++){
        this.reflectSceneSetting(selectSceneSettingList[i].lightAttrsMap, selectSceneSettingList[i].grpSeq as number);
      }
    },
    setAllSceneInfo(scenes: Array<Scene>) {
      this.AllSceneInfo = scenes;
    },
    reflectSceneSetting(sceneSettingString: string, grpSeq: number){
      const editStore = useEditStore();
      // const dpointList = editStore.inDPointList as InDPoint[];

      // const elList = [] as HTMLElement[];

      // dpointList.forEach(point => {
      //   const el = document.getElementById(point.nodeID as string);
      //   elList.push(el as HTMLElement);
      // });

      // elList.map(el => {
      //   const shape = el.children[0] as HTMLElement;

      //   shape.style.fill = "#000000";
      // });

      let lightMap = {onoff: 0, level: 0, colortemp: 0};
      if(sceneSettingString === "" || sceneSettingString === null || sceneSettingString === undefined){
        lightMap = {onoff: 0, level: 0, colortemp: 0};
      } else {
        lightMap = JSON.parse(sceneSettingString);
      }

      // const lightMap = JSON.parse(sceneSettingString);

      const grpList = editStore.selectSpaceInfo?.ctrlGrps as CtrlGrp[];
      const inGrp = grpList.find(v => v.grpSeq === grpSeq);
      const grpPoint = editStore.getGroupInDPoints(inGrp as CtrlGrp);
      const GrpEl = [] as HTMLElement[];

      grpPoint.forEach(gpoint => {
        const el = document.getElementById(gpoint.nodeID as string);
        
        GrpEl.push(el as HTMLElement);
      });

      const colorTemp:{[x:string]: string} = ColorTemp;

      for(let i = 0; i < GrpEl.length; i++){
        
        const elChildren = GrpEl[i].children;
        // let shape = {} as HTMLElement;
        for(let j = 0; j < elChildren.length; j++){
          const child = elChildren[j] as HTMLElement;
          if(child.classList.length === 0){
            child.style.fill = `rgb${colorTemp[lightMap.colortemp]}`;
            child.style.filter = `brightness(${lightMap.level * 0.01})`;
            // shape = elChildren[i] as HTMLElement;
          }
        }
      }

      // GrpEl.forEach(element => {
      //   const elChildren = element.children;
      //   // let shape = {} as HTMLElement;
      //   for(let i = 0; i < elChildren.length; i++){
      //     const child = elChildren[i] as HTMLElement;
      //     if(child.classList.length === 0){
      //       child.style.fill = `rgb${colorTemp[lightMap.colortemp]}`;
      //       child.style.filter = `brightness(${lightMap.level * 0.01})`;
      //       // shape = elChildren[i] as HTMLElement;
      //     }
      //   }

   
      //   // console.log(lightMap, colorTemp[lightMap.colortemp]);
      //   // shape.style.fill = `rgb${colorTemp[lightMap.colortemp]}`;
      //   // shape.style.fill = `rgb(${(Math.floor(2.55 * lightMap.level)).toString()}, ${Math.floor(2.55 * lightMap.level)}, ${Math.floor(2.55 * lightMap.level)})`;
      //   // shape.style.filter = `brightness(${lightMap.level * 0.01})`;
        
     
      // });
    },

    setSceneParamter(value: number){
      this.setSceneSettingGrpSeq(value);
      useEditStore().setSelectGroupInfo(Number(value));

      this.attrMap = useDeviceStore().setGrpDevTypeCode(useEditStore().selectGroupInfo as CtrlGrp);
      
      for(let i = 0; i < Object.keys(this.selectSceneLightAttrsMap as stringObject).length; i++){
        const key = Object.keys(this.selectSceneLightAttrsMap as stringObject)[i];
    
        if(key === undefined) return;
    
        const props = {
          value: (this.selectSceneLightAttrsMap as stringObject)[key],
          target: key,
        };
    
        this.sceneAttrValueSet(props);
      }
    
      if(useEditStore().sceneGroupONOFF === false){
        const allGroups = document.querySelectorAll("[class*=groupBox]");
        (allGroups as unknown as HTMLElement[]).forEach(el => {
          el.style.display = "none";
        });
    
        const selectGroup = document.getElementsByClassName(`groupBox${value}`);
        (selectGroup[0] as HTMLElement).style.display = "block";
      }
    },
    sceneAttrValueSet(props: {value: string | number | null | undefined, target: string}){
      (this.selectSceneLightAttrsMap as stringObject)[props.target] = props.value;

      (this.selectSceneSetting as SceneSetting).lightAttrsMap = JSON.stringify((this.selectSceneLightAttrsMap as stringObject));

      // console.log(value);

      // console.log(selectSceneSetting.value?.lightAttrsMap);

      // console.log(lightMapValue);
      // console.log(props.target);
      const value = props.value as number;

      const selectGroupMember = useEditStore().selectGroupInfo?.members as GrpMember[];
      const memberDpoint = [] as InDPoint[];

      for(let i = 0; i < selectGroupMember.length; i++){
        const dpoint = useEditStore().inDPointList.find(v => v.dpointSeq === selectGroupMember[i].dpointSeq);
        memberDpoint.push(dpoint as InDPoint);
      }


      if(props.target === "level"){
        for(let i = 0; i < memberDpoint.length; i++){
          const dpointNode = (document.getElementById(memberDpoint[i].nodeID as string) as HTMLElement).children[0] as HTMLElement;
          dpointNode.style.filter = `brightness(${value * 0.01})`;
        }
      } else if(props.target === "colortemp"){
        const colortemp = ColorTemp as {[x:string]: string};
        for(let i = 0; i < memberDpoint.length; i++){
          const dpointNode = (document.getElementById(memberDpoint[i].nodeID as string) as HTMLElement).children[0] as HTMLElement;
          dpointNode.style.fill = `rgb${colortemp[value]}`;
        }
      }
  
      return props.value;
    },

    addNewScene(siteSeq: number, zoneSeq: number) {
      const newScene = new Scene();
      newScene.sceneSeq = this.AllSceneInfo.length === 0 ? 1 : this.AllSceneInfo[this.AllSceneInfo.length-1].sceneSeq as number + 1;
      newScene.sceneName = `씬 ${newScene.sceneSeq}`;
      newScene.sceneSettings = [] as SceneSetting[];
      newScene.siteSeq = siteSeq;
      newScene.zoneSeq = zoneSeq;
      newScene.modUserId = useUserStore().user?.userId;
      newScene.modDate = new Date().toISOString();
      newScene.regUserId = useUserStore().user?.userId;
      newScene.regDate = new Date().toISOString();

      this.AllSceneInfo.push(newScene);
    },
    newSceneSetting(grpSeq: number) {
      const newSceneSetting = new SceneSetting();

      newSceneSetting.siteSeq = this.SelectSceneInfo?.siteSeq;
      newSceneSetting.sceneSeq = this.SelectSceneInfo?.sceneSeq;
      newSceneSetting.zoneSeq = this.SelectSceneInfo?.zoneSeq;
      newSceneSetting.grpSeq = grpSeq;
      newSceneSetting.modDate = new Date().toISOString();
      newSceneSetting.modUserId = useUserStore().user?.userId;
      newSceneSetting.regDate = new Date().toISOString();
      newSceneSetting.regUserId = useUserStore().user?.userId;
      newSceneSetting.lightAttrsMap = "";

      this.SelectSceneInfo?.sceneSettings?.push(newSceneSetting);

      return newSceneSetting;
    },
    removeScene(sceneSeq: number){
      for(let i = 0; i < this.AllSceneInfo.length; i++){
        if(this.AllSceneInfo[i].sceneSeq === sceneSeq){
          this.SelectSceneInfo = null;
          this.selectSceneSetting = null;
          this.SceneSettingGrpSeq = null;
          this.AllSceneInfo.splice(i, 1);
          break;
        }
      }
    },
    removeSceneInGrp(grpSeq: number){
      for(let i = 0; i < this.AllSceneInfo.length; i++){
        const sceneSetting = this.AllSceneInfo[i].sceneSettings as SceneSetting[];

        for(let j = 0; j < sceneSetting.length; j++){
          if(sceneSetting[j].grpSeq === grpSeq){
            sceneSetting.splice(j, 1);
            break;
          }
        }
      }
    }
  },
});
