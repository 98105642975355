import { Type } from "class-transformer";
import "reflect-metadata";
import type Compo from "./compo";
import type CtrlGrp from "./ctrl-grp";
import type CZone from "./czone";
import type DPoint from "./d-point";
import type Face from "./face";
import type ImageFile from "./image-file";
import type ModelFile from "./model-file";
import type RZone from "./rzone";
import type Scenario from "./scenario";
import type Scene from "./scene";
import type UZone from "./uzone";
import type ZoneSmlAdjust from "./zone-sml-adjust";
import type ZoneSimul from "./zone-sml-type";

/**
 * 단위 혹은 묶음 공간 클래스
 * @class
 */
class Zone {
  siteSeq = 0; // 연결 site ID;
  zoneSeq = 0; // zone ID
  pzoneSeq: number | null = null; // 부모 zone ID;
  zoneName: string | null = null; // zone 명칭
  zoneTypeCode: string | null = null; // zone 구분 코드 (대표, 묶음, 단위)
  loc?: string;
  totalFloorArea?: number;
  azimuthCode?: string;
  originX = 0;
  originY = 0;
  originZ = 0;
  horizSize = 0;
  vertiSize = 0;
  boundsPath: string | null = null;
  area = 0;
  height = 0;
  planImageFileSeq: number | null = null;
  modelFile?: ModelFile | null;
  modelFileSeq?: number | null;
  rzone?: RZone;
  uzone?: UZone;
  czone?: CZone;
  regDate: Date | null = new Date();
  regUserId = 0;
  modDate: Date | null = new Date();
  modUserId = 0;
  scenarios?: Scenario[];
  scenes?: Scene[];
  compos?: Compo[]; 
  ctrlGrps?: CtrlGrp[];
  dpoints?: DPoint[];
  faces?: Face[];
  rangeOfFloors?: string;
  modelFileGrp?: string;

  @Type(() => Zone)
    childZones: Array<Zone> = new Array<Zone>();


  constructor(){
    this.dpoints = [] as DPoint[];
    this.faces = [] as Face[];
    this.ctrlGrps = [] as CtrlGrp[];
    this.scenarios = [] as Scenario[];
    this.scenes = [] as Scene[];
    this.compos = [] as Compo[];
  }
}
export default Zone;
