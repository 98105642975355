import { EditorMode } from "@/global/enums";

/**
 * 에디터 정보 클래스
 * @class
 */
class EditorSetting {  
  public scale = 1;
  public snap = 10;  
  public dragBoxInfo;
  public gridSetting;

  public editorMode = EditorMode.None;  

  constructor(gridSetting: GridSetting) {
    this.gridSetting = gridSetting;
    this.dragBoxInfo = new DragBoxInfo();        
  }
}

class GridSetting {
  public pageWidth;
  public pageHeight;
  public majorGridSize;
  public minorGridSize;
  public majorGridStrokeSize;
  public minorGridStrokeSize;
  public majorGridStrokeColor;
  public minorGridStrokeColor;

  constructor(
    pageWidth: number,
    pageHeight: number,
    majorGridSize = 100,
    minorGridSize = 10,
    majorGridStrokeSize = 4,
    minorGridStrokeSize = 1,
    majorGridStrokeColor = "#FF0000",
    minorGridStrokeColor = "#FFFFFF"
  ) {

    this.pageWidth = pageWidth;
    this.pageHeight = pageHeight;
    this.majorGridSize = majorGridSize;
    this.minorGridSize = minorGridSize;
    this.majorGridStrokeSize = majorGridStrokeSize;
    this.minorGridStrokeSize = minorGridStrokeSize;
    this.majorGridStrokeColor = majorGridStrokeColor;
    this.minorGridStrokeColor = minorGridStrokeColor;
  }
}

/**
 * 드래그 박스(선택 박스) 정보 클래스
 * @class
 */
class DragBoxInfo {

  public ox = 0;
  public oy = 0;
  public x = 0;
  public y = 0;
  public w = 0;
  public h = 0;

  initPosition(scale: number, e:MouseEvent) {
    this.ox = e.offsetX / scale;
    this.oy = e.offsetY / scale;
    this.x = e.offsetX / scale;
    this.y = e.offsetY / scale;
    this.w = 0;
    this.h = 0;
  }

  /**
   * DragBox 좌표 업데이트
   * @param e 마우스 이벤트
   */
  updatePosition(scale: number, e: MouseEvent) {
    
    const ox = this.ox;
    const oy = this.oy;
    let x = this.x;
    let y = this.y;
    let w, h = 0;

    const scaledoffsetX = e.offsetX / scale;
    const scaledoffsetY = e.offsetY / scale;

    if (scaledoffsetX < ox) {
      x = scaledoffsetX;
      w = ox - x;
    } else {
      w = scaledoffsetX - x;
    }
    if (scaledoffsetY < oy) {
      y = scaledoffsetY;
      h = oy - y;
    } else {
      h = scaledoffsetY - y;
    }
    this.x = x;
    this.y = y;
    this.w = w;
    this.h= h;

    //console.log(`DragBox updatePosition -> x: ${x}, y: ${y}, w:${w}, h: ${h}`);
    
  }

  get x2(): number {
    return this.x + this.w;
  }
  get y2(): number {
    return this.y + this.h;
  }
}

export { EditorSetting as EditorInfo, GridSetting, DragBoxInfo };