import { TimeType } from "@/global/enums";
import ScenarioNodeEvent from "@/models/entity/scenario-node-event";
import CommonUtils from "@/utils/common-util";
import { NodeItem, type INodeItemEvent } from "../info/node-item-info";
import type { IPackageNodeSetting } from "./node-setting";

class ScenarioTimeBranchNodeSetting implements IPackageNodeSetting {

  public timeEvents: TimeBranchEvent[] = [];
  
  constructor() {
    
  }

  /**
   * 노드 아이템을 가져온다. (노드 아이템 = 이벤트)
   * @returns 
   */
  getNodeItems(): NodeItem[] {

    const nodeItems: NodeItem[] = [];    

    for (let i = 0; i < this.timeEvents.length; i++) {
      const timeSetting = this.timeEvents[i];

      const nodeItem = new NodeItem(i.toString(), timeSetting.name, "");
      nodeItems.push(nodeItem);
    }

    return nodeItems;
  }

  /**
   * 이벤트 추가 및 갱신
   * @param event 
   */
  addAndReplaceEvent(event: TimeBranchEvent) {

    let index = this.timeEvents.findIndex(s => s.code === event.code);
    if (index > -1) {
      this.timeEvents.splice(index, 1);
    } else {
      index = this.timeEvents.length;
    }
    this.timeEvents.splice(index, 0, event);
  }

  /**
   * 이벤트 삭제
   * @param code 
   */
  deleteEvent(code: string) {
    const index = this.timeEvents.findIndex(s => s.code === code);
    this.timeEvents.splice(index, 1);
  }
}

class TimeBranchEvent implements INodeItemEvent{
  public code: string;
  public name: string;  
  public startTime: string;
  public endTime: string;

  public startTimeType;
  public endTimeType;
  
  constructor(code: string,  name: string, startTimeType:TimeType, endTimeType:TimeType, startTime: string, endTime: string) {
    this.code = code;
    this.name = name;    
    this.startTimeType = startTimeType;
    this.endTimeType = endTimeType;
    this.startTime = startTime;
    this.endTime = endTime;    
  }

  /**
   * 이벤트 발생조건 스트링으로 변환한다.
   * @returns 
   */
  makeEventOccurCond() {

    const startDateString = CommonUtils.toDateString(this.startTimeType, this.startTime);
    const endDateString = CommonUtils.toDateString(this.endTimeType, this.endTime);

    return `${startDateString}-${endDateString}`;
  }

  /**
   * 이벤트 발생조건 스트링을 TimeBranchEvent에 맞는 값으로 리턴
   * @param eventOccurCond 
   * @returns 
   */
  static solveEventOccurCond(eventOccurCond: string): [TimeType, TimeType, string, string] {

    const s = eventOccurCond.split("-");

    const startTimeType = (s[0] === TimeType.Sunrise) || (s[0] === TimeType.SunSet) ? s[0] as TimeType : TimeType.Custom;        
    const endTimeType = (s[1] === TimeType.Sunrise) || (s[1] === TimeType.SunSet) ? s[1] as TimeType : TimeType.Custom;
    
    const startTime = startTimeType === TimeType.Custom ? s[0] : "00:00";
    const endTime = endTimeType === TimeType.Custom ? s[1] : "00:00";

    return [startTimeType, endTimeType, startTime, endTime];
  }
}

export default ScenarioTimeBranchNodeSetting;
export { TimeBranchEvent };