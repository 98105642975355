import { defineStore } from "pinia";
import settingObj from "@/settings/tempSetting_v4.json";
import { useSiteStore } from "@/stores/site-store";
import SiteSetting from "@/models/site-setting";
import CommonUtils from "@/utils/common-util";
import Site from "@/models/site";
import type Settings from "@/models/setting";

export const useSettingStore = defineStore("settingStore", {
  state: () => ({
    userSetting: {} as Settings,
  }),
  actions: {
    initSetting() {
      this.userSetting.ModelHelper = false;
    },
    async load() {
      try {
        // const json = JSON.stringify(settingObj, null, 2);
        // const setting = this.deserialize(json);
        // console.log(setting);
        // const siteStore = useSiteStore();

        // siteStore.setSiteList(setting as Array<Site>);
        // const editStore = useEditStore();

        // editStore?.setDefaultSiteInfo(setting ?? {});
        // editStore?.setFirstSiteInfo(setting ?? {});

        // const codes = await this.apiServices.settingApiService.getCodes();
        // const appInfo = await this.apiServices.settingApiService.getAppInfo();
        // const loginInfo = await this.apiServices.settingApiService.login();
      } catch (e) {
        throw new Error("Method not implemented.");
      }
    },
    /*
     */
    /**
     * SiteSetting 클래스를 복제한다.
     * @param siteSetting
     * @returns
     */
    clone(siteSetting: Site) {
      return CommonUtils.deepClone(siteSetting);
    },

    /**
     * SiteSetting를 Json으로 변환
     * @param siteSetting
     * @returns
     */
    serialize(siteSetting: Site) {
      return JSON.stringify(siteSetting, null, 2);
    },
    deserialize(json: string) {
      try {
        const siteSetting = CommonUtils.jsonDeserialize<Site>(Site, json);

        return siteSetting;
      } catch (e) {
        throw new Error("Method not implemented.");
      }
    },
  },
});
