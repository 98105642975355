import { DeviceKind, DeviceShape, HttpMethods, NodeElementType, NodeType, SensorType } from "@/global/enums";
import LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import ConfigGroup from "@/models/editor/nodes/config/config-group";
import type Code from "@/models/entity/code";
import DevAset from "@/models/entity/dev-aset";
import DevAttr from "@/models/entity/dev-attr";
import DevModel from "@/models/entity/dev_model";
import LightDeviceType from "@/models/entity/light-device-type";
import SensorDevice from "@/models/entity/sensor-device";
import type { RequestParameter } from "@/net/rest-client";
import type RestClient from "@/net/rest-client";
import CommonUtils from "@/utils/common-util";
import BaseApiService from "./base-api-service";

class DeviceApiService extends BaseApiService{
  constructor(restClient: RestClient) {
    super(restClient);    
  }

  async getSensorDevice(sensorType: SensorType) {

    const sensorDevices: SensorDevice[] = [];

    if (sensorType === SensorType.Presence) {
      const device1 = new SensorDevice(1, "인지 센서-107", SensorType.Presence);
      const device2 = new SensorDevice(2, "인지 센서-108", SensorType.Presence);

      sensorDevices.push(device1);
      sensorDevices.push(device2);
    }
    else {
      const device3 = new SensorDevice(3, "조도 센서-109", SensorType.ILLumiation);
      const device4 = new SensorDevice(4, "조도 센서-110", SensorType.ILLumiation);

      sensorDevices.push(device3);
      sensorDevices.push(device4);
    }


    return sensorDevices;
  }

  async getDeviceTypes() {

    try {
      const requestParams: RequestParameter = {
      };

      const res = await this.restClient.call<LightDeviceType[]>(HttpMethods.Get, "/v1/dev-types", requestParams, LightDeviceType);      

      const deviceTypes = res.data;

      // console.log(`getDeviceTypes => ${JSON.stringify(res.data, null, 2)}`);

      return deviceTypes;
    } catch {
      //throw new Error("getDeviceTypes call Error");
      console.log("getDeviceTypes call Error");
      return [];
      
    }
  }

  async getAllDeviceTypes(){
    try{

      const res = await this.restClient.call<LightDeviceType[]>(HttpMethods.Get, "/v1/dev-types", undefined, LightDeviceType);

      return res.data;

    } catch {
      console.log("getAllDeviceType call ERROR");
      return [];
    }
  }

  async getDeviceTypeCode(devTypeCode: string | null = null){
    try {
      
      const res = await this.restClient.call<LightDeviceType>(HttpMethods.Get, `/v1/dev-types/${devTypeCode}`, undefined, LightDeviceType);

      return res.data;

    } catch{
      console.log("getDeviceTypeCode call ERROR");
      return {};
    }
  }

  /**
   * 디바이스 모델 정보 API
   * @returns 
   */
  async getDeviceModel(){
    try {
      const res = await this.restClient.call<DevModel[]>(HttpMethods.Get, "/v1/dev-models", undefined, DevModel);

      // const deviceModel = res.data.filter(d => d.dev)

      return res.data;
    } catch {
      console.log("getdevModel error");
      return [];
    }
  }


  async getDeviceConfigGroups(devTypeCodes: Code[]) {

    const deviceModels = await this.getDeviceModel();
    const deviceTypes = await this.getAllDeviceTypes();

    const deviceConfigGroups: ConfigGroup[] = [];

    const sortedDevTypeCodes = devTypeCodes.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return a.sortOrder! - b.sortOrder!;
    });

    for(const devTypeCode of sortedDevTypeCodes) {
        
      const deviceConfigGroup = new ConfigGroup();
      deviceConfigGroup.id = devTypeCode.code;
      deviceConfigGroup.title = `${devTypeCode.codeName}`;
      deviceConfigGroups.push(deviceConfigGroup);

      for(const deviceModel of deviceModels) {

        const deviceType = deviceTypes.find(d => d.devTypeCode === deviceModel.devTypeCode);

        if (CommonUtils.isNullOrEmpty(deviceType))
          continue;

        if(devTypeCode.code !== deviceType.devClsfyCode)
          continue;

        const id = deviceModel.devTypeCode as string;
        const name = deviceModel.devModelName;
        const nodeType = deviceType.modelDisplayType === "SHP" ? NodeType.Shape : NodeType.Image;
        const shapeType = DeviceShape[deviceType.shapeType as keyof typeof DeviceShape];
        const nodeElementType = NodeElementType.Device;
        const lightType = deviceModel.devTypeCode;
        const imageUrl = deviceModel.imageFileSeq === null ? "null" : `${import.meta.env.VITE_API_URL}/v1/imagefiles/${deviceModel.imageFileSeq}`;
        const consumpPower = deviceModel.consumpPower as number;
        const standbyPower = deviceModel.standbyPower as number;
        const devClsfyCode = deviceType.devClsfyCode as string;
        const lightConfig = new LightNodeConfig(id, name ?? "", nodeType, nodeElementType, 15, 15, 0, 0, lightType, "", imageUrl, shapeType, consumpPower, standbyPower, devClsfyCode);

        deviceConfigGroup.configs.push(lightConfig);
      }
    } 
    
    return deviceConfigGroups;
  }

  async addDevcieModel(devModel: DevModel | null = null){
    const test = {
      "consumpPower": 100,
      "devModelName": "미등",
      "devModelSeq": 2,
      "devTypeCode": "FL-R-W345xL185xH165",
      "energyConsumes": [
        {
          "consumpPower": 100,
          "devModelSeq": 2,
          "level": 100
        }
      ],
      "imageFileSeq": null,
      "manufacturer": "제조사2",
      "modDate": "2022-09-29T02:00:54.762Z",
      "modUserId": 7,
      "productCode": "LIGHTNO_2",
      "regDate": "2022-09-29T02:00:54.762Z",
      "regUserId": 7,
      "standbyPower": 50
    };


    try{
      const requestParams: RequestParameter = {
        body: JSON.stringify(test),
      };

      const res = await this.restClient.call(HttpMethods.Post, "/v1/dev-models", requestParams);

      return res.data;

    } catch {

    }


  }

  async getDevAsets(){
    const res = await this.restClient.call<DevAset[]>(HttpMethods.Get, "v1/dev-asets",undefined, DevAset);

    return res.data;
  }

  async getDevAttrs(){
    const res = await this.restClient.call<DevAttr[]>(HttpMethods.Get, "v1/dev-attrs", undefined, DevAttr);
    console.log(res);
    return res.data;
  }

  async getDevAsetCode(asetCode: string){
    const res = await this.restClient.call<DevAset>(HttpMethods.Get, `v1/dev-asets/${asetCode}`, undefined, DevAset);

    return res.data;
  }
}

export default DeviceApiService;