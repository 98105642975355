<script setup lang="ts">

import { ref } from "vue";
import { Chart, registerables } from "chart.js";
import SimulationCommonTabComponent from "./SimulationCommonTabComponent.vue";
import SimulationTrandLedComponent from "./SimulationTrandLedComponent.vue";
import SimulationWindoorsComponent from "./SimulationWindoorsComponent.vue";
import { useSimulationStore } from "@/stores/simulation-store";


const tabs = ref("공통");

Chart.register(...registerables);

defineProps({
  isLeftOpen: Boolean,
});

</script>

<template>
  <div class="SimulationContentsComponent fit">
    <q-tabs
    v-model="tabs"
    indicator-color="transparent"
    class="text-white shadow-2 tabsClass"
    active-class="tabsActiveClass"
    content-class="tabsContentsClass"
    align="left"
    :narrow-indicator="true"
    dense
    outside-arrows
    >
    <q-tab class="tabBtnClass" name="공통" label="공통"/>
    <q-tab class="tabBtnClass" name="창호" label="창호" />
    <q-tab class="tabBtnClass" name="전통조명" label="전통조명" />
    <q-tab class="tabBtnClass" name="LED조명" label="LED조명" />
    <q-tab class="tabBtnClass" name="스마트조명" label="스마트조명" />
  </q-tabs>
  
  <q-tab-panels class="tabsPanel" v-model="tabs" :dark="false">
    <q-tab-panel class="SimulationPanel"  name="공통">
      <SimulationCommonTabComponent></SimulationCommonTabComponent>
    </q-tab-panel>
    <q-tab-panel class="SimulationPanel"  name="창호">
      <SimulationWindoorsComponent></SimulationWindoorsComponent>
    </q-tab-panel>
    <q-tab-panel class="SimulationPanel" name="전통조명">
      <SimulationTrandLedComponent :simulation-type="'TL'"></SimulationTrandLedComponent>
    </q-tab-panel>
    <q-tab-panel class="SimulationPanel" name="LED조명">
      <SimulationTrandLedComponent :simulation-type="'LL'"></SimulationTrandLedComponent>
    </q-tab-panel>
    <q-tab-panel class="SimulationPanel" name="스마트조명">
      <!-- <SimulationSmartLightComponent></SimulationSmartLightComponent> -->
      <SimulationTrandLedComponent :simulation-type="'SL'"></SimulationTrandLedComponent>
      
    </q-tab-panel>
  </q-tab-panels>
  
  
  <!-- <TopBarComponent></TopBarComponent> -->
  <!-- <div :style="{ padding: '50px' }">
    <div class="row">
      <div class="col-6">
        <apexchart type="line" :options="apexOptions" :series="apexData"></apexchart>
        
      </div>
      <div class="col-6">
        <apexchart type="bar" :options="apexOptions2" :series="apexData"></apexchart>
        
      </div>
      <div class="col-6">
        <DoughnutChart v-bind="doughnutChartProps" />
      </div>
      <BarChart class="col-6" v-bind="barChartProps" />
      <LineChart class="col-6" v-bind="lineChartProps" />
      <DoughnutChart class="col-6" v-bind="doughnutChartProps" />
      <DoughnutChart class="col-6" v-bind="doughnutChartProps" />
      <DoughnutChart class="col-6" v-bind="doughnutChartProps" />
    </div>
  </div>
  
  <q-btn z-max class="leftButton" z-top unelevated color="primary"
  :icon="isLeftOpen === true ? `chevron_left` : 'chevron_right'" @click="collapseOpenClose('left')"
  :style="{ position: 'fixed', left: '0', top: '50%', zIndex: '20', width: '5px' }" />
-->
</div> 
</template>

<style lang="scss">
.SimulationContentsComponent {
  background-color: #0f1837;
  // border-top: 1px solid #000000;
  
  .tabsActiveClass{
    background-color: #f2f4fb;
    color: #404040;
    .q-tab__label{
      font-weight: bold;
    }
  }
  
  .tabsContentsClass{
    background-color: #0f1837;
    color: #f2f4fb;
    .q-tab__label{
      font-weight: bold;
    }
  }
  
  .tabsClass{
    border: 0px;
    background-color: #0f1837;
    display: block;
    
    .tabBtnClass{
      min-width: 96px;
      width: 96px;
      height: 34px;
      color: #515f93;
      border-radius: 10px 10px 0px 0px;
      font-weight: bold;
    }

    .q-tabs__arrow{
      background-color: #0f1837;
    }
  }
  
  .tabsPanel{
    height: calc(100% - 36px);
    .SimulationPanel{
      padding: 0;
      background-color: #f2f4fb;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
