import type RestClient from "@/net/rest-client";

/**
 * 기본 API 서비스 (추상 클래스)
 * @abstract
 */
abstract class BaseApiService {
  protected restClient;
  constructor(restClient: RestClient) {
    this.restClient = restClient;
  }
}

export default BaseApiService;